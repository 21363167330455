import React, { useEffect, useState } from "react";
import Checkbox from '@mui/material/Checkbox';
import { apis } from "../../configuration/configurationAPI";
import axios from "axios";
import { Navigate, useNavigate, useParams } from "react-router-dom";
import { Link } from "react-router-dom";
import { Alert, Snackbar, ClickAwayListener, Select, MenuItem, Button } from "@mui/material";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import { commonActions } from "../../utils/commonstore";
import store from "../../utils/index";
import { useSelector } from 'react-redux';
import { commonSelectors } from '../../utils/commonstore';

const dispatch = store.dispatch;
let commonStore = store.getState().commonStore;
store.subscribe(function () {
  commonStore = store.getState().commonStore;
});
const formatDate = (date) => {
    const options = { month: '2-digit', day: '2-digit', year: 'numeric' };
    return date.toLocaleDateString(undefined, options);
};
function ReassignPermissionsSet() {
    const today = new Date();
    const Navigate = useNavigate();
    const { profileId } = useParams();
    const [permissionsetName, setPermissionsetName] = useState(formatDate(today));
    const [fromDate, setFromDate] = useState(null);
    // console.log(fromDate, "fromdate")
    const [toDate, setToDate] = useState(null);
    const [permissions, setPermissions] = useState(null);
    const [editPermissions, setEditPermission] = useState("");
    const [userData, setUserData] = useState([]);
    const [selectedUser, setSelectedUser] = useState(0);
    const [error, setError] = useState(null);
    const [snackbar, setSnackbar] = useState({
        open: false,
        message: "",
        severity: "success",
    });
    const baererToken = useSelector(commonSelectors.selectBaererToken);
    const loggedinId = useSelector(commonSelectors.selectLoginUserId);
    const userInfo = useSelector(commonSelectors.selectUserInfo);
    const loginUserId = useSelector(commonSelectors.selectLoginUserId);
    useEffect(() => {
        axios.get(apis.base + apis.GET_PROILE_PERMISSIONID_API + profileId, {
            headers: {

                userid: loggedinId,
                Authorization:"Bearer "+ baererToken

            }
        })
            .then((response) => {
                const { dashboard, profiles, audit, forms, submitted_files, roles, users, departments, permission_list, locations,  reports, exports, profile_name, valid_from, valid_to } = response.data;
                setPermissions({ dashboard, profiles, audit, forms, submitted_files, roles, users, departments, permission_list, locations,  reports, exports });
                // setError(null);
                setPermissionsetName(response.data.profile_name);
                setFromDate(new Date(valid_from).toISOString().split('T')[0]);
                setToDate(new Date(valid_to).toISOString().split('T')[0]);
            })
            .catch((error) => {
                console.error("Error fetching profile details:", error);
                setError("Error while fetching profile details. Please try again.");
            });
        axios.get(apis.base + apis.GET_USER_API, {
            headers: {

                userid: loggedinId,
                Authorization:"Bearer "+ baererToken

            }
        })
            .then((response) => {
                setUserData(response.data);
            })
            .catch((error) => {
                console.error("Error fetching user data:", error);
            });
    }, [profileId]);

    const handleCheckboxChange = (key, index, isChecked) => {
        setEditPermission((prevEditPermissions) => {
            const updatedPermissions = { ...prevEditPermissions };
            if (!updatedPermissions[key]) {
                updatedPermissions[key] = { ...permissions[key] };
            }
            updatedPermissions[key][index] = isChecked ? '1' : '0';
            // console.log(updatedPermissions);
            return updatedPermissions;
        });
    };

    const handleUserChange = (e) => {
        const selectedUserId = e.target.value;
        setSelectedUser(selectedUserId);
        setError(null);
    };
    const flattenObjectValues = (obj) => {
        return Object.values(obj || {}).join('');
    };
    const handleReassignPermissions = () => {
        if (selectedUser === 0) {
            setError("Please select a user before submitting.");
            return;
        }
        const flattenedPermissions = {};

        for (const [key, value] of Object.entries(editPermissions)) {
            if (typeof value === 'object') {
                flattenedPermissions[key] = flattenObjectValues(value);
            } else {
                flattenedPermissions[key] = value;
            }
        }


        const payload = {
            // profile_id: -1,
            profile_name: permissionsetName,
            is_deleted: false,
            type: "permission_set",
            valid_from: fromDate,
            valid_to: toDate,
            created_by: loggedinId,
            updated_by:loggedinId,
            assigned_to: selectedUser,
            ...flattenedPermissions,


        };
        axios.post(apis.base + apis.POST_PROFILE_API, payload, {
            params: {
                postType: 'post'
            },
            headers: {

                userid: loggedinId,
                Authorization:"Bearer "+ baererToken

            }
        })
            .then((response) => {
                setSnackbar({
                    open: true,
                    message: 'Submitted successfully',
                    severity: 'success',
                });
            })
            .catch((error) => {
                console.error("Error reassigning profile:", error);
                setSnackbar({
                    open: true,
                    message: 'Submission failed',
                    severity: 'error',
                });
            });
    };

    const handleSnackbarClose = () => {
        Navigate("/permissionsetlist")
        setSnackbar({ ...snackbar, open: false });
    };

    return (
        <div>
            <div className="page-inner">
                <div className="panel panel-white">
                    <div className="page-title">
                        <h3>Permission Set Clone</h3>
                        <div className="page-breadcrumb">
                            <ol className="breadcrumb">
                                <li><Link to="/Dashboard">Home</Link></li>
                                <li><Link to="/permissionsetlist">Permission Set List</Link></li>
                                <li>Permission Set Clone</li>
                            </ol>
                        </div>
                    </div>
                    <ClickAwayListener onClickAway={() => { }}>
                        <Snackbar
                            open={snackbar.open}
                            onClose={() => setSnackbar({ ...snackbar, open: false })}
                            style={{ position: 'fixed', top: '15%', left: '50%', transform: 'translate(-50%, -50%)' }}
                        >
                            <Alert onClose={handleSnackbarClose} severity={snackbar.severity} style={{ width: '300px', height: '40px', fontSize: '12px' }}>
                                {snackbar.message}
                            </Alert>
                        </Snackbar>
                    </ClickAwayListener>
                    <div className="card-header card-header-secondary">
                        <div className="forms-sample">
                            <div className="row" style={{ marginLeft: "0px", marginRight: "0px", marginTop: "10px" }}>
                                <div className="custom-dropdown col-md-3">
                                    <label>User Name</label>
                                    <Autocomplete

                                        options={userData}
                                        getOptionLabel={(user) => user.u_user_name}
                                        value={userData.find((user) => user.u_user_id === selectedUser) || null}
                                        onChange={(e, newValue) => {
                                            setSelectedUser(newValue ? newValue.u_user_id : 0);
                                            setError(null);
                                        }}

                                        renderInput={(params) => (
                                            <TextField {...params} label="Select User"
                                                variant="outlined" x />

                                        )}
                                    />
                                </div>
                                <div className="custom-dropdown col-md-2">
                                    <label>Profile Name</label>
                                    <TextField
                                        type="text"
                                        variant="outlined"
                                        fullWidth
                                        value={permissionsetName}
                                        onChange={(e) => setPermissionsetName(e.target.value)}
                                        inputProps={{ style: { height: "3px" } }}
                                    />
                                </div>
                                <div className="col-md-2">
                                    <label htmlFor="toDate" className="form-label">From Date</label>
                                    <DatePicker
                                        id="toDate"
                                        className="form-control"
                                        selected={fromDate}
                                        isClearable
                                        placeholderText="MM/dd/yyyy"
                                        showYearDropdown
                                        scrollableYearDropdown
                                        onChange={(date) => setFromDate(date)}
                                        dateFormat="MM/dd/yyyy"
                                    />
                                </div>
                                <div className="col-md-2">
                                    <label htmlFor="toDate" className="form-label">To Date</label>
                                    <DatePicker
                                        id="toDate"
                                        className="form-control"
                                        selected={toDate}
                                        isClearable
                                        placeholderText="MM/dd/yyyy"
                                        showYearDropdown
                                        scrollableYearDropdown
                                        onChange={(date) => setToDate(date)}
                                        dateFormat="MM/dd/yyyy"
                                    />
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="panel-body">
                        {error && <p style={{ color: "red", marginLeft: "30%" }}>{error}</p>}
                        {!error && (
                            <div className="table-responsive">
                                {permissions === null ? (
                                    <p>Loading permissions...</p>
                                ) : permissions ? (
                                    <table id="example" className="display table" style={{ width: "100%" }}>
                                        <thead style={{ backgroundColor: "rgb(35 212 182)" }}>
                                            <tr>
                                                <th> </th>
                                                <th>VIEW</th>
                                                <th>CREATE</th>
                                                <th>EDIT</th>
                                                <th>DELETE</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {Object.entries(permissions).map(([key, permissionValue]) => (
                                                <tr key={key}>
                                                    <td>{key.toUpperCase()}</td>
                                                    {typeof permissionValue === "string" ? (
                                                        permissionValue.split("").map((value, index) => {
                                                            if (!editPermissions[key] || (editPermissions[key][index] == undefined))
                                                                (value == "1") && handleCheckboxChange(key, index, value)
                                                            return (
                                                                <td key={index}>
                                                                    <Checkbox
                                                                        checked={(editPermissions[key] && editPermissions[key][index] === "1")}
                                                                        onChange={(e) => handleCheckboxChange(key, index, e.target.checked)}
                                                                        style={{
                                                                            color: value == "1" ? "blue" : "inherit",

                                                                        }}
                                                                    />
                                                                </td>
                                                            )
                                                        })
                                                    ) : (
                                                        <td colSpan="5">Invalid permissions data</td>
                                                    )}
                                                </tr>
                                            ))}
                                        </tbody>
                                    </table>
                                ) : (
                                    <p>No permissions found</p>
                                )}
                            </div>
                        )}
                        <div className="panel-body" style={{ display: 'flex' }}>
                            <div>
                                <Button variant="contained" type="button" className="btn btn-primary" style={{ backgroundColor: "#22baa0", marginLeft: '20px',color:"white" }} onClick={handleReassignPermissions}>Submit</Button>
                            </div>
                            <div>
                                <Link to="/permissionsetlist">
                                    <Button type="button" variant="contained" className="btn btn-success" style={{ marginLeft: '30px', backgroundColor: "#34425a" }} >Cancel</Button>
                                </Link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default ReassignPermissionsSet;
