
import React, { useState, useEffect } from 'react';
import { Link, useNavigate, useParams } from "react-router-dom";
import axios from 'axios';
import { apis } from '../../configuration/configurationAPI';
import Accordion from '@mui/material/Accordion';
import AccordionActions from '@mui/material/AccordionActions';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Select from '@mui/material/Select';
import Box from '@mui/material/Box';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import { Button, TextField } from "@mui/material";
import FileUploadButton from '../../Components/FileSubmitButton/FileUploadButton';
import Autocomplete from '@mui/material/Autocomplete';
import MuiAlert from '@material-ui/lab/Alert';
import { Snackbar } from "@mui/material";
import DatePicker from 'react-datepicker';
import { IconButton } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import { useSelector } from 'react-redux';
import { commonSelectors } from '../../utils/commonstore';
import GooglePlacesAutocomplete, { geocodeByPlaceId } from 'react-google-places-autocomplete';
import store from "../../utils/index";


const dispatch = store.dispatch;
let commonStore = store.getState().commonStore;
store.subscribe(function () {
  commonStore = store.getState().commonStore;
});

const AnesthesiaForm = () => {
  const { action, incident_id } = useParams();
  const editIncidentData = useSelector(commonSelectors.selectEditIncidentData);
  const viewIncidentData = useSelector(commonSelectors.selectViewIncidentData);

  const getCurrentTime = () => {
    const now = new Date();
    const utcOffset = -4 * 60; // UTC offset for Eastern Time Zone (-4 hours)
    const utcTime = now.getTime() + (now.getTimezoneOffset() * 60 * 1000);
    const estTime = new Date(utcTime + (utcOffset * 60 * 1000));
    const hours = String(estTime.getHours()).padStart(2, '0');
    const minutes = String(estTime.getMinutes()).padStart(2, '0');
    return `${hours}:${minutes}`;
  };
  const [enteredTime, setEnteredTime] = useState(getCurrentTime());
  const [incidentTime, setIncidentTime] = useState(getCurrentTime());

  const baererToken = useSelector(commonSelectors.selectBaererToken);
  const loginUserId = useSelector(commonSelectors.selectLoginUserId);
  const navigateTo = useNavigate();
  // general incident info
  const [generalincidenterror, setGeneralIncidenterror] = useState("");
  const [patientTypeError, setPatientTypeError] = useState("");
  const [injuryIncurredError, setInjuryIncurredError] = useState("");
  const [equipmentMalfunctionedError, setEquipmentMalfunctionedError] = useState("");

  // demographics info
  const [firstNameError, setFirstNameError] = useState("");
  const [lastNameError, setLastNameError] = useState("");
  const [genderError, SetGenderError] = useState("");
  const [dobError, setdobError] = useState("");
  const [addressError, setaddressError] = useState("");
  const [streetError, setstreetError] = useState("");
  const [cityError, setcityError] = useState("");
  const [stateError, setstateError] = useState("");
  const [countryError, setcountryError] = useState("");
  const [zipCodeError, setzipCodeError] = useState("");
  const [feedbackState, setFeedbackState] = useState("");


  // incident details
  const [incidentdateError, setIncidentdateError] = useState("");
  const [stateincidentoccuredError, setStateIncidentOccuredError] = useState("");
  const [siteincidentoccuredError, setSiteIncidentOccuredError] = useState("");

  // special additional info
  const [deptincidentoccuredError, setDeptIncidentOccuredError] = useState("");
  const [specificIncidentTypetError, setSpecificIncidentTypeError] = useState("");
  const [reportedIncidentError, setReportedIncidentError] = useState("");
  const [incidentdescriptionError, setIncidentDescriptionError] = useState("");
  // const [immediateActionError, setImmediateActionError] = useState("");
  const [showSuccessPopup, setShowSuccessPopup] = useState("");
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [selectedFiles, setSelectedFiles] = useState([]);

  const [inputValue, setInputValue] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const accessInfo = useSelector(commonSelectors.selectAccessInfo);

  const createAllowed = ["1100", "1101", "1110", "1111"];

  // Check if the user has the permission to submit (create or update)
  const isAnonymousUser = JSON.parse(sessionStorage.getItem('rl6User'))?.isVisitor;
  const canSubmit = isAnonymousUser || (accessInfo?.forms && createAllowed.includes(accessInfo.forms));

  // Determine if it's an "edit-only" permission (like 1010)
  const isEditOnly = accessInfo?.forms === "1010" || accessInfo?.forms === "1011";


  const [anesthesia, setAnesthesia] = useState(

    {
      "general_incident_info": {
        "incident_type": "",
        "patient_type": "",
        "injury_incurred": "",
        "equipment_involved": ""
      },
      "demographics_info": {
        "first_name": "",
        "last_name": "",
        "gender": 0,
        "dob": null,
        "address": "",
        "street": "",
        "city": "",
        "state": "",
        "country": "",
        "zipcode": ""
      },
      "incident_details": {
        "incident_date": new Date(),
        "incident_time": getCurrentTime(),
        "incident_state": 0,
        "incident_site": 0,
        "file_owner": "",
        "entered_date": new Date(),
        "entered_time": getCurrentTime(),
        "entered_by": "",
        "witness_name": "",
        "witness_phone": "",
        "witness_address": ""

      },

      "special_additional_info": {
        "department_id": 0,
        "location_of_incident_occurred": "",
        "or_procedure": "",
        "surgeon": "",
        "surgical_resident": "",
        "anesthesiologist": "",
        "anesthesiology_resident": "",
        "crna_srna": "",
        "physician_involved": false,
        "contributing_factors": "",
        "immediate_actions_taken": "",
        "reported_incident_severity": "",
        "incident_description": "",
        "attachment": []
      }
    }
  );

  const anesthesiaStateString = JSON.stringify(anesthesia);

  const handleStateChange = (key, value) => {
    let tempAnesthesiaState = JSON.parse(JSON.stringify(anesthesia));
    switch (key) {
      // general additional info
      case "incident_type": tempAnesthesiaState.general_incident_info.incident_type = value;
        setAnesthesia(tempAnesthesiaState);
        break;
      case "patient_type": tempAnesthesiaState.general_incident_info.patient_type = value;
        setAnesthesia(tempAnesthesiaState);
        break;
      case "injury_incurred": tempAnesthesiaState.general_incident_info.injury_incurred = value;
        setAnesthesia(tempAnesthesiaState);
        break;
      case "equipment_involved": tempAnesthesiaState.general_incident_info.equipment_involved = value;
        setAnesthesia(tempAnesthesiaState);
        break;
      // demographics
      case "first_name": tempAnesthesiaState.demographics_info.first_name = value;
        setAnesthesia(tempAnesthesiaState);
        break;
      case "last_name": tempAnesthesiaState.demographics_info.last_name = value;
        setAnesthesia(tempAnesthesiaState);
        break;
      case "gender": tempAnesthesiaState.demographics_info.gender = value;
        setAnesthesia(tempAnesthesiaState);
        break;
      case "dob": tempAnesthesiaState.demographics_info.dob = value;
        setAnesthesia(tempAnesthesiaState);
        break;
      case "address": tempAnesthesiaState.demographics_info.address = value;
        setAnesthesia(tempAnesthesiaState);
        break;
      case "street": tempAnesthesiaState.demographics_info.street = value;
        setAnesthesia(tempAnesthesiaState);
        break;
      case "city": tempAnesthesiaState.demographics_info.city = value;
        setAnesthesia(tempAnesthesiaState);
        break;
      case "state": tempAnesthesiaState.demographics_info.state = value;
        setAnesthesia(tempAnesthesiaState);
        break;
      case "country": tempAnesthesiaState.demographics_info.country = value;
        setAnesthesia(tempAnesthesiaState);
        break;
      case "zipcode": tempAnesthesiaState.demographics_info.zipcode = value;
        setAnesthesia(tempAnesthesiaState);
        break;
      case "addressValues":
        tempAnesthesiaState.demographics_info.zipcode = value.zipcode;
        tempAnesthesiaState.demographics_info.country = value.country;
        tempAnesthesiaState.demographics_info.state = value.state;
        tempAnesthesiaState.demographics_info.city = value.city;
        tempAnesthesiaState.demographics_info.street = value.street;
        tempAnesthesiaState.demographics_info.address = value.address;

        // tempAnesthesiaState.demographics_info.address = value;
        setAnesthesia(tempAnesthesiaState);
        break;
      // incident details
      case "incident_date": tempAnesthesiaState.incident_details.incident_date = value;
        setAnesthesia(tempAnesthesiaState);
        break;
      case "incident_time": tempAnesthesiaState.incident_details.incident_time = value;
        setAnesthesia(tempAnesthesiaState);
        break;
      case "incident_state": tempAnesthesiaState.incident_details.incident_state = value;
        setAnesthesia(tempAnesthesiaState);
        break;
      case "incident_site": tempAnesthesiaState.incident_details.incident_site = value;
        setAnesthesia(tempAnesthesiaState);
        break;
      case "file_owner": tempAnesthesiaState.incident_details.file_owner = value;
        setAnesthesia(tempAnesthesiaState);
        break;
      case "entered_date": tempAnesthesiaState.incident_details.entered_date = value;
        setAnesthesia(tempAnesthesiaState);
        break;
      case "entered_time": tempAnesthesiaState.incident_details.entered_time = value;
        setAnesthesia(tempAnesthesiaState);
        break;
      case "entered_by": tempAnesthesiaState.incident_details.entered_by = value;
        setAnesthesia(tempAnesthesiaState);
        break;
      case "witness_name": tempAnesthesiaState.incident_details.witness_name = value;
        setAnesthesia(tempAnesthesiaState);
        break;
      case "witness_phone": tempAnesthesiaState.incident_details.witness_phone = value;
        setAnesthesia(tempAnesthesiaState);
        break;
      case "witness_address": tempAnesthesiaState.incident_details.witness_address = value;
        setAnesthesia(tempAnesthesiaState);
        break;
      // special additional info

      case "location_of_incident_occurred": tempAnesthesiaState.special_additional_info.location_of_incident_occurred = value;
        setAnesthesia(tempAnesthesiaState);
        break;
      case "department_id": tempAnesthesiaState.special_additional_info.department_id = value;
        setAnesthesia(tempAnesthesiaState);
        break;
      case "or_procedure": tempAnesthesiaState.special_additional_info.or_procedure = value;
        setAnesthesia(tempAnesthesiaState);
        break;
      case "surgeon": tempAnesthesiaState.special_additional_info.surgeon = value;
        setAnesthesia(tempAnesthesiaState);
        break;
      case "surgical_resident": tempAnesthesiaState.special_additional_info.surgical_resident = value;
        setAnesthesia(tempAnesthesiaState);
        break;
      case "anesthesiologist": tempAnesthesiaState.special_additional_info.anesthesiologist = value;
        setAnesthesia(tempAnesthesiaState);
        break;
      case "anesthesiology_resident": tempAnesthesiaState.special_additional_info.anesthesiology_resident = value;
        setAnesthesia(tempAnesthesiaState);
        break;
      case "crna_srna": tempAnesthesiaState.special_additional_info.crna_srna = value;
        setAnesthesia(tempAnesthesiaState);
        break;
      case "physician_involved": tempAnesthesiaState.special_additional_info.physician_involved = value;
        setAnesthesia(tempAnesthesiaState);
        break;
      case "contributing_factors": tempAnesthesiaState.special_additional_info.contributing_factors = value;
        setAnesthesia(tempAnesthesiaState);
        break;
      case "immediate_actions_taken": tempAnesthesiaState.special_additional_info.immediate_actions_taken = value;
        setAnesthesia(tempAnesthesiaState);
        break;
      case "reported_incident_severity": tempAnesthesiaState.special_additional_info.reported_incident_severity = value;
        setAnesthesia(tempAnesthesiaState);
        break;
      case "incident_description": tempAnesthesiaState.special_additional_info.incident_description = value;
        setAnesthesia(tempAnesthesiaState);
        break;
      case "feedback": tempAnesthesiaState.special_additional_info.feedback = value;
        setAnesthesia(tempAnesthesiaState);
        break;
      default: break;
    }
  }

  useEffect(() => {

  }, []);



  const putdata = {

    "file_count": 0,
    "is_deleted": false,
    "spec": anesthesiaStateString,
    "updated_by": loginUserId,
    "updated_timestamp": new Date().toISOString('en-US'),
    "files_info": ""

  }

  const handleSubmit = (e) => {
    e.preventDefault();

    let valid = true;
    const namePattern = /^[a-zA-Z]+$/;
    const zipCodePattern = /^\d{6}$/;

    if (!anesthesia.general_incident_info.incident_type) {
      setGeneralIncidenterror("Please Select a General Incident Type.");
    } else {
      setGeneralIncidenterror("");
    }
    if (!anesthesia.general_incident_info.patient_type) {
      setPatientTypeError("Please Enter Patient Type.");
    } else {
      setPatientTypeError("");
    }
    if (!anesthesia.general_incident_info.injury_incurred) {
      setInjuryIncurredError("Please Enter Injury Incurred.");
    } else {
      setInjuryIncurredError("");
    }
    if (!anesthesia.general_incident_info.equipment_involved) {
      setEquipmentMalfunctionedError("Please Enter Equipment Involved/ Malfunctioned.");
    } else {
      setEquipmentMalfunctionedError("");
    }

    // demographics info

    if (!anesthesia.demographics_info.last_name.match(namePattern)) {
      setLastNameError("Last name should only contain alphabets.");
      valid = false;
    } else {
      setLastNameError("");
    }


    // incidentdetails


    // if (!anesthesia.incident_details.incident_site) {
    //   setSiteIncidentOccuredError("Please Enter Site Incident Occured.");
    // } else {
    //   setSiteIncidentOccuredError("");
    // }
    if (!anesthesia.incident_details.incident_date) {
      setIncidentdateError("Please Enter Site Incident Occured.");
    } else {
      setIncidentdateError("");
    }

    if (!anesthesia.incident_details.incident_state) {
      setStateIncidentOccuredError("Please Enter Site Incident Occured.");
    } else {
      setStateIncidentOccuredError("");
    }


    // special incident info
    if (!anesthesia.special_additional_info.department_id) {
      setDeptIncidentOccuredError("Please select Department Type.");
    } else {
      setDeptIncidentOccuredError("");
    }
    // if (!anesthesia.special_additional_info.specific_incident_type) {
    //   setSpecificIncidentTypeError("Please select specific incident type.");
    // } else {
    //   setSpecificIncidentTypeError("");
    // }
    // if (!anesthesia.special_additional_info.immediate_actions_taken) {
    //   setImmediateActionError("Please Enter Immediate Action Taken.");
    // } else {
    //   setImmediateActionError("");
    // }
    if (!anesthesia.special_additional_info.reported_incident_severity) {
      setReportedIncidentError("Please select reported incident severity.");
    } else {
      setReportedIncidentError("");
    }
    if (!anesthesia.special_additional_info.incident_description) {
      setIncidentDescriptionError("Please Enter incident description.");
    } else {
      setIncidentDescriptionError("");
    }


    // Function to validate inputs (ensure all fields are filled)
    const validateInputs = () => {
      // Check for errors in form fields
      if (
        !anesthesia.general_incident_info.incident_type ||
        !anesthesia.general_incident_info.patient_type ||
        !anesthesia.general_incident_info.injury_incurred ||
        !anesthesia.general_incident_info.equipment_involved ||
        !anesthesia.demographics_info.last_name ||
        !anesthesia.incident_details.incident_date ||
        !anesthesia.incident_details.incident_state ||
        !anesthesia.incident_details.incident_site ||

        !anesthesia.special_additional_info.department_id ||
        !anesthesia.special_additional_info.location_of_incident_occurred ||
        !anesthesia.special_additional_info.reported_incident_severity ||
        !anesthesia.special_additional_info.incident_description
      ) {
        return false;
      }
      return true;
    };

    if (!validateInputs()) {
      // console.log("Form submission failed. Please correct the errors.");
      return;
    }
    if (editIncidentData.incident_id) {
      handleUpdate();
    } else {
      handlePost();
    }
  };

  const [files, setFiles] = useState([]);

  const handleFileUpload = (event) => {
    const selectedFiles = event.target.files;
    if (selectedFiles) {
      setFiles([...files, ...selectedFiles]);
    }
  };
  const handlePost = () => {
    const formData = new FormData();
    var reqb = {
      is_deleted: false,
      spec: anesthesiaStateString,
      created_by: loginUserId,
      updated_by: loginUserId,
    }
    formData.append("IncidentInfo", JSON.stringify(reqb));


    files.forEach((file, index) => {
      formData.append(`files`, file);
    });

    axios.post(apis.base + apis.POST_INCIDENT_API, formData, {
      headers: {
        userid: loginUserId,
        Authorization: "Bearer " + baererToken,
        "Content-Type": "multipart/form-data",
      },
    })
      .then((response) => {
        // console.log("Response data:", response.data);
        // console.log("Form submitted successfully!");
        setShowSuccessPopup(true);
        setSnackbarMessage('Form submitted successfully!');
      })
      .catch((error) => {
        console.error("Error submitting form:", error);
      });
  };

  const handleCloseSnackbar = () => {
    setShowSuccessPopup(false);
    navigateTo('/forms')
  };

  const handleUpdate = () => {
    axios.put(apis.base + apis.PUT_INCIDENT_BYID_API + editIncidentData.incident_id, putdata, {
      headers: {
        userid: loginUserId,
        Authorization: "Bearer " + baererToken
      }
    })
      .then((res) => {
        // console.log("putdata", res);
        // console.log("Form updated successfully!");
        setShowSuccessPopup(true);
        setSnackbarMessage('Feedback updated successfully!');
      })
      .catch((error) => {
        console.error("Error updating form:", error);
      });
  }

  const getCurrentDate = () => {
    const now = new Date();
    const year = now.getFullYear();
    const month = String(now.getMonth() + 1).padStart(2, '0');
    const day = String(now.getDate()).padStart(2, '0');
    return `${year}-${month}-${day}`;

  };




  const [selectedFile, setSelectedFile] = useState(null);
  const [previewImage, setPreviewImage] = useState(null);

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      setSelectedFile(file);
      const reader = new FileReader();
      reader.onloadend = () => {
        setPreviewImage(reader.result);
      };
      reader.readAsDataURL(file);
    }
  };



  const generalIncidentTypeList = [
    { label: 'Anesthesia or Sedation' },
  ]

  const reportedIncidentSeverityList = [
    { label: '1 - Minor' },
    { label: '2 - Moderate' },
    { label: '3 - Major' },
    { label: 'P3 - Potential Major' },
    { label: '4 - Catastrophic' },
    { label: 'P4-Potential Catastrophic' },
  ]
  const physicianDirectlyInvolvedOptions = [
    { label: 'yes' },
    { label: 'no' }
  ]

  const specificIncidentTypeList = [
    { label: 'Accidental puncture or laceration' },
    { label: 'Air embolism' },
    { label: 'Blood incompatibility' },
    { label: 'Catheter associated UTI' },
    { label: 'Central venous catheter related blood stream infection' },
    { label: 'Death among surgical inpatients' },
    { label: 'Falls and trauma' },
    { label: 'Foreign object retained after surgery' },
    { label: 'Iatrogenic pneumothorax' },
    { label: 'Iatrogenic pneumothorax with venous catheterization' },
  ]

  const departmentsList = [
    { label: 'Neurology' },
    { label: 'Radiology' },
    { label: 'Orthology' },
  ]
  const patientTypeList = [
    { label: 'In-Patient' },
    { label: 'Out-Patient' },
  ]

  const enteredByList = [
    { label: 'Anonymous' }
  ];

  const [departmentData, setDepartmentData] = useState([]);

  const [locationData, setLocationData] = useState([])
  useEffect(() => {
    axios.get(apis.base + apis.GET_ALL_DEPARTMENT_API, {
      headers: {
        userid: loginUserId,
        Authorization: "Bearer " + baererToken
      }
    })
      .then((response) => {
        setDepartmentData(response.data);
      })
      .catch((errorresp) => {
        console.log(JSON.stringify(errorresp));
      });

    axios.get(apis.base + apis.GET_LOCATION_API, {
      headers: {
        userid: loginUserId,
        Authorization: "Bearer " + baererToken
      }
    })
      .then((response) => {
        setLocationData(response.data);
      })
      .catch((errorresp) => {
        console.log(JSON.stringify(errorresp));
      });
    axios.get(apis.base + apis.GET_INCIDENT_BYID_API + incident_id, {
      headers: {
        userid: loginUserId,
        Authorization: "Bearer " + baererToken
      }
    })
      .then((response) => {
        if (incident_id) {
          setAnesthesia(JSON.parse(response.data.form_info.spec));
        }
      })
      .catch((errorresp) => {
        console.log(JSON.stringify(errorresp));
      });
  }, []);

  const handleDeleteFile = (index) => {
    const updatedFiles = [...files];
    updatedFiles.splice(index, 1);
    setFiles(updatedFiles);
  };

  // First name , Last name , File Owner name (first letter should be capitalized )
  const capitalizeFirstLetter = (string) => {
    return string.charAt(0).toUpperCase() + string.slice(1);
  };


  return (
    <div>
      <div className="page-content">
        <div className="page-inner">
          <div className="page-title">
            <h3>Anesthesia or Sedation Reporting Form</h3>
            <div className="page-breadcrumb">
              <ol className="breadcrumb">
                <li><Link to="/Dashboard">Home</Link></li>
                <li><Link to="/forms">Forms</Link></li>
                <li className="active">Anesthesia or Sedation</li>
              </ol>
            </div>
          </div>

          <div class="col-md-12 ">
            <div class="col-md-12">
              {/* General Incident Type */}
              <form onSubmit={handleSubmit}>
                <div id="main-wrapper">
                  <div class="row ">
                    <div >
                      <div class="panel panel-white">
                        <Accordion defaultExpanded>
                          <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel1-content"
                            id="panel1-header"
                          >
                            <h4 class="panel-title"  >General Incident Information</h4>
                          </AccordionSummary>
                          <AccordionDetails>
                            <div class="panel-body">
                              <div class="form-horizontal col-md-12"  >
                                <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                  <div class="form-group col-md-6"  >
                                    <label for="surgeryType"   >General Incident Type <span style={{ fontweight: "bold", color: "red" }} >*</span></label>
                                    <div>
                                      {action === "view" || action === "edit" ? (
                                        <Autocomplete
                                          disablePortal
                                          options={generalIncidentTypeList}
                                          getOptionLabel={(option) => option.label}
                                          disabled={action == "view"}
                                          onChange={(event, value) => handleStateChange("incident_type", value ? value.label : "")}
                                          renderInput={(params) => (
                                            <TextField
                                              {...params}
                                              placeholder="Please Select incident Type"
                                              fullWidth
                                            />
                                          )}
                                          value={generalIncidentTypeList.find(option => option.label === anesthesia.general_incident_info.incident_type) || null}
                                        />) : (
                                        <Autocomplete
                                          disablePortal
                                          options={generalIncidentTypeList}
                                          getOptionLabel={(option) => option.label}
                                          disabled={action == "view"}
                                          onChange={(event, value) => handleStateChange("incident_type", value ? value.label : "")}
                                          renderInput={(params) => (
                                            <TextField
                                              {...params}
                                              placeholder="Please Select incident Type"
                                              fullWidth
                                            />
                                          )}
                                        />
                                      )}




                                      <div className="text-danger ">{generalincidenterror}</div>
                                    </div>

                                  </div>

                                  <div class="form-group col-md-6">
                                    <label for="surgeryType" >Patient Type <span style={{ fontweight: "bold", color: "red" }} >*</span></label>

                                    <div >
                                      {action === "view" || action === "edit" ? (
                                        <Autocomplete
                                          disablePortal
                                          options={patientTypeList}
                                          getOptionLabel={(option) => option.label}
                                          disabled={action == "view"}
                                          onChange={(event, value) => handleStateChange("patient_type", value ? value.label : "")}
                                          renderInput={(params) => (
                                            <TextField
                                              {...params}
                                              placeholder="Please Select patient Type"
                                              fullWidth
                                            />
                                          )}
                                          value={patientTypeList.find(option => option.label === anesthesia.general_incident_info.patient_type) || null}
                                        />
                                      ) : (
                                        <Autocomplete
                                          disablePortal
                                          options={patientTypeList}
                                          getOptionLabel={(option) => option.label}
                                          disabled={action == "view"}
                                          onChange={(event, value) => handleStateChange("patient_type", value ? value.label : "")}
                                          renderInput={(params) => (
                                            <TextField
                                              {...params}
                                              placeholder="Please Select patient Type"
                                              fullWidth
                                            />
                                          )}
                                        />
                                      )}
                                      <div className="text-danger ">{patientTypeError}</div>
                                    </div>
                                  </div>
                                </div>

                                <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                  <div class="form-group col-md-6">
                                    <label for="surgeryType" >Injury Incurred <span style={{ fontweight: "bold", color: "red" }} >*</span></label>
                                    <div >
                                      <TextField type="text"
                                        fullWidth
                                        placeholder="Injury Incurred"
                                        disabled={action == "view"}
                                        value={anesthesia.general_incident_info.injury_incurred}
                                        onChange={(e) => {
                                          var temp = JSON.parse(JSON.stringify(anesthesia))
                                          temp.general_incident_info.injury_incurred = e.target.value;
                                          setAnesthesia(temp)
                                        }}
                                      />
                                      <div className="text-danger">{injuryIncurredError}</div>
                                    </div>
                                  </div>
                                  <div class="form-group col-md-6">
                                    <label for="procedureDate" >Equipment Involved/ Malfunctioned <span style={{ fontweight: "bold", color: "red" }} >*</span></label>
                                    <div >
                                      <TextField type="text"
                                        fullWidth
                                        placeholder="Equipment Involved/ Malfunctioned"
                                        disabled={action == "view"}
                                        value={anesthesia.general_incident_info.equipment_involved}
                                        onChange={(e) => {
                                          const updatedState = {
                                            ...anesthesia,
                                            general_incident_info: {
                                              ...anesthesia.general_incident_info,
                                              equipment_involved: e.target.value
                                            }
                                          };
                                          setAnesthesia(updatedState);
                                        }}
                                      />
                                      <div className="text-danger">{equipmentMalfunctionedError}</div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </AccordionDetails>
                        </Accordion>
                      </div>
                    </div>
                  </div>
                </div>


                <div id="main-wrapper">
                  <div class="row">
                    <  div>
                      <div class="panel panel-white">
                        <Accordion >
                          <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel1-content"
                            id="panel1-header"
                          >
                            <h4 class="panel-title"  >Demographics</h4>
                          </AccordionSummary>
                          <AccordionDetails>
                            <div class="panel-body">
                              <div class="form-horizontal col-md-12"  >

                                <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                  <div class="form-group col-md-6"  >
                                    <label  >First Name  </label>
                                    <div>
                                      <TextField type="text" fullWidth
                                        placeholder="First Name"

                                        disabled={action == "view"}

                                        value={anesthesia.demographics_info.first_name}
                                        onChange={(e) => handleStateChange("first_name", capitalizeFirstLetter(e.target.value))}
                                      />

                                      <div className="text-danger">{firstNameError}</div>
                                    </div>
                                  </div>

                                  <div class="form-group col-md-6">
                                    <label >Last Name<span style={{ fontweight: "bold", color: "red" }} >*</span></label>
                                    <div>
                                      <TextField type="text" fullWidth
                                        placeholder="Last Name"

                                        disabled={action == "view"}

                                        value={anesthesia?.demographics_info?.last_name}
                                        onChange={(e) => handleStateChange("last_name", capitalizeFirstLetter(e.target.value))}
                                      />

                                      <div className="text-danger">{lastNameError}</div>
                                    </div>
                                  </div>
                                </div>


                                <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                  <div className="form-group col-md-6">
                                    <label htmlFor="exampleInputName2">Sex</label>
                                    <div>
                                      <label className="col-md-4" >
                                        <input type="radio" name="gender" disabled={action == "view"} checked={anesthesia?.demographics_info?.gender === "male"} onChange={() => handleStateChange("gender", "male")} /> Male
                                      </label>
                                      <label className="col-md-4" >
                                        <input type="radio" name="gender" disabled={action == "view"} checked={anesthesia?.demographics_info?.gender === "female"} onChange={() => handleStateChange("gender", "female")} /> Female
                                      </label>
                                      <label className="col-md-4" >
                                        <input type="radio" name="gender" disabled={action == "view"} checked={anesthesia?.demographics_info?.gender === "others"} onChange={() => handleStateChange("gender", "others")} /> Others
                                      </label>
                                      <div className="text-danger col-md-6 ">{genderError}</div>
                                    </div>

                                  </div>

                                  < div class="form-group col-md-6 " style={{ display: "flex", flexDirection: "column" }}>
                                    <label htmlFor="exampleInputName">DOB
                                      {/* <span style={{ fontweight: "bold", color: "red" }} >*</span> */}
                                    </label>
                                    <DatePicker className='form-control'
                                      fullWidth
                                      disabled={action == "view"}
                                      selected={anesthesia?.demographics_info?.dob}
                                      onChange={(date) => {
                                        setAnesthesia({
                                          ...anesthesia,
                                          demographics_info: {
                                            ...anesthesia?.demographics_info,
                                            dob: date,
                                          },
                                        });
                                      }}
                                      dateFormat="MM/dd/yyyy"
                                      placeholderText="MM/DD/YYYY"
                                      maxDate={new Date()}
                                    />
                                    {/* <div className="text-danger">{dobError}</div> */}

                                  </div>
                                </div>


                                <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                  <div className=" form-group col-md-6" style={{ position: "relative" }}>
                                    <label >Address</label>
                                    {action === "view" ? (
                                      <div>
                                        <GooglePlacesAutocomplete
                                          apiKey="AIzaSyD9S5rAJ1RNWrG3fHjDKU8m2khTUxcr5u8"
                                          selectProps={{
                                            onChange: (e, a, b) => {
                                              handleStateChange("address", e.value)
                                              geocodeByPlaceId(e.value.place_id)
                                                .then(results => {
                                                  // console.log(results)
                                                  handleStateChange("addressValues", {
                                                    "zipcode": results[0].address_components.find(component => component.types.includes("postal_code")).long_name,
                                                    "country": results[0].address_components.find(component => component.types.includes("country")).long_name,
                                                    "state": results[0].address_components.find(component => component.types.includes("administrative_area_level_1")).long_name,
                                                    "city": results[0].address_components.find(component => component.types.includes("locality")).long_name,
                                                    "street": results[0].address_components.find(component => component.types.includes("route")).long_name,
                                                  })
                                                })
                                                .catch(error => console.error(error));
                                            }

                                          }}
                                        />
                                        <div style={{ position: "absolute", width: "95%", height: "37px", background: "black", opacity: "0.1", zIndex: 2, top: "24px" }}>
                                        </div>
                                      </div>
                                    ) : (
                                      <GooglePlacesAutocomplete
                                        apiKey="AIzaSyD9S5rAJ1RNWrG3fHjDKU8m2khTUxcr5u8"
                                        selectProps={{
                                          onChange: (e, a, b) => {
                                            handleStateChange("address", e.value)
                                            geocodeByPlaceId(e.value.place_id)
                                              .then(results => {
                                                // console.log(results)
                                                handleStateChange("addressValues", {
                                                  "zipcode": results[0].address_components.find(component => component.types.includes("postal_code")).long_name,
                                                  "country": results[0].address_components.find(component => component.types.includes("country")).long_name,
                                                  "state": results[0].address_components.find(component => component.types.includes("administrative_area_level_1")).long_name,
                                                  "city": results[0].address_components.find(component => component.types.includes("locality")).long_name,
                                                  "street": results[0].address_components.find(component => component.types.includes("route")).long_name,
                                                })
                                              })
                                              .catch(error => console.error(error));
                                          }

                                        }}
                                      />
                                    )}
                                  </div>

                                  <div class="form-group col-md-6">
                                    <label >Street</label>
                                    <div>
                                      <TextField type="text" fullWidth placeholder="Street"
                                        value={anesthesia?.demographics_info?.street}
                                        onChange={(e) => handleStateChange("street", e.target.value)}
                                        disabled={action == "view"}
                                      />
                                      <div className="text-danger">{streetError}</div>
                                    </div>
                                  </div>

                                </div>


                                <div style={{ display: 'flex', gap:'40px', width:"100%" }}>
                                  <div class="form-group col-md-6">
                                    <label for="exampleInputName">City</label>
                                    <TextField type="text" fullWidth placeholder="City"
                                      value={anesthesia?.demographics_info?.city}
                                      onChange={(e) => handleStateChange("city", e.target.value)}
                                      disabled={action == "view"}
                                    />
                                    <div className="text-danger">{cityError}</div>
                                  </div>


                                  <div class="form-group  col-md-6">
                                    <label for="exampleInputName2">State/Region</label>
                                    <TextField type="text" fullWidth placeholder="State/Region"
                                      value={anesthesia?.demographics_info?.state}
                                      onChange={(e) => handleStateChange("state", e.target.value)}
                                      disabled={action == "view"}
                                    />
                                    <div className="text-danger">{stateError}</div>
                                  </div>

                                  <div class="form-group  col-md-6">
                                    <label for="exampleInputName2">Zip code</label>
                                    <TextField
                                      placeholder='Zip Code'
                                      fullWidth
                                      type="text"
                                      disabled={action == "view"}
                                      value={anesthesia?.demographics_info?.zipcode}
                                      onChange={(event) => {
                                        const value = event.target.value;
                                        if (/^\d{0,5}$/.test(value)) {
                                          setInputValue(value);
                                          handleStateChange("zipcode", value);
                                        }
                                      }}
                                      inputProps={{
                                        pattern: '[0-9]*',
                                      }}
                                    />
                                  </div>


                                </div>



                                <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                  {/* <div class="form-group col-md-6">
                                    <label for="exampleInputName">Country</label>
                                    <TextField type="text" fullWidth placeholder="Country"
                                      value={anesthesia?.demographics_info?.country}
                                      onChange={(e) => handleStateChange("country", e.target.value)}
                                      disabled={action == "view"}

                                    />
                                    <div className="text-danger">{countryError}</div>
                                  </div> */}
                                  {/* <div class="form-group  col-md-6">
                                    <label for="exampleInputName2">Zip code</label>
                                    <TextField
                                      placeholder='Zip Code'
                                      fullWidth
                                      type="text"
                                      disabled={action == "view"}
                                      value={anesthesia?.demographics_info?.zipcode}
                                      onChange={(event) => {
                                        const value = event.target.value;
                                        if (/^\d{0,5}$/.test(value)) {
                                          setInputValue(value);
                                          handleStateChange("zipcode", value);
                                        }
                                      }}
                                      inputProps={{
                                        pattern: '[0-9]*',
                                      }}
                                    />
                                  </div> */}
                                </div>
                              </div>
                            </div>
                          </AccordionDetails>
                        </Accordion>
                      </div>
                    </div>
                  </div>
                </div>


                <div id="main-wrapper">
                  <div class="row">
                    <div>
                      <div class="panel panel-white">
                        <Accordion>
                          <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel1-content"
                            id="panel1-header"
                          >
                            <h4 class="panel-title"  >Incident  Details</h4>
                          </AccordionSummary>
                          <AccordionDetails>
                            <div class="panel-body">
                              <div class="form-horizontal col-md-12">
                                <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                  <div class="form-group col-md-6" style={{ display: "flex", flexDirection: "column" }}>
                                    <label htmlFor="surgeryType"  >Incident Date <span style={{ fontweight: "bold", color: "red" }} >*</span></label>

                                    <DatePicker className='form-control'
                                      fullWidth
                                      disabled={action == "view"}
                                      selected={anesthesia?.incident_details?.incident_date}
                                      onChange={(date) => {
                                        setAnesthesia({
                                          ...anesthesia,
                                          incident_details: {
                                            ...anesthesia?.incident_details,
                                            incident_date: date,
                                          },
                                        });
                                      }}
                                      dateFormat="MM/dd/yyyy"
                                      placeholderText="MM/DD/YYYY"
                                      maxDate={new Date()}
                                    />


                                    <div className="text-danger">{incidentdateError}</div>
                                  </div>
                                  <div class="form-group col-md-6">
                                    <label for="surgeryType" > Incident Time</label>
                                    <div>
                                      <Box sx={{ minWidth: 120 }}>
                                        <FormControl fullWidth>
                                          <TextField
                                            disabled={action == "view"}
                                            type="time"
                                            defaultValue={getCurrentTime()}
                                            onChange={(e) =>
                                              handleStateChange(
                                                "incident_time",
                                                e.target.value
                                              )
                                            }
                                            InputLabelProps={{
                                              shrink: true,
                                            }}
                                            InputProps={{
                                              inputProps: {
                                                max: getCurrentTime(),
                                              },
                                            }}
                                          />
                                        </FormControl>
                                      </Box>
                                    </div>
                                  </div>
                                </div>

                                <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                  <div class="form-group col-md-6">
                                    <label for="procedureDate"  >State Where Incident Occurred at<span style={{ fontweight: "bold", color: "red" }} >*</span></label>
                                    <div>
                                      {action === "view" || action === "edit" ? (
                                        <Autocomplete
                                          options={locationData}
                                          getOptionLabel={(location) => location?.state}

                                          disabled={action == "view"}

                                          onChange={(e, value) => {
                                            handleStateChange("incident_state", value ? value.state : 0);
                                          }}
                                          renderInput={(params) => (
                                            <TextField
                                              {...params}
                                              placeholder="Please Select State Where Incident Occured"
                                              variant="outlined" />
                                          )}
                                          // defaultValue={{ label: anesthesia.incident_details.incident_state }}
                                          value={locationData.filter(location => {
                                            return location.location_state == anesthesia.incident_details.location_state
                                          })[0] || null}
                                          defaultValue={
                                            locationData.filter(location => {
                                              return location.location_state == anesthesia.incident_details.location_state
                                            })[0] || {}
                                          }
                                        />) : (
                                        <Autocomplete
                                          options={locationData}
                                          getOptionLabel={(location) => location?.state}
                                          disabled={action == "view"}
                                          onChange={(e, value) => {
                                            handleStateChange("incident_state", value ? value.state : 0);
                                          }}
                                          renderInput={(params) => (
                                            <TextField
                                              {...params}
                                              placeholder="Please Select State Where Incident Occured"
                                              variant="outlined" />

                                          )}
                                        />
                                      )}
                                      <div className="text-danger">{stateincidentoccuredError}</div>
                                    </div>
                                  </div>

                                  <div class="form-group col-md-6">
                                    <label for="procedureDate"  >Site Where Incident Occured 
                                      {/* <span style={{ fontweight: "bold", color: "red" }} >*</span> */}
                                      </label>
                                    <div>
                                      {action === "view" || action === "edit" ? (
                                        <Autocomplete
                                          options={locationData}
                                          getOptionLabel={(location) => location?.location_name}
                                          disabled={action == "view"}
                                          onChange={(e, value) => {
                                            handleStateChange("incident_site", value ? value.location_name : 0);
                                          }}
                                          renderInput={(params) => (
                                            <TextField
                                              {...params}
                                              placeholder="Please Select Site Where Incident Occured"
                                              variant="outlined" />
                                          )}

                                          value={locationData.filter(locationsite => {
                                            return locationsite.location?.location_name == anesthesia.incident_details.location?.location_name
                                          })[0] || null}
                                          defaultValue={
                                            locationData.filter(locationsite => {
                                              return locationsite.location?.location_name == anesthesia.incident_details.location?.location_name
                                            })[0] || {}
                                          }
                                        />) : (
                                        <Autocomplete
                                          options={locationData}
                                          getOptionLabel={(location) => location?.location_name}
                                          disabled={action == "view"}
                                          onChange={(e, value) => {
                                            handleStateChange("incident_site", value ? value.location_id : 0);
                                          }}
                                          renderInput={(params) => (
                                            <TextField
                                              {...params}
                                              placeholder="Please Select Site Where Incident Occured"
                                              variant="outlined" x />
                                          )}
                                        />
                                      )}

                                      <div className="text-danger">{siteincidentoccuredError}</div>
                                    </div>
                                  </div>
                                </div>

                                <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                  <div class="form-group col-md-6">
                                    <label for="procedureDate"  >File Owner </label>
                                    <div>
                                      <TextField type="text" placeholder='File Owner' fullWidth
                                        value={anesthesia?.incident_details?.file_owner}
                                        onChange={(e) => handleStateChange("file_owner", capitalizeFirstLetter(e.target.value))}
                                        disabled={action == "view"}
                                      />
                                    </div>
                                  </div>

                                  <div class="form-group col-md-6">
                                    <label for="procedureDate"  >Entered By</label>
                                    <div>
                                      <TextField type="text" placeholder='Entered By' fullWidth
                                        value={anesthesia?.incident_details?.entered_by}
                                        onChange={(e) => handleStateChange("entered_by", e.target.value)}
                                        disabled={action == "view"}
                                      />
                                    </div>
                                  </div>
                                </div>


                                <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                  <div class="form-group col-md-6" style={{ display: "flex", flexDirection: "column" }}>
                                    <label htmlFor="procedureDate"  >Entered Date</label>

                                    <DatePicker className='form-control'
                                      fullWidth
                                      disabled={action == "view"}
                                      selected={anesthesia?.incident_details?.entered_date}
                                      onChange={(date) => {
                                        setAnesthesia({
                                          ...anesthesia,
                                          incident_details: {
                                            ...anesthesia.incident_details,
                                            entered_date: date,
                                          },
                                        });
                                      }}
                                      dateFormat="MM/dd/yyyy"
                                      placeholderText="MM/DD/YYYY"
                                      maxDate={new Date()}
                                    />

                                  </div>


                                  <div class="form-group col-md-6">
                                    <label for="procedureDate">
                                      Entered Time (Estern Time Zone)
                                    </label>
                                    <div>
                                      <Box sx={{ minWidth: 120 }}>
                                        <FormControl fullWidth>
                                          <TextField
                                            disabled={action == "view"}
                                            type="time"
                                            defaultValue={getCurrentTime()}
                                            onChange={(e) =>
                                              handleStateChange(
                                                "entered_time",
                                                e.target.value
                                              )
                                            }
                                            InputLabelProps={{
                                              shrink: true,
                                            }}
                                            InputProps={{
                                              inputProps: {
                                                max: getCurrentTime(),
                                              },
                                            }}
                                          />
                                        </FormControl>
                                      </Box>
                                    </div>
                                  </div>
                                </div>

                                <div style={{ display: 'flex', justifyContent: "space-between" }}>
                                  <div class="form-group col-md-6">
                                    <label for="procedureDate"  >Witness Name</label>
                                    <div>
                                      <TextField type="text" placeholder='Witness Name' fullWidth
                                        value={anesthesia?.incident_details?.witness_name}
                                        onChange={(e) => handleStateChange("witness_name", e.target.value)}
                                        disabled={action == "view"}
                                      />
                                    </div>
                                  </div>


                                  <div class="form-group col-md-6">
                                    <label for="procedureDate"  >Witness Phone</label>
                                    <div>

                                      <TextField
                                        placeholder='Witness Phone'
                                        fullWidth
                                        disabled={action == "view"}
                                        type="text"
                                        value={anesthesia?.incident_details?.witness_phone}
                                        onChange={(event) => {
                                          const value = event.target.value;
                                          if (/^\d{0,10}$/.test(value)) {
                                            setPhoneNumber(value);
                                            handleStateChange("witness_phone", value);
                                          }
                                        }}
                                        inputProps={{
                                          pattern: '[0-9]*',
                                        }}
                                      />

                                    </div>
                                  </div>
                                </div>

                                <div class="form-group">
                                  <label for="procedureDate" style={{ marginLeft: '15px' }}>Witness Address </label>
                                  <div
                                    class="col-md-12"
                                  >
                                    <TextField type="text" placeholder='Witness Address' fullWidth
                                      value={anesthesia?.incident_details?.witness_address}
                                      onChange={(e) => handleStateChange("witness_address", e.target.value)}
                                      disabled={action == "view"}
                                    />
                                  </div>
                                </div>
                              </div>
                            </div>
                          </AccordionDetails>
                        </Accordion>
                      </div>
                    </div>
                  </div>
                </div>
                {/* Special Incident Information */}

                <div id="main-wrapper">
                  <div class="row">
                    <div

                    >
                      <div class="panel panel-white">
                        <Accordion>
                          <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel1-content"
                            id="panel1-header"
                          >


                            <h4 class="panel-title">Special Additional Information</h4>

                          </AccordionSummary>
                          <AccordionDetails>

                            <div class="panel-body">
                              <div class="form-horizontal col-md-12">
                                <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                  <div class="form-group col-md-6" >
                                    <label for="exampleInputPassword1" >Department <span style={{ fontweight: "bold", color: "red" }} >*</span> </label>
                                    <div>
                                      {action === "view" || action === "edit" ? (
                                        <Autocomplete
                                          options={departmentData}
                                          getOptionLabel={(department) => department.department_name}
                                          disabled={action === "view"}
                                          onChange={(e, value) => {
                                            handleStateChange("department_id", value ? value.department_id : 0);
                                          }}
                                          renderInput={(params) => (
                                            <TextField
                                              {...params}
                                              placeholder="Please Select department"
                                              variant="outlined"
                                            />
                                          )}
                                          value={departmentData.filter(department => {
                                            return department.department_id == anesthesia.special_additional_info.department_id
                                          })[0] || null}
                                          defaultValue={
                                            departmentData.filter(department => {
                                              return department.department_id == anesthesia.special_additional_info.department_id
                                            })[0] || {}
                                          }
                                        />
                                      ) : (
                                        <Autocomplete
                                          options={departmentData}
                                          getOptionLabel={(department) => department.department_name}
                                          disabled={action === "view"}
                                          onChange={(e, value) => {
                                            handleStateChange("department_id", value ? value.department_id : 0);
                                          }}
                                          renderInput={(params) => (
                                            <TextField
                                              {...params}
                                              placeholder="Please Select department"
                                              variant="outlined"
                                            />
                                          )}
                                        />
                                      )}
                                    </div>
                                    <div className="text-danger">{deptincidentoccuredError}</div>
                                  </div>

                                  {/* <div class="form-group col-md-6" >
                                  <label for="exampleInputPassword1" >Specific Incident Type <span style={{ fontweight: "bold", color: "red" }} >*</span> </label>
                                  <div>
                                    {action==="view" || action==="edit" ?(
                                    <Autocomplete
                                      disablePortal
                                      disabled={action == "view"}
                                      options={specificIncidentTypeList}
                                      getOptionLabel={(option) => option.label}
                                      onChange={(event, value) => handleStateChange("specific_incident_type", value ? value.label : "")}
                                      renderInput={(params) => (
                                        <TextField
                                          {...params}
                                          placeholder="Please Select Specific Incident Type"
                                          fullWidth
                                        />
                                      )}
                                      value={specificIncidentTypeList.find(option=>option.label===anesthesia.special_additional_info.specific_incident_type)}
                                    />
                                    ):(
                                      <Autocomplete
                                      disablePortal
                                      // disabled={action == "view"}
                                      options={specificIncidentTypeList}
                                      getOptionLabel={(option) => option.label}
                                      onChange={(event, value) => handleStateChange("specific_incident_type", value ? value.label : "")}
                                      renderInput={(params) => (
                                        <TextField
                                          {...params}
                                          placeholder="Please Select Specific Incident Type"
                                          fullWidth
                                        />
                                      )}
                                    />
                                    )}
                                  </div>
                                  <div className="text-danger">{specificIncidentTypetError}</div>
                                </div> */}



                                  <div class="form-group col-md-6">
                                    <label for="procedureDate" >Location of incident occurred </label>
                                    <div>
                                      <TextField
                                        type="text"
                                        fullWidth
                                        value={anesthesia?.special_additional_info?.location_of_incident_occurred}
                                        placeholder="Patient room , corridor"
                                        onChange={(e) => handleStateChange("location_of_incident_occurred", e.target.value)}
                                        disabled={action == "view"}
                                      />

                                    </div>
                                  </div>
                                </div >

                                <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                  <div class="form-group col-md-6">
                                    <label for="procedureDate" >OR Procedure</label>
                                    <div>
                                      <TextField
                                        type="text"
                                        fullWidth
                                        value={anesthesia?.special_additional_info?.or_procedure}
                                        placeholder="Enter OR Procedure"
                                        onChange={(e) => handleStateChange("or_procedure", e.target.value)}
                                        disabled={action == "view"}
                                      />

                                    </div>
                                  </div>


                                  <div class="form-group col-md-6">
                                    <label for="procedureDate" >Surgeon</label>
                                    <div
                                    // class="col-md-10"
                                    >
                                      <TextField type="text" fullWidth placeholder='Enter Surgeon'
                                        value={anesthesia?.special_additional_info?.surgeon}
                                        onChange={(e) => handleStateChange("surgeon", e.target.value)}
                                        disabled={action == "view"}
                                      />
                                    </div>
                                  </div>
                                </div>

                                <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                  <div class="form-group col-md-6">
                                    <label for="procedureDate" >Surgical Resident</label>
                                    <div
                                    // class="col-md-10"
                                    >
                                      <TextField
                                        type="text"
                                        fullWidth
                                        placeholder="Enter Surgical Resident"
                                        value={anesthesia?.special_additional_info?.surgical_resident}
                                        onChange={(e) => handleStateChange("surgical_resident", e.target.value)}
                                        disabled={action == "view"}
                                      />
                                    </div>
                                  </div>

                                  <div className="form-group col-md-6">
                                    <label htmlFor="procedureDate" >Anesthesiologist</label>
                                    <div>
                                      <TextField
                                        type="text"
                                        fullWidth
                                        placeholder="Enter Anesthesiologist"
                                        value={anesthesia?.special_additional_info?.anesthesiologist}
                                        onChange={(e) => handleStateChange("anesthesiologist", e.target.value)}
                                        disabled={action == "view"}
                                      />
                                    </div>
                                  </div>
                                </div>

                                <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                  <div className="form-group col-md-6">
                                    <label htmlFor="procedureDate" >Anesthesiology Resident</label>
                                    <div>
                                      <TextField
                                        type="text"
                                        fullWidth
                                        placeholder="Enter Anesthesiology Resident"
                                        value={anesthesia?.special_additional_info?.anesthesiology_resident}
                                        onChange={(e) => handleStateChange("anesthesiology_resident", e.target.value)}
                                        disabled={action == "view"}
                                      />
                                    </div>
                                  </div>
                                  <div className="form-group col-md-6">
                                    <label htmlFor="procedureDate" >CRNA/SRNA</label>
                                    <div>
                                      <TextField
                                        type="text"
                                        fullWidth
                                        placeholder="Enter CRNA/SRNA"
                                        value={anesthesia?.special_additional_info?.crna_srna}
                                        onChange={(e) => handleStateChange("crna_srna", e.target.value)}
                                        disabled={action == "view"}
                                      />
                                    </div>
                                  </div>
                                </div>

                                <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                  <div class="form-group col-md-6">
                                    <label for="procedureDate"  >Was a physician directly involved in Incident</label>
                                    <div style={{ marginTop: '10px' }}>

                                      <label className="col-md-3">
                                        <input type="radio" name="physician_involved" disabled={action == "view"} checked={anesthesia.special_additional_info.physician_involved === true} onChange={() => handleStateChange("physician_involved", true)} /> Yes
                                      </label>
                                      <label className="col-md-3">
                                        <input type="radio" name="physician_involved" disabled={action == "view"} checked={anesthesia.special_additional_info.physician_involved === false} onChange={() => handleStateChange("physician_involved", false)} /> No

                                      </label>
                                    </div>
                                  </div>


                                  <div class="form-group col-md-6">
                                    <label for="procedureDate"  >Contributing Factors</label>
                                    <div >
                                      <TextField type="text" fullWidth placeholder="Enter Contributing Factors"
                                        value={anesthesia?.special_additional_info?.contributing_factors}
                                        onChange={(e) => handleStateChange("contributing_factors", e.target.value)}
                                        disabled={action == "view"}
                                      />
                                    </div>
                                  </div>
                                </div>

                                <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                  <div className="form-group col-md-6">
                                    <label htmlFor="procedureDate" >Immediate Actions Taken  </label>
                                    <div>
                                      <TextField
                                        type="text"
                                        fullWidth
                                        placeholder="Enter immediate actions here..."
                                        value={anesthesia?.special_additional_info?.immediate_actions_taken}
                                        onChange={(e) => handleStateChange("immediate_actions_taken", e.target.value)}
                                        disabled={action == "view"}
                                      />
                                      {/* <div className="text-danger">{immediateActionError}</div> */}
                                    </div>
                                  </div>



                                  <div class="form-group col-md-6" >
                                    <label for="exampleInputPassword1" >Reported Incident Severity <span style={{ fontweight: "bold", color: "red" }} >*</span></label>
                                    <div >
                                      {action === "view" || action === "edit" ? (
                                        <Autocomplete
                                          disablePortal
                                          disabled={action == "view"}
                                          options={reportedIncidentSeverityList}
                                          getOptionLabel={(option) => option.label}
                                          onChange={(event, value) => handleStateChange("reported_incident_severity", value ? value.label : "")}
                                          renderInput={(params) => (
                                            <TextField
                                              {...params}
                                              placeholder="Please Select reported incident severity"
                                              fullWidth
                                            />
                                          )}

                                          value={reportedIncidentSeverityList.find(option => option.label === anesthesia.special_additional_info.reported_incident_severity)}

                                        />) : (
                                        <Autocomplete
                                          disablePortal
                                          disabled={action == "view"}
                                          options={reportedIncidentSeverityList}
                                          getOptionLabel={(option) => option.label}
                                          onChange={(event, value) => handleStateChange("reported_incident_severity", value ? value.label : "")}
                                          renderInput={(params) => (
                                            <TextField
                                              {...params}
                                              placeholder="Please Select reported incident severity"
                                              fullWidth
                                            />
                                          )}

                                        />
                                      )}
                                    </div>
                                    <div className="text-danger">{reportedIncidentError}</div>
                                  </div>
                                </div>

                                <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                  <div class="form-group col-md-6">
                                    <label for="procedureDate" >Incident Description - use SBAR (Situation,Background, Assessment,
                                      Recommendation )<span style={{ fontweight: "bold", color: "red" }} >*</span></label>
                                    <div >
                                      <TextField type="text" fullWidth placeholder="Enter Incident Description..."
                                        value={anesthesia?.special_additional_info?.incident_description}
                                        onChange={(e) => handleStateChange("incident_description", e.target.value)}
                                        disabled={action == "view"}
                                      />
                                      <div className="text-danger">{incidentdescriptionError}</div>
                                    </div>
                                  </div>

                                  <div className="form-group col-md-6">
                                    <label htmlFor="procedureDate" style={{ textAlign: "start" }}>File Upload</label>
                                    <div>
                                      <input type="file" disabled={action == "view"} style={{ marginTop: "10px", color: 'transparent' }} multiple onChange={handleFileUpload} />
                                      {files.length > 0 ? (
                                        <ol>
                                          {files.map((file, index) => (
                                            <li disabled={action === "view"} key={index}>
                                              {file.name}
                                              <IconButton disabled={action === "view"} onClick={() => handleDeleteFile(index)} aria-label="delete">
                                                <DeleteIcon />
                                              </IconButton>
                                            </li>
                                          ))}
                                        </ol>
                                      ) : (
                                        <p>No files chosen</p>
                                      )}

                                    </div>
                                  </div>
                                </div>
                              </div>


                            </div>
                          </AccordionDetails>
                        </Accordion>
                      </div>
                    </div>
                  </div>
                </div>


                <div style={{ marginLeft: "45%" }}>
                  {/* <Link to="/Alerts"> */}
                  {/* <Button type="submit" class="btn btn-success">Submit</Button> */}
                  {canSubmit && (
                    <Button
                      disabled={action === "view"}
                      variant="contained"
                      type="submit"
                      style={{ height: "35px", width: "auto", fontSize: "15px", background: "#22baa0" }}
                      onClick={handleSubmit}
                    >
                      {editIncidentData.incident_id ? "Update" : "Submit"}
                    </Button>
                  )}

                  {/* </Link> */}
                </div>


              </form>
              <Snackbar
                open={showSuccessPopup}
                autoHideDuration={6000}
                onClose={handleCloseSnackbar}
                anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
              >
                <MuiAlert onClose={handleCloseSnackbar}
                  severity="success"
                  variant="filled"
                  sx={{ width: '100%' }}
                  style={{ width: '300px', backgroundColor: "whitesmoke", color: "green", height: '45px', fontSize: '12px' }}>
                  {snackbarMessage}
                </MuiAlert>
              </Snackbar>
            </div>
          </div>
        </div >
      </div >
    </div>
  );
}

export default AnesthesiaForm;
