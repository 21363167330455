import React, { useState,useEffect } from 'react';
import axios from 'axios';
import { Snackbar, Alert } from "@mui/material";

const FileUploadButton = () => {

  const [file, setFile] = useState();
  const [uploadedFile, setUploadedFile] = useState();
  const [error, setError] = useState();
  const [snackbar, setSnackbar] = useState({
    open: false,
    message: "",
    severity: "success",
  });


  function handleChange(event) {
    setFile(event.target.files[0]);
  }

  function handleSubmit(event) {
    event.preventDefault();
    const url = '';
    const formData = new FormData();
    formData.append('file', file);
    formData.append('fileName', file.name);
    const config = {
      headers: {
        'content-type': 'multipart/form-data',
      },
    };
    axios.post(url, formData, config)
      .then((response) => {
        // console.log(response.data);
        setUploadedFile(response.data.file);
        setSnackbar({
          open: true,
          message: 'File Uploaded Successfully',
          severity: 'success',
        });
      })
      .catch((error) => {
        console.error("Error uploading file: ", error);
        setError(error);
        setSnackbar({
          open: true,
          message: 'File Upload Unsuccessful',
          severity: 'error',
        });
      });
  }
  useEffect(() => {
    const timer = setTimeout(() => {
      setSnackbar({ ...snackbar, open: false });
    }, 6000);
    return () => clearTimeout(timer);
  }, [snackbar]);

  return (
    <div>
      <form onSubmit={handleSubmit}>
        <input type="file" onChange={handleChange} />
        <button className="btn btn-primary fa fa-cloud-upload" aria-hidden="true" style={{ margin: "10px 10px 10px 50px", borderRadius: "2px" }} type="submit"><span style={{ marginLeft: "5px" }}>Upload</span></button>
      </form>
      {uploadedFile && <img src={uploadedFile} alt="Uploaded content" />}
      {error && <Snackbar
        open={snackbar.open}
        autoHideDuration={6000}
        onClose={() => setSnackbar({ ...snackbar, open: false })}
        style={{ position: 'fixed', top: '15%', left: '50%', transform: 'translate(-50%, -50%)' }}
      >
        <Alert onClose={() => setSnackbar({ ...snackbar, open: false })} severity={snackbar.severity} style={{ width: '400px', height: '50px', fontSize: '14px' }}>
          {snackbar.message}
        </Alert>
      </Snackbar>}
    </div>
  );
}

export default FileUploadButton;