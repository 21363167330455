import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { Line } from 'react-chartjs-2';
import { apis } from '../../configuration/configurationAPI';
import {useSelector} from "react-redux";
import { commonSelectors } from '../../utils/commonstore';

const Linegraph = () => {

  const baererToken = useSelector(commonSelectors.selectBaererToken);
  const loggedinId = useSelector(commonSelectors.selectLoginUserId);
  const [lineChartData, setLineChartData] = useState({
    labels: [], 
    datasets: [
      {
        label: 'Line Graph',
        data: [],
        borderColor: 'rgba(75, 192, 192, 1)',
        borderWidth: 2,
        fill: false,
      },
    ],
  });
  const [showFilters, setShowFilters] = useState(false);

  const [date, setDate] = React.useState({
      dateFrom: new Date("2000-01-01"),
      dateTo: new Date()
  });

const [fromDate, setFromDate] = useState('');
const [toDate, setToDate] = useState('');
useEffect(() => {
  fetchData();
}, [fromDate, toDate]);



const fetchData = async () => {
  try {
    const response = await axios.post(
      apis.base + apis.GET_INCIDENT_FILTER_INCIDENT_COUNT_REPORT_API,
      {
        
        "based_on": "department_id",
        "is_report": false
      },
      {
        headers: {
          userid: loggedinId,
          Authorization: "Bearer " + baererToken
        }
      }
    )
    ;

    // console.log(response.data,"kkkkkkkkkkkkxjssssss");


    const departments = response.data.map((el) => {if(el.count>0) return (el.info[0]["department_name"])});
    const counts = response.data.map((el) => Object.values(el)[0].length);

    // console.log(departments,counts,"jjjjjjjjjjjjjjaassdc")

    setLineChartData((prevChartData) => ({
      ...prevChartData,
      labels: departments, // Set department names as labels
      datasets: [
        {
          ...prevChartData.datasets[0],
          data: counts,
        },
      ],
    }));
  } catch (error) {
    console.error('Error fetching real-time data:', error);
  }
};



const options = {
  responsive: true,
  maintainAspectRatio: false, 
};
  return (
    
    <div style={{ position: "relative", height: "300px", width: "100%" }}>
      <Line data={lineChartData} options={options} />
    </div>
    
  );
};

export default Linegraph;
