import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import axios from "axios";
import { apis } from "../../configuration/configurationAPI";
import "react-datepicker/dist/react-datepicker.css";
import { commonActions } from "../../utils/commonstore";
import store from "../../utils/index";
import { useSelector } from 'react-redux';
import { commonSelectors } from '../../utils/commonstore';
import { DataGrid, GridToolbarQuickFilter } from '@mui/x-data-grid';
import { useDemoData } from '@mui/x-data-grid-generator';
import IncidentAuditExportExcel from "../../Components/AuditExportButtons/IncidentAuditExcel";
import IncidentAuditExportPDF from "../../Components/AuditExportButtons/IncidentAuditPDF";

const dispatch = store.dispatch;
let commonStore = store.getState().commonStore;
store.subscribe(function () {
    commonStore = store.getState().commonStore;
});

function IncidentAudit() {
    const accessInfo = useSelector(commonSelectors.selectAccessInfo);
    const baererToken = useSelector(commonSelectors.selectBaererToken);
    const loginUserId = useSelector(commonSelectors.selectLoginUserId);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
    const [profileIdToDelete, setProfileIdToDelete] = useState(null);
    const [auditData, setAuditData] = useState([]);
    const userInfo = useSelector(commonSelectors.selectUserInfo);
    const [rows, setRows] = useState([]);
   

    useEffect(() => {
        fetchIncidentsData();
    }, []);

    const fetchIncidentsData = () => {
        axios.get(apis.base + apis.GET_INCIDENT_ALL_AUDIT_API,
            {
                headers: {
                    userid: loginUserId,
                    Authorization: "Bearer " + baererToken
                }
            })
            .then((succResp) => {

             
                const incidentWithIds = succResp.data.map((incident, index) => ({
                    ...incident,
                    id: index + 1, 
                    incident_type: incident.spec.general_incident_info.incident_type || "",
                    last_name: incident.spec.demographics_info.last_name || "", 
                    first_name: incident.spec.demographics_info.first_name || "",
                    incident_date: incident.spec.incident_details.incident_date || "",
                    reported_incident_severity: incident.spec.special_additional_info.reported_incident_severity || "",
                }));

                // console.log(incidentWithIds, " hhhhhhhhhhhjjjjjjjjjjkkkkkkkkkkkkkklllllllllll")
                setRows(incidentWithIds);
                setLoading(false);
            })
            .catch((errorresp) => {
                console.log(JSON.stringify(errorresp));
                setError("Error while fetching incident details. Please try again.");
                setLoading(false);
            });
    };

    const renderCreatedByCell = (params) => {
        return params.value ? params.value : 'Anonymous';
      };

    const sortedData = [...rows].sort((a, b) => b.id - a.id);
    const columns = [
        { field: 'id', headerName: <strong> Id</strong>, width: 80 },
        { field: 'incident_id', headerName: <strong>Incident Id</strong>, width: 120 },
        { field: 'incident_type', headerName: <strong>Incident Type</strong>, width: 200 },
        {
            field: ' incident_date',
            headerName: <strong>Incident Date</strong>,
            width: 120,
            valueGetter: (params) => new Date(params.row.updated_timestamp).toLocaleString('en-US')
        },
        { field: 'reported_incident_severity', headerName: <strong>Severity</strong>, width: 200 },
        {
            field: 'last_name',
            headerName: <strong>Patient Name</strong>,
            width: 150,
            valueGetter: (params) => {
                const lastName = params.row.last_name || '';
                const firstName = params.row.first_name || '';
                return `${lastName}${firstName ? ' ' + firstName : ''}`;
            }
        },
        { field: 'location_name', headerName: <strong>Location</strong>, width: 120 },
        { field: 'department_name', headerName: <strong>Department</strong>, width: 120 },
        { field: 'created_by_user', headerName: <strong>Created By</strong>, width: 100,renderCell: renderCreatedByCell },
        {
            field: 'created_timestamp',
            headerName: <strong>Created Timestamp</strong>,
            width: 150,
            valueGetter: (params) => new Date(params.row.created_timestamp).toLocaleString('en-US')
        },
        { field: 'updated_by_user', headerName: <strong>Updated By</strong>, width: 100 },
        {
            field: 'updated_timestamp',
            headerName: <strong>Updated Timestamp</strong>,
            width: 150,
            valueGetter: (params) => new Date(params.row.updated_timestamp).toLocaleString('en-US')
        },
        {
            field: 'is_deleted', headerName: <strong>Status</strong>, width: 100,
            renderCell: (params) => (
                <span>{params.value === 0 ? "Active" : "Inactive"}</span>
            )
        },



    ];
    return (
        <div>
            <div className="page-content">
                <div className="page-inner">
                    <div className="panel panel-white">
                        <div className="page-title">
                            <h3>Incidents Audit Data</h3>
                            <div className="page-breadcrumb">
                                <ol className="breadcrumb">
                                    <li><Link to="/Dashboard">Home</Link></li>
                                    <li>Incidents Audit</li>
                                </ol>
                            </div>
                        </div>

                        <div id="main-wrapper">
                            <div className="row m-t-md">
                                <div className="col-md-12">
                                    <div className="mailbox-content" style={{ overflow: 'auto' }}>
                                        {!error && (
                                          <div style={{ display: "flex", position: "relative", top: "20px", gap: "20px", justifyContent: "end", paddingRight: "20px" }}>
                                                {accessInfo?.exports ==="1" && (
                                                <>
                                                <IncidentAuditExportExcel />
                                                <IncidentAuditExportPDF />
                                                </>
                                        )}
                                            </div>
                                        )}
                                        <div style={{ height: "100%", width: '100%', paddingTop: "20px" }}>
                                            {error && <p style={{ marginLeft: "30%", fontSize: "15px", color: "red" }}>{error}</p>}
                                            {loading && <p style={{ textAlign: "center", fontSize: "15px" }}>Loading...</p>}
                                            {!loading && !error && (
                                                <DataGrid
                                                rows={sortedData}
                                                columns={columns}
                                                pageSize={5}
                                                rowsPerPageOptions={[10, 20, 30]}
                                                loading={loading}
                                                classes={{
                                                    overlayWrapper: 'customOverlayWrapper'
                                                }}
                                                sx={{
                                                    "& .customOverlayWrapper ": {
                                                        height: "63px !important"
                                                    },
                                                    fontWeight: 'fontWeightLight',
                                                    mx: 0.5,
                                                    fontSize: 14, fontFamily: "sans-serif",
                                                    boxShadow: 2
                                                }}
                                               
                                                components={{
                                                    Toolbar: () => (
                                                        <GridToolbarQuickFilter
                                                            classes={{
                                                                toolbarQuickFilter: 'customtoolbarQuickFilter'
                                                            }}
                                                            sx={{
                                                                width: '35% !important',
                                                                position: 'absolute !important',
                                                                right: 400,
                                                                top: '-30px !important'
                                                            }}
                                                        />
                                                    ),
                                                    NoRowsOverlay: () => (
                                                        <div
                                                            style={{
                                                                position: 'sticky',
                                                                top: '50%',
                                                                transform: 'translate(-50%, 0%)',
                                                                textAlign: 'center',
                                                                marginLeft: '45%'
                                                            }}
                                                        >
                                                            No data found
                                                        </div>
                                                    ),
                                                }}
                                                   
                                            />
                                            )}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}


export default IncidentAudit;