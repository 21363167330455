import React, { useState, useEffect } from 'react';
import { Link, useNavigate, useParams } from "react-router-dom";
import axios from 'axios';
import { apis } from '../../configuration/configurationAPI';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Box from '@mui/material/Box';
import FormControl from '@mui/material/FormControl';
import { Button, TextField, Snackbar, } from "@mui/material";
import Autocomplete from '@mui/material/Autocomplete';
import MuiAlert from '@material-ui/lab/Alert';
import { MenuItem } from "@material-ui/core";
import DatePicker from 'react-datepicker';
import { IconButton } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import GooglePlacesAutocomplete, { geocodeByPlaceId } from 'react-google-places-autocomplete';
import { useSelector } from 'react-redux';
import store from "../../utils/index";
import { commonSelectors } from "../../utils/commonstore";

const dispatch = store.dispatch;
let commonStore = store.getState().commonStore;
store.subscribe(function () {
  commonStore = store.getState().commonStore;
});

const CodingComplicationsForm = () => {

  const { action, incident_id } = useParams();
  const editIncidentData = useSelector(commonSelectors.selectEditIncidentData);
  const viewIncidentData = useSelector(commonSelectors.selectViewIncidentData);

  const getCurrentTime = () => {
    const now = new Date();
    const utcOffset = -4 * 60; // UTC offset for Eastern Time Zone (-4 hours)
    const utcTime = now.getTime() + (now.getTimezoneOffset() * 60 * 1000);
    const estTime = new Date(utcTime + (utcOffset * 60 * 1000));
    const hours = String(estTime.getHours()).padStart(2, '0');
    const minutes = String(estTime.getMinutes()).padStart(2, '0');
    return `${hours}:${minutes}`;
  };
  const [enteredTime, setEnteredTime] = useState(getCurrentTime());
  const [incidentTime, setIncidentTime] = useState(getCurrentTime());



  // general incident info
  const [generalincidenterror, setGeneralIncidenterror] = useState("");
  const [patientTypeError, setPatientTypeError] = useState("");
  const [injuryIncurredError, setInjuryIncurredError] = useState("");
  const [equipmentMalfunctionedError, setEquipmentMalfunctionedError] = useState("");

  // demographics info
  const [firstNameError, setFirstNameError] = useState("");
  const [lastNameError, setLastNameError] = useState("");
  const [genderError, SetGenderError] = useState("");
  const [incident_dateError, setincident_dateError] = useState("");
  const [addressError, setaddressError] = useState("");
  const [streetError, setstreetError] = useState("");
  const [cityError, setcityError] = useState("");
  const [stateError, setstateError] = useState("");
  const [countryError, setcountryError] = useState("");
  const [zipCodeError, setzipCodeError] = useState("");

  // incident details
  const [incidentdateError, setIncidentdateError] = useState("");
  const [stateincidentoccuredError, setStateIncidentOccuredError] = useState("");
  const [siteincidentoccuredError, setSiteIncidentOccuredError] = useState("");


  // special additional info
  const [deptincidentoccuredError, setDeptIncidentOccuredError] = useState("");
  const [reportedIncidentError, setReportedIncidentError] = useState("");
  const [incidentdescriptionError, setIncidentDescriptionError] = useState("");
  const [specificIncidentTypetError, setSpecificIncidentTypeError] = useState("");

  const [showSuccessPopup, setShowSuccessPopup] = useState("");
  const [snackbarMessage, setSnackbarMessage] = useState('')

  const [selectedFiles, setSelectedFiles] = useState([]);
  const [departmentError, setDepartmentError] = useState("");

  const [inputValue, setInputValue] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const baererToken = useSelector(commonSelectors.selectBaererToken);
  const loginUserId = useSelector(commonSelectors.selectLoginUserId);

  const accessInfo = useSelector(commonSelectors.selectAccessInfo);

  const createAllowed = [ "1100", "1101", "1110", "1111"];

// Check if the user has the permission to submit (create or update)
const isAnonymousUser = JSON.parse(sessionStorage.getItem('rl6User'))?.isVisitor;
const canSubmit =isAnonymousUser || (accessInfo?.forms && createAllowed.includes(accessInfo.forms));

// Determine if it's an "edit-only" permission (like 1010)
const isEditOnly = accessInfo?.forms === "1010" || accessInfo?.forms === "1011";

  const [codingComplicationState, setCodingComplicationState] = useState(
   {
        "general_incident_info": {
          "incident_type": "",
          "patient_type": "",
          "injury_incurred": "",
          "equipment_involved": ""
        },
        "demographics_info": {
          "first_name": "",
          "last_name": "",
          "gender": 0,
          "incident_date": null,
          "address": "",
          "street": "",
          "city": "",
          "state": "",
          "country": "",
          "zipcode": ""
        },
        "incident_details": {
          "incident_date": new Date(),
          "incident_time": getCurrentTime(),
          "incident_state": "",
          "incident_site": "",
          "file_owner": "",
          "entered_date": new Date(),
          "entered_time": getCurrentTime(),
          "entered_by": "",
          "witness_name": "",
          "witness_phone": "",
          "witness_address": "",
        },
        "special_additional_info": {
          "department_id": 0,
          // "specific_incident_type": "",
          "location_of_incident_occurred":"",
          "type_of_lineOrtube": "",
          "type_of_restraints": "",
          "physician_involved": false,
          "contributing_factors": "",
          "immediate_actions_taken": "",
          "reported_incident_severity": "",
          "incident_description": "",
          "attachment": []
        }

      }
  );

  const codingComplicationStateString = JSON.stringify(codingComplicationState);

  const handleStateChange = (key, value) => {
    let tempCodingComplicatiomState = JSON.parse(JSON.stringify(codingComplicationState));
    switch (key) {
      // general incident info
      case "incident_type": tempCodingComplicatiomState.general_incident_info.incident_type = value;
        setCodingComplicationState(tempCodingComplicatiomState);
        break;
      case "patient_type": tempCodingComplicatiomState.general_incident_info.patient_type = value;
        setCodingComplicationState(tempCodingComplicatiomState);
        break;
      case "injury_incurred": tempCodingComplicatiomState.general_incident_info.injury_incurred = value;
        setCodingComplicationState(tempCodingComplicatiomState);
        break;
      case "equipment_involved": tempCodingComplicatiomState.general_incident_info.equipment_involved = value;
        setCodingComplicationState(tempCodingComplicatiomState);
        break;
      // demographics
      case "first_name": tempCodingComplicatiomState.demographics_info.first_name = value;
        setCodingComplicationState(tempCodingComplicatiomState);
        break;
      case "last_name": tempCodingComplicatiomState.demographics_info.last_name = value;
        setCodingComplicationState(tempCodingComplicatiomState);
        break;
      case "gender": tempCodingComplicatiomState.demographics_info.gender = value;
        setCodingComplicationState(tempCodingComplicatiomState);
        break;
      case "incident_date": tempCodingComplicatiomState.demographics_info.incident_date = value;
        setCodingComplicationState(tempCodingComplicatiomState);
        break;
      case "address": tempCodingComplicatiomState.demographics_info.address = value;
        setCodingComplicationState(tempCodingComplicatiomState);
        break;
      case "street": tempCodingComplicatiomState.demographics_info.street = value;
        setCodingComplicationState(tempCodingComplicatiomState);
        break;
      case "city": tempCodingComplicatiomState.demographics_info.city = value;
        setCodingComplicationState(tempCodingComplicatiomState);
        break;
      case "state": tempCodingComplicatiomState.demographics_info.state = value;
        setCodingComplicationState(tempCodingComplicatiomState);
        break;
      case "country": tempCodingComplicatiomState.demographics_info.country = value;
        setCodingComplicationState(tempCodingComplicatiomState);
        break;
      case "zipcode": tempCodingComplicatiomState.demographics_info.zipcode = value;
        setCodingComplicationState(tempCodingComplicatiomState);
        break;
      case "addressValues":
        tempCodingComplicatiomState.demographics_info.zipcode = value.zipcode;
        tempCodingComplicatiomState.demographics_info.country = value.country;
        tempCodingComplicatiomState.demographics_info.state = value.state;
        tempCodingComplicatiomState.demographics_info.city = value.city;
        tempCodingComplicatiomState.demographics_info.street = value.street;
        tempCodingComplicatiomState.demographics_info.address = value.address;

        // tempCodingComplicatiomState.demographics_info.address = value;
        setCodingComplicationState(tempCodingComplicatiomState);
        break;

      // incident details
      case "incident_date": tempCodingComplicatiomState.incident_details.incident_date = value;
        setCodingComplicationState(tempCodingComplicatiomState);
        break;
      case "incident_time": tempCodingComplicatiomState.incident_details.incident_time = value;
        setCodingComplicationState(tempCodingComplicatiomState);
        break;
      case "incident_state": tempCodingComplicatiomState.incident_details.incident_state = value;
        setCodingComplicationState(tempCodingComplicatiomState);
        break;
      case "incident_site": tempCodingComplicatiomState.incident_details.incident_site = value;
        setCodingComplicationState(tempCodingComplicatiomState);
        break;
      case "file_owner": tempCodingComplicatiomState.incident_details.file_owner = value;
        setCodingComplicationState(tempCodingComplicatiomState);
        break;
      case "entered_date": tempCodingComplicatiomState.incident_details.entered_date = value;
        setCodingComplicationState(tempCodingComplicatiomState);
        break;
      case "entered_time": tempCodingComplicatiomState.incident_details.entered_time = value;
        setCodingComplicationState(tempCodingComplicatiomState);
        break;
      case "entered_by": tempCodingComplicatiomState.incident_details.entered_by = value;
        setCodingComplicationState(tempCodingComplicatiomState);
        break;
      case "witness_name": tempCodingComplicatiomState.incident_details.witness_name = value;
        setCodingComplicationState(tempCodingComplicatiomState);
        break;
      case "witness_phone": tempCodingComplicatiomState.incident_details.witness_phone = value;
        setCodingComplicationState(tempCodingComplicatiomState);
        break;
      case "witness_address": tempCodingComplicatiomState.incident_details.witness_address = value;
        setCodingComplicationState(tempCodingComplicatiomState);
        break;
      // special incident info

      case "location_of_incident_occurred": tempCodingComplicatiomState.special_additional_info.location_of_incident_occurred = value;
        setCodingComplicationState(tempCodingComplicatiomState);
        break;
      case "department_id": tempCodingComplicatiomState.special_additional_info.department_id = value;
        setCodingComplicationState(tempCodingComplicatiomState);
        break;
      case "type_of_lineOrtube": tempCodingComplicatiomState.special_additional_info.type_of_lineOrtube = value;
        setCodingComplicationState(tempCodingComplicatiomState);
        break;
      case "type_of_restraints": tempCodingComplicatiomState.special_additional_info.type_of_restraints = value;
        setCodingComplicationState(tempCodingComplicatiomState);
        break;
      case "physician_involved": tempCodingComplicatiomState.special_additional_info.physician_involved = value;
        setCodingComplicationState(tempCodingComplicatiomState);
        break;
      case "contributing_factors": tempCodingComplicatiomState.special_additional_info.contributing_factors = value;
        setCodingComplicationState(tempCodingComplicatiomState);
        break;
      case "immediate_actions_taken": tempCodingComplicatiomState.special_additional_info.immediate_actions_taken = value;
        setCodingComplicationState(tempCodingComplicatiomState);
        break;
      case "reported_incident_severity": tempCodingComplicatiomState.special_additional_info.reported_incident_severity = value;
        setCodingComplicationState(tempCodingComplicatiomState);
        break;
      case "incident_description": tempCodingComplicatiomState.special_additional_info.incident_description = value;
        setCodingComplicationState(tempCodingComplicatiomState);
        break;
      default: break;
    }
  }
  useEffect(() => {

  }, []);

  const getCurrentDate = () => {
    const now = new Date();
    const year = now.getFullYear();
    const month = String(now.getMonth() + 1).padStart(2, '0');
    const day = String(now.getDate()).padStart(2, '0');
    return `${year}-${month}-${day}`;

  };



  const putdata = {

    "file_count": 0,
    "is_deleted": false,
    "spec": codingComplicationStateString,
    "updated_by": loginUserId,
    "updated_timestamp": new Date().toISOString('en-US'),
    "files_info": ""

  }

  const handleSubmit = (e) => {
    e.preventDefault();

    let valid = true;
    const namePattern = /^[a-zA-Z]+$/;
    const zipCodePattern = /^\d{6}$/;

    if (!codingComplicationState.general_incident_info.incident_type) {
      setGeneralIncidenterror("Please Select a General Incident Type.");
    } else {
      setGeneralIncidenterror("");
    }
    if (!codingComplicationState.general_incident_info.patient_type) {
      setPatientTypeError("Please Enter Patient Type.");
    } else {
      setPatientTypeError("");
    }
    if (!codingComplicationState.general_incident_info.injury_incurred) {
      setInjuryIncurredError("Please Enter Injury Incurred.");
    } else {
      setInjuryIncurredError("");
    }
    if (!codingComplicationState.general_incident_info.equipment_involved) {
      setEquipmentMalfunctionedError("Please Enter Equipment Involved/ Malfunctioned.");
    } else {
      setEquipmentMalfunctionedError("");
    }

    // demographics info
    // if (!codingComplicationState.demographics_info.first_name.match(namePattern)) {
    //   setFirstNameError("First name should only contain alphabets.");
    //   valid = false;
    // } else {
    //   setFirstNameError("");
    // }
    if (!codingComplicationState.demographics_info.last_name.match(namePattern)) {
      setLastNameError("Please select Last Name and should be Capital Alphabets.");
      valid = false;
    } else {
      setLastNameError("");
    }
    if (!codingComplicationState.incident_details.incident_date) {
      setIncidentdateError("Please Enter Incidentdate.");
    } else {
      setIncidentdateError("");
    }
    if (!codingComplicationState.incident_details.incident_state) {
      setStateIncidentOccuredError("Please Enter State Incident Occured.");
    } else {
      setStateIncidentOccuredError("");
    }

    // if (!codingComplicationState.incident_details.incident_site) {
    //   setSiteIncidentOccuredError("Please Enter Site Incident Occured.");
    // } else {
    //   setSiteIncidentOccuredError("");
    // }

    // special incident info
    if (!codingComplicationState.special_additional_info.specific_incident_type) {
      setSpecificIncidentTypeError("Please select specific incident type.");
    } else {
      setSpecificIncidentTypeError("");
    }
    if (!codingComplicationState.special_additional_info.reported_incident_severity) {
      setReportedIncidentError("Please select reported incident severity.");
    } else {
      setReportedIncidentError("");
    }
    if (!codingComplicationState.special_additional_info.incident_description) {
      setIncidentDescriptionError("Please Enter incident description.");
    } else {
      setIncidentDescriptionError("");
    }

    if (!codingComplicationState.special_additional_info.department_id) {
      setDeptIncidentOccuredError("Please Enter Department.");
    } else {
      setDeptIncidentOccuredError("");
    }
    if (!codingComplicationState.special_additional_info.department_id) {
      setDepartmentError("Select Department.");
    } else {
      setDepartmentError("");
    }

    const validateInputs = () => {
      if (
        !codingComplicationState.general_incident_info.incident_type ||
        !codingComplicationState.general_incident_info.patient_type ||
        !codingComplicationState.general_incident_info.injury_incurred ||
        !codingComplicationState.general_incident_info.equipment_involved ||
        !codingComplicationState.demographics_info.last_name ||
        !codingComplicationState.incident_details.incident_date ||
        !codingComplicationState.incident_details.incident_state ||
        !codingComplicationState.incident_details.incident_site ||
        !codingComplicationState.special_additional_info.specific_incident_type ||
        !codingComplicationState.special_additional_info.reported_incident_severity ||
        !codingComplicationState.special_additional_info.incident_description ||
        !codingComplicationState.special_additional_info.department_id
      ) {
        return false;
      }
      return true;
    };

    if (editIncidentData.incident_id) {
      handleUpdate();
    } else {
      handlePost();
    }

  };

  const [files, setFiles] = useState([]);

  const handleFileUpload = (event) => {
    const selectedFiles = event.target.files;
    if (selectedFiles) {
      setFiles([...files, ...selectedFiles]);
    }
  };
  const handlePost = () => {
    const formData = new FormData();
    var reqb = {
      is_deleted: false,
      spec: codingComplicationStateString,
      created_by: loginUserId,
      updated_by: loginUserId
    }
    formData.append("IncidentInfo", JSON.stringify(reqb));


    files.forEach((file, index) => {
      formData.append(`files`, file);
    });

    // if (!validateInputs()) {
    //   console.log("Form submission failed. Please correct the errors.");
    //   return;
    // }


    axios.post(apis.base + apis.POST_INCIDENT_API, formData, {
      headers: {
        userid: loginUserId,
        Authorization: "Bearer " + baererToken,
        "Content-Type": "multipart/form-data",
      },
    })
      .then((response) => {
        // console.log("Response data:", response.data);
        // console.log("Form submitted successfully!");
        // resetFormState();
        setShowSuccessPopup(true);
        setSnackbarMessage('Form submitted successfully!');
      })
      .catch((error) => {
        console.error("Error submitting form:", error);
      });
  };

  const handleUpdate = () => {
    axios.put(apis.base + apis.PUT_INCIDENT_BYID_API + editIncidentData.incident_id, putdata, {
      headers: {
        userid: loginUserId,
        Authorization: "Bearer " + baererToken
      }
    })

      .then((res) => {
        // console.log("putdata", res);
        // console.log("Form updated successfully!");
        // resetFormState();
        setShowSuccessPopup(true);
        setSnackbarMessage('Feedback updated successfully!');
      })
      .catch((error) => {
        console.error("Error updating form:", error);
      });
  };
  const [selectedFile, setSelectedFile] = useState(null);
  const [previewImage, setPreviewImage] = useState(null);

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      setSelectedFile(file);
      const reader = new FileReader();
      reader.onloadend = () => {
        setPreviewImage(reader.result);
      };
      reader.readAsDataURL(file);
    }
  };

  const handleUpload = () => {
    if (selectedFile) {
      console.log('Selected File:', selectedFile);
      console.log('Preview Image:', previewImage);
    } else {
      console.warn('No file selected for upload.');
    }
  };

  const navigateTo = useNavigate();
  const handleCloseSnackbar = () => {
    setShowSuccessPopup(false);
    navigateTo('/forms')
  };


  const generalIncidentTypeList = [
    { label: 'Coding Complications' }
  ]


  const specialIncidentTypeList = [
    { label: 'Accidental puncture or laceration' },
    { label: 'Air embolism' },
    { label: 'Blood incompatibility' },
    { label: 'Catheter associated UTI' },
    { label: 'Central venous catheter related blood stream infection' },
    { label: 'Death among surgical inpatients' },
    { label: 'Falls and trauma' },
    { label: 'Foreign object retained after surgery' },
    { label: 'Iatrogenic pneumothorax' },
    { label: 'Iatrogenic pneumothorax with venous catheterization' },
  ]

  const typeofLinetube = [
    { label: 'Airway-Endotracheal Tube' },
    { label: 'Airway-Laryngeal Mask Airway' },
    { label: 'Airway-Nasal Airway' },
    { label: 'Airway-Oral Airway' },
    { label: 'Airway-Tracheostomy Airway' },
    { label: 'catheter-IV' },
    { label: 'catheter-Urinary Urethral' },
  ]
  const typeofRestraints = [
    { label: 'Vest' },
    { label: 'Siderailsx4' },
    { label: 'Siderailsx3' },
    { label: 'Siderailsx2' },
    { label: 'Posey Jacket' },
    { label: 'Other' },
    { label: 'None' },
    { label: 'Laptray' },
    { label: 'Lapbelt' },
  ]

  const reportedIncidentSeverityList = [
    { label: '1 - Minor' },
    { label: '2 - Moderate' },
    { label: '3 - Major' },
    { label: 'P3 - Potential Major' },
    { label: '4 - Catastrophic' },
    { label: 'P4-Potential Catastrophic' },
  ]

  const patientTypeList = [
    { label: 'In-Patient' },
    { label: 'Out-Patient' },
  ]


  const [locationData, setLocationData] = useState([])
  const [departmentData, setDepartmentData] = useState([]);
  useEffect(() => {
    axios.get(apis.base + apis.GET_ALL_DEPARTMENT_API, {
      headers: {
        userid: loginUserId,
        Authorization: "Bearer " + baererToken
      }
    })
      .then((response) => {
        setDepartmentData(response.data);
      })
      .catch((errorresp) => {
        console.log(JSON.stringify(errorresp));
      });
   axios.get(apis.base + apis.GET_LOCATION_API, {
      headers: {
        userid: loginUserId,
        Authorization: "Bearer " + baererToken
      }
    })
      .then((response) => {
        setLocationData(response.data);
      })
      .catch((errorresp) => {
        console.log(JSON.stringify(errorresp));
      });
      axios.get(apis.base + apis.GET_INCIDENT_BYID_API + incident_id, {
        headers: {
          userid: loginUserId,
          Authorization: "Bearer " + baererToken
        }
      })
        .then((response) => {
         if (incident_id) {
            setCodingComplicationState(JSON.parse(response.data.form_info.spec));
          }
        })
        .catch((errorresp) => {
          console.log(JSON.stringify(errorresp));
        });
  }, []);

  const handleDeleteFile = (index) => {
    // Create a copy of the files array
    const updatedFiles = [...files];
    // Remove the file at the specified index
    updatedFiles.splice(index, 1);
    // Update the files state with the new array
    setFiles(updatedFiles);
  };

// First name , Last name , File Owner name (first letter should be capitalized )
const capitalizeFirstLetter = (string) => {
  return string.charAt(0).toUpperCase() + string.slice(1);
};
  return (
    <div>
       <div className="page-content">
      <div className="page-inner">
        <div className="page-title">
          <h3>Coding Complications Report Form</h3>
          <div className="page-breadcrumb">
            <ol className="breadcrumb">
              <li><Link to="/Dashboard">Home</Link></li>
              <li><Link to="/forms">Forms</Link></li>
              <li className="active">Coding Complications</li>
            </ol>
          </div>
        </div>

        <div class="col-md-12 ">
          <div class="col-md-12">
            {/* General Incident Type */}
            <form onSubmit={handleSubmit}>
            <div id="main-wrapper">
                  <div class="row ">
                    <div >
                      <div class="panel panel-white">
                        <Accordion defaultExpanded>
                          <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel1-content"
                            id="panel1-header"
                          >
                            <h4 class="panel-title"  >General Incident Information</h4>
                          </AccordionSummary>
                          <AccordionDetails>
                            <div class="panel-body">
                              <div class="form-horizontal col-md-12"  >
                                <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                <div class="form-group col-md-6"  >
                                    <label for="surgeryType"   >General Incident Type <span style={{ fontweight: "bold", color: "red" }} >*</span></label>
                                    <div>
                                      {action==="view"|| action==="edit" ?(
                                      <Autocomplete
                                        disablePortal
                                        options={generalIncidentTypeList}
                                        getOptionLabel={(option) => option.label}
                                        disabled={action == "view"}
                                        onChange={(event, value) => handleStateChange("incident_type", value ? value.label : "")}
                                        renderInput={(params) => (
                                          <TextField
                                            {...params}
                                            placeholder="Please Select incident Type"
                                            fullWidth
                                          />
                                        )}
                                        value={generalIncidentTypeList.find(option => option.label === codingComplicationState.general_incident_info.incident_type) || null}
                                      />):(
                                        <Autocomplete
                                        disablePortal
                                        options={generalIncidentTypeList}
                                        getOptionLabel={(option) => option.label}
                                        disabled={action == "view"}
                                        onChange={(event, value) => handleStateChange("incident_type", value ? value.label : "")}
                                        renderInput={(params) => (
                                          <TextField
                                            {...params}
                                            placeholder="Please Select incident Type"
                                            fullWidth
                                          />
                                        )}
                                      />
                                      )}
                                      <div className="text-danger ">{generalincidenterror}</div>
                                    </div>

                                  </div>

                                  <div class="form-group col-md-6">
                                    <label for="surgeryType" >Patient Type <span style={{ fontweight: "bold", color: "red" }} >*</span></label>
                                   
                                    <div >
                                      {action === "view" || action === "edit" ? (
                                      <Autocomplete
                                        disablePortal
                                        options={patientTypeList}
                                        getOptionLabel={(option) => option.label}
                                        disabled={action == "view"}
                                        onChange={(event, value) => handleStateChange("patient_type", value ? value.label : "")}
                                        renderInput={(params) => (
                                          <TextField
                                            {...params}
                                            placeholder="Please Select patient Type"
                                            fullWidth
                                          />
                                        )}
                                        value={patientTypeList.find(option => option.label === codingComplicationState.general_incident_info.patient_type) || null}
                                      />
                                    ) : (
                                        <Autocomplete
                                        disablePortal
                                        options={patientTypeList}
                                        getOptionLabel={(option) => option.label}
                                        disabled={action == "view"}
                                        onChange={(event, value) => handleStateChange("patient_type", value ? value.label : "")}
                                        renderInput={(params) => (
                                          <TextField
                                            {...params}
                                            placeholder="Please Select patient Type"
                                            fullWidth
                                          />
                                          )}
                                        />
                                      )}
                                      <div className="text-danger ">{patientTypeError}</div>
                                    </div>
                                  </div>
                                </div>

                                <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                  <div class="form-group col-md-6">
                                    <label for="surgeryType" >Injury Incurred <span style={{ fontweight: "bold", color: "red" }} >*</span></label>
                                    <div >
                                      <TextField type="text"
                                        fullWidth
                                        placeholder="Injury Incurred"
                                        disabled={action == "view"}
                                        value={codingComplicationState.general_incident_info.injury_incurred}
                                        onChange={(e) => {
                                          var temp = JSON.parse(JSON.stringify(codingComplicationState))
                                          temp.general_incident_info.injury_incurred = e.target.value;
                                          setCodingComplicationState(temp)
                                        }}
                                      />
                                      <div className="text-danger">{injuryIncurredError}</div>
                                    </div>
                                  </div>
                                  <div class="form-group col-md-6">
                                    <label for="procedureDate" >Equipment Involved/ Malfunctioned <span style={{ fontweight: "bold", color: "red" }} >*</span></label>
                                    <div >
                                      <TextField type="text"
                                        fullWidth
                                        placeholder="Equipment Involved/ Malfunctioned"
                                        disabled={action == "view"}
                                        value={codingComplicationState.general_incident_info.equipment_involved}
                                        onChange={(e) => {
                                          const updatedState = {
                                            ...codingComplicationState,
                                            general_incident_info: {
                                              ...codingComplicationState.general_incident_info,
                                              equipment_involved: e.target.value
                                            }
                                          };
                                          setCodingComplicationState(updatedState);
                                        }}
                                      />
                                      <div className="text-danger">{equipmentMalfunctionedError}</div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </AccordionDetails>
                        </Accordion>
                      </div>
                    </div>
                  </div>
                </div>


                <div id="main-wrapper">
                  <div class="row">
                    <  div>
                      <div class="panel panel-white">
                        <Accordion >
                          <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel1-content"
                            id="panel1-header"
                          >
                            <h4 class="panel-title"  >Demographics</h4>
                          </AccordionSummary>
                          <AccordionDetails>
                            <div class="panel-body">
                              <div class="form-horizontal col-md-12"  >

                                <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                  <div class="form-group col-md-6"  >
                                    <label  >First Name  </label>
                                    <div>
                                      <TextField type="text" fullWidth
                                        placeholder="First Name"

                                        disabled={action == "view"}

                                        value={codingComplicationState.demographics_info.first_name}
                                        onChange={(e) => handleStateChange("first_name", capitalizeFirstLetter(e.target.value))}
                                      />

                                      <div className="text-danger">{firstNameError}</div>
                                    </div>
                                  </div>

                                  <div class="form-group col-md-6">
                                    <label >Last Name<span style={{ fontweight: "bold", color: "red" }} >*</span></label>
                                    <div>
                                      <TextField type="text" fullWidth
                                        placeholder="Last Name"

                                        disabled={action == "view"}

                                        value={codingComplicationState?.demographics_info?.last_name}
                                        onChange={(e) => handleStateChange("last_name", capitalizeFirstLetter(e.target.value))}
                                      />

                                      <div className="text-danger">{lastNameError}</div>
                                    </div>
                                  </div>
                                </div>


                                <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                  <div className="form-group col-md-6">
                                    <label htmlFor="exampleInputName2">Sex</label>
                                    <div>
                                      <label className="col-md-4" >
                                        <input type="radio" name="gender" disabled={action == "view"} checked={codingComplicationState?.demographics_info?.gender === "male"} onChange={() => handleStateChange("gender", "male")} /> Male
                                      </label>
                                      <label className="col-md-4" >
                                        <input type="radio" name="gender" disabled={action == "view"} checked={codingComplicationState?.demographics_info?.gender === "female"} onChange={() => handleStateChange("gender", "female")} /> Female
                                      </label>
                                      <label className="col-md-4" >
                                        <input type="radio" name="gender" disabled={action == "view"} checked={codingComplicationState?.demographics_info?.gender === "others"} onChange={() => handleStateChange("gender", "others")} /> Others
                                      </label>
                                      <div className="text-danger col-md-6 ">{genderError}</div>
                                    </div>

                                  </div>

                                  < div class="form-group col-md-6 " style={{ display: "flex", flexDirection: "column" }}>
                                    <label htmlFor="exampleInputName">DOB
                                      {/* <span style={{ fontweight: "bold", color: "red" }} >*</span> */}
                                    </label>
                                    <DatePicker className='form-control'
                                      fullWidth
                                      disabled={action == "view"}
                                      selected={codingComplicationState?.demographics_info?.dob}
                                      onChange={(date) => {
                                        setCodingComplicationState({
                                          ...codingComplicationState,
                                          demographics_info: {
                                            ...codingComplicationState?.demographics_info,
                                            dob: date,
                                          },
                                        });
                                      }}
                                      dateFormat="MM/dd/yyyy"
                                      placeholderText="MM/DD/YYYY"
                                      maxDate={new Date()}
                                    />
                                    {/* <div className="text-danger">{dobError}</div> */}

                                  </div>
                                </div>


                                <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                  <div className=" form-group col-md-6" style={{ position: "relative" }}>
                                  <label >Address</label>
                                    {action === "view" ? (
                                      <div>
                                      <GooglePlacesAutocomplete
                                        apiKey="AIzaSyD9S5rAJ1RNWrG3fHjDKU8m2khTUxcr5u8"
                                        selectProps={{
                                          onChange: (e, a, b) => {
                                            handleStateChange("address", e.value)
                                            geocodeByPlaceId(e.value.place_id)
                                              .then(results => {
                                                // console.log(results)
                                                handleStateChange("addressValues", {
                                                  "zipcode": results[0].address_components.find(component => component.types.includes("postal_code")).long_name,
                                                  "country": results[0].address_components.find(component => component.types.includes("country")).long_name,
                                                  "state": results[0].address_components.find(component => component.types.includes("administrative_area_level_1")).long_name,
                                                  "city": results[0].address_components.find(component => component.types.includes("locality")).long_name,
                                                  "street": results[0].address_components.find(component => component.types.includes("route")).long_name,
                                                })
                                              })
                                              .catch(error => console.error(error));
                                          }

                                        }}
                                      />
                                      <div style={{ position: "absolute", width: "95%", height: "37px", background: "black", opacity: "0.1", zIndex: 2, top: "24px" }}>
                                    </div>
                                    </div>
                                    ) : (
                                    <GooglePlacesAutocomplete
                                      apiKey="AIzaSyD9S5rAJ1RNWrG3fHjDKU8m2khTUxcr5u8"
                                      selectProps={{
                                        onChange: (e, a, b) => {
                                          handleStateChange("address", e.value)
                                          geocodeByPlaceId(e.value.place_id)
                                            .then(results => {
                                              // console.log(results)
                                              handleStateChange("addressValues", {
                                                "zipcode": results[0].address_components.find(component => component.types.includes("postal_code")).long_name,
                                                "country": results[0].address_components.find(component => component.types.includes("country")).long_name,
                                                "state": results[0].address_components.find(component => component.types.includes("administrative_area_level_1")).long_name,
                                                "city": results[0].address_components.find(component => component.types.includes("locality")).long_name,
                                                "street": results[0].address_components.find(component => component.types.includes("route")).long_name,
                                              })
                                            })
                                            .catch(error => console.error(error));
                                        }

                                      }}
                                    />
                       )}
                                  </div>

                                  <div class="form-group col-md-6">
                                    <label >Street</label>
                                    <div>
                                      <TextField type="text" fullWidth placeholder="Street"
                                        value={codingComplicationState?.demographics_info?.street}
                                        onChange={(e) => handleStateChange("street", e.target.value)}
                                        disabled={action == "view"}
                                      />
                                      <div className="text-danger">{streetError}</div>
                                    </div>
                                  </div>

                                </div>


                                <div style={{ display: 'flex', gap:'40px',width:'100%' }}>
                                  <div class="form-group col-md-6">
                                    <label for="exampleInputName">City</label>
                                    <TextField type="text" fullWidth placeholder="City"
                                      value={codingComplicationState?.demographics_info?.city}
                                      onChange={(e) => handleStateChange("city", e.target.value)}
                                      disabled={action == "view"}
                                    />
                                    <div className="text-danger">{cityError}</div>
                                  </div>


                                  <div class="form-group  col-md-6">
                                    <label for="exampleInputName2">State/Region</label>
                                    <TextField type="text" fullWidth placeholder="State/Region"
                                      value={codingComplicationState?.demographics_info?.state}
                                      onChange={(e) => handleStateChange("state", e.target.value)}
                                      disabled={action == "view"}
                                    />
                                    <div className="text-danger">{stateError}</div>
                                  </div>
                                  <div class="form-group  col-md-6">
                                    <label for="exampleInputName2">Zip code</label>
                                    <TextField
                                      placeholder='Zip Code'
                                      fullWidth
                                      type="text"
                                      disabled={action == "view"}
                                      value={codingComplicationState?.demographics_info?.zipcode}
                                      onChange={(event) => {
                                        const value = event.target.value;
                                        if (/^\d{0,5}$/.test(value)) {
                                          setInputValue(value);
                                          handleStateChange("zipcode", value);
                                        }
                                      }}
                                      inputProps={{
                                        pattern: '[0-9]*',
                                      }}
                                    />
                                  </div>
                                  
                                </div>



                                <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                  {/* <div class="form-group col-md-6">
                                    <label for="exampleInputName">Country</label>
                                    <TextField type="text" fullWidth placeholder="Country"
                                      value={codingComplicationState?.demographics_info?.country}
                                      onChange={(e) => handleStateChange("country", e.target.value)}
                                      disabled={action == "view"}

                                    />
                                    <div className="text-danger">{countryError}</div>
                                  </div> */}
                                  {/* <div class="form-group  col-md-6">
                                    <label for="exampleInputName2">Zip code</label>
                                    <TextField
                                      placeholder='Zip Code'
                                      fullWidth
                                      type="text"
                                      disabled={action == "view"}
                                      value={codingComplicationState?.demographics_info?.zipcode}
                                      onChange={(event) => {
                                        const value = event.target.value;
                                        if (/^\d{0,5}$/.test(value)) {
                                          setInputValue(value);
                                          handleStateChange("zipcode", value);
                                        }
                                      }}
                                      inputProps={{
                                        pattern: '[0-9]*',
                                      }}
                                    />
                                  </div> */}
                                </div>
                              </div>
                            </div>
                          </AccordionDetails>
                        </Accordion>
                      </div>
                    </div>
                  </div>
                </div>


                <div id="main-wrapper">
                  <div class="row">
                    <div>
                      <div class="panel panel-white">
                        <Accordion>
                          <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel1-content"
                            id="panel1-header"
                          >
                            <h4 class="panel-title"  >Incident  Details</h4>
                          </AccordionSummary>
                          <AccordionDetails>
                            <div class="panel-body">
                              <div class="form-horizontal col-md-12">
                                <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                  <div class="form-group col-md-6" style={{ display: "flex", flexDirection: "column" }}>
                                    <label htmlFor="surgeryType"  >Incident Date <span style={{ fontweight: "bold", color: "red" }} >*</span></label>

                                    <DatePicker className='form-control'
                                      fullWidth
                                      disabled={action == "view"}
                                      selected={codingComplicationState?.incident_details?.incident_date}
                                      onChange={(date) => {
                                        setCodingComplicationState({
                                          ...codingComplicationState,
                                          incident_details: {
                                            ...codingComplicationState?.incident_details,
                                            incident_date: date,
                                          },
                                        });
                                      }}
                                      dateFormat="MM/dd/yyyy"
                                      placeholderText="MM/DD/YYYY"
                                      maxDate={new Date()}
                                    />


                                    <div className="text-danger">{incidentdateError}</div>
                                  </div>
                                  <div class="form-group col-md-6">
                                    <label for="surgeryType" > Incident Time</label>
                                    <div>
                                      <Box sx={{ minWidth: 120 }}>
                                        <FormControl fullWidth>
                                          <TextField
                                            disabled={action == "view"}
                                            type="time"
                                            defaultValue={getCurrentTime()}
                                            onChange={(e) =>
                                              handleStateChange(
                                                "incident_time",
                                                e.target.value
                                              )
                                            }
                                            InputLabelProps={{
                                              shrink: true,
                                            }}
                                            InputProps={{
                                              inputProps: {
                                                max: getCurrentTime(),
                                              },
                                            }}
                                          />
                                        </FormControl>
                                      </Box>
                                    </div>
                                  </div>
                                </div>

                                <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                  <div class="form-group col-md-6">
                                    <label for="procedureDate"  >State Where Incident Occurred at<span style={{ fontweight: "bold", color: "red" }} >*</span></label>
                                    <div>
                                      {action === "view" || action === "edit" ? (
                                        <Autocomplete
                                          options={locationData}
                                          getOptionLabel={(location) => location?.state}

                                          disabled={action == "view"}

                                          onChange={(e, value) => {
                                            handleStateChange("incident_state", value ? value.state : 0);
                                          }}
                                          renderInput={(params) => (
                                            <TextField
                                              {...params}
                                              placeholder="Please Select State Where Incident Occured"
                                              variant="outlined" />
                                          )}
                                          // defaultValue={{ label: codingComplicationState.incident_details.incident_state }}
                                          value={locationData.filter(location => {
                                            return location.location_state == codingComplicationState.incident_details.location_state
                                          })[0] || null}
                                          defaultValue={
                                            locationData.filter(location => {
                                              return location.location_state == codingComplicationState.incident_details.location_state
                                            })[0] || {}
                                          }
                                        />) : (
                                        <Autocomplete
                                          options={locationData}
                                          getOptionLabel={(location) => location?.state}
                                          disabled={action == "view"}
                                          onChange={(e, value) => {
                                            handleStateChange("incident_state", value ? value.state : 0);
                                          }}
                                          renderInput={(params) => (
                                            <TextField
                                              {...params}
                                              placeholder="Please Select State Where Incident Occured"
                                              variant="outlined" />

                                          )}
                                        />
                                      )}
                                      <div className="text-danger">{stateincidentoccuredError}</div>
                                    </div>
                                  </div>

                                  <div class="form-group col-md-6">
                                    <label for="procedureDate"  >Site Where Incident Occured 
                                      {/* <span style={{ fontweight: "bold", color: "red" }} >*</span> */}
                                      </label>
                                    <div>
                                    {action === "view" || action === "edit" ? ( 
                                        <Autocomplete
                                          options={locationData}
                                          getOptionLabel={(location) => location?.location_name}
                                          disabled={action == "view"}
                                          onChange={(e, value) => {
                                            handleStateChange("incident_site", value ? value.location_name : 0);
                                          }}
                                          renderInput={(params) => (
                                            <TextField
                                              {...params}
                                              placeholder="Please Select Site Where Incident Occured"
                                              variant="outlined"  />
                                          )}
                                         
                                          value={locationData.filter(locationsite => {
                                            return locationsite.location?.location_name == codingComplicationState.incident_details.location?.location_name
                                          })[0] || null}
                                          defaultValue={
                                            locationData.filter(locationsite => {
                                              return locationsite.location?.location_name == codingComplicationState.incident_details.location?.location_name
                                            })[0] || {}
                                          }
                                        />) : (
                                        <Autocomplete
                                          options={locationData}
                                          getOptionLabel={(location) => location?.location_name}
                                          disabled={action == "view"}
                                          onChange={(e, value) => {
                                            handleStateChange("incident_site", value ? value.location_id : 0);
                                          }}
                                          renderInput={(params) => (
                                            <TextField
                                              {...params}
                                              placeholder="Please Select Site Where Incident Occured"
                                              variant="outlined" x />
                                          )}
                                        />
                                      )}

                                      <div className="text-danger">{siteincidentoccuredError}</div>
                                    </div>
                                  </div>
                                </div>

                                <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                  <div class="form-group col-md-6">
                                    <label for="procedureDate"  >File Owner </label>
                                    <div>
                                      <TextField type="text" placeholder='File Owner' fullWidth
                                        value={codingComplicationState?.incident_details?.file_owner}
                                        onChange={(e) => handleStateChange("file_owner", capitalizeFirstLetter(e.target.value))}
                                        disabled={action == "view"}
                                      />
                                    </div>
                                  </div>

                                  <div class="form-group col-md-6">
                                    <label for="procedureDate"  >Entered By</label>
                                    <div>
                                      <TextField type="text" placeholder='Entered By' fullWidth
                                        value={codingComplicationState?.incident_details?.entered_by}
                                        onChange={(e) => handleStateChange("entered_by", e.target.value)}
                                        disabled={action == "view"}
                                      />
                                    </div>
                                  </div>
                                </div>


                                <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                  <div class="form-group col-md-6" style={{ display: "flex", flexDirection: "column" }}>
                                    <label htmlFor="procedureDate"  >Entered Date</label>

                                    <DatePicker className='form-control'
                                      fullWidth
                                      disabled={action == "view"}
                                      selected={codingComplicationState?.incident_details?.entered_date}
                                      onChange={(date) => {
                                        setCodingComplicationState({
                                          ...codingComplicationState,
                                          incident_details: {
                                            ...codingComplicationState.incident_details,
                                            entered_date: date,
                                          },
                                        });
                                      }}
                                      dateFormat="MM/dd/yyyy"
                                      placeholderText="MM/DD/YYYY"
                                      maxDate={new Date()}
                                    />

                                  </div>


                                  <div class="form-group col-md-6">
                                    <label for="procedureDate">
                                      Entered Time (Estern Time Zone)
                                    </label>
                                    <div>
                                      <Box sx={{ minWidth: 120 }}>
                                        <FormControl fullWidth>
                                          <TextField
                                            disabled={action == "view"}
                                            type="time"
                                            defaultValue={getCurrentTime()}
                                            onChange={(e) =>
                                              handleStateChange(
                                                "entered_time",
                                                e.target.value
                                              )
                                            }
                                            InputLabelProps={{
                                              shrink: true,
                                            }}
                                            InputProps={{
                                              inputProps: {
                                                max: getCurrentTime(),
                                              },
                                            }}
                                          />
                                        </FormControl>
                                      </Box>
                                    </div>
                                  </div>
                                </div>

                                <div style={{ display: 'flex', justifyContent: "space-between" }}>
                                  <div class="form-group col-md-6">
                                    <label for="procedureDate"  >Witness Name</label>
                                    <div>
                                      <TextField type="text" placeholder='Witness Name' fullWidth
                                        value={codingComplicationState?.incident_details?.witness_name}
                                        onChange={(e) => handleStateChange("witness_name", e.target.value)}
                                        disabled={action == "view"}
                                      />
                                    </div>
                                  </div>


                                  <div class="form-group col-md-6">
                                    <label for="procedureDate"  >Witness Phone</label>
                                    <div>

                                      <TextField
                                        placeholder='Witness Phone'
                                        fullWidth
                                        disabled={action == "view"}
                                        type="text"
                                        value={codingComplicationState?.incident_details?.witness_phone}
                                        onChange={(event) => {
                                          const value = event.target.value;
                                          if (/^\d{0,10}$/.test(value)) {
                                            setPhoneNumber(value);
                                            handleStateChange("witness_phone", value);
                                          }
                                        }}
                                        inputProps={{
                                          pattern: '[0-9]*',
                                        }}
                                      />

                                    </div>
                                  </div>
                                </div>

                                <div class="form-group">
                                  <label for="procedureDate" style={{ marginLeft: '15px' }}>Witness Address </label>
                                  <div
                                    class="col-md-12"
                                  >
                                    <TextField type="text" placeholder='Witness Address' fullWidth
                                      value={codingComplicationState?.incident_details?.witness_address}
                                      onChange={(e) => handleStateChange("witness_address", e.target.value)}
                                      disabled={action == "view"}
                                    />
                                  </div>
                                </div>
                              </div>
                            </div>
                          </AccordionDetails>
                        </Accordion>
                      </div>
                    </div>
                  </div>
                </div>

              <div id="main-wrapper">
                <div class="row">
                  <div >
                    <div class="panel panel-white">
                      <Accordion>
                        <AccordionSummary
                          expandIcon={<ExpandMoreIcon />}
                          aria-controls="panel1-content"
                          id="panel1-header"
                        >
                          <h4 class="panel-title"  >Special Additional Information</h4>
                        </AccordionSummary>
                        <AccordionDetails>

                          <div class="panel-body">
                            <div class="form-horizontal col-md-12">
                              <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                              <div class="form-group col-md-6">
                                    <label for="exampleInputPassword1"   >Department<span style={{ fontweight: "bold", color: "red" }} >*</span></label>
                                    <div >
                                      {action === "view" || action === "edit" ? (

                                        <Autocomplete
                                          disabled={action == "view"}
                                          options={departmentData}
                                          getOptionLabel={(department) => department.department_name}
                                          onChange={(e, value) => {
                                            handleStateChange("department_id", value ? value.department_id : 0);
                                          }}
                                          renderInput={(params) => (
                                            <TextField
                                              {...params}
                                              placeholder="Please Select department"
                                              variant="outlined" x />
                                          )}
                                          value={departmentData.filter(department => {
                                            return department.department_id == codingComplicationState.special_additional_info.department_id
                                          })[0] || null}
                                          defaultValue={
                                            departmentData.filter(department => {
                                              return department.department_id == codingComplicationState.special_additional_info.department_id
                                            })[0] || {}
                                          }
                                        />
                                      ) : (
                                        <Autocomplete
                                          options={departmentData}
                                          getOptionLabel={(department) => department.department_name}
                                          onChange={(e, value) => {
                                            handleStateChange("department_id", value ? value.department_id : 0);
                                          }}
                                          renderInput={(params) => (
                                            <TextField
                                              {...params}
                                              placeholder="Please Select department"
                                              variant="outlined"
                                            />
                                          )}
                                        />
                                      )}
                                    </div>
                                    <div className="text-danger">{departmentError}</div>
                                  </div>
                                  <div class="form-group col-md-6">
                                  <label for="procedureDate" style={{ textAlign: "start" }}>Location of incident occurred</label>
                                  <div >
                                    <TextField type="text" fullWidth placeholder="Patient room, corridor"
                                      value={codingComplicationState?.special_additional_info?.location_of_incident_occurred}
                                      onChange={(e) => handleStateChange("location_of_incident_occurred", e.target.value)}
                                      disabled={action == "view"}
                                    />
                                  </div>
                                </div>

                                {/* <div class="form-group col-md-6" >
                                  <label for="exampleInputPassword1" style={{ textAlign: "start" }} >Specific Incident Type<span style={{ fontweight: "bold", color: "red" }} >*</span></label>
                                  <div>
                                  {action === "view" || action === "edit" ? (

                                    <Autocomplete
                                      disablePortal
                                      disabled={action == "view"}
                                      options={specialIncidentTypeList}
                                      getOptionLabel={(option) => option.label}
                                      onChange={(event, value) => handleStateChange("specific_incident_type", value ? value.label : "")}
                                      renderInput={(params) => (
                                        <TextField
                                          {...params}
                                          placeholder="Please Select Specific Incident Type"
                                          fullWidth
                                        />
                                      )}
                                      value={specialIncidentTypeList.find(option => option.label === codingComplicationState.special_additional_info.specific_incident_type) || null}

                                    />):(
                                      <Autocomplete
                                      disablePortal
                                      options={specialIncidentTypeList}
                                      getOptionLabel={(option) => option.label}
                                      onChange={(event, value) => handleStateChange("specific_incident_type", value ? value.label : "")}
                                      renderInput={(params) => (
                                        <TextField
                                          {...params}
                                          placeholder="Please Select Specific Incident Type"
                                          fullWidth
                                        />
                                      )}

                                    />
                                    )}
                                  </div>
                                  <div className="text-danger">{specificIncidentTypetError}</div>

                                </div> */}
                              </div>
                              <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                <div class="form-group col-md-6" >
                                  <label style={{ textAlign: "start" }}>Type of Line/Tube</label>
                                  <div >
                                  {action === "view" || action === "edit" ? (

                                    <Autocomplete
                                      disablePortal
                                      disabled={action == "view"}
                                      options={typeofLinetube}
                                      getOptionLabel={(option) => option.label}
                                      onChange={(event, value) => handleStateChange("type_of_lineOrtube", value ? value.label : "")}
                                      renderInput={(params) => (
                                        <TextField
                                          {...params}
                                          placeholder="Please Select Type of Line/Tube"
                                          fullWidth
                                        />
                                      )}
                                      value={typeofLinetube.find(option => option.label === codingComplicationState.special_additional_info.type_of_lineOrtube) || null}

                                   
                                    />):(
                                      <Autocomplete
                                      disablePortal
                                      options={typeofLinetube}
                                      getOptionLabel={(option) => option.label}
                                      onChange={(event, value) => handleStateChange("type_of_lineOrtube", value ? value.label : "")}
                                      renderInput={(params) => (
                                        <TextField
                                          {...params}
                                          placeholder="Please Select Type of Line/Tube"
                                          fullWidth
                                        />
                                      )}
                                    
                                    />
                                    )}
                                  </div>
                                </div>
                                <div class="form-group col-md-6" >
                                  <label style={{ textAlign: "start" }}>Type of Restraints </label>
                                  <div>
                                  {action === "view" || action === "edit" ? (

                                    <Autocomplete
                                      disablePortal
                                      disabled={action == "view"}
                                      options={typeofRestraints}
                                      getOptionLabel={(option) => option.label}
                                      onChange={(event, value) => handleStateChange("type_of_restraints", value ? value.label : "")}
                                      renderInput={(params) => (
                                        <TextField
                                          {...params}
                                          placeholder="Please select Type of Restraints"
                                          fullWidth
                                        />
                                      )}
                                      value={typeofRestraints.find(option => option.label === codingComplicationState.special_additional_info.type_of_restraints) || null}

                                    />):(
                                      <Autocomplete
                                      disablePortal
                                      
                                     
                                      options={typeofRestraints}
                                      getOptionLabel={(option) => option.label}
                                      onChange={(event, value) => handleStateChange("type_of_restraints", value ? value.label : "")}
                                      renderInput={(params) => (
                                        <TextField
                                          {...params}
                                          placeholder="Please select Type of Restraints"
                                          fullWidth
                                        />
                                      )}

                                    />
                                    )}
                                  </div>
                                </div>
                              </div>
                              <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                <div class="form-group col-md-6">
                                  <label for="procedureDate" class=" control-label" style={{ textAlign: "start" }}>Was a physician directly involved in Incident</label>
                                  <div style={{ marginTop: '10px' }}>

                                    <label className="col-md-3">
                                      <input type="radio" name="physician_involved" disabled={action == "view"} checked={codingComplicationState.special_additional_info.physician_involved === true} onChange={() => handleStateChange("physician_involved", true)} /> Yes
                                    </label>
                                    <label className="col-md-3">
                                      <input type="radio" name="physician_involved" disabled={action == "view"} checked={codingComplicationState.special_additional_info.physician_involved === false} onChange={() => handleStateChange("physician_involved", false)} /> No

                                    </label>
                                  </div>
                                </div>
                                <div class="form-group col-md-6">
                                  <label for="procedureDate" style={{ textAlign: "start" }}>Contributing Factors</label>
                                  <div >
                                    <TextField type="text" fullWidth placeholder="Enter Contributing Factors"
                                      value={codingComplicationState?.special_additional_info?.contributing_factors}
                                      onChange={(e) => handleStateChange("contributing_factors", e.target.value)}
                                      disabled={action == "view"}
                                    />
                                  </div>
                                </div>
                              </div>

                              <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                <div class="form-group col-md-6">
                                  <label style={{ textAlign: "start" }}>Immediate Actions Taken</label>
                                  <div >
                                    <TextField type="text" fullWidth placeholder="Enter Immediate Actions Taken"
                                      value={codingComplicationState?.special_additional_info?.immediate_actions_taken}
                                      onChange={(e) => handleStateChange("immediate_actions_taken", e.target.value)}
                                      disabled={action == "view"}
                                    />
                                  </div>
                                </div>
                                <div class="form-group col-md-6">
                                  <label style={{ textAlign: "start" }}>Incident Description - use SBAR
                                    {/* (Situation,Background, Assessment,Recommendation)  */}
                                    <span style={{ fontweight: "bold", color: "red" }} >*</span></label>
                                  <div >

                                    <TextField type="text" fullWidth placeholder="Enter Incident Description..."
                                      value={codingComplicationState?.special_additional_info?.incident_description}
                                      onChange={(e) => handleStateChange("incident_description", e.target.value)} 
                                      disabled={action == "view"}/>
                                    
                                  </div>
                                  <div className="text-danger">{incidentdescriptionError}</div>
                                </div>

                              </div>
                              <div style={{ display: 'flex', justifyContent: 'space-between' }}>

                                <div class="form-group col-md-6">

                                  <label style={{ textAlign: "start" }}>Reported Incident Severity <span style={{ fontweight: "bold", color: "red" }} >*</span></label>
                                  <div >
                                  {action === "view" || action === "edit" ? (

                                    <Autocomplete
                                      disablePortal
                                      disabled={action == "view"}
                                      options={reportedIncidentSeverityList}
                                      getOptionLabel={(option) => option.label}
                                      onChange={(event, value) => handleStateChange("reported_incident_severity", value ? value.label : "")}
                                      renderInput={(params) => (
                                        <TextField
                                          {...params}
                                          placeholder="Please Select reported incident severity"
                                          fullWidth
                                        />
                                      )}
                                      value={reportedIncidentSeverityList.find(option => option.label === codingComplicationState.special_additional_info.reported_incident_severity) || null}

                                    />):(
                                      <Autocomplete
                                      disablePortal 
                                      options={reportedIncidentSeverityList}
                                      getOptionLabel={(option) => option.label}
                                      onChange={(event, value) => handleStateChange("reported_incident_severity", value ? value.label : "")}
                                      renderInput={(params) => (
                                        <TextField
                                          {...params}
                                          placeholder="Please Select reported incident severity"
                                          fullWidth
                                        />
                                      )}
                                    
                                    />
                                    )}
                                  </div>
                                  <div className="text-danger" >{reportedIncidentError}</div>
                                </div>



                                <div className="form-group col-md-6">
                                  <label htmlFor="procedureDate" style={{ textAlign: "start" }}>File Upload</label>
                                  <div>
                                    <input type="file" disabled={action == "view"} style={{ marginTop: "10px", color: 'transparent' }} multiple onChange={handleFileUpload} />
                                    {files.length > 0 ? (
                                      <ol>
                                        {files.map((file, index) => (
                                          <li disabled={action === "view"} key={index}>
                                            {file.name}
                                            <IconButton disabled={action === "view"} onClick={() => handleDeleteFile(index)} aria-label="delete">
                                              <DeleteIcon />
                                            </IconButton>
                                          </li>
                                        ))}
                                      </ol>
                                    ) : (
                                      <p>No files chosen</p>
                                    )}
                                  </div>
                                </div>

                              </div>
                            </div>

                            {/* <div style={{ marginLeft: "50%" }}>
                          <Link to="/Alerts">
                            <button type="submit" onClick={handleSubmit}
                              class="btn btn-success">Submit</button></Link>
               
                        </div> */}

                          </div>
                        </AccordionDetails>
                      </Accordion>
                    </div>

                  </div>

                </div>
              </div>

              <div style={{ marginLeft: "45%" }}>
              {canSubmit && (
                <Button
                disabled={action === "view"}
                  variant="contained"
                  type="submit"
                  style={{ height: "35px", width: "auto", fontSize: "15px", background: "#22baa0" }}
                  onClick={handleSubmit}
                >
                  {editIncidentData.incident_id ? "Update" : "Submit"}
                </Button>
              )}
                {/* </Link> */}
              </div>
            </form>
            <Snackbar
              open={showSuccessPopup}
              // autoHideDuration={6000}
              onClose={handleCloseSnackbar}
              anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
            >
              <MuiAlert onClose={handleCloseSnackbar}
                severity="success"
                variant="filled"
                sx={{ width: '100%' }}
                style={{ width: '300px', backgroundColor: "whitesmoke", color: "green", height: '45px', fontSize: '12px' }}>
                {snackbarMessage}
              </MuiAlert>
            </Snackbar>
          </div>
        </div>
      </div>
    </div >
    </div>

  );
}

export default CodingComplicationsForm