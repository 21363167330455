import React, { useState, useEffect } from 'react';
import { Link, useParams } from "react-router-dom";
import axios from 'axios';
import { apis } from '../../configuration/configurationAPI';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Box from '@mui/material/Box';
import FormControl from '@mui/material/FormControl';
import { Button, TextField, Snackbar, } from "@mui/material";
import Autocomplete from '@mui/material/Autocomplete';
import MuiAlert from '@material-ui/lab/Alert';
import { useNavigate } from 'react-router-dom';
import { MenuItem } from "@material-ui/core";
import DatePicker from 'react-datepicker';
import { IconButton } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import GooglePlacesAutocomplete, { geocodeByPlaceId } from 'react-google-places-autocomplete';

import { useSelector } from 'react-redux';
import store from "../../utils/index";
import { commonSelectors } from "../../utils/commonstore";

const dispatch = store.dispatch;
let commonStore = store.getState().commonStore;
store.subscribe(function () {
  commonStore = store.getState().commonStore;
});

const AdverseDrugForm = () => {

  const { action, incident_id } = useParams();
  const editIncidentData = useSelector(commonSelectors.selectEditIncidentData);
  const viewIncidentData = useSelector(commonSelectors.selectViewIncidentData);
  const navigateTo = useNavigate();
  const accessInfo = useSelector(commonSelectors.selectAccessInfo);

  const getCurrentTime = () => {
    const now = new Date();
    const utcOffset = -4 * 60; // UTC offset for Eastern Time Zone (-4 hours)
    const utcTime = now.getTime() + (now.getTimezoneOffset() * 60 * 1000);
    const estTime = new Date(utcTime + (utcOffset * 60 * 1000));
    const hours = String(estTime.getHours()).padStart(2, '0');
    const minutes = String(estTime.getMinutes()).padStart(2, '0');
    return `${hours}:${minutes}`;
  };
  const [enteredTime, setEnteredTime] = useState(getCurrentTime());
  const [incidentTime, setIncidentTime] = useState(getCurrentTime());

  // general incident info
  const [generalincidenterror, setGeneralIncidenterror] = useState("");
  const [patientTypeError, setPatientTypeError] = useState("");
  const [injuryIncurredError, setInjuryIncurredError] = useState("");
  const [equipmentMalfunctionedError, setEquipmentMalfunctionedError] = useState("");

  // demographics info
  const [firstNameError, setFirstNameError] = useState("");
  const [lastNameError, setLastNameError] = useState("");
  const [genderError, SetGenderError] = useState("");
  const [dobError, setdobError] = useState("");
  const [addressError, setaddressError] = useState("");
  const [streetError, setstreetError] = useState("");
  const [cityError, setcityError] = useState("");
  const [stateError, setstateError] = useState("");
  const [countryError, setcountryError] = useState("");
  const [zipCodeError, setzipCodeError] = useState("");

  // incident details
  const [incidentdateError, setIncidentdateError] = useState("");
  const [stateincidentoccuredError, setStateIncidentOccuredError] = useState("");
  const [siteincidentoccuredError, setSiteIncidentOccuredError] = useState("");

  // special incident info
  const [departmentError, setDepartmentError] = useState("");
  const [specificIncidentTypetError, setSpecificIncidentTypeError] = useState("");
  const [reportedIncidentError, setReportedIncidentError] = useState("");
  const [incidentdescriptionError, setIncidentDescriptionError] = useState("");
  const [selectedFiles, setSelectedFiles] = useState([]);
  const [showSuccessPopup, setShowSuccessPopup] = useState("");
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [patientweightError, setpatientweightError] = useState("");

  const [inputValue, setInputValue] = useState("");
  const [weightinputValue, setWeightInputValue] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");

  const baererToken = useSelector(commonSelectors.selectBaererToken);
  const loginUserId = useSelector(commonSelectors.selectLoginUserId);

  const [adverseDrugFormState, setAdverseDrugFormState] = useState(


    {
      general_incident_info: {
        "incident_type": "",
        "patient_type": "",
        "injury_incurred": "",
        "equipment_involved": "",
      },
      "demographics_info": {
        "first_name": "",
        "last_name": "",
        "gender": 0,
        "dob": null,
        "address": "",
        "street": "",
        "city": "",
        "state": "",
        "country": "",
        "zipcode": "",
      },
      "incident_details": {
        "incident_date": new Date().toISOString(),
        "incident_time": getCurrentTime(),
        "incident_state": 0,
        "incident_site": 0,
        "file_owner": "",
        "entered_date": new Date(),
        "entered_time": getCurrentTime(),
        "entered_by": "",
        "witness_name": "",
        "witness_phone": "",
        "witness_address": ""
      },
      "special_additional_info": {
        "department_id": 0,
        // "specific_incident_type": "",
        "location_of_incident_occurred":"",
        "reaction_type": "",
        "medication_details": "",
        "patient_weight": "",
        "treatment_of_reaction": "",
        "patient_outcome": "",
        "present_on_admission": "",
        "adr_reported_externally": false,
        "adr_preventable": false,
        "physician_involved": false,
        "contributing_factors": "",
        "immediate_actions_taken": "",
        "reported_incident_severity": "",
        "casuality_or_probability": "",
        "incident_description": "",
        "attachment": [],
      },
    }

  );
  //                      vced, 1010 and 1011 for update button directly 
  const createAllowed = ["1100", "1101", "1110", "1111"];

  // Check if the user has the permission to submit (create or update)
  const isAnonymousUser = JSON.parse(sessionStorage.getItem('rl6User'))?.isVisitor;
  const canSubmit = isAnonymousUser || (accessInfo?.forms && createAllowed.includes(accessInfo.forms));

  // Determine if it's an "edit-only" permission (like 1010)
  const isEditOnly = accessInfo?.forms === "1010" || accessInfo?.forms === "1011";



  const adverseDrugFormStateString = JSON.stringify(adverseDrugFormState);

  const handleStateChange = (key, value) => {
    let tempAdverseDrugFormState = JSON.parse(JSON.stringify(adverseDrugFormState));
    switch (key) {
      // general incident info
      case "incident_type": tempAdverseDrugFormState.general_incident_info.incident_type = value;
        setAdverseDrugFormState(tempAdverseDrugFormState);
        break;
      case "patient_type": tempAdverseDrugFormState.general_incident_info.patient_type = value;
        setAdverseDrugFormState(tempAdverseDrugFormState);
        break;
      case "injury_incurred": tempAdverseDrugFormState.general_incident_info.injury_incurred = value;
        setAdverseDrugFormState(tempAdverseDrugFormState);
        break;
      case "equipment_involved": tempAdverseDrugFormState.general_incident_info.equipment_involved = value;
        setAdverseDrugFormState(tempAdverseDrugFormState);
        break;
      // demographics
      case "first_name":
        tempAdverseDrugFormState.demographics_info.first_name = value;
        setAdverseDrugFormState(tempAdverseDrugFormState);
        break;
      case "last_name":
        tempAdverseDrugFormState.demographics_info.last_name = value;
        setAdverseDrugFormState(tempAdverseDrugFormState);
        break;
      case "gender":
        tempAdverseDrugFormState.demographics_info.gender = value;
        setAdverseDrugFormState(tempAdverseDrugFormState);
        break;
      case "dob":
        tempAdverseDrugFormState.demographics_info.dob = value;
        setAdverseDrugFormState(tempAdverseDrugFormState);
        break;
      case "address":
        tempAdverseDrugFormState.demographics_info.address = value;
        setAdverseDrugFormState(tempAdverseDrugFormState);
        break;
      case "street":
        tempAdverseDrugFormState.demographics_info.street = value;
        setAdverseDrugFormState(tempAdverseDrugFormState);
        break;
      case "city":
        tempAdverseDrugFormState.demographics_info.city = value;
        setAdverseDrugFormState(tempAdverseDrugFormState);
        break;
      case "state":
        tempAdverseDrugFormState.demographics_info.state = value;
        setAdverseDrugFormState(tempAdverseDrugFormState);
        break;
      case "country":
        tempAdverseDrugFormState.demographics_info.country = value;
        setAdverseDrugFormState(tempAdverseDrugFormState);
        break;
      case "zipcode":
        tempAdverseDrugFormState.demographics_info.zipcode = value;
        setAdverseDrugFormState(tempAdverseDrugFormState);
        break;
      case "addressValues":
        tempAdverseDrugFormState.demographics_info.zipcode = value.zipcode;
        tempAdverseDrugFormState.demographics_info.country = value.country;
        tempAdverseDrugFormState.demographics_info.state = value.state;
        tempAdverseDrugFormState.demographics_info.city = value.city;
        tempAdverseDrugFormState.demographics_info.street = value.street;
        tempAdverseDrugFormState.demographics_info.address = value.address;
        // tempAdverseDrugFormState.demographics_info.address = value;
        setAdverseDrugFormState(tempAdverseDrugFormState);
        break;

      // incident details
      case "incident_date":
        tempAdverseDrugFormState.incident_details.incident_date = value;
        setAdverseDrugFormState(tempAdverseDrugFormState);
        break;
      case "incident_time":
        tempAdverseDrugFormState.incident_details.incident_time = value;
        setAdverseDrugFormState(tempAdverseDrugFormState);
        break;
      case "incident_state":
        tempAdverseDrugFormState.incident_details.incident_state = value;
        setAdverseDrugFormState(tempAdverseDrugFormState);
        break;
      case "incident_site":
        tempAdverseDrugFormState.incident_details.incident_site = value;
        setAdverseDrugFormState(tempAdverseDrugFormState);
        break;
      case "file_owner":
        tempAdverseDrugFormState.incident_details.file_owner = value;
        setAdverseDrugFormState(tempAdverseDrugFormState);
        break;
      case "entered_date":
        tempAdverseDrugFormState.incident_details.entered_date = value;
        setAdverseDrugFormState(tempAdverseDrugFormState);
        break;
      case "entered_time":
        tempAdverseDrugFormState.incident_details.entered_time = value;
        setAdverseDrugFormState(tempAdverseDrugFormState);
        break;
      case "entered_by":
        tempAdverseDrugFormState.incident_details.entered_by = value;
        setAdverseDrugFormState(tempAdverseDrugFormState);
        break;
      case "witness_name":
        tempAdverseDrugFormState.incident_details.witness_name = value;
        setAdverseDrugFormState(tempAdverseDrugFormState);
        break;
      case "witness_phone":
        tempAdverseDrugFormState.incident_details.witness_phone = value;
        setAdverseDrugFormState(tempAdverseDrugFormState);
        break;
      case "witness_address":
        tempAdverseDrugFormState.incident_details.witness_address = value;
        setAdverseDrugFormState(tempAdverseDrugFormState);
        break;

      // special incident info
      case "department_id":
        tempAdverseDrugFormState.special_additional_info.department_id = value;
        setAdverseDrugFormState(tempAdverseDrugFormState);
        break;
      case "location_of_incident_occurred":
        tempAdverseDrugFormState.special_additional_info.location_of_incident_occurred =
          value;
        setAdverseDrugFormState(tempAdverseDrugFormState);
        break;
      case "reaction_type":
        tempAdverseDrugFormState.special_additional_info.reaction_type = value;
        setAdverseDrugFormState(tempAdverseDrugFormState);
        break;
      case "medication_details":
        tempAdverseDrugFormState.special_additional_info.medication_details =
          value;
        setAdverseDrugFormState(tempAdverseDrugFormState);
        break;
      case "patient_weight":
        tempAdverseDrugFormState.special_additional_info.patient_weight = value;
        setAdverseDrugFormState(tempAdverseDrugFormState);
        break;
      case "treatment_of_reaction":
        tempAdverseDrugFormState.special_additional_info.treatment_of_reaction =
          value;
        setAdverseDrugFormState(tempAdverseDrugFormState);
        break;
      case "patient_outcome":
        tempAdverseDrugFormState.special_additional_info.patient_outcome = value;
        setAdverseDrugFormState(tempAdverseDrugFormState);
        break;
      case "present_on_admission":
        tempAdverseDrugFormState.special_additional_info.present_on_admission =
          value;
        setAdverseDrugFormState(tempAdverseDrugFormState);
        break;
      case "adr_reported_externally":
        tempAdverseDrugFormState.special_additional_info.adr_reported_externally =
          value;
        setAdverseDrugFormState(tempAdverseDrugFormState);
        break;
      case "adr_preventable":
        tempAdverseDrugFormState.special_additional_info.adr_preventable =
          value;
        setAdverseDrugFormState(tempAdverseDrugFormState);
        break;
      case "physician_involved":
        tempAdverseDrugFormState.special_additional_info.physician_involved =
          value;
        setAdverseDrugFormState(tempAdverseDrugFormState);
        break;
      case "contributing_factors":
        tempAdverseDrugFormState.special_additional_info.contributing_factors =
          value;
        setAdverseDrugFormState(tempAdverseDrugFormState);
        break;
      case "immediate_actions_taken":
        tempAdverseDrugFormState.special_additional_info.immediate_actions_taken =
          value;
        setAdverseDrugFormState(tempAdverseDrugFormState);
        break;
      case "reported_incident_severity":
        tempAdverseDrugFormState.special_additional_info.reported_incident_severity =
          value;
        setAdverseDrugFormState(tempAdverseDrugFormState);
        break;
      case "casuality_or_probability":
        tempAdverseDrugFormState.special_additional_info.casuality_or_probability =
          value;
        setAdverseDrugFormState(tempAdverseDrugFormState);
        break;
      case "incident_description":
        tempAdverseDrugFormState.special_additional_info.incident_description =
          value;
        setAdverseDrugFormState(tempAdverseDrugFormState);
        break;
      default: break;
    }
  }






  const handleSubmit = (e) => {

    e.preventDefault();

    let valid = true;
    const namePattern = /^[a-zA-Z]+$/;
    const zipCodePattern = /^\d{6}$/;

    if (!adverseDrugFormState.general_incident_info.incident_type) {
      setGeneralIncidenterror("Please Select a General Incident Type.");
    } else {
      setGeneralIncidenterror("");
    }
    if (!adverseDrugFormState.general_incident_info.patient_type) {
      setPatientTypeError("Please Enter Patient Type.");
    } else {
      setPatientTypeError("");
    }
    if (!adverseDrugFormState.general_incident_info.injury_incurred) {
      setInjuryIncurredError("Please Enter Injury Incurred.");
    } else {
      setInjuryIncurredError("");
    }
    if (!adverseDrugFormState.general_incident_info.equipment_involved) {
      setEquipmentMalfunctionedError(
        "Please Enter Equipment Involved/ Malfunctioned."
      );
    } else {
      setEquipmentMalfunctionedError("");
    }

    if (!adverseDrugFormState.demographics_info.last_name.match(namePattern)) {
      setLastNameError("Last name should only contain alphabets.");
      valid = false;
    } else {
      setLastNameError("");
    }


    // incidentdetails
    if (!adverseDrugFormState.incident_details.incident_date) {
      setIncidentdateError("Please Enter Incidentdate.");
    } else {
      setIncidentdateError("");
    }
    if (!adverseDrugFormState.incident_details.incident_state) {
      setStateIncidentOccuredError("Please Enter State Incident Occured.");
    } else {
      setStateIncidentOccuredError("");
    }

    // if (!adverseDrugFormState.incident_details.incident_site) {
    //   setSiteIncidentOccuredError("Please Enter Site Incident Occured.");
    // } else {
    //   setSiteIncidentOccuredError("");
    // }

    // special incident info
    // if (!adverseDrugFormState.special_additional_info.specific_incident_type) {
    //   setSpecificIncidentTypeError("Please select specific incident type.");
    // } else {
    //   setSpecificIncidentTypeError("");
    // }
    if (!adverseDrugFormState.special_additional_info.department_id) {
      setDepartmentError("Please select the department.");
    } else {
      setDepartmentError("");
    }
    if (
      !adverseDrugFormState.special_additional_info.reported_incident_severity
    ) {
      setReportedIncidentError("Please select reported incident severity.");
    } else {
      setReportedIncidentError("");
    }
    if (!adverseDrugFormState.special_additional_info.incident_description) {
      setIncidentDescriptionError("Please Enter incident description.");
    } else {
      setIncidentDescriptionError("");
    }

    if (!(adverseDrugFormState.special_additional_info.patient_weight >= 1 && adverseDrugFormState.special_additional_info.patient_weight <= 500)) {
      setpatientweightError("Weight must be between 1 and 500 lbs");
    } else {
      setpatientweightError("");
    }
    const validateInputs = () => {
      if (
        !adverseDrugFormState.general_incident_info.incident_type ||
        !adverseDrugFormState.general_incident_info.patient_type ||
        !adverseDrugFormState.general_incident_info.injury_incurred ||
        !adverseDrugFormState.general_incident_info.equipment_involved ||
        !adverseDrugFormState.demographics_info.last_name ||


        !adverseDrugFormState.incident_details.incident_date ||
        !adverseDrugFormState.incident_details.incident_state ||
        !adverseDrugFormState.incident_details.incident_site ||

        !adverseDrugFormState.special_additional_info.location_of_incident_occurred ||
        !adverseDrugFormState.special_additional_info.department_id ||
        !adverseDrugFormState.special_additional_info.reported_incident_severity ||
        !adverseDrugFormState.special_additional_info.patient_weight ||
        !adverseDrugFormState.special_additional_info.incident_description
      ) {
        return false;
      }
      return true;
    };

    if (!validateInputs()) {
      // console.log("Form submission failed. Please correct the errors.");
      return;
    }
    if (editIncidentData.incident_id) {
      handleUpdate();
    } else {
      handlePost();
    }
  };
  const putdata = {

    "file_count": 0,
    "is_deleted": false,
    "spec": adverseDrugFormStateString,
    "updated_by": loginUserId,
    "updated_timestamp": new Date().toISOString('en-US'),
    "files_info": ""

  }

  const [files, setFiles] = useState([]);

  const handleFileUpload = (event) => {
    const selectedFiles = event.target.files;
    if (selectedFiles) {
      setFiles([...files, ...selectedFiles]);
    }
  };
  const handlePost = () => {
    const formData = new FormData();
    var reqb = {
      is_deleted: false,
      spec: adverseDrugFormStateString,
      created_by: loginUserId,
      updated_by: loginUserId
    }
    formData.append("IncidentInfo", JSON.stringify(reqb));


    files.forEach((file, index) => {
      formData.append(`files`, file);
    });


    axios.post(apis.base + apis.POST_INCIDENT_API, formData, {
      headers: {
        userid: loginUserId,
        Authorization: "Bearer " + baererToken,
        "Content-Type": "multipart/form-data",
      },
    })
      .then((response) => {
        // console.log("Response data:", response.data);
        // console.log("Form submitted successfully!");
        setShowSuccessPopup(true);
        setSnackbarMessage("Form submitted successfully!");
      })
      .catch((error) => {
        console.error("Error submitting form:", error);
      });
  };

  const handleUpdate = () => {
    axios.put(apis.base + apis.PUT_INCIDENT_BYID_API + editIncidentData.incident_id, putdata, {
      headers: {
        userid: loginUserId,
        Authorization: "Bearer " + baererToken
      }
    })
      .then((res) => {
        // console.log("putdata", res);
        // console.log("Form updated successfully!");
        // resetFormState();
        setShowSuccessPopup(true);
        setSnackbarMessage('Feedback updated successfully!');
      })
      .catch((error) => {
        console.error("Error updating form:", error);
      });
  }
  const [selectedFile, setSelectedFile] = useState(null);
  const [previewImage, setPreviewImage] = useState(null);

  const handleCloseSnackbar = () => {
    setShowSuccessPopup(false);
    navigateTo('/forms')
  };

  const [locationData, setLocationData] = useState([])
  const [departmentData, setDepartmentData] = useState([]);
  useEffect(() => {
    axios.get(apis.base + apis.GET_ALL_DEPARTMENT_API, {
      // headers: {
      //   userid: loginUserId,
      //   Authorization: "Bearer " + baererToken
      // }
    })
      .then((response) => {
        setDepartmentData(response.data);
      })
      .catch((errorresp) => {
        console.log(JSON.stringify(errorresp));
      });

    axios.get(apis.base + apis.GET_LOCATION_API, {
      // headers: {
      //   userid: loginUserId,
      //   Authorization: "Bearer " + baererToken
      // }
    })
      .then((response) => {
        setLocationData(response.data);
      })
      .catch((errorresp) => {
        console.log(JSON.stringify(errorresp));
      });
    axios.get(apis.base + apis.GET_INCIDENT_BYID_API + incident_id, {
      headers: {
        userid: loginUserId,
        Authorization: "Bearer " + baererToken
      }
    })
      .then((response) => {
        if (incident_id) {
          setAdverseDrugFormState(JSON.parse(response.data.form_info.spec));
        }
      })
      .catch((errorresp) => {
        console.log(JSON.stringify(errorresp));
      });
  }, []);

  const handleDeleteFile = (index) => {
    const updatedFiles = [...files];
    updatedFiles.splice(index, 1);
    setFiles(updatedFiles);
  };


  const patientTypeList = [{ label: "In-Patient" }, { label: "Out-Patient" }];

  const reportedIncidentSeverityList = [
    { label: '1 - Minor' },
    { label: '2 - Moderate' },
    { label: '3 - Major' },
    { label: 'P3 - Potential Major' },
    { label: '4 - Catastrophic' },
    { label: 'P4-Potential Catastrophic' },
  ];

  const specificIncidentTypeList = [
    { label: "Accidental puncture or laceration" },
    { label: "Air embolism" },
    { label: "Blood incompatibility" },
    { label: "Catheter associated UTI" },
    { label: "Central venous catheter related blood stream infection" },
    { label: "Death among surgical inpatients" },
    { label: "Falls and trauma" },
    { label: "Foreign object retained after surgery" },
    { label: "Iatrogenic pneumothorax" },
    { label: "Iatrogenic pneumothorax with venous catheterization" },
  ];

  const generalIncidentTypeList = [
    { label: 'Adverse Drug' }

  ]

  const TreatmentOfReactionList = [
    { label: "Accidental puncture or laceration" },
    { label: "Air Embolism" },
    { label: "Blood incompatability" },
    { label: "Death among surgicial inpatients" },
    { label: "Falls and trauma" },
  ]

  // First name , Last name , File Owner name (first letter should be capitalized )
  const capitalizeFirstLetter = (string) => {
    return string.charAt(0).toUpperCase() + string.slice(1);
  };

  return (
    <div>
      <div className="page-content">

        <div className="page-inner">
          <div className="page-title">
            <h3>Adverse Drug Form</h3>
            <div className="page-breadcrumb">
              <ol className="breadcrumb">
                <li>
                  <Link to="/Dashboard">Home</Link>
                </li>
                <li>
                  <Link to="/forms">Forms</Link>
                </li>
                <li className="active">Adverse Drug</li>
              </ol>
            </div>
          </div>

          <div class="col-md-12 ">
            <div class="col-md-12">
              <form onSubmit={handleSubmit}>
                <div id="main-wrapper">
                  <div class="row ">
                    <div >
                      <div class="panel panel-white">
                        <Accordion defaultExpanded>
                          <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel1-content"
                            id="panel1-header"
                          >
                            <h4 class="panel-title"  >General Incident Information</h4>
                          </AccordionSummary>
                          <AccordionDetails>
                            <div class="panel-body">
                              <div class="form-horizontal col-md-12"  >
                                <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                  <div class="form-group col-md-6"  >
                                    <label for="surgeryType"   >General Incident Type <span style={{ fontweight: "bold", color: "red" }} >*</span></label>
                                    <div>
                                      {action === "view" || action === "edit" ? (
                                        <Autocomplete
                                          disablePortal
                                          options={generalIncidentTypeList}
                                          getOptionLabel={(option) => option.label}
                                          disabled={action == "view"}
                                          onChange={(event, value) => handleStateChange("incident_type", value ? value.label : "")}
                                          renderInput={(params) => (
                                            <TextField
                                              {...params}
                                              placeholder="Please Select incident Type"
                                              fullWidth
                                            />
                                          )}
                                          value={generalIncidentTypeList.find(option => option.label === adverseDrugFormState.general_incident_info.incident_type) || null}
                                        />) : (
                                        <Autocomplete
                                          disablePortal
                                          options={generalIncidentTypeList}
                                          getOptionLabel={(option) => option.label}
                                          disabled={action == "view"}
                                          onChange={(event, value) => handleStateChange("incident_type", value ? value.label : "")}
                                          renderInput={(params) => (
                                            <TextField
                                              {...params}
                                              placeholder="Please Select incident Type"
                                              fullWidth
                                            />
                                          )}
                                        />
                                      )}




                                      <div className="text-danger ">{generalincidenterror}</div>
                                    </div>

                                  </div>

                                  <div class="form-group col-md-6">
                                    <label for="surgeryType" >Patient Type <span style={{ fontweight: "bold", color: "red" }} >*</span></label>

                                    <div >
                                      {action === "view" || action === "edit" ? (
                                        <Autocomplete
                                          disablePortal
                                          options={patientTypeList}
                                          getOptionLabel={(option) => option.label}
                                          disabled={action == "view"}
                                          onChange={(event, value) => handleStateChange("patient_type", value ? value.label : "")}
                                          renderInput={(params) => (
                                            <TextField
                                              {...params}
                                              placeholder="Please Select patient Type"
                                              fullWidth
                                            />
                                          )}
                                          value={patientTypeList.find(option => option.label === adverseDrugFormState.general_incident_info.patient_type) || null}
                                        />
                                      ) : (
                                        <Autocomplete
                                          disablePortal
                                          options={patientTypeList}
                                          getOptionLabel={(option) => option.label}
                                          disabled={action == "view"}
                                          onChange={(event, value) => handleStateChange("patient_type", value ? value.label : "")}
                                          renderInput={(params) => (
                                            <TextField
                                              {...params}
                                              placeholder="Please Select patient Type"
                                              fullWidth
                                            />
                                          )}
                                        />
                                      )}
                                      <div className="text-danger ">{patientTypeError}</div>
                                    </div>
                                  </div>
                                </div>

                                <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                  <div class="form-group col-md-6">
                                    <label for="surgeryType" >Injury Incurred <span style={{ fontweight: "bold", color: "red" }} >*</span></label>
                                    <div >
                                      <TextField type="text"
                                        fullWidth
                                        placeholder="Injury Incurred"
                                        disabled={action == "view"}
                                        value={adverseDrugFormState.general_incident_info.injury_incurred}
                                        onChange={(e) => {
                                          var temp = JSON.parse(JSON.stringify(adverseDrugFormState))
                                          temp.general_incident_info.injury_incurred = e.target.value;
                                          setAdverseDrugFormState(temp)
                                        }}
                                      />
                                      <div className="text-danger">{injuryIncurredError}</div>
                                    </div>
                                  </div>
                                  <div class="form-group col-md-6">
                                    <label for="procedureDate" >Equipment Involved/ Malfunctioned <span style={{ fontweight: "bold", color: "red" }} >*</span></label>
                                    <div >
                                      <TextField type="text"
                                        fullWidth
                                        placeholder="Equipment Involved/ Malfunctioned"
                                        disabled={action == "view"}
                                        value={adverseDrugFormState.general_incident_info.equipment_involved}
                                        onChange={(e) => {
                                          const updatedState = {
                                            ...adverseDrugFormState,
                                            general_incident_info: {
                                              ...adverseDrugFormState.general_incident_info,
                                              equipment_involved: e.target.value
                                            }
                                          };
                                          setAdverseDrugFormState(updatedState);
                                        }}
                                      />
                                      <div className="text-danger">{equipmentMalfunctionedError}</div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </AccordionDetails>
                        </Accordion>
                      </div>
                    </div>
                  </div>
                </div>


                <div id="main-wrapper">
                  <div class="row">
                    <  div>
                      <div class="panel panel-white">
                        <Accordion >
                          <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel1-content"
                            id="panel1-header"
                          >
                            <h4 class="panel-title"  >Demographics</h4>
                          </AccordionSummary>
                          <AccordionDetails>
                            <div class="panel-body">
                              <div class="form-horizontal col-md-12"  >

                                <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                  <div className="form-group col-md-6">
                                    <label>First Name</label>
                                    <div>
                                      <TextField
                                        type="text"
                                        fullWidth
                                        placeholder="First Name"
                                        disabled={action === "view"}
                                        value={adverseDrugFormState.demographics_info.first_name}
                                        onChange={(e) => handleStateChange("first_name", capitalizeFirstLetter(e.target.value))}
                                      />
                                      <div className="text-danger">{firstNameError}</div>
                                    </div>
                                  </div>

                                  <div className="form-group col-md-6">
                                    <label>
                                      Last Name<span style={{ fontWeight: "bold", color: "red" }}>*</span>
                                    </label>
                                    <div>
                                      <TextField
                                        type="text"
                                        fullWidth
                                        placeholder="Last Name"
                                        disabled={action === "view"}
                                        value={adverseDrugFormState?.demographics_info?.last_name}
                                        onChange={(e) => handleStateChange("last_name", capitalizeFirstLetter(e.target.value))}
                                      />
                                      <div className="text-danger">{lastNameError}</div>
                                    </div>
                                  </div>
                                </div>



                                <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                  <div className="form-group col-md-6">
                                    <label htmlFor="exampleInputName2">Sex</label>
                                    <div>
                                      <label className="col-md-4" >
                                        <input type="radio" name="gender" disabled={action == "view"} checked={adverseDrugFormState?.demographics_info?.gender === "male"} onChange={() => handleStateChange("gender", "male")} /> Male
                                      </label>
                                      <label className="col-md-4" >
                                        <input type="radio" name="gender" disabled={action == "view"} checked={adverseDrugFormState?.demographics_info?.gender === "female"} onChange={() => handleStateChange("gender", "female")} /> Female
                                      </label>
                                      <label className="col-md-4" >
                                        <input type="radio" name="gender" disabled={action == "view"} checked={adverseDrugFormState?.demographics_info?.gender === "others"} onChange={() => handleStateChange("gender", "others")} /> Others
                                      </label>
                                      <div className="text-danger col-md-6 ">{genderError}</div>
                                    </div>

                                  </div>

                                  < div class="form-group col-md-6 " style={{ display: "flex", flexDirection: "column" }}>
                                    <label htmlFor="exampleInputName">DOB
                                      {/* <span style={{ fontweight: "bold", color: "red" }} >*</span> */}
                                    </label>
                                    <DatePicker className='form-control'
                                      fullWidth
                                      disabled={action == "view"}
                                      selected={adverseDrugFormState?.demographics_info?.dob}
                                      onChange={(date) => {
                                        setAdverseDrugFormState({
                                          ...adverseDrugFormState,
                                          demographics_info: {
                                            ...adverseDrugFormState?.demographics_info,
                                            dob: date,
                                          },
                                        });
                                      }}
                                      dateFormat="MM/dd/yyyy"
                                      placeholderText="MM/DD/YYYY"
                                      maxDate={new Date()}
                                    />
                                    {/* <div className="text-danger">{dobError}</div> */}

                                  </div>
                                </div>


                                <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                  <div className=" form-group col-md-6" style={{ position: "relative" }}>
                                    <label >Address</label>
                                    {action === "view" ? (
                                      <div>
                                        <GooglePlacesAutocomplete
                                          apiKey="AIzaSyD9S5rAJ1RNWrG3fHjDKU8m2khTUxcr5u8"
                                          selectProps={{
                                            onChange: (e, a, b) => {
                                              handleStateChange("address", e.value)
                                              geocodeByPlaceId(e.value.place_id)
                                                .then(results => {
                                                  // console.log(results)
                                                  handleStateChange("addressValues", {
                                                    "zipcode": results[0].address_components.find(component => component.types.includes("postal_code")).long_name,
                                                    "country": results[0].address_components.find(component => component.types.includes("country")).long_name,
                                                    "state": results[0].address_components.find(component => component.types.includes("administrative_area_level_1")).long_name,
                                                    "city": results[0].address_components.find(component => component.types.includes("locality")).long_name,
                                                    "street": results[0].address_components.find(component => component.types.includes("route")).long_name,
                                                  })
                                                })
                                                .catch(error => console.error(error));
                                            }

                                          }}
                                        />
                                        <div style={{ position: "absolute", width: "95%", height: "37px", background: "black", opacity: "0.1", zIndex: 2, top: "24px" }}>
                                        </div>
                                      </div>
                                    ) : (
                                      <GooglePlacesAutocomplete
                                        apiKey="AIzaSyD9S5rAJ1RNWrG3fHjDKU8m2khTUxcr5u8"
                                        selectProps={{
                                          onChange: (e, a, b) => {
                                            handleStateChange("address", e.value)
                                            geocodeByPlaceId(e.value.place_id)
                                              .then(results => {
                                                // console.log(results)
                                                handleStateChange("addressValues", {
                                                  "zipcode": results[0].address_components.find(component => component.types.includes("postal_code")).long_name,
                                                  "country": results[0].address_components.find(component => component.types.includes("country")).long_name,
                                                  "state": results[0].address_components.find(component => component.types.includes("administrative_area_level_1")).long_name,
                                                  "city": results[0].address_components.find(component => component.types.includes("locality")).long_name,
                                                  "street": results[0].address_components.find(component => component.types.includes("route")).long_name,
                                                })
                                              })
                                              .catch(error => console.error(error));
                                          }

                                        }}
                                      />
                                    )}
                                  </div>

                                  <div class="form-group col-md-6">
                                    <label >Street</label>
                                    <div>
                                      <TextField type="text" fullWidth placeholder="Street"
                                        value={adverseDrugFormState?.demographics_info?.street}
                                        onChange={(e) => handleStateChange("street", e.target.value)}
                                        disabled={action == "view"}
                                      />
                                      <div className="text-danger">{streetError}</div>
                                    </div>
                                  </div>

                                </div>


                                <div style={{ display: 'flex',gap:'40px',width:'100%' }}>
                                  <div class="form-group col-md-6">
                                    <label for="exampleInputName">City</label>
                                    <TextField type="text" fullWidth placeholder="City"
                                      value={adverseDrugFormState?.demographics_info?.city}
                                      onChange={(e) => handleStateChange("city", e.target.value)}
                                      disabled={action == "view"}
                                    />
                                    <div className="text-danger">{cityError}</div>
                                  </div>


                                  <div class="form-group  col-md-6">
                                    <label for="exampleInputName2">State/Region</label>
                                    <TextField type="text" fullWidth placeholder="State/Region"
                                      value={adverseDrugFormState?.demographics_info?.state}
                                      onChange={(e) => handleStateChange("state", e.target.value)}
                                      disabled={action == "view"}
                                    />
                                    <div className="text-danger">{stateError}</div>
                                  </div>
                                  <div class="form-group  col-md-6">
                                    <label for="exampleInputName2">Zip code</label>
                                    <TextField
                                      placeholder='Zip Code'
                                      fullWidth
                                      type="text"
                                      disabled={action == "view"}
                                      value={adverseDrugFormState?.demographics_info?.zipcode}
                                      onChange={(event) => {
                                        const value = event.target.value;
                                        if (/^\d{0,5}$/.test(value)) {
                                          setInputValue(value);
                                          handleStateChange("zipcode", value);
                                        }
                                      }}
                                      inputProps={{
                                        pattern: '[0-9]*',
                                      }}
                                    />
                                  </div>
                                </div>



                                <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                  {/* <div class="form-group col-md-6">
                                    <label for="exampleInputName">Country</label>
                                    <TextField type="text" fullWidth placeholder="Country"
                                      value={adverseDrugFormState?.demographics_info?.country}
                                      onChange={(e) => handleStateChange("country", e.target.value)}
                                      disabled={action == "view"}

                                    />
                                    <div className="text-danger">{countryError}</div>
                                  </div> */}
                                  {/* <div class="form-group  col-md-6">
                                    <label for="exampleInputName2">Zip code</label>
                                    <TextField
                                      placeholder='Zip Code'
                                      fullWidth
                                      type="text"
                                      disabled={action == "view"}
                                      value={adverseDrugFormState?.demographics_info?.zipcode}
                                      onChange={(event) => {
                                        const value = event.target.value;
                                        if (/^\d{0,5}$/.test(value)) {
                                          setInputValue(value);
                                          handleStateChange("zipcode", value);
                                        }
                                      }}
                                      inputProps={{
                                        pattern: '[0-9]*',
                                      }}
                                    />
                                  </div> */}
                                </div>
                              </div>
                            </div>
                          </AccordionDetails>
                        </Accordion>
                      </div>
                    </div>
                  </div>
                </div>


                <div id="main-wrapper">
                  <div class="row">
                    <div>
                      <div class="panel panel-white">
                        <Accordion>
                          <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel1-content"
                            id="panel1-header"
                          >
                            <h4 class="panel-title"  >Incident  Details</h4>
                          </AccordionSummary>
                          <AccordionDetails>
                            <div class="panel-body">
                              <div class="form-horizontal col-md-12">
                                <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                  <div class="form-group col-md-6" style={{ display: "flex", flexDirection: "column" }}>
                                    <label htmlFor="surgeryType"  >Incident Date <span style={{ fontweight: "bold", color: "red" }} >*</span></label>

                                    <DatePicker className='form-control'
                                      fullWidth
                                      disabled={action == "view"}
                                      selected={adverseDrugFormState?.incident_details?.incident_date}
                                      onChange={(date) => {
                                        setAdverseDrugFormState({
                                          ...adverseDrugFormState,
                                          incident_details: {
                                            ...adverseDrugFormState?.incident_details,
                                            incident_date: date,
                                          },
                                        });
                                      }}
                                      dateFormat="MM/dd/yyyy"
                                      placeholderText="MM/DD/YYYY"
                                      maxDate={new Date()}
                                    />


                                    <div className="text-danger">{incidentdateError}</div>
                                  </div>
                                  <div class="form-group col-md-6">
                                    <label for="surgeryType" > Incident Time</label>
                                    <div>
                                      <Box sx={{ minWidth: 120 }}>
                                        <FormControl fullWidth>
                                          <TextField
                                            disabled={action == "view"}
                                            type="time"
                                            defaultValue={getCurrentTime()}
                                            onChange={(e) =>
                                              handleStateChange(
                                                "incident_time",
                                                e.target.value
                                              )
                                            }
                                            InputLabelProps={{
                                              shrink: true,
                                            }}
                                            InputProps={{
                                              inputProps: {
                                                max: getCurrentTime(),
                                              },
                                            }}
                                          />
                                        </FormControl>
                                      </Box>
                                    </div>
                                  </div>
                                </div>

                                <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                  <div class="form-group col-md-6">
                                    <label for="procedureDate"  >State Where Incident Occurred at<span style={{ fontweight: "bold", color: "red" }} >*</span></label>
                                    <div>
                                      {action === "view" || action === "edit" ? (
                                        <Autocomplete
                                          options={locationData}
                                          getOptionLabel={(location) => location?.state}

                                          disabled={action == "view"}

                                          onChange={(e, value) => {
                                            handleStateChange("incident_state", value ? value.state : 0);
                                          }}
                                          renderInput={(params) => (
                                            <TextField
                                              {...params}
                                              placeholder="Please Select State Where Incident Occured"
                                              variant="outlined" />
                                          )}
                                          // defaultValue={{ label: adverseDrugFormState.incident_details.incident_state }}
                                          value={locationData.filter(location => {
                                            return location.location_state == adverseDrugFormState.incident_details.location_state
                                          })[0] || null}
                                          defaultValue={
                                            locationData.filter(location => {
                                              return location.location_state == adverseDrugFormState.incident_details.location_state
                                            })[0] || {}
                                          }
                                        />) : (
                                        <Autocomplete
                                          options={locationData}
                                          getOptionLabel={(location) => location?.state}
                                          disabled={action == "view"}
                                          onChange={(e, value) => {
                                            handleStateChange("incident_state", value ? value.state : 0);
                                          }}
                                          renderInput={(params) => (
                                            <TextField
                                              {...params}
                                              placeholder="Please Select State Where Incident Occured"
                                              variant="outlined" />

                                          )}
                                        />
                                      )}
                                      <div className="text-danger">{stateincidentoccuredError}</div>
                                    </div>
                                  </div>

                                  <div class="form-group col-md-6">
                                    <label for="procedureDate"  >Site Where Incident Occured
                                       {/* <span style={{ fontweight: "bold", color: "red" }} >*</span> */}
                                       </label>
                                    <div>
                                      {action === "view" || action === "edit" ? (
                                        <Autocomplete
                                          options={locationData}
                                          getOptionLabel={(location) => location?.location_name}
                                          disabled={action == "view"}
                                          onChange={(e, value) => {
                                            handleStateChange("incident_site", value ? value.location_name : 0);
                                          }}
                                          renderInput={(params) => (
                                            <TextField
                                              {...params}
                                              placeholder="Please Select Site Where Incident Occured"
                                              variant="outlined" />
                                          )}

                                          value={locationData.filter(locationsite => {
                                            return locationsite.location?.location_name == adverseDrugFormState.incident_details.location?.location_name
                                          })[0] || null}
                                          defaultValue={
                                            locationData.filter(locationsite => {
                                              return locationsite.location?.location_name == adverseDrugFormState.incident_details.location?.location_name
                                            })[0] || {}
                                          }
                                        />) : (
                                        <Autocomplete
                                          options={locationData}
                                          getOptionLabel={(location) => location?.location_name}
                                          disabled={action == "view"}
                                          onChange={(e, value) => {
                                            handleStateChange("incident_site", value ? value.location_id : 0);
                                          }}
                                          renderInput={(params) => (
                                            <TextField
                                              {...params}
                                              placeholder="Please Select Site Where Incident Occured"
                                              variant="outlined" x />
                                          )}
                                        />
                                      )}

                                      <div className="text-danger">{siteincidentoccuredError}</div>
                                    </div>
                                  </div>
                                </div>

                                <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                  <div class="form-group col-md-6">
                                    <label for="procedureDate"  >File Owner </label>
                                    <div>
                                      <TextField type="text" placeholder='File Owner' fullWidth
                                        value={adverseDrugFormState?.incident_details?.file_owner}
                                        onChange={(e) => handleStateChange("file_owner", capitalizeFirstLetter(e.target.value))}

                                        disabled={action == "view"}
                                      />
                                    </div>
                                  </div>

                                  <div class="form-group col-md-6">
                                    <label for="procedureDate"  >Entered By</label>
                                    <div>
                                      <TextField type="text" placeholder='Entered By' fullWidth
                                        value={adverseDrugFormState?.incident_details?.entered_by}
                                        onChange={(e) => handleStateChange("entered_by", e.target.value)}
                                        disabled={action == "view"}
                                      />
                                    </div>
                                  </div>
                                </div>


                                <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                  <div class="form-group col-md-6" style={{ display: "flex", flexDirection: "column" }}>
                                    <label htmlFor="procedureDate"  >Entered Date</label>

                                    <DatePicker className='form-control'
                                      fullWidth
                                      disabled={action == "view"}
                                      selected={adverseDrugFormState?.incident_details?.entered_date}
                                      onChange={(date) => {
                                        setAdverseDrugFormState({
                                          ...adverseDrugFormState,
                                          incident_details: {
                                            ...adverseDrugFormState.incident_details,
                                            entered_date: date,
                                          },
                                        });
                                      }}
                                      dateFormat="MM/dd/yyyy"
                                      placeholderText="MM/DD/YYYY"
                                      maxDate={new Date()}
                                    />

                                  </div>


                                  <div class="form-group col-md-6">
                                    <label for="procedureDate">
                                      Entered Time (Estern Time Zone)
                                    </label>
                                    <div>
                                      <Box sx={{ minWidth: 120 }}>
                                        <FormControl fullWidth>
                                          <TextField
                                            disabled={action == "view"}
                                            type="time"
                                            defaultValue={getCurrentTime()}
                                            onChange={(e) =>
                                              handleStateChange(
                                                "entered_time",
                                                e.target.value
                                              )
                                            }
                                            InputLabelProps={{
                                              shrink: true,
                                            }}
                                            InputProps={{
                                              inputProps: {
                                                max: getCurrentTime(),
                                              },
                                            }}
                                          />
                                        </FormControl>
                                      </Box>
                                    </div>
                                  </div>
                                </div>

                                <div style={{ display: 'flex', justifyContent: "space-between" }}>
                                  <div class="form-group col-md-6">
                                    <label for="procedureDate"  >Witness Name</label>
                                    <div>
                                      <TextField type="text" placeholder='Witness Name' fullWidth
                                        value={adverseDrugFormState?.incident_details?.witness_name}
                                        onChange={(e) => handleStateChange("witness_name", e.target.value)}
                                        disabled={action == "view"}
                                      />
                                    </div>
                                  </div>


                                  <div class="form-group col-md-6">
                                    <label for="procedureDate"  >Witness Phone</label>
                                    <div>

                                      <TextField
                                        placeholder='Witness Phone'
                                        fullWidth
                                        disabled={action == "view"}
                                        type="text"
                                        value={adverseDrugFormState?.incident_details?.witness_phone}
                                        onChange={(event) => {
                                          const value = event.target.value;
                                          if (/^\d{0,10}$/.test(value)) {
                                            setPhoneNumber(value);
                                            handleStateChange("witness_phone", value);
                                          }
                                        }}
                                        inputProps={{
                                          pattern: '[0-9]*',
                                        }}
                                      />

                                    </div>
                                  </div>
                                </div>

                                <div class="form-group">
                                  <label for="procedureDate" style={{ marginLeft: '15px' }}>Witness Address </label>
                                  <div
                                    class="col-md-12"
                                  >
                                    <TextField type="text" placeholder='Witness Address' fullWidth
                                      value={adverseDrugFormState?.incident_details?.witness_address}
                                      onChange={(e) => handleStateChange("witness_address", e.target.value)}
                                      disabled={action == "view"}
                                    />
                                  </div>
                                </div>
                              </div>
                            </div>
                          </AccordionDetails>
                        </Accordion>
                      </div>
                    </div>
                  </div>
                </div>

                <div id="main-wrapper">
                  <div class="row">
                    <div>
                      <div class="panel panel-white">
                        <Accordion>
                          <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel1-content"
                            id="panel1-header"
                          >
                            <h4 class="panel-title">
                              Special Incident Information
                            </h4>
                          </AccordionSummary>
                          <AccordionDetails>
                            <div class="panel-body">
                              <div class="form-horizontal col-md-12">
                                <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                  <div class="form-group col-md-6" >
                                    <label for="exampleInputPassword1" >Department</label>
                                    <div>
                                      {action === "view" || action === "edit" ? (
                                        <Autocomplete
                                          options={departmentData}
                                          getOptionLabel={(department) => department.department_name}
                                          disabled={action === "view"}
                                          onChange={(e, value) => {
                                            handleStateChange("department_id", value ? value.department_id : 0);
                                          }}
                                          renderInput={(params) => (
                                            <TextField
                                              {...params}
                                              placeholder="Please Select department"
                                              variant="outlined"
                                            />
                                          )}
                                          value={departmentData.filter(department => {
                                            return department.department_id == adverseDrugFormState.special_additional_info.department_id
                                          })[0] || null}
                                          defaultValue={
                                            departmentData.filter(department => {
                                              return department.department_id == adverseDrugFormState.special_additional_info.department_id
                                            })[0] || {}
                                          }
                                        />
                                      ) : (
                                        <Autocomplete
                                          options={departmentData}
                                          getOptionLabel={(department) => department.department_name}
                                          disabled={action === "view"}
                                          onChange={(e, value) => {
                                            handleStateChange("department_id", value ? value.department_id : 0);
                                          }}
                                          renderInput={(params) => (
                                            <TextField
                                              {...params}
                                              placeholder="Please Select department"
                                              variant="outlined"
                                            />
                                          )}
                                        />
                                      )}
                                    </div>
                                    <div className="text-danger">{departmentError}</div>
                                  </div>

                                  {/* <div class="form-group col-md-6" >
                                    <label for="exampleInputPassword1" >Specific Incident Type <span style={{ fontweight: "bold", color: "red" }} >*</span></label>
                                    <div>
                                      {action === "view" || action === "edit" ? (
                                        <Autocomplete
                                          disablePortal
                                          disabled={action == "view"}
                                          options={specificIncidentTypeList}
                                          getOptionLabel={(option) => option.label}
                                          onChange={(event, value) => handleStateChange("specific_incident_type", value ? value.label : "")}
                                          renderInput={(params) => (
                                            <TextField
                                              {...params}
                                              placeholder="Please Select Specific Incident Type"
                                              fullWidth
                                            />
                                          )}

                                          value={specificIncidentTypeList.find(option => option.label === adverseDrugFormState.special_additional_info.specific_incident_type) || null}

                                        />
                                      ) : (

                                        <Autocomplete
                                          disablePortal
                                          disabled={action == "view"}
                                          options={specificIncidentTypeList}
                                          getOptionLabel={(option) => option.label}
                                          onChange={(event, value) => handleStateChange("specific_incident_type", value ? value.label : "")}
                                          renderInput={(params) => (
                                            <TextField
                                              {...params}
                                              placeholder="Please Select Specific Incident Type"
                                              fullWidth
                                            />
                                          )}


                                        />
                                      )}

                                    </div>
                                    <div className="text-danger">{specificIncidentTypetError}</div>
                                  </div> */}

                                  <div class="form-group col-md-6">
                                    <label for="procedureDate">  Location of incident occurred  </label>
                                    <div>
                                      <TextField
                                        type="text" fullWidth placeholder=" Patient room , corridor "
                                        value={adverseDrugFormState?.special_additional_info?.location_of_incident_occurred}
                                        onChange={(e) => handleStateChange("location_of_incident_occurred", e.target.value)}
                                        disabled={action == "view"}
                                      />
                                    </div>
                                  </div>
                                </div>

                                <div style={{ display: "flex", justifyContent: "space-between" }}>
                                  <div class="form-group col-md-6">
                                    <label for="procedureDate">  Type of Reaction  </label>
                                    <div>
                                      <TextField
                                        type="text" fullWidth placeholder="Type of Reaction "
                                        value={adverseDrugFormState?.special_additional_info?.reaction_type}
                                        onChange={(e) => handleStateChange("reaction_type", e.target.value)}
                                        disabled={action == "view"}
                                      />
                                    </div>
                                  </div>

                                  <div class="form-group col-md-6">
                                    <label for="procedureDate">  Medication/Fluid Details  </label>
                                    <div>
                                      <TextField
                                        type="text" fullWidth
                                        placeholder=" Medication or Fluid Details"
                                        value={adverseDrugFormState?.special_additional_info?.medication_details}
                                        onChange={(e) => handleStateChange("medication_details", e.target.value)}
                                        disabled={action == "view"}
                                      />
                                    </div>
                                  </div>
                                </div>

                                <div style={{ display: "flex", justifyContent: "space-between" }} >
                                  <div class="form-group col-md-6">
                                    <label for="procedureDate"> Actual Patient Weight(lbs) <span style={{ fontweight: "bold", color: "red" }}> * </span>
                                    </label>
                                    <div>
                                      <TextField
                                        type="number"
                                        fullWidth
                                        placeholder="Specify Patient Weight between 0-500 (lbs)"
                                        inputProps={{ min: 0, max: 500 }}
                                        disabled={action === "view"}
                                        value={adverseDrugFormState?.special_additional_info?.patient_weight}
                                        onChange={(e) => {
                                          let inputValue = e.target.value;
                                          // Ensure input is a number or empty
                                          if (/^\d*$/.test(inputValue)) {
                                            // Limit to three digits
                                            inputValue = inputValue.slice(0, 3);
                                            // Ensure value is less than or equal to 500 or empty
                                            if (inputValue === "" || (parseInt(inputValue) <= 500)) {
                                              handleStateChange("patient_weight", inputValue);
                                            }
                                          }
                                        }}
                                      />
                                    </div>
                                    <div className="text-danger">{patientweightError}</div>
                                  </div>


                                  <div class="form-group col-md-6">
                                    <label for="exampleInputPassword1"> Treatment of Reaction </label>
                                    <div>
                                      {action === "view" || action === "edit" ? (
                                        <Autocomplete
                                          disablePortal
                                          disabled={action == "view"}
                                          options={specificIncidentTypeList}
                                          getOptionLabel={(option) => option.label}
                                          onChange={(event, value) => handleStateChange("treatment_of_reaction", value ? value.label : "")}
                                          renderInput={(params) => (
                                            <TextField
                                              {...params}
                                              placeholder="Please Select reaction"
                                              fullWidth
                                            />
                                          )}
                                          value={TreatmentOfReactionList.find(option => option.label === adverseDrugFormState.special_additional_info.treatment_of_reaction) || null}

                                        />
                                      ) : (
                                        <Autocomplete
                                          disablePortal
                                          disabled={action == "view"}
                                          options={specificIncidentTypeList}
                                          getOptionLabel={(option) => option.label}
                                          onChange={(event, value) => handleStateChange("treatment_of_reaction", value ? value.label : "")}
                                          renderInput={(params) => (
                                            <TextField
                                              {...params}
                                              placeholder="Please Select reaction"
                                              fullWidth
                                            />
                                          )}
                                        />
                                      )}
                                    </div>
                                  </div>
                                </div>

                                <div style={{ display: "flex", justifyContent: "space-between" }}>
                                  <div class="form-group col-md-6">
                                    <label for="exampleInputPassword1"> Patient Outcome </label>
                                    <div>
                                      <TextField
                                        type="number"
                                        fullWidth
                                        placeholder="Specify Patient Weight (in lbs)"
                                        inputProps={{ min: 0, max: 500 }}
                                        disabled={action === "view"}
                                        value={adverseDrugFormState?.special_additional_info?.patient_outcome}
                                        onChange={(e) => {
                                          let weightinputValue = e.target.value;
                                          // Ensure input is a number or empty
                                          if (/^\d*$/.test(weightinputValue)) {
                                            // Limit to three digits
                                            weightinputValue = weightinputValue.slice(0, 3);
                                            // Ensure value is less than or equal to 500 or empty
                                            if (weightinputValue === "" || (parseInt(weightinputValue) <= 500)) {
                                              handleStateChange("patient_outcome", weightinputValue);
                                            }
                                          }
                                        }}
                                      />
                                    </div>
                                  </div>

                                  <div class="form-group col-md-6">
                                    <label for="procedureDate"> Present On Admission? </label>
                                    <div>
                                      <TextField type="text" placeholder=" Present on Admission" fullWidth
                                        value={adverseDrugFormState?.special_additional_info?.present_on_admission}
                                        onChange={(e) => handleStateChange("present_on_admission", e.target.value)}
                                        disabled={action == "view"}
                                      />
                                    </div>
                                  </div>
                                </div>

                                <div style={{ display: "flex", justifyContent: "space-between" }}>
                                  <div class="form-group col-md-6">
                                    <label for="exampleInputPassword1">  Was ADR reported externally?  </label>
                                    <div>
                                      <label className="col-md-3">
                                        <input
                                          type="radio"
                                          disabled={action == "view"}
                                          name="adr_reported_externally"
                                          checked={
                                            adverseDrugFormState
                                              .special_additional_info
                                              .adr_reported_externally === true
                                          }
                                          onChange={() =>
                                            handleStateChange(
                                              "adr_reported_externally",
                                              true
                                            )
                                          }
                                        />{" "}
                                        Yes
                                      </label>
                                      <label className="col-md-3">
                                        <input
                                          type="radio"
                                          disabled={action == "view"}
                                          name="adr_reported_externally"
                                          checked={
                                            adverseDrugFormState
                                              .special_additional_info
                                              .adr_reported_externally === false
                                          }
                                          onChange={() =>
                                            handleStateChange(
                                              "adr_reported_externally",
                                              false
                                            )
                                          }
                                        />{" "}
                                        No
                                      </label>
                                    </div>

                                  </div>
                                  <div class="form-group col-md-6">
                                    <label for="exampleInputPassword1">
                                      Was This ADR Preventable?
                                    </label>
                                    <div>

                                    </div>
                                    <div style={{ marginTop: "10px" }}>
                                      <label className="col-md-3">
                                        <input
                                          type="radio"
                                          disabled={action == "view"}
                                          name="adr_preventable"
                                          checked={
                                            adverseDrugFormState
                                              .special_additional_info
                                              .adr_preventable === true
                                          }
                                          onChange={() =>
                                            handleStateChange(
                                              "adr_preventable",
                                              true
                                            )
                                          }
                                        />{" "}
                                        Yes
                                      </label>
                                      <label className="col-md-3">
                                        <input
                                          type="radio"
                                          disabled={action == "view"}
                                          name="adr_preventable"
                                          checked={
                                            adverseDrugFormState
                                              .special_additional_info
                                              .adr_preventable === false
                                          }
                                          onChange={() =>
                                            handleStateChange(
                                              "adr_preventable",
                                              false
                                            )
                                          }
                                        />{" "}
                                        No
                                      </label>
                                    </div>
                                  </div>
                                </div>

                                <div
                                  style={{
                                    display: "flex",
                                    justifyContent: "space-between",
                                  }}
                                >
                                  <div class="form-group col-md-6">
                                    <label for="procedureDate" >Was a physician directly involved in the incident?</label>
                                    <div style={{ marginTop: '10px' }}>

                                      <label className="col-md-3">
                                        <input type="radio" name="physician_involved" disabled={action == "view"} checked={adverseDrugFormState.special_additional_info.physician_involved === true} onChange={() => handleStateChange("physician_involved", true)} /> Yes
                                      </label>
                                      <label className="col-md-3">
                                        <input type="radio" name="physician_involved" disabled={action == "view"} checked={adverseDrugFormState.special_additional_info.physician_involved === false} onChange={() => handleStateChange("physician_involved", false)} /> No
                                      </label>
                                    </div>
                                  </div>

                                  <div class="form-group col-md-6">
                                    <label for="procedureDate"  >Contributing Factors</label>
                                    <div >
                                      <TextField type="text" fullWidth placeholder="Enter Contributing Factors"
                                        value={adverseDrugFormState?.special_additional_info?.contributing_factors}
                                        onChange={(e) => handleStateChange("contributing_factors", e.target.value)}
                                        disabled={action == "view"}
                                      />
                                    </div>
                                  </div>
                                </div>

                                <div style={{ display: "flex", justifyContent: "space-between" }}>
                                  <div class="form-group col-md-6">
                                    <label for="procedureDate"   >Immediate Actions Taken</label>
                                    <div >
                                      <TextField type="text" fullWidth placeholder="Enter Immediate Actions Taken"
                                        value={adverseDrugFormState?.special_additional_info?.immediate_actions_taken}
                                        onChange={(e) => handleStateChange("immediate_actions_taken", e.target.value)}
                                        disabled={action == "view"}
                                      />
                                    </div>
                                  </div>

                                  <div class="form-group col-md-6" >
                                    <label for="exampleInputPassword1" >Reported Incident Severity <span style={{ fontweight: "bold", color: "red" }} >*</span></label>
                                    <div >
                                      {action === "view" || action === "edit" ? (
                                        <Autocomplete
                                          disablePortal
                                          disabled={action == "view"}
                                          options={reportedIncidentSeverityList}
                                          getOptionLabel={(option) => option.label}
                                          onChange={(event, value) => handleStateChange("reported_incident_severity", value ? value.label : "")}
                                          renderInput={(params) => (
                                            <TextField
                                              {...params}
                                              placeholder="Please Select reported incident severity"
                                              fullWidth
                                            />
                                          )}
                                          defaultValue={{ label: adverseDrugFormState.special_additional_info.reported_incident_severity }}
                                        />
                                      ) : (
                                        <Autocomplete
                                          disablePortal
                                          disabled={action == "view"}
                                          options={reportedIncidentSeverityList}
                                          getOptionLabel={(option) => option.label}
                                          onChange={(event, value) => handleStateChange("reported_incident_severity", value ? value.label : "")}
                                          renderInput={(params) => (
                                            <TextField
                                              {...params}
                                              placeholder="Please Select reported incident severity"
                                              fullWidth
                                            />
                                          )}
                                        />
                                      )}
                                    </div>
                                    <div className="text-danger">{reportedIncidentError}</div>
                                  </div>
                                </div>

                                <div style={{ display: "flex", justifyContent: "space-between" }}>
                                  <div class="form-group col-md-6 ">
                                    <label for="procedureDate"> Causality/Probability? </label>
                                    <div class="">
                                      <TextField
                                        type="text" placeholder="Causality/Probability " fullWidth
                                        value={adverseDrugFormState?.special_additional_info?.casuality_or_probability}
                                        onChange={(e) => handleStateChange("casuality_or_probability", e.target.value)}
                                        disabled={action == "view"}
                                      />
                                    </div>
                                  </div>

                                  <div class="form-group col-md-6">
                                    <label for="procedureDate" >Incident Description - use SBAR (Situation,Background, Assessment,
                                      Recommendation )<span style={{ fontweight: "bold", color: "red" }} >*</span></label>
                                    <div >
                                      <TextField type="text" fullWidth placeholder="Enter Incident Description..."
                                        value={adverseDrugFormState?.special_additional_info?.incident_description}
                                        onChange={(e) => handleStateChange("incident_description", e.target.value)}
                                        disabled={action == "view"}
                                      />
                                      <div className="text-danger">{incidentdescriptionError}</div>
                                    </div>
                                  </div>
                                </div>

                                <div className="form-group col-md-6">
                                  <label htmlFor="procedureDate" style={{ textAlign: "start" }}>File Upload</label>
                                  <div>
                                    <input type="file" disabled={action == "view"} style={{ marginTop: "10px", color: 'transparent' }} multiple onChange={handleFileUpload} />
                                    {files.length > 0 ? (
                                      <ol>
                                        {files.map((file, index) => (
                                          <li disabled={action === "view"} key={index}>
                                            {file.name}
                                            <IconButton disabled={action === "view"} onClick={() => handleDeleteFile(index)} aria-label="delete">
                                              <DeleteIcon />
                                            </IconButton>
                                          </li>
                                        ))}
                                      </ol>
                                    ) : (
                                      <p>No files chosen</p>
                                    )}

                                  </div>
                                </div>


                              </div>
                            </div>
                          </AccordionDetails>
                        </Accordion>
                      </div>
                    </div>
                  </div>
                </div>


                <div style={{ marginLeft: "45%" }}>
                  {canSubmit && (
                    <Button
                      variant="contained"
                      type="submit"
                      style={{ height: "35px", width: "auto", fontSize: "15px", background: "#22baa0" }}
                      onClick={handleSubmit}
                    >
                      {isEditOnly || editIncidentData.incident_id ? "Update" : "Submit"}
                    </Button>
                  )}
                </div>


              </form>
              <Snackbar
                open={showSuccessPopup}
                autoHideDuration={6000}
                onClose={handleCloseSnackbar}
                anchorOrigin={{ vertical: "top", horizontal: "center" }}
              >
                <MuiAlert
                  onClose={handleCloseSnackbar}
                  severity="success"
                  variant="filled"
                  sx={{ width: "100%" }}
                  style={{
                    width: "300px",
                    backgroundColor: "whitesmoke",
                    color: "green",
                    height: "45px",
                    fontSize: "12px",
                  }}
                >
                  {snackbarMessage}
                </MuiAlert>
              </Snackbar>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AdverseDrugForm;