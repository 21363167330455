import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { apis } from "../../configuration/configurationAPI";
import "react-datepicker/dist/react-datepicker.css";
import store from "../../utils/index";
import { useSelector } from 'react-redux';
import { commonActions, commonSelectors } from '../../utils/commonstore';
import { DataGrid, GridToolbarQuickFilter } from '@mui/x-data-grid';
import BorderColorIcon from '@mui/icons-material/BorderColor';
import { FaEye, FaTrash, FaFile, FaDownload } from 'react-icons/fa';
import { format } from 'date-fns'
import { Dialog, DialogActions, DialogContent, DialogTitle, Button } from "@mui/material";
import { Alert, Snackbar, ClickAwayListener } from "@mui/material";
import { useData } from "../../DataContext";
import { routers } from "../../configuration/configurationUI";
import IncidentExportExcel from "../../Components/IncidentExportButtons/IncidentExportExcel";
import IncidentExportPDF from "../../Components/IncidentExportButtons/IncidentExportPDF";


const dispatch = store.dispatch;
let commonStore = store.getState().commonStore;
store.subscribe(function () {
  commonStore = store.getState().commonStore;
});

function MedicalDeviceReportingEvent() {
  const Navigate = useNavigate();
  const baererToken = useSelector(commonSelectors.selectBaererToken);
  const editIncidentData = useSelector(commonSelectors.selectEditIncidentData);
  const viewIncidentData = useSelector(commonSelectors.selectViewIncidentData);

  // console.log("baererToken", baererToken);
  const loginUserId = useSelector(commonSelectors.selectLoginUserId);
  const [data, setData] = useState([]);
  const { setMyData } = useData();
  var navigate = useNavigate();

  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [filterModel, setFilterModel] = useState({ items: [] });
  const [OpenEditDialog, setOpenEditDialog] = useState(false);
  const [openDialog, setOpenDialog] = useState(false)
  const [selectedIncidentFiles, setSelectedIncidentFiles] = useState([]);
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
  const [selectedFileToDelete, setSelectedFileToDelete] = useState(null);
  const [selectedIncidentId, setSelectedIncidentId] = useState(null);
  const [snackbar, setSnackbar] = useState({
    open: false,
    message: "",
    severity: "success",
});

  useEffect(() => {
    fetchData();
    fetchData2 ();
    // console.log(selectedIncidentFiles.length,"selectedIncidentFiles.length")
  }, []);

  const fetchData = () => {
    axios.get(apis.base + apis.GET_INCIDENTS_TYPE_SEARCH_API+`incident_type=Medical Device Reporting`,
      {
        headers: {
          userid: loginUserId,
          Authorization: "Bearer " + baererToken
        }
      })
      .then(response => {
        setData(response.data);
        setLoading(false);
      })
      .catch(error => {
        setError("Error while fetching data. Please try again.");
        setLoading(false);
        console.error("Error fetching data:", error);
      });
  };

  const handleEditClick = (rowData) => {
    // Logic for handling edit click
    // console.log(rowData, "ppppppppppp")
    // setMyData(rowData);
    dispatch(commonActions.setEditIncidentData(rowData))


    const eventType2 = rowData.spec.general_incident_info.incident_type;


      switch (eventType2) {
       
        
        case "MedicalCare Treatment":
          navigate(routers.MedicalCareTreatment + "/edit");
          break;
        
     
      default: 
          break;
        }
 
  };

  const handleViewClick = (rowData) => {

    dispatch(commonActions.setViewIncidentData(rowData));
    const eventType = rowData.spec.general_incident_info.incident_type;

  
      switch (eventType) {
       
       
        case "MedicalCare Treatment":
          navigate(routers.MedicalCareTreatment + "/view");
          break;
        
        default:
          break;
      }
  };

  const handleFilesClick = (incidentId) => {
    fetchData2(incidentId);
    setSelectedIncidentId(incidentId);
    setOpenDialog(true);
  };
  const handleCloseDialog = () => {
    setOpenDialog(false);
  };
  const fetchData2 = (incidentId) => {

    axios.get(apis.base + apis.GET_INCIDENT_BYID_API + incidentId,

      {
        headers: {
          userid: loginUserId,
          Authorization: "Bearer " + baererToken
        }
      })
      .then((response) => {
        const filesInfo = JSON.parse(response.data.incident_info?.files_info || "[]");
        setSelectedIncidentFiles(filesInfo);
        // console.log(response.data.incident_info?.files_info, "response.data.incident_info?.spec?.special_additional_info?.attachment")
      })
      .catch((error) => {
        console.error("Error fetching uploaded files: ", error);
      });
  };

  const handleOpenDeleteDialog = (file) => {
    setSelectedFileToDelete(file);
    setOpenDeleteDialog(true);
  };

  const handleCloseDeleteDialog = () => {
    setSelectedFileToDelete(null);
    setOpenDeleteDialog(false);
  };

  const handleDeleteSingleFile = (fileName) => {
    const url = `${apis.base}${apis.DELETE_INCIDENT_INCIDENTID_FILENAME_API}?fileKeyword=${encodeURIComponent(fileName)}`;
    axios.delete(url, {
      responseType: 'blob',
      headers: {
        userid: loginUserId,
        Authorization: "Bearer " + baererToken,
        incident_id: selectedIncidentId
      }
    })
      .then((response) => {
        setSnackbar({
          open: true,
          message: 'Deleted successfully',
          severity: 'success',
      });

      })
      .catch((error) => {
        console.error("Error deleting file: ", error);
        setSnackbar({
          open: true,
          message: 'Delete Unsuccessful',
          severity: 'error',
      });
      });
    handleCloseDeleteDialog();
  };
  const handleDownloadSingleFile = (file) => {
    const fileName = file;
    axios.get(apis.base + apis.GET_INCIDENT_FILENAME_INCIDENTID_DOWNLOAD_API + `?fileKeyword=${encodeURIComponent(fileName)}`, {
      responseType: 'blob',
      headers: {
        userid: loginUserId,
        Authorization: "Bearer " + baererToken,
        incident_id: selectedIncidentId
      }
    })
      .then((response) => {
        const blob = new Blob([response.data]);
        const extension = fileName.split('.').pop();
        const downloadLink = document.createElement("a");
        downloadLink.href = window.URL.createObjectURL(blob);
        downloadLink.setAttribute("download", fileName);
        document.body.appendChild(downloadLink);
        downloadLink.click();
        document.body.removeChild(downloadLink);
        setSnackbar({
          open: true,
          message: 'Downloaded successfully',
          severity: 'success',
      });
      })
      .catch((error) => {
        console.error("Error downloading file: ", error);
        setSnackbar({
          open: true,
          message: 'Download failed',
          severity: 'error',
      });
      });

  };


  const handleDownloadAllattachements = (incidentId) => {
    // console.log(incidentId, "incidentId")
    axios.get(apis.base + apis.GET_INCIDENT_INCIDENTID_FILEZIP_API, {
      headers: {
        userId: loginUserId,
        Authorization: "Bearer " + baererToken,

        incident_id: incidentId
      },
      responseType: 'blob' // Set response type to 'blob'

    })
      .then((response) => {
        const downloadLink = document.createElement("a");
        downloadLink.href = window.URL.createObjectURL(new Blob([response.data], { type: 'application/zip' })); // Set content type to 'application/zip'
        downloadLink.setAttribute("download", "incident_files.zip");
        document.body.appendChild(downloadLink);
        downloadLink.click();
        document.body.removeChild(downloadLink);
        setSnackbar({
          open: true,
          message: 'Downloaded successfully',
          severity: 'success',
      });
      })
      .catch((error) => {
        setSnackbar({
          open: true,
          message: 'Download failed',
          severity: 'error',
      });
        console.error("Error fetching zip file: ", error);
      });
  }

  const renderCreatedByCell = (params) => {
    return params.value ? params.value : 'Anonymous';
  };

  const columns = [
    {
      field: 'incident_id',
      headerName: <strong>Incident ID</strong>,
      width: 120,
      renderCell: (params) => (
        <Link to={`/workflowactions/${params.row.incident_id}`}>{params.row.incident_id}</Link>
      )
    },
    {
        field: 'incident_type',
        headerName: <strong>Incident Type</strong>,
        width: 220,
        valueGetter: (params) => {
          if (params.row.spec) {
            const specData = JSON.parse(params.row.spec);
            if (specData.general_incident_info) {
              return specData.general_incident_info.incident_type || '';
            }
          }
          return '';
        }
      },
      {
        field: 'patient_type', headerName: <strong> Patient Type</strong>, width: 150,
        valueGetter: (params) => {
           if (params.row.spec) {
              const specData=JSON.parse(params.row.spec);
              if (specData.general_incident_info){
        return specData.general_incident_info.patient_type || '';
      } 
  }
        return '';
      }
        
      },
    {
      field: 'department_name', headerName: <strong> Department</strong>, width: 150,
    },
    { field: 'created_by_user', headerName: <strong>Created By</strong>, width: 150,renderCell: renderCreatedByCell },
    {
      field: 'created_timestamp', headerName: <strong> Created Timestamp</strong>, width: 200,
      valueGetter: (params) => new Date(params.row.created_timestamp).toLocaleString('en-US')
    },
    { field: 'updated_by_user', headerName: <strong>Updated By</strong>, width: 150 },
    {
      field: 'updated_timestamp', headerName: <strong>Updated Timestamp</strong>, width: 200,
      valueGetter: (params) => new Date(params.row.updated_timestamp).toLocaleString('en-US')
    },
    // {
    //   field: 'file_count',
    //   headerName: <strong>Documents</strong>,
    //   width: 100,
    //   renderCell: (params) => (
    //     <div style={{ cursor: 'pointer' }}  onClick={() => handleFilesClick(params.row.incident_id)}>
        
    //     <FaFile style={{ color: 'black' }}  />
     
    //     </div>
    //   )
    // },
    // {
    //   field: 'actions', headerName: <strong>Actions</strong>,
    //   renderCell: (params) => {
    //      let incidentType = '';
    // if (params.row.spec && params.row.spec.general_incident_info) {
    //   incidentType = params.row.spec.general_incident_info.incident_type || '';
    // }
    //     const rowData = params.row;
    //     const rowData2 = params.row;


    //     return (

    //       <div>

    //         <span style={{ marginRight: '10px', color: 'black' }}><FaEye onClick={() => handleViewClick(rowData2)} style={{ color: 'black' }} /></span>



    //         <span style={{ marginRight: '10px', color: 'black' }} ><BorderColorIcon onClick={() => handleEditClick(rowData)} /></span>



    //       </div>
    //     );

    //   }
    // },
  ];
  const handleSnackbarClose = () => {
    Navigate("/submittedfiles")
    setSnackbar({ ...snackbar, open: false });
};
  return (
    <div className="page-content">
      <div className="page-inner">
        <div className="panel panel-white">


        <div className="page-title">
                        <h3>Total MedicalDevice Reporting Events</h3>
                        <div className="page-breadcrumb">
                            <ol className="breadcrumb">
                                <li><Link to="/Dashboard">Home</Link></li>
                                <li>Medical Device Reporting Events</li>
                            </ol>
                        </div>
                    </div>
                    <ClickAwayListener onClickAway={() => { }}>
                        <Snackbar
                            open={snackbar.open}
                            onClose={() => setSnackbar({ ...snackbar, open: false })}
                            style={{ position: 'fixed', top: '15%', left: '50%', transform: 'translate(-50%, -50%)' }}
                        >
                            <Alert onClose={handleSnackbarClose} severity={snackbar.severity} style={{ width: '300px', height: '40px', fontSize: '12px' }}>
                                {snackbar.message}
                            </Alert>
                        </Snackbar>
                    </ClickAwayListener>

          <div id="main-wrapper">
            <div className="row">
              <div className="col-md-12">
                <div className="mailbox-content" style={{ overflow: 'auto' }}>
                  {!error && (
                    <div style={{ display: "flex", position: "relative", top: "20px", gap: "20px" }}>
                      {/* <IncidentExportExcel />
                      <IncidentExportPDF /> */}
                    </div>
                  )}
                  <div style={{ height: "100%", width: '100%', paddingTop: "20px" }}>
                    {error && <p style={{ marginLeft: "30%", fontSize: "15px", color: "red" }}>{error}</p>}
                    {loading && <p style={{ textAlign: "center", fontSize: "15px" }}>Loading...</p>}
                    {!loading && !error && (
                      <DataGrid
                        rows={data}
                        columns={columns}
                        pageSize={5}
                        rowsPerPageOptions={[10, 20, 30]}
                        loading={loading}
                        classes={{
                          overlayWrapper: 'customOverlayWrapper'
                        }}
                        sx={{
                          "& .customOverlayWrapper ": {
                            height: "63px !important"
                          },
                          fontWeight: 'fontWeightLight',
                          mx: 0.5,
                          fontSize: 14, fontFamily: "sans-serif",
                          boxShadow: 2
                        }}
                        components={{
                          Toolbar: () => (
                            <GridToolbarQuickFilter
                              classes={{
                                toolbarQuickFilter: 'customtoolbarQuickFilter'
                              }}
                              sx={{
                                width: '35% !important',
                                position: 'absolute !important',
                                right: 400,
                                top: '-30px !important'
                              }}
                              filterModel={filterModel}
                              onFilterModelChange={(newModel) => setFilterModel(newModel)}
                            />
                          ),
                          NoRowsOverlay: () => (
                            <div
                              style={{
                                position: 'sticky',
                                top: '50%',
                                transform: 'translate(-50%, 0%)',
                                textAlign: 'center',
                                marginLeft: '45%'
                              }}
                            >
                              No data found
                            </div>
                          ),

                        }}
                      
                      />
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <Dialog open={openDialog} onClose={handleCloseDialog}>
            <DialogTitle style={{ fontSize: "20px" }}>Available Files</DialogTitle>
            <DialogContent>
              {selectedIncidentFiles.length === 0 ? (
                <p>No files available</p>
              ) : (
                selectedIncidentFiles.map((file, index) => (
                  <div key={index} style={{ display: "flex", justifyContent: "space-between", alignItems: "center", color: "black" }}>                            <p>{file}</p>
                    <div style={{ position: "relative", left: "40px" }}>
                      <Button onClick={() => handleDownloadSingleFile(file)}><FaDownload style={{ color: "black", fontSize: "13px" }} /></Button>
                      <Button onClick={() => handleOpenDeleteDialog(file)}><FaTrash style={{ color: "red", fontSize: "13px" }} /></Button>

                    </div>
                  </div>
                ))
              )}
            </DialogContent>
            <DialogActions>
              {selectedIncidentFiles.length > 0 && (
                <Button style={{ backgroundColor: "#22baa0", color: "white" }} onClick={() => handleDownloadAllattachements(selectedIncidentId)} color="primary">
                  Download All
                </Button>
              )}
              <Button onClick={handleCloseDialog} color="primary">
                Close
              </Button>
            </DialogActions>
          </Dialog>
          <Dialog open={openDeleteDialog} onClose={handleCloseDeleteDialog}>
            <DialogTitle style={{ fontSize: "20px" }}>Confirm Delete</DialogTitle>
            <DialogContent>
              {selectedFileToDelete && (
                <div>
                  <p>Are you sure you want to delete the file ?</p>
                  {/* <p>Are you sure you want to delete the file "{selectedFileToDelete.name}"?</p> */}
                </div>
              )}
            </DialogContent>
            <DialogActions>
              <Button onClick={() => handleDeleteSingleFile(selectedFileToDelete)} color="primary">
                Delete
              </Button>
              <Button onClick={handleCloseDeleteDialog} color="primary">
                Cancel
              </Button>
            </DialogActions>
          </Dialog>
        </div>
      </div>
    </div>
  );
}

export default MedicalDeviceReportingEvent;