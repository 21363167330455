import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import axios from "axios";
import { apis } from "../../configuration/configurationAPI";
import "react-datepicker/dist/react-datepicker.css";
import store from "../../utils/index";
import { useSelector } from 'react-redux';
import { commonSelectors } from '../../utils/commonstore';
import { DataGrid, GridToolbar, GridToolbarQuickFilter } from '@mui/x-data-grid';
import AuditExportExcel from "../../Components/AuditExportButtons/AuditExportExcel";
import AuditExportPDF from "../../Components/AuditExportButtons/AuditExportPDF";

const dispatch = store.dispatch;
let commonStore = store.getState().commonStore;
store.subscribe(function () {
  commonStore = store.getState().commonStore;
});
function UsersAudit() {
    const baererToken = useSelector(commonSelectors.selectBaererToken);
    // console.log(baererToken,"kkkkjjjjjjjhhhhhhsssssss")
    const loginUserId = useSelector(commonSelectors.selectLoginUserId);
    // console.log(loginUserId,"kkkkjjjjjjjhhhhhhsssssss")
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
    const [profileIdToDelete, setProfileIdToDelete] = useState(null);
    const [auditData, setAuditData] = useState([]);
    const userInfo = useSelector(commonSelectors.selectUserInfo);
    const [rows, setRows] = useState([]);
    const accessInfo = useSelector(commonSelectors.selectAccessInfo);
   

    useEffect(() => {
        fetchUsersData();
    }, []);

    const fetchUsersData = () => {
        axios.get(apis.base + apis.GET_USER_AUDIT_API,
            {
                headers: {
                    userid:loginUserId,
                    Authorization:"Bearer "+ baererToken
                }
            })
            .then((succResp) => {

                const UsersWithIds  = succResp.data.map((el, index)=>{
                    return ({...el,
                        location_name:el.location_info.map((el)=>(el.location_name)).join(", ")   ,
                        id: index + 1
                        
                    })
                })
        
                setRows( UsersWithIds);
                setLoading(false);
            })
            .catch((errorresp) => {
                console.log(JSON.stringify(errorresp));
                setError("Error while fetching Audit  details. Please try again.");
                setLoading(false);
            });
    };

    const sortedData = [...rows].sort((a, b) => b.id - a.id);
    const columns = [
        { field: 'id', headerName: <strong>Id</strong>, width: 80 },
        { field: 'u_user_id', headerName: <strong>User Id</strong>, width: 120 },
        { field: 'u_user_name', headerName: <strong>User Name</strong>, width: 150 },
        { field: 'u_email', headerName: <strong>Email</strong>, width: 200 },
        { field: 'role_name', headerName: <strong>Role</strong>, width: 150 },
        { field: 'location_name', headerName: <strong>Location</strong>, width: 150 },
        { field: 'department_name', headerName: <strong>Department</strong>, width: 150 },
        { field: 'created_user', headerName: <strong>Created By</strong>, width: 100 },
        { 
            field: 'created_timestamp', 
            headerName: <strong>Created Timestamp</strong>, 
            width: 200,
            valueGetter: (params) => new Date(params.row.created_timestamp).toLocaleString('en-US') 
        },
        { field: 'updated_user', headerName: <strong>Updated By</strong>, width: 100 },
        { 
            field: 'updated_timestamp', 
            headerName: <strong>Updated Timestamp</strong>, 
            width: 200,
            valueGetter: (params) => new Date(params.row.updated_timestamp).toLocaleString('en-US') 
        },
        { field: 'u_is_deleted', headerName: <strong>Status</strong>, width: 100 ,
        renderCell: (params) => (
            <span>{params.value === 0 ? "Active" : "Inactive"}</span>
        )
        },
    ];
   
 
    return (
        <div>
            <div className="page-content">
            <div className="page-inner">
                <div className="panel panel-white">
                    <div className="page-title">
                        <h3>User Audit Data</h3>
                        <div className="page-breadcrumb">
                            <ol className="breadcrumb">
                                <li><Link to="/Dashboard">Home</Link></li>
                                <li>User Audit</li>
                            </ol>
                        </div>
                    </div>

                    <div id="main-wrapper">
                        <div className="row m-t-md">
                            <div className="col-md-12">
                                <div className="mailbox-content" style={{ overflow: 'auto' }}>
                                {!error && (
                               <div style={{ display: "flex", position: "relative", top: "20px", gap: "20px", justifyContent: "end", paddingRight: "20px" }}>
                               {accessInfo?.exports ==="1" && (
                               <>
                               <AuditExportExcel />
                               <AuditExportPDF/>
                               </>
                       )}
                           </div>
                                )}
                                    <div style={{ height: "100%", width: '100%', paddingTop:"20px"}}>
                                        {error && <p style={{ marginLeft: "30%", fontSize: "15px", color: "red" }}>{error}</p>}
                                        {loading && <p style={{ textAlign: "center", fontSize: "15px" }}>Loading...</p>}
                                        {!loading && !error && (
                                            <DataGrid
                                                rows={sortedData}
                                                columns={columns}
                                                pageSize={5}
                                                rowsPerPageOptions={[10, 20, 30]}
                                                loading={loading}
                                                classes={{
                                                    overlayWrapper: 'customOverlayWrapper'
                                                }}
                                                sx={{
                                                    "& .customOverlayWrapper ": {
                                                        height: "63px !important"
                                                    },
                                                    fontWeight: 'fontWeightLight',
                                                    mx: 0.5,
                                                    fontSize: 14, fontFamily: "sans-serif",
                                                    boxShadow: 2
                                                }}
                                               
                                                components={{
                                                    Toolbar: () => (
                                                        <GridToolbarQuickFilter
                                                            classes={{
                                                                toolbarQuickFilter: 'customtoolbarQuickFilter'
                                                            }}
                                                            sx={{
                                                                width: '35% !important',
                                                                position: 'absolute !important',
                                                                right: 400,
                                                                top: '-30px !important'
                                                            }}
                                                        />
                                                    ),
                                                    NoRowsOverlay: () => (
                                                        <div
                                                            style={{
                                                                position: 'sticky',
                                                                top: '50%',
                                                                transform: 'translate(-50%, 0%)',
                                                                textAlign: 'center',
                                                                marginLeft: '45%'
                                                            }}
                                                        >
                                                            No data found
                                                        </div>
                                                    ),
                                                }}
                                                   
                                            />
                                        )}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        </div>
    );
}

export default UsersAudit;
