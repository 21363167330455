import React, { useState, useEffect } from "react";
import axios from "axios";
import { apis } from "../../configuration/configurationAPI";
import { Link, useNavigate, useParams } from "react-router-dom";
import IconButton from '@mui/material/IconButton';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import FormControl from '@mui/material/FormControl';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputLabel from '@mui/material/InputLabel';
import InputAdornment from '@mui/material/InputAdornment';
import { Modal, Snackbar, Alert, Select, MenuItem, TextField, DialogContentText, Button, ClickAwayListener } from "@mui/material";
import store from "../../utils/index";
import { useSelector } from 'react-redux';
import { commonSelectors } from '../../utils/commonstore';

const dispatch = store.dispatch;
let commonStore = store.getState().commonStore;
store.subscribe(function () {
    commonStore = store.getState().commonStore;
});

function EditUser() {

    const baererToken = useSelector(commonSelectors.selectBaererToken);
    const loginUserId = useSelector(commonSelectors.selectLoginUserId);

    const { userId } = useParams();
    // console.log("userId:", userId)
    // const handleClickShowPassword = () => setShowPassword((show) => !show);

    const handleMouseDownPassword = (event) => {
        event.preventDefault();
    };


    // const [name, setName] = useState("");
    // const [title, setTitle] = useState("");
    // const [email, setEmail] = useState("");
    // // const [password, setPassword] = useState("");
    // const [showPassword, setShowPassword] = React.useState(false);

    // const [role, setRole] = useState("");
    // console.log("SetRole:", role);
    // const [roleId, setRoleId] = useState("");
    // console.log("RoleId:",roleId)
    // const [roleData, setRolesData] = useState([]);


    // const [profile, setProfile] = useState("");
    // console.log("profile:",profile)
    // const [profileId, setProfileId] = useState("");
    // console.log("profileid:", profileId);
    // const [profileData, setProfileData] = useState([]);

    // const [department, setDepartment] = useState("");
    // const [departmentId, setDepartmentId] = useState("");
    // console.log("DepartmentId:",departmentId)
    // const [departmentData, setDepartmentData] = useState([]);

    // const [location, setLocation] = useState("");
    // console.log("location:",location)
    // const [locationId, setLocationId] = useState("");
    // console.log("locationid:",locationId)
    // const [locationData, setLocationtData] = useState([]);

    const [name, setName] = useState("");
    const [title, setTitle] = useState("");
    const [email, setEmail] = useState("");
    // const [showPassword, setShowPassword] = useState(false);
    const [role, setRole] = useState("");
    const [roleId, setRoleId] = useState();
    // console.log("RoleId:", roleId)
    const [roleData, setRolesData] = useState([]);
    const [profile, setProfile] = useState("");
    const [profileId, setProfileId] = useState();
    // console.log("profileid:", profileId)
    const [profileData, setProfileData] = useState([]);
    const [department, setDepartment] = useState("");
    const [departmentId, setDepartmentId] = useState();
    // console.log("departmentId:", departmentId)
    const [departmentData, setDepartmentData] = useState([]);
    const [location, setLocation] = useState("");
    const [locationId, setLocationId] = useState("");
    // console.log("locationId:", locationId)
    const [locationData, setLocationData] = useState([]);


    const [nameError, setNameError] = useState("");
    // const [titleError, setTitileError] = useState("");
    const [emailError, setEmailError] = useState("");
    const [passwordError, setPasswordError] = useState("");
    const [roleError, setRoleError] = useState("");
    const [profileError, setProfileError] = useState("");
    const [locationError, setLocationError] = useState("");
    const [departmentError, setDepartmentError] = useState("");
    const [formError, setFormError] = useState("");
    const [userData, setUserData] = useState({})
    const [snackbar, setSnackbar] = useState({
        open: false,
        message: "",
        severity: "success",
    });

    const [error, setError] = useState(null);

    const Navigate = useNavigate();

    const handleSnackbarClose = () => {
        Navigate("/userslist")
        setSnackbar({ ...snackbar, open: false });
    };

    // useEffect(() => {
    //     axios.get(apis.base + apis.GET_USERBYID_API + userId,
    //         {
    //             headers: {
    //                 userid:loginUserId,
    //                 Authorization:"Bearer "+ baererToken
    //             }
    //         })
    //         .then((response) => {
    //             setUserData(response.data.user_id);
    //             setName(response.data.user_name);
    //             setTitle(response.data.title);
    //             // setPassword(response.data.user_info?.password);
    //             setRole(response.data.role_name);
    //             setProfile(response.data.profile_name);
    //             setLocation(response.data.location_info[0]?.location_name);
    //             setDepartment(response.data.department_name);
    //             setEmail(response.data.u_email);
    //             setError(null);
    //         })
    //         .catch((error) => {
    //             console.error("Error fetching user data:", error);
    //             setError("Error while fetching profile details. Please try again.");


    //         });
    //     axios.get(apis.base + apis.GET_ALL_PROFILE_API, {
    //         headers: {
    //             userid:loginUserId,
    //             Authorization:"Bearer "+ baererToken
    //         }
    //     })
    //         .then((succResp) => {
    //             setProfileData(succResp.data);

    //         })
    //         .catch((errorresp) => {
    //             console.log(JSON.stringify(errorresp));
    //         });

    //     axios.get(apis.base + apis.GET_ALL_ROLES_API,
    //         {
    //             headers: {
    //                 userid:loginUserId,
    //                 Authorization:"Bearer "+ baererToken
    //             }
    //         })
    //         .then((succResp) => {
    //             setRolesData(succResp.data);
    //             console.log("eeeeee", succResp.data)

    //         })
    //         .catch((errorresp) => {
    //             console.log(JSON.stringify(errorresp));
    //         });

    //     axios.get(apis.base + apis.GET_LOCATION_API,
    //         {
    //             headers: {
    //                 userid:loginUserId,
    //                 Authorization:"Bearer "+ baererToken
    //             }
    //         })
    //         .then((response) => {
    //             setLocationtData(response.data);

    //         })
    //         .catch((errorresp) => {
    //             console.log(JSON.stringify(errorresp));
    //         });
    //     axios.get(apis.base + apis.GET_ALL_DEPARTMENT_API,
    //         {
    //             headers: {
    //                 userid:loginUserId,
    //                 Authorization:"Bearer "+ baererToken
    //             }
    //         })
    //         .then((response) => {
    //             setDepartmentData(response.data);
    //         })
    //         .catch((errorresp) => {
    //             console.log(JSON.stringify(errorresp));
    //         });

    // }, []);

    useEffect(() => {
        // Fetch user data
        axios.get(apis.base + apis.GET_USERBYID_API + userId, { headers: { userid: loginUserId, Authorization: "Bearer " + baererToken } })
            .then(response => {
                const userData = response.data;
                setName(userData.user_name);
                setTitle(userData.title);
                setEmail(userData.u_email);
                setRole(userData.role_name);
                setRoleId(userData.role_id);
                setProfile(userData.profile_name);
                setProfileId(userData.profile_id);
                setDepartment(userData.department_name);
                setDepartmentId(userData.department_id);
                setLocation(userData.location_info[0]?.location_name);
                setLocationId(userData.location_id);
            })
            .catch(error => {
                console.error("Error fetching user data:", error);
                // Handle error
            });

        // Fetch roles data
        axios.get(apis.base + apis.GET_ALL_ROLES_API, { headers: { userid: loginUserId, Authorization: "Bearer " + baererToken } })
            .then(response => {
                setRolesData(response.data);
            })
            .catch(error => {
                console.log("Error fetching roles data:", error);
                // Handle error
            });

        // Fetch profile data
        axios.get(apis.base + apis.GET_ALL_PROFILE_API, { headers: { userid: loginUserId, Authorization: "Bearer " + baererToken } })
            .then(response => {
                setProfileData(response.data);
            })
            .catch(error => {
                console.log("Error fetching profile data:", error);
                // Handle error
            });

        // Fetch department data
        axios.get(apis.base + apis.GET_ALL_DEPARTMENT_API, { headers: { userid: loginUserId, Authorization: "Bearer " + baererToken } })
            .then(response => {
                setDepartmentData(response.data);
            })
            .catch(error => {
                console.log("Error fetching department data:", error);
                // Handle error
            });

        // Fetch location data
        axios.get(apis.base + apis.GET_LOCATION_API, { headers: { userid: loginUserId, Authorization: "Bearer " + baererToken } })
            .then(response => {
                setLocationData(response.data);
            })
            .catch(error => {
                console.log("Error fetching location data:", error);
                // Handle error
            });
    }, [userId, loginUserId, baererToken]);

    const handleProfileChange = (e) => {
        const selectedProfile = profileData.find(profile => profile.profile_info.profile_name === e.target.value);
        if (selectedProfile) {
            setProfile(selectedProfile.profile_info.profile_name);
            setProfileId(selectedProfile.profile_info.profile_id);
        }
    };
    const handleRoleChange = (e) => {
        const selectedRole = roleData.find(role => role.role_name === e.target.value);
        if (selectedRole) {
            setRole(selectedRole.role_name);
            setRoleId(selectedRole.role_id);
        }
    };
    const handleDepartmentChange = (e) => {
        const selectedDepartment = departmentData.find(department => department.department_name === e.target.value);
        if (selectedDepartment) {
            setRole(selectedDepartment.Department_name);
            setRoleId(selectedDepartment.Department_id);
        }
    };


    const handleSubmit = () => {
        let formIsValid = true;

        if (!name.trim()) {
            setNameError("Please enter a username");
            formIsValid = false;
        } else {
            setNameError("");
        }

        if (!email.trim()) {
            setEmailError("Please enter an email");
            formIsValid = false;
        } else if (!/\S+@\S+\.\S+/.test(email)) {
            setEmailError("Please enter a valid email address");
            formIsValid = false;
        } else {
            setEmailError("");
        }


        // if (!password.trim()) {
        //     setPasswordError("Please enter a password");
        //     formIsValid = false;
        // } else if (password.length < 8) {
        //     setPasswordError("Password must be at least 8 characters long and contain at least one lowercase letter, one uppercase letter, one number, and one special character.");
        //     formIsValid = false;
        // } else if (!/\d/.test(password)) {
        //     setPasswordError("Password must contain at least one number");
        //     formIsValid = false;
        // } else if (!/[a-z]/.test(password)) {
        //     setPasswordError("Password must contain at least one lowercase letter");
        //     formIsValid = false;
        // } else if (!/[A-Z]/.test(password)) {
        //     setPasswordError("Password must contain at least one uppercase letter");
        //     formIsValid = false;
        // } else if (!/\W/.test(password)) {
        //     setPasswordError("Password must contain at least one special character");
        //     formIsValid = false;
        // } else {
        //     setPasswordError("");
        // }

        if (!role) {
            setRoleError("Please Select a role");
            formIsValid = false;
        } else {
            setRoleError("");
        }
        if (!profile) {
            setProfileError("Please Select a profile");
            formIsValid = false;
        } else {
            setProfileError("");
        }
        if (!location) {
            setLocationError("Please Select a location");
            formIsValid = false;
        } else {
            setLocationError("");
        }
        if (!department) {
            setDepartmentError("Please Select a department");
            formIsValid = false;
        } else {
            setDepartmentError("");
        }

        if (formIsValid) {
            const userData = {

                "user_name": name,
                "email": email,
                "role_id": roleId,
                "profile_id": profileId,
                "department_id": departmentId,
                "locations": locationId,
                "title": title,
                "updated_by": loginUserId,

            };

            axios.put(apis.base + apis.PUT_USER_BY_ID_API + `${userId}`, userData,
                {
                    headers: {
                        userid: loginUserId,
                        Authorization: "Bearer " + baererToken
                    }
                })

                .then((res) => {
                    // console.log("res---", res.data);
                    setName("");
                    setEmail("");
                    // setPassword("");
                    setRoleId("");
                    // console.log("setroleid:", setRoleId);
                    setProfileId("");
                    // console.log("setprofileid:", setProfileId);
                    setDepartmentId("");
                    // console.log("setdepartmentid:", setDepartmentId);
                    setLocationId("");
                    // console.log("setlocationid:", setLocationId);
                    setTitle("");
                    setSnackbar({
                        open: true,
                        message: 'User updated successfully',
                        severity: 'success',
                    });

                })
                .catch((error) => {
                    console.error("Error submitting form:", error);
                    setSnackbar({
                        open: true,
                        message: 'User updated Failed',
                        severity: 'error',
                    });
                });
        } else {
            setFormError("");
        }
    };


    return (
        <div className="page-content">
            <div className="page-inner">
                <div className="page-title">
                    <h3 style={{ color: "black" }}>Edit User</h3>
                    <div className="page-breadcrumb">
                        <ol className="breadcrumb">
                            <li><Link to="/Dashboard">Home</Link></li>
                            <li><Link to="/Dashboard">User management</Link></li>
                            <li><Link to="/userslist">Users</Link></li>
                            <li>Edit User</li>
                        </ol>
                    </div>
                </div>
                <div className=" panel panel-white">
                    {/* {error && <Box bgcolor="white" color="red" p={2} mb={2} border={1} borderColor="black">
                        {error}
                    </Box>
                    } */}

                    <div id="main-wrapper">
                        <div className="col-md-12" style={{ display: "flex", justifyContent: "center" }}>
                            <div className="col-md-6 panel panel-white">
                                <ClickAwayListener onClickAway={() => { }}>
                                    <Snackbar
                                        open={snackbar.open}
                                        onClose={() => setSnackbar({ ...snackbar, open: false })}
                                        style={{ position: 'fixed', top: '15%', left: '50%', transform: 'translate(-50%, -50%)' }}
                                    >
                                        <Alert onClose={handleSnackbarClose} severity={"success"} sx={{ width: '150%' }} style={{ width: '300px', color: "green", height: '45px', fontSize: '12px' }}>
                                            {snackbar.message}
                                        </Alert>
                                    </Snackbar>
                                </ClickAwayListener>
                                <div className="panel-body">

                                    <div>
                                        <div className="form-group">

                                            <label htmlFor="exampleInputEmail1">User Name</label>
                                            <TextField
                                                fullWidth
                                                // value={userData.user_info?.user_name}
                                                value={name}
                                                onChange={(e) => setName(e.target.value)}
                                                type="text"
                                                placeholder="Enter user name"
                                                InputProps={{
                                                    style: { fontSize: '13px' } // Adjust the font size here
                                                }} />
                                            {nameError && <p style={{ color: "red" }}>{nameError}</p>}
                                        </div>

                                        <div className="form-group">
                                            <label htmlFor="exampleInputEmail1">Title</label>
                                            <Select fullWidth
                                                value={title}
                                                onChange={(e) => setTitle(e.target.value)}>
                                                {/* <MenuItem value="">Select title</MenuItem> */}
                                                <MenuItem value="Mr.">Mr.</MenuItem>
                                                <MenuItem value="Mrs.">Mrs.</MenuItem>
                                                <MenuItem value="Miss.">Miss.</MenuItem>
                                                <MenuItem value="Ms.">Ms.</MenuItem>
                                            </Select>
                                            {/* {titleError && <p style={{ color: "red" }}>{titleError}</p>} */}
                                        </div>

                                        <div className="form-group">
                                            <label htmlFor="exampleInputEmail1">Email ID</label>
                                            <TextField fullWidth
                                                // value={userData.user_info?.email}
                                                value={email}
                                                onChange={(e) => setEmail(e.target.value)}
                                                type="text" id="exampleInputEmail1" placeholder="Example@gmail.com"
                                                InputProps={{
                                                    style: { fontSize: '13px' } // Adjust the font size here
                                                }} />
                                            {emailError && <p style={{ color: "red" }}>{emailError}</p>}
                                        </div>




                                        {/* <div className="form-group"
                                        // style={{ marginRight: '10px' }}
                                        >
                                            <InputLabel htmlFor="outlined-adornment-password"
                                                style={{ fontSize: '12px', color: 'black' }}
                                            >Password</InputLabel>
                                            <FormControl fullWidth variant="outlined"
                                            //  style={{ marginRight: '10px' }}
                                            >
                                                <OutlinedInput
                                                    id="outlined-adornment-password"
                                                    type={showPassword ? 'text' : 'password'}
                                                    // value={userData.user_info?.password}
                                                    value={password}
                                                    onChange={(e) => setPassword(e.target.value)}
                                                    endAdornment={
                                                        <InputAdornment position="end">
                                                            <IconButton
                                                                aria-label="toggle password visibility"
                                                                onClick={handleClickShowPassword}
                                                                onMouseDown={handleMouseDownPassword}
                                                            // edge="end"
                                                            >
                                                                {showPassword ? <VisibilityOff /> : <Visibility />}
                                                            </IconButton>
                                                        </InputAdornment>
                                                    }
                                                // label="Password"
                                                />
                                            </FormControl>
                                            {passwordError && <p style={{ color: "red" }}>{passwordError}</p>}
                                        </div> */}



                                        <div className="form-group">
                                            <label htmlFor="exampleInputEmail1">Role</label>
                                            <Select fullWidth value={role} onChange={(e) => handleRoleChange(e)}
                                            >
                                                <MenuItem disabled> Select Role</MenuItem>
                                                {roleData?.map((role) => (
                                                    <MenuItem key={role?.role_id} value={role?.role_name}>{role?.role_name}</MenuItem>

                                                ))}
                                            </Select>
                                            {roleError && <p style={{ color: "red" }}>{roleError}</p>}
                                        </div>

                                        <div className="form-group">
                                            <label htmlFor="exampleInputEmail1">Profile</label>
                                            <Select value={profile} fullWidth onChange={(e) => handleProfileChange(e)}>
                                                <MenuItem disabled>Select profile</MenuItem>
                                                {profileData.map((profile_info) => (
                                                    <MenuItem key={profile_info?.profile_info?.profile_id} value={profile_info?.profile_info?.profile_name} selected={profile_info?.profile_info?.profile_id === profile}>
                                                        {profile_info.profile_info.profile_name}
                                                    </MenuItem>
                                                ))}
                                            </Select>

                                            {profileError && <p style={{ color: "red" }}>{profileError}</p>}
                                        </div>

                                        <div className="form-group">
                                            <label htmlFor="exampleInputEmail1">Location</label>
                                            <Select value={location} fullWidth onChange={(e) => setLocation(e.target.value)}>
                                                <MenuItem disabled> Select Location</MenuItem>
                                                {locationData?.map((location) => (
                                                    <MenuItem key={location?.location_id} value={location?.location_name}>{location?.location_name}</MenuItem>
                                                ))}
                                            </Select>
                                            {locationError && <p style={{ color: "red" }}>{locationError}</p>}
                                        </div>



                                        <div className="form-group">
                                            <label htmlFor="exampleInputEmail1">Department</label>
                                            <Select value={department} fullWidth onChange={(e) => handleDepartmentChange(e)}>

                                                <MenuItem disabled> Select department</MenuItem>
                                                {departmentData?.map((department) => (
                                                    <MenuItem key={department?.department_id} value={department?.department_name}>{department?.department_name}</MenuItem>
                                                ))}
                                            </Select>
                                            {departmentError && <p style={{ color: "red" }}>{departmentError}</p>}

                                        </div>

                                        {/* {formError && <p style={{ color: "red" }}>{formError}</p>} */}

                                        <div className="panel-body" style={{ display: 'flex' }}>
                                            <div>
                                                <Button type="button" className="btn btn-primary" style={{
                                                    marginLeft: '40px', width: '80px',
                                                    height: '38px', background: '#22baa0', fontSize: '12px', color: 'white'
                                                }} onClick={handleSubmit}>Submit</Button>
                                            </div>
                                            <div>
                                                <Link to='/userslist'> <Button type="button" className="btn btn-success" style={{
                                                    marginLeft: '50px',
                                                    width: '80px', height: '38px', background: '#34425a', color: 'white', fontSize: '12px'
                                                }}>Cancel</Button></Link>
                                            </div>
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default EditUser;