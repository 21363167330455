import React, { useState, useEffect } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import axios from "axios";
import { Button, MenuItem, Select, TextField } from "@mui/material";
import { apis } from "../../configuration/configurationAPI";
import { Snackbar, Alert, ClickAwayListener } from "@mui/material";
import { useSelector } from 'react-redux';
import { commonSelectors } from '../../utils/commonstore';

function DepartmentEdit() {
    const baererToken = useSelector(commonSelectors.selectBaererToken);
    const loginUserId = useSelector(commonSelectors.selectLoginUserId);
    const { departmentId } = useParams();
    // console.log("ssssss", departmentId)
    const Navigate = useNavigate();

    const [department, setDepartment] = useState({
        department_name: "",
        location_id: ""
    });

    const [location, setLocation] = useState([]);
    const [snackbar, setSnackbar] = useState({
        open: false,
        message: "",
        severity: "success"
    });
    const [errors, setErrors] = useState({
        department_name: "",
        location_id: ""
    });

    useEffect(() => {
        if (departmentId) {
            axios.get(apis.base + apis.GET_DEPARTMENTID_API + departmentId,{
                headers: {
                    userid:loginUserId,
                    Authorization:"Bearer "+ baererToken
                }
            })
                .then((response) => {
                    setDepartment(response.data);
                    // console.log(response.data,"JJJJKKKKK")
                })
                .catch((error) => {
                    console.error("Error fetching department details:", error);
                    setSnackbar({
                        open: true,
                        message: "Failed to fetch department details",
                        severity: "error"
                    });
                });
        }

        axios.get(apis.base + apis.GET_LOCATION_API ,{
            headers: {
                userid:loginUserId,
                Authorization:"Bearer "+ baererToken
            }
        })
            .then((response) => {
                setLocation(response.data);
            })
            .catch((error) => {
                console.log("Error fetching locations:", error);
            });
    }, [departmentId]);

    const handleInputChange = (event) => {
        const { name, value } = event.target;
        setDepartment({ ...department, [name]: value });
    };
    const validateForm = () => {
        let isValid = true;
        let errorsCopy = { ...errors };

        if (!department.department_name.match(/^[a-zA-Z\s]{3,}$/)) {
            errorsCopy.department_name = "Department name must contain only alphabets and be at least 3 characters long";
            isValid = false;
        } else {
            errorsCopy.department_name = "";
        }

        if (!department.location_id) {
            errorsCopy.location_id = "Please select a location";
            isValid = false;
        } else {
            errorsCopy.location_id = "";
        }

        setErrors(errorsCopy);
        return isValid;
    };
    const submit = () => {
        if (validateForm()) {
            const updatedData = {
                department_name: department.department_name,
                location_id: department.location_id
            };

            axios.put(apis.base + apis.PUT_DEPARTMENT_API + departmentId, updatedData,{
                headers: {
                    userid:loginUserId,
                    Authorization:"Bearer "+ baererToken
                }
            })
                .then(succresp => {
                    setSnackbar({
                        open: true,
                        message: "Department details updated successfully",
                        severity: "success"
                    });
                })
                .catch(errorresp => {
                    console.error("Error updating department:", errorresp);
                    setSnackbar({
                        open: true,
                        message: "Failed to update department",
                        severity: "error"
                    });
                });
        }
    };

    const handleSnackbarClose = () => {
        Navigate("/department/list")
        setSnackbar({ ...snackbar, open: false });
    };   

    return (
        <div className="page-content">
            <div className="page-inner">
                <div className="page-title">
                    <h3 style={{ color: "black" }}>Departments Edit</h3>
                    <div className="page-breadcrumb">
                        <ol className="breadcrumb">
                            <li><Link to="/Dashboard">Home</Link></li>
                            <li><Link to="">User management</Link></li>
                            <li><Link to="/department/list">Departments</Link></li>
                            <li>Department Edit</li>
                        </ol>
                    </div>
                </div>
                <div className="panel panel-white" style={{ alignItems: 'center', marginLeft: '400px', maxWidth: '40%' }}>
                    <div id="main-wrapper">
                        <div className="row">
                            <ClickAwayListener onClickAway={() => { }}>
                                <Snackbar
                                    open={snackbar.open}
                                    onClose={() => setSnackbar({ ...snackbar, open: false })}
                                    style={{ position: 'fixed', top: '15%', left: '50%', transform: 'translate(-50%, -50%)' }}
                                >
                                    <Alert onClose={handleSnackbarClose} severity={snackbar.severity}variant="filled" sx={{ width: '100%' }} style={{ width: '400px', color: "white", height: '45px', fontSize: '12px' }}>
                                        {snackbar.message}
                                    </Alert>
                                </Snackbar>
                            </ClickAwayListener>
                            <div className="panel-body">
                                <div className="panel panel-white" >
                                    <div className="col-md-10">
                                        <div className="panel-heading clearfix" style={{ marginBottom: "-30px" }}>
                                            <h4 className="panel-title">Edit Department</h4>
                                        </div>
                                        <div className="panel-body">
                                            <div>
                                                <div className="form-group">
                                                    <label htmlFor="exampleInputEmail1" style={{ paddingLeft: '10px' }}>Department Name</label>
                                                    <TextField
                                                        name="department_name"
                                                        value={department.department_name}
                                                        onChange={(e) => handleInputChange(e)}
                                                        style={{ width: '400px' }}
                                                        // error={Boolean(errors.department_name)}
                                                        // helperText={errors.department_name}
                                                        className="form-control" placeholder="Department Name"
                                                    />
                                                </div>
                                    {errors.department_name && <p className="text-danger" style={{marginLeft:'10px'}}>{errors.department_name}</p>}

                                                <div className="form-group">
                                                    <label htmlFor="exampleInputEmail1" style={{ paddingLeft: '10px', padding: '10px' }}>Location Name</label>
                                                    <Select
                                                        name="location_id"
                                                        value={department.location_id}
                                                        onChange={(e) => handleInputChange(e)}
                                                        label="Location"
                                                        style={{ fontSize: "11px", border: "1px solid #ccc", width: '380px', height: '45px', marginLeft:'10px' }}
                                                    >
                                                        <MenuItem disabled>Select Location</MenuItem>
                                                        {location.map((location) => (
                                                            <MenuItem key={location.location_id} value={location.location_id}>{location.location_name}</MenuItem>
                                                        ))}
                                                    </Select>
                                                    {errors.location_id && (
                                                        <div style={{ color: "red", fontSize: "11px", marginTop: "5px" }}>{errors.location_id}</div>
                                                    )}
                                                </div>
                                                <div className="panel-body" style={{ display: 'flex' }}>
                                                    <Button onClick={submit} variant="contained" color="primary" style={{ marginLeft: '30px', width: '65px', background: '#22baa0' }} >Submit</Button>
                                                    <Link to="/department/list"><Button variant="contained" color="success" style={{ marginLeft: '70px', background: '#34425a' }}>Cancel</Button></Link>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default DepartmentEdit;
