import React from 'react';
import axios from 'axios';
import { Button } from '@mui/material';
import { useSelector } from 'react-redux';
import { commonSelectors } from '../../utils/commonstore';
import { apis } from '../../configuration/configurationAPI';

const SingleFileExportPDF = (props) => {
  const baererToken = useSelector(commonSelectors.selectBaererToken);
  const loginUserId = useSelector(commonSelectors.selectLoginUserId);

  const exportSinglePdf = async () => {
    try {
      const headers = {
        userid: loginUserId,
        Authorization: "Bearer " + baererToken,
        format: "pdf",
      };

      // Await the response of the GET request
      const response = await axios.get(
        `${apis.base}${apis.GET_INCIDENT_EXPORT_PDF}?search=${encodeURIComponent(props.search)}`, 
        {
          headers: headers,
          responseType: 'blob', // Ensure the response is in blob format
        }
      );

      // Create a URL for the PDF blob
      const url = window.URL.createObjectURL(new Blob([response.data]));

      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', 'searched_incident.pdf'); // Change the filename as needed
      document.body.appendChild(link);
      link.click(); // Simulate click to download the file
      window.URL.revokeObjectURL(url); // Clean up the URL object
      document.body.removeChild(link); // Remove the link element after download
    } catch (error) {
      console.error('Error exporting data:', error);
    }
  };

  return (
    <Button variant="contained" onClick={exportSinglePdf}>
      Export Filtered as PDF
    </Button>
  );
};

export default SingleFileExportPDF;
