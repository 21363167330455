import React, { useEffect, useState } from "react";
import axios from "axios";
import { apis } from "../../configuration/configurationAPI";
import { Checkbox, FormControlLabel } from "@mui/material";
import { Link, useParams } from "react-router-dom";
import { commonSelectors } from '../../utils/commonstore';
import store from "../../utils/index";
import { useSelector } from 'react-redux';

const dispatch = store.dispatch;
let commonStore = store.getState().commonStore;
store.subscribe(function () {
  commonStore = store.getState().commonStore;
});

const ViewProfile = () => {
    const { profile_id } = useParams();
    const [profileName, setProfileName] = useState("");
    const [permissions, setPermissions] = useState(null);
    const [error, setError] = useState(null);
    const baererToken = useSelector(commonSelectors.selectBaererToken);
    const loggedinId = useSelector(commonSelectors.selectLoginUserId);
    useEffect(() => {
        axios
            .get(apis.base + apis.GET_PROFILE_BY_ID_API + profile_id,{
                headers: {

                    userid: loggedinId,
                    Authorization:"Bearer "+ baererToken
    
                }
            })
            .then((response) => {
                // console.log("qweqwe", profile_id)
                const { dashboard, audit, forms, submitted_files, roles, users, profiles, departments, permission_list, reports, exports, profile_name } = response.data;

                setPermissions({ dashboard, audit, forms, submitted_files, roles, users, profiles, departments, permission_list, reports, exports });
                setProfileName(profile_name);
                setError(null);
            })
            .catch((error) => {

                console.error("Error fetching profile details:", error);
                setError("Error while fetching profile details. Please try again.");
            });
    }, [profile_id]);

    return (
        <div>
            <div className="page-inner">
                <div className="page-title">
                    <h3 style={{ color: "black" }}>View Profile</h3>
                    <div className="page-breadcrumb" >
                        <ol className="breadcrumb">
                            <li><Link to="/Dashboard">Home</Link></li>
                            <li><Link to="/profilelist">Profile</Link></li>
                            <li>View Profile</li>
                        </ol>
                    </div>
                </div>
                <div className="panel panel-white">
                    <div style={{ marginLeft: "40px" }}>
                        <label style={{ fontSize: "15px", marginTop: "15px", fontWeight: "bold" }}>Profile Name <br/> <span style={{ fontSize: "15px", fontWeight: "normal" }} >{profileName}</span></label>
                    </div>
                    <div className="panel-body">
                        {error && <p style={{ marginLeft: "30%", fontSize: "15px", color: "red" }}>{error}</p>}
                        {!error && (
                            <div className="table-responsive">
                                {permissions === null ? (
                                    <p style={{ textAlign: "center" }}>Loading permissions...</p>
                                ) : permissions ? (
                                    <table id="example" className="display table" style={{ width: "100%" }}>
                                        {/* <thead style={{ backgroundColor: "#22baa0" }}> */}
                                        <thead style={{backgroundColor:"rgb(35 212 182)"}}>
                                            <tr>
                                                <th> </th>
                                                <th>VIEW</th>
                                                <th>CREATE</th>
                                                <th>EDIT</th>
                                                <th>DELETE</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {Object.entries(permissions).map(([key, permission]) => (
                                                <tr key={key}>
                                                    <td>{key.toUpperCase()} </td>

                                                    {permissions[key] && permissions[key].split("").map((value, index) => (
                                                        <td key={index}>
                                                            <FormControlLabel style={{ marginLeft: "1px" }}
                                                                control={<Checkbox
                                                                    disabled
                                                                    checked={value === "1"}
                                                                    style={{ color: value === "1" ? "#3c70dc" : "inherit" }}
                                                                />}
                                                            />
                                                        </td>
                                                    ))}
                                                </tr>
                                            ))}
                                        </tbody>
                                    </table>
                                ) : (
                                    <p>No permissions found</p>
                                )}
                            </div>
                        )}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ViewProfile;
