// import React from 'react';
// import { Pie,} from 'react-chartjs-2';
// import 'chart.js/auto';

// const PieCharts1 = () => {
//   const pieData = {
//     // labels: ['Day', 'Weekly', 'Monthly', 'Yearly'],
//     datasets: [
//       {
//         data: [10, 20, 30, 45],
//         backgroundColor: ['#f0e67d', '#8B8883', '#e86b84', '#f0ac78'],
//       },
//     ],
//   };

//   const barData = {
//     labels: ['Vendor 1', 'Vendor 2', 'Vendor 3', 'Vendor 4'],
//     datasets: [
//       {
//         label: 'Yearly',
//         data: [10, 20, 30, 45],
//         backgroundColor: ['#1E90FF', '#FFA500', '#C71585', '#00FF00'],
//         hoverBackgroundColor: ['#1E90FF', '#FFA500', '#C71585', '#00FF00'],
//       },
//     ],
//   };

  
//   return (
    
//     // <div style={{display:'flex',justifyContent: 'space-between' ,marginLeft:'100px',marginBottom:'20px'}}>
        
//     //   <div style={{ width: '60%'}}>
//     //     <div className="page-wrapper">
//     //       <div className="page-content">
//     //         <div className="row" style={{width:"100%",height:'50%'}}>
//     //           <div className="col-12 col-lg-12">
//     //             <div className="card radius-5 ">
//                 <div style={{ height: '318px' }}>
//                   <Pie data={pieData}   />
//                   </div>
//     //             </div>
//     //           </div>
//     //         </div>
//     //       </div>
//     //     </div>
//     //   </div>
//     // </div>
//   );
// };

// export default PieCharts1;
import React, { useState, useEffect } from 'react';
import { Pie } from 'react-chartjs-2';
import axios from 'axios';
import { apis } from '../../configuration/configurationAPI';
import DatePicker from "react-datepicker";
import {useSelector} from "react-redux";
import { commonSelectors } from '../../utils/commonstore';
import store from '../../utils';

const dispatch = store.dispatch;
let commonStore = store.getState().commonStore;
store.subscribe(function () {
  commonStore = store.getState().commonStore;
});



const PieChart = () => {
    const [chartData, setChartData] = useState({
        labels: [],
        datasets: [
            {
                data: [],
                backgroundColor: ['#f0e67d', '#8B8883', '#e86b84', '#f0ac78', '#78baf0', '#f3f3f3', '#3f4754', '#3e8745', '#383820', '#999918', '#380334', '#6925cf', '#18d9b5', '#e0dae8'],
            },
        ],
    });

    const baererToken = useSelector(commonSelectors.selectBaererToken);
    const loggedinId = useSelector(commonSelectors.selectLoginUserId);
    // console.log(loggedinId,"mmmmnnnnnbbbbb")
    const userInfo = useSelector(commonSelectors.selectUserInfo);
//    console.log(userInfo,"userInfo")
//    console.log(baererToken,"token")

    const [fromDate, setFromDate] = useState(new Date("01-01-2020"));
    const [toDate, setToDate] = useState(new Date());
    const [showFilters, setShowFilters] = useState(false);
    const [fetchData, setFetchData] = useState(false);

    useEffect(() => {
        fetchDataAndDisplayChart();
    }, [fromDate, toDate]);

    const fetchDataAndDisplayChart = async () => {
        try {
            const response = await axios.post(apis.base + apis.GET_INCIDENT_FILTER_INCIDENT_COUNT_REPORT_API, {
                "start_date": formatDateToDDMMYYYY(fromDate),
                "to_date": formatDateToDDMMYYYY(toDate),
                "based_on": "incident_type",
                "is_report": false,
            }, {
                headers: {
                    userId: loggedinId,
                    Authorization: "Bearer " + baererToken
                }
            });
    
            const responseData = response.data;
    
            // Convert incident types to lowercase
            const resp_labels = responseData.map((el) => el.incident_type.toUpperCase());
            const resp_labels_count = responseData.map((el) => JSON.parse(el.count));
    
            setChartData((prevChartData) => ({
                ...prevChartData,
                labels: resp_labels,
                datasets: [
                    {
                        ...prevChartData.datasets[0],
                        data: resp_labels_count,
                    },
                ],
            }));
        } catch (error) {
            console.error('Error fetching real-time data:', error);
        }
    };

    const formatDateToDDMMYYYY = (date) => {
        var day = date.getDate();
        var month = date.getMonth() + 1;
        var year = date.getFullYear();

        var paddedDay = day < 10 ? '0' + day : day;
        var paddedMonth = month < 10 ? '0' + month : month;

        var formattedDate = '' + paddedDay + paddedMonth + year;

        return formattedDate;
    };

    // const handleFilterButtonClick = () => {
    //     fetchDataAndDisplayChart();
    // };

    const options = {
        maintainAspectRatio: false,
        plugins: {
            legend: {
                display: true,
                position: 'right',
                labels: {
                    boxWidth: 15,
                    maxHeight: 200,
                },
            },
        },
    };
    const handleFilterButtonClick = () => {
        setShowFilters(!showFilters);
        if (!showFilters) {
            fetchDataAndDisplayChart();
        }
    };

    // Fetch data whenever fromDate, toDate, or fetchData changes
    useEffect(() => {
        if (showFilters) {
            fetchDataAndDisplayChart();
        }
    }, [fromDate, toDate, showFilters]);

    return (
        <div>
            <div>
                {/* <button type="button" className="btn btn-success" onClick={handleFilterButtonClick} style={{ position: "relative", bottom: "12px", left: "290px" }}>
                    Apply Filters
                </button> */}
                <div style={{ position: "relative", bottom: "85px", left: "183px" }}>
                  {/* <div style={{ position: "relative", bottom: "102px", left: "280px", }}> */}
                  <div className="col-md-4">
                      <label htmlFor="toDate" className="form-label">From Date</label>
                      <DatePicker
                          id="toDate"
                          className="form-control"
                          selected={fromDate}
                        //   isClearable
                          placeholderText="MM/DD/YYYY"
                          showYearDropdown
                          scrollableYearDropdown
                          onChange={(date) => setFromDate(date)}
                          dateFormat="MM/dd/yyyy"
                      />
                  </div>
                  <div className="col-md-4">
                      <label htmlFor="toDate" className="form-label">To Date</label>
                      <DatePicker
                          id="toDate"
                          className="form-control"
                          selected={toDate}
                        //   isClearable
                          placeholderText="MM/DD/YYYY"
                          showYearDropdown
                          scrollableYearDropdown
                          onChange={(date) => setToDate(date)}
                          dateFormat="MM/dd/yyyy"
                      />
                  </div>
              {/* </div> */}
              </div>
            </div>
            <div style={{ position: "", height: "450px"}}>
                <Pie data={chartData} options={options} />
            </div>
        </div>
    );
};

export default PieChart;
