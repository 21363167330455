import React, { useState } from 'react';
import ChatIcon from './chatIcon'
import ChatPopup from './ChatPopup';

const Chatbot = () => {
    const [isChatOpen, setIsChatOpen] = useState(false);
    const [info, setInfo] = useState(null);
    const [loading, setLoading] = useState(false);
    const toggleChat = () => {
        if (isChatOpen) {
            setInfo(null); 
        }
        setIsChatOpen(!isChatOpen);
    
    };

    const fetchInfo = async (id) => {
        setLoading(true); 
        try {
            const response = await fetch(`http://ec2-43-205-137-172.ap-south-1.compute.amazonaws.com:8071/incidentAI/incidentAILocation`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({ incident_id: id })
            });

            const data = await response.json();

            if (response.ok && data.description_analysis) {
                setInfo(data.description_analysis);
            } else {
                setInfo({ error: 'Description not found' });
            }
        } catch (error) {
            setInfo({ error: 'An error occurred while fetching the data' });
        } finally {
            setLoading(false);
        }
    };

    return (
        <div>
            <ChatIcon onClick={toggleChat} />
            {isChatOpen && <ChatPopup onClose={toggleChat} onFetchInfo={fetchInfo} info={info} loading={loading} />}
        </div>
    );
};

export default Chatbot;