import React, { useState, useEffect } from 'react';
import { Link, useParams } from "react-router-dom";
import axios from 'axios';
import { apis } from '../../configuration/configurationAPI';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Box from '@mui/material/Box';
import FormControl from '@mui/material/FormControl';
import { Button, TextField, Snackbar, } from "@mui/material";
import Autocomplete from '@mui/material/Autocomplete';
import MuiAlert from '@material-ui/lab/Alert';
import DatePicker from 'react-datepicker';
import { IconButton } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import GooglePlacesAutocomplete, { geocodeByPlaceId } from 'react-google-places-autocomplete';
import { useSelector } from 'react-redux';
import store from "../../utils/index";
import { useNavigate } from 'react-router-dom';
import { commonSelectors } from "../../utils/commonstore";
const dispatch = store.dispatch;
let commonStore = store.getState().commonStore;
store.subscribe(function () {
  commonStore = store.getState().commonStore;
});


const PatientBehaviorForm = () => {
  const { action, incident_id } = useParams();
  const editIncidentData = useSelector(commonSelectors.selectEditIncidentData);
  const viewIncidentData = useSelector(commonSelectors.selectViewIncidentData);
  const getCurrentTime = () => {
    const now = new Date();
    const utcOffset = -4 * 60; // UTC offset for Eastern Time Zone (-4 hours)
    const utcTime = now.getTime() + (now.getTimezoneOffset() * 60 * 1000);
    const estTime = new Date(utcTime + (utcOffset * 60 * 1000));
    const hours = String(estTime.getHours()).padStart(2, '0');
    const minutes = String(estTime.getMinutes()).padStart(2, '0');
    return `${hours}:${minutes}`;
  };
  const baererToken = useSelector(commonSelectors.selectBaererToken);
  const loginUserId = useSelector(commonSelectors.selectLoginUserId);
  // general incident info
  const [generalincidenterror, setGeneralIncidenterror] = useState("");
  const [patientTypeError, setPatientTypeError] = useState("");
  const [injuryIncurredError, setInjuryIncurredError] = useState("");
  const [equipmentMalfunctionedError, setEquipmentMalfunctionedError] = useState("");

  // demographics info
  const [firstNameError, setFirstNameError] = useState("");
  const [lastNameError, setLastNameError] = useState("");
  const [genderError, SetGenderError] = useState("");
  const [dobError, setdobError] = useState("");
  const [addressError, setaddressError] = useState("");
  const [streetError, setstreetError] = useState("");
  const [cityError, setcityError] = useState("");
  const [stateError, setstateError] = useState("");
  const [countryError, setcountryError] = useState("");
  const [zipCodeError, setzipCodeError] = useState("");


  // incident details
  const [incidentdateError, setIncidentdateError] = useState("");
  const [stateincidentoccuredError, setStateIncidentOccuredError] = useState("");
  const [siteincidentoccuredError, setSiteIncidentOccuredError] = useState("");

  // special incident info
  const [specificIncidentError, setSpecificIncidentError] = useState("");
  const [reportedIncidentError, setReportedIncidentError] = useState("");
  const [incidentdescriptionError, setIncidentDescriptionError] = useState("");
  const [deptincidentoccuredError, setDeptIncidentOccuredError] = useState("");
  const [immediateActionError, setImmediateActionError] = useState("");

  const [showSuccessPopup, setShowSuccessPopup] = useState("");
  const [snackbarMessage, setSnackbarMessage] = useState('');

  const [selectedFiles, setSelectedFiles] = useState([]);

  const [inputValue, setInputValue] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const navigateTo = useNavigate();

  const accessInfo = useSelector(commonSelectors.selectAccessInfo);
  const createAllowed = [ "1100", "1101", "1110", "1111"];

  // Check if the user has the permission to submit (create or update)
  const isAnonymousUser = JSON.parse(sessionStorage.getItem('rl6User'))?.isVisitor;
  const canSubmit =isAnonymousUser || (accessInfo?.forms && createAllowed.includes(accessInfo.forms));
  
  // Determine if it's an "edit-only" permission (like 1010)
  const isEditOnly = accessInfo?.forms === "1010" || accessInfo?.forms === "1011";

  const [patientBehavior, setPatientBehavior] = useState(
    viewIncidentData.spec ? viewIncidentData.spec :
      (editIncidentData.spec ? editIncidentData.spec :
        {
          "general_incident_info": {
            "incident_type": "",
            "patient_type": "",
            "injury_incurred": "",
            "equipment_involved": ""
          },
          "demographics_info": {
            "first_name": "",
            "last_name": "",
            "gender": 0,
            "dob": null,
            "address": "",
            "street": "",
            "city": "",
            "state": "",
            "country": "",
            "zipcode": ""
          },
          "incident_details": {
            "incident_date": new Date(),
            "incident_time": getCurrentTime(),
            "incident_state": 0,
            "incident_site": 0,
            "file_owner": "",
            "entered_date": new Date(),
            "entered_time": getCurrentTime(),
            "entered_by": "",
            "witness_name": "",
            "witness_phone": ""
          },

          "special_additional_info": {
            "department_id": 0,
            "main_behavioral_concern": "",
            "issue_duration": "",
            "triggers_or_patterns": "",
            "current_medications": "",
            "past_medications": "",
            "patient_reaction": "",
            "family_history": "",
            "social_support": "",
            "previous_interventions": "",
            "other_information": "",
            "patient_reaction": false,
            "physician_involved": false,
            "immediate_actions_taken": "",
            "contributing_factors": "",
            "reported_incident_severity": "",
            "incident_description": "",
            "attachment": []
          }
        })
  );


  const patientBehaviourStateString = JSON.stringify(patientBehavior);

  const handleStateChange = (key, value) => {
    let temppatientBehaviorState = JSON.parse(JSON.stringify(patientBehavior));
    switch (key) {
      // general incident info
      case "incident_type": temppatientBehaviorState.general_incident_info.incident_type = value;
      setPatientBehavior(temppatientBehaviorState);;
        break;
      case "patient_type": temppatientBehaviorState.general_incident_info.patient_type = value;
      setPatientBehavior(temppatientBehaviorState);
        break;
      case "injury_incurred": temppatientBehaviorState.general_incident_info.injury_incurred = value;
      setPatientBehavior(temppatientBehaviorState);
        break;
      case "equipment_involved": temppatientBehaviorState.general_incident_info.equipment_involved = value;
      setPatientBehavior(temppatientBehaviorState);
        break;

      // demographics
      case "first_name": temppatientBehaviorState.demographics_info.first_name = value;
        setPatientBehavior(temppatientBehaviorState);
        break;
      case "last_name": temppatientBehaviorState.demographics_info.last_name = value;
        setPatientBehavior(temppatientBehaviorState);
        break;
      case "gender": temppatientBehaviorState.demographics_info.gender = value;
        setPatientBehavior(temppatientBehaviorState);
        break;
      case "dob": temppatientBehaviorState.demographics_info.dob = value;
        setPatientBehavior(temppatientBehaviorState);
        break;
      case "address": temppatientBehaviorState.demographics_info.address = value;
        setPatientBehavior(temppatientBehaviorState);
        break;
      case "street": temppatientBehaviorState.demographics_info.street = value;
        setPatientBehavior(temppatientBehaviorState);
        break;
      case "city": temppatientBehaviorState.demographics_info.city = value;
        setPatientBehavior(temppatientBehaviorState);
        break;
      case "state": temppatientBehaviorState.demographics_info.state = value;
        setPatientBehavior(temppatientBehaviorState);
        break;
      case "country": temppatientBehaviorState.demographics_info.country = value;
        setPatientBehavior(temppatientBehaviorState);
        break;
      case "zipcode": temppatientBehaviorState.demographics_info.zipcode = value;
        setPatientBehavior(temppatientBehaviorState);
        break;
      case "addressValues":
        temppatientBehaviorState.demographics_info.zipcode = value.zipcode;
        temppatientBehaviorState.demographics_info.country = value.country;
        temppatientBehaviorState.demographics_info.state = value.state;
        temppatientBehaviorState.demographics_info.city = value.city;
        temppatientBehaviorState.demographics_info.street = value.street;
        temppatientBehaviorState.demographics_info.address = value.address;

        // temppatientBehaviorState.demographics_info.address = value;
        setPatientBehavior(temppatientBehaviorState);
        break;

      // incident details
      case "incident_date": temppatientBehaviorState.incident_details.incident_date = value;
        setPatientBehavior(temppatientBehaviorState);
        break;
      case "incident_time": temppatientBehaviorState.incident_details.incident_time = value;
        setPatientBehavior(temppatientBehaviorState);
        break;
      case "incident_state": temppatientBehaviorState.incident_details.incident_state = value;
        setPatientBehavior(temppatientBehaviorState);
        break;
      case "incident_site": temppatientBehaviorState.incident_details.incident_site = value;
        setPatientBehavior(temppatientBehaviorState);
        break;
      case "file_owner": temppatientBehaviorState.incident_details.file_owner = value;
        setPatientBehavior(temppatientBehaviorState);
        break;
      case "entered_date": temppatientBehaviorState.incident_details.entered_date = value;
        setPatientBehavior(temppatientBehaviorState);
        break;
      case "entered_time": temppatientBehaviorState.incident_details.entered_time = value;
        setPatientBehavior(temppatientBehaviorState);
        break;
      case "entered_by": temppatientBehaviorState.incident_details.entered_by = value;
        setPatientBehavior(temppatientBehaviorState);
        break;
      case "witness_name": temppatientBehaviorState.incident_details.witness_name = value;
        setPatientBehavior(temppatientBehaviorState);
        break;
      case "witness_phone": temppatientBehaviorState.incident_details.witness_phone = value;
        setPatientBehavior(temppatientBehaviorState);
        break;
      case "witness_address": temppatientBehaviorState.incident_details.witness_address = value;
        setPatientBehavior(temppatientBehaviorState);
        break;

      // special additinal info
      case "department_id": temppatientBehaviorState.special_additional_info.department_id = value;
        setPatientBehavior(temppatientBehaviorState);
        break;
      case "main_behavioral_concern": temppatientBehaviorState.special_additional_info.main_behavioral_concern = value;
        setPatientBehavior(temppatientBehaviorState);
        break;
      case "issue_duration": temppatientBehaviorState.special_additional_info.issue_duration = value;
        setPatientBehavior(temppatientBehaviorState);
        break;
      case "triggers_or_patterns": temppatientBehaviorState.special_additional_info.triggers_or_patterns = value;
        setPatientBehavior(temppatientBehaviorState);
        break;
      case "current_medications": temppatientBehaviorState.special_additional_info.current_medications = value;
        setPatientBehavior(temppatientBehaviorState);
        break;
      case "past_medications": temppatientBehaviorState.special_additional_info.past_medications = value;
        setPatientBehavior(temppatientBehaviorState);
        break;
      case "patient_reaction": temppatientBehaviorState.special_additional_info.patient_reaction = value;
        setPatientBehavior(temppatientBehaviorState);
        break;
      case "family_history": temppatientBehaviorState.special_additional_info.family_history = value;
        setPatientBehavior(temppatientBehaviorState);
        break;
      case "social_support": temppatientBehaviorState.special_additional_info.social_support = value;
        setPatientBehavior(temppatientBehaviorState);
        break;
      case "previous_interventions": temppatientBehaviorState.special_additional_info.previous_interventions = value;
        setPatientBehavior(temppatientBehaviorState);
        break;
      case "physician_involved": temppatientBehaviorState.special_additional_info.physician_involved = value;
        setPatientBehavior(temppatientBehaviorState);
        break;
      case "other_information": temppatientBehaviorState.special_additional_info.other_information = value;
        setPatientBehavior(temppatientBehaviorState);
        break;
      case "immediate_actions_taken": temppatientBehaviorState.special_additional_info.immediate_actions_taken = value;
        setPatientBehavior(temppatientBehaviorState);
        break;
      case "contributing_factors": temppatientBehaviorState.special_additional_info.contributing_factors = value;
        setPatientBehavior(temppatientBehaviorState);
        break;
      case "reported_incident_severity": temppatientBehaviorState.special_additional_info.reported_incident_severity = value;
        setPatientBehavior(temppatientBehaviorState);
        break;
      case "incident_description": temppatientBehaviorState.special_additional_info.incident_description = value;
        setPatientBehavior(temppatientBehaviorState);
        break;
      case "attachment": temppatientBehaviorState.special_additional_info.attachment = value;
        setPatientBehavior(temppatientBehaviorState);
        break;
      default: break;
    }
  }
  useEffect(() => {

  }, []);

  const handleSubmit = (e) => {
    e.preventDefault();

    let valid = true;
    const namePattern = /^[a-zA-Z]+$/;
    const zipCodePattern = /^\d{6}$/;


    if (!patientBehavior.general_incident_info.incident_type) {
      setGeneralIncidenterror("Please Select a General Incident Type.");
    } else {
      setGeneralIncidenterror("");
    }
    if (!patientBehavior.general_incident_info.patient_type) {
      setPatientTypeError("Please Enter Patient Type.");
    } else {
      setPatientTypeError("");
    }
    if (!patientBehavior.general_incident_info.injury_incurred) {
      setInjuryIncurredError("Please Enter Injury Incurred.");
    } else {
      setInjuryIncurredError("");
    }
    if (!patientBehavior.general_incident_info.equipment_involved) {
      setEquipmentMalfunctionedError("Please Enter Equipment Involved/ Malfunctioned.");
    } else {
      setEquipmentMalfunctionedError("");
    }

    if (!patientBehavior.demographics_info.last_name.match(namePattern)) {
      setLastNameError("Last name should only contain alphabets.");
      valid = false;
    } else {
      setLastNameError("");
    }

    // incidentdetails

    // if (!patientBehavior.incident_details.incident_site) {
    //   setSiteIncidentOccuredError("Please Enter Site Incident Occured.");
    // } else {
    //   setSiteIncidentOccuredError("");
    // }

    if (!patientBehavior.incident_details.incident_date) {
      setIncidentdateError("Please Enter Incidentdate.");
    } else {
      setIncidentdateError("");
    }
    if (!patientBehavior.incident_details.incident_state) {
      setStateIncidentOccuredError("Please Enter State Incident Occured.");
    } else {
      setStateIncidentOccuredError("");
    }

    if (!patientBehavior.special_additional_info.department_id) {
      setDeptIncidentOccuredError("Please select Department Type.");
    } else {
      setDeptIncidentOccuredError("");
    }

    if (!patientBehavior.special_additional_info.reported_incident_severity) {
      setReportedIncidentError("Please select reported incident severity.");
    } else {
      setReportedIncidentError("");
    }

    if (!patientBehavior.special_additional_info.incident_description) {
      setIncidentDescriptionError("Please select reported incident severity.");
    } else {
      setIncidentDescriptionError("");
    }

    const validateInputs = () => {
      // Check for errors in form fields
      if (
        !patientBehavior.general_incident_info.incident_type ||
        !patientBehavior.general_incident_info.patient_type ||
        !patientBehavior.general_incident_info.injury_incurred ||
        !patientBehavior.general_incident_info.equipment_involved ||
        !patientBehavior.demographics_info.last_name ||
        !patientBehavior.incident_details.incident_date ||
        !patientBehavior.incident_details.incident_state ||
        !patientBehavior.incident_details.incident_site ||
        !patientBehavior.special_additional_info.department_id ||
        !patientBehavior.special_additional_info.reported_incident_severity ||
        !patientBehavior.special_additional_info.incident_description
      ) {
        return false;
      }
      return true;
    };

    if (!validateInputs()) {
      // console.log("Form submission failed. Please correct the errors.");
      return;
    }
    if (editIncidentData.incident_id) {
      handleUpdate();
    } else {
      handlePost();
    }
  };
  const putdata = {

    "file_count": 0,
    "is_deleted": false,
    "spec": patientBehaviourStateString,
    "updated_by": loginUserId,
    "updated_timestamp": new Date().toISOString('en-US'),
    "files_info": ""

  }

  const [files, setFiles] = useState([]);

  const handleFileUpload = (event) => {
    const selectedFiles = event.target.files;
    if (selectedFiles) {
      setFiles([...files, ...selectedFiles]);
    }
  };
  const handlePost = () => {
    const formData = new FormData();
    var reqb = {
      is_deleted: false,
      spec: patientBehaviourStateString,
      created_by: loginUserId,
      updated_by: loginUserId
    }
    formData.append("IncidentInfo", JSON.stringify(reqb));


    files.forEach((file, index) => {
      formData.append(`files`, file);
    });


    axios.post(apis.base + apis.POST_INCIDENT_API, formData, {
      headers: {
        userid: loginUserId,
        Authorization: "Bearer " + baererToken,
        "Content-Type": "multipart/form-data",
      },
    })
      .then((response) => {
        // console.log("Response data:", response.data);
        // console.log("Form submitted successfully!");
        setShowSuccessPopup(true);
        setSnackbarMessage("Form submitted successfully!");
      })
      .catch((error) => {
        console.error("Error submitting form:", error);
      });
  };

  const handleUpdate = () => {
    axios.put(apis.base + apis.PUT_INCIDENT_BYID_API + editIncidentData.incident_id, putdata, {
      headers: {
        userid: loginUserId,
        Authorization: "Bearer " + baererToken
      }
    })
      .then((res) => {
        // console.log("putdata", res);
        // console.log("Form updated successfully!");
        // resetFormState();
        setShowSuccessPopup(true);
        setSnackbarMessage('Feedback updated successfully!');
      })
      .catch((error) => {
        console.error("Error updating form:", error);
      });
  }
  const [selectedFile, setSelectedFile] = useState(null);
  const [previewImage, setPreviewImage] = useState(null);

  const handleCloseSnackbar = () => {
    setShowSuccessPopup(false);
    navigateTo('/forms')
  };
 

  const [departmentData, setDepartmentData] = useState([]);
  useEffect(() => {
    axios.get(apis.base + apis.GET_ALL_DEPARTMENT_API, {
      headers: {
        userid: loginUserId,
        Authorization: "Bearer " + baererToken
      }
    })
      .then((response) => {
        setDepartmentData(response.data);
      })
      .catch((errorresp) => {
        console.log(JSON.stringify(errorresp));
      });
  }, []);

  const [locationData, setLocationData] = useState([])
  useEffect(() => {
    axios.get(apis.base + apis.GET_LOCATION_API, {
      headers: {
        userid: loginUserId,
        Authorization: "Bearer " + baererToken
      }
    })
      .then((response) => {
        setLocationData(response.data);
      })
      .catch((errorresp) => {
        console.log(JSON.stringify(errorresp));
      });
  }, []);


  const handleDeleteFile = (index) => {
    const updatedFiles = [...files];
    updatedFiles.splice(index, 1);
    setFiles(updatedFiles);
  };


  const generalIncidentTypeList = [
    { label: 'Patient Behavior' },
    // { label: 'AIRWAY MANAGEMENT' },
    // { label: 'BLOOD/BLOOD PRODUCT' },
    // { label: 'CODING COMPLICATION' },
    // { label: 'COMMUNICATION RE PATIENT CARE' },
  ]

  const reportedIncidentSeverityList = [
    { label: '1 - Minor' },
    { label: '2 - Moderate' },
    { label: '3 - Major' },
    { label: 'P3 - Potential Major' },
    { label: '4 - Catastrophic' },
    { label: 'P4-Potential Catastrophic' },
  ]
  const physicianDirectlyInvolvedOptions = [
    { label: 'yes' },
    { label: 'no' }
  ]
  const reactionofpatient = [
    { label: 'Allergic Reaction' },
    { label: 'Local Reaction' },
    { label: 'Systemic Reaction' },
    { label: 'Neurological Reaction' }
  ]

  const specificIncidentTypeList = [
    { label: 'Accidental puncture or laceration' },
    { label: 'Air embolism' },
    { label: 'Blood incompatibility' },
    { label: 'Catheter associated UTI' },
    { label: 'Central venous catheter related blood stream infection' },
    { label: 'Death among surgical inpatients' },
    { label: 'Falls and trauma' },
    { label: 'Foreign object retained after surgery' },
    { label: 'Iatrogenic pneumothorax' },
    { label: 'Iatrogenic pneumothorax with venous catheterization' },
  ]

  const departmentsList = [
    { label: 'Neurology' },
    { label: 'Radiology' },
    { label: 'Orthology' },
  ]

  const patientTypeList = [
    { label: 'In-Patient' },
    { label: 'Out-Patient' },
  ]

// First name , Last name , File Owner name (first letter should be capitalized )
const capitalizeFirstLetter = (string) => {
  return string.charAt(0).toUpperCase() + string.slice(1);
};
  return (
    <div>
      <div className="page-content">
      <div className="page-inner">
        <div className="page-title">
          <h3>Patient Behavior Reporting Form</h3>
          <div className="page-breadcrumb">
            <ol className="breadcrumb">
              <li><Link to="/Dashboard">Home</Link></li>
              <li><Link to="/forms">Forms</Link></li>
              <li className="active"> Patient Behavior</li>
            </ol>
          </div>
        </div>

        <div class="col-md-12 ">
          <div class="col-md-12">
            {/* General Incident Type */}
            <form onSubmit={handleSubmit}>
            <div id="main-wrapper">
                  <div class="row ">
                    <div >
                      <div class="panel panel-white">
                        <Accordion defaultExpanded>
                          <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel1-content"
                            id="panel1-header"
                          >
                            <h4 class="panel-title"  >General Incident Information</h4>
                          </AccordionSummary>
                          <AccordionDetails>
                            <div class="panel-body">
                              <div class="form-horizontal col-md-12"  >
                                <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                  <div class="form-group col-md-6"  >
                                    <label for="surgeryType"   >General Incident Type <span style={{ fontweight: "bold", color: "red" }} >*</span></label>
                                    <div>
                                      {action === "view" || action === "edit" ? (
                                        <Autocomplete
                                          disablePortal
                                          options={generalIncidentTypeList}
                                          getOptionLabel={(option) => option.label}
                                          disabled={action == "view"}
                                          onChange={(event, value) => handleStateChange("incident_type", value ? value.label : "")}
                                          renderInput={(params) => (
                                            <TextField
                                              {...params}
                                              placeholder="Please Select incident Type"
                                              fullWidth
                                            />
                                          )}
                                          value={generalIncidentTypeList.find(option => option.label === patientBehavior.general_incident_info.incident_type) || null}
                                        />) : (
                                        <Autocomplete
                                          disablePortal
                                          options={generalIncidentTypeList}
                                          getOptionLabel={(option) => option.label}
                                          onChange={(event, value) => handleStateChange("incident_type", value ? value.label : "")}
                                          renderInput={(params) => (
                                            <TextField
                                              {...params}
                                              placeholder="Please Select incident Type"
                                              fullWidth
                                            />
                                          )}
                                        />
                                      )}




                                      <div className="text-danger ">{generalincidenterror}</div>
                                    </div>

                                  </div>
                                  <div class="form-group col-md-6">
                                    <label for="surgeryType" >Patient Type <span style={{ fontweight: "bold", color: "red" }} >*</span></label>

                                    <div >
                                      {action === "view" || action === "edit" ? (
                                        <Autocomplete
                                          disablePortal
                                          options={patientTypeList}
                                          getOptionLabel={(option) => option.label}
                                          disabled={action == "view"}
                                          onChange={(event, value) => handleStateChange("patient_type", value ? value.label : "")}
                                          renderInput={(params) => (
                                            <TextField
                                              {...params}
                                              placeholder="Please Select patient Type"
                                              fullWidth
                                            />
                                          )}
                                          value={patientTypeList.find(option => option.label === patientBehavior.general_incident_info.patient_type) || null}
                                        />
                                      ) : (
                                        <Autocomplete
                                          disablePortal
                                          options={patientTypeList}
                                          getOptionLabel={(option) => option.label}
                                          onChange={(event, value) => handleStateChange("patient_type", value ? value.label : "")}
                                          renderInput={(params) => (
                                            <TextField
                                              {...params}
                                              placeholder="Please Select patient Type"
                                              fullWidth
                                            />
                                          )}
                                        />
                                      )}
                                      <div className="text-danger ">{patientTypeError}</div>
                                    </div>
                                  </div>
                                </div>

                                <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                  <div class="form-group col-md-6">
                                    <label for="surgeryType" >Injury Incurred <span style={{ fontweight: "bold", color: "red" }} >*</span></label>
                                    <div >
                                      <TextField type="text"
                                        fullWidth
                                        placeholder="Injury Incurred"
                                        disabled={action == "view"}
                                        value={patientBehavior.general_incident_info.injury_incurred}
                                        onChange={(e) => {
                                          var temp = JSON.parse(JSON.stringify(patientBehavior))
                                          temp.general_incident_info.injury_incurred = e.target.value;
                                          setPatientBehavior(temp)
                                        }}
                                      />
                                      <div className="text-danger">{injuryIncurredError}</div>
                                    </div>
                                  </div>
                                  <div class="form-group col-md-6">
                                    <label for="procedureDate" >Equipment Involved/ Malfunctioned <span style={{ fontweight: "bold", color: "red" }} >*</span></label>
                                    <div >
                                      <TextField type="text"
                                        fullWidth
                                        placeholder="Equipment Involved/ Malfunctioned"
                                        disabled={action == "view"}
                                        value={patientBehavior.general_incident_info.equipment_involved}
                                        onChange={(e) => {
                                          const updatedState = {
                                            ...patientBehavior,
                                            general_incident_info: {
                                              ...patientBehavior.general_incident_info,
                                              equipment_involved: e.target.value
                                            }
                                          };
                                          setPatientBehavior(updatedState);
                                        }}
                                      />
                                      <div className="text-danger">{equipmentMalfunctionedError}</div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </AccordionDetails>
                        </Accordion>
                      </div>
                    </div>
                  </div>
                </div>


                <div id="main-wrapper">
                  <div class="row">
                    <  div>
                      <div class="panel panel-white">
                        <Accordion >
                          <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel1-content"
                            id="panel1-header"
                          >
                            <h4 class="panel-title"  >Demographics</h4>
                          </AccordionSummary>
                          <AccordionDetails>
                            <div class="panel-body">
                              <div class="form-horizontal col-md-12"  >

                                <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                  <div class="form-group col-md-6"  >
                                    <label  >First Name  </label>
                                    <div>
                                      <TextField type="text" fullWidth
                                        placeholder="First Name"

                                        disabled={action == "view"}

                                        value={patientBehavior.demographics_info.first_name}
                                        onChange={(e) => handleStateChange("first_name", capitalizeFirstLetter(e.target.value))}
                                      />

                                      <div className="text-danger">{firstNameError}</div>
                                    </div>
                                  </div>

                                  <div class="form-group col-md-6">
                                    <label >Last Name<span style={{ fontweight: "bold", color: "red" }} >*</span></label>
                                    <div>
                                      <TextField type="text" fullWidth
                                        placeholder="Last Name"

                                        disabled={action == "view"}

                                        value={patientBehavior?.demographics_info?.last_name}
                                        onChange={(e) => handleStateChange("last_name", capitalizeFirstLetter(e.target.value))}
                                      />

                                      <div className="text-danger">{lastNameError}</div>
                                    </div>
                                  </div>
                                </div>


                                <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                  <div className="form-group col-md-6">
                                    <label htmlFor="exampleInputName2">Sex</label>
                                    <div>
                                      <label className="col-md-4" >
                                        <input type="radio" name="gender" disabled={action == "view"} checked={patientBehavior?.demographics_info?.gender === "male"} onChange={() => handleStateChange("gender", "male")} /> Male
                                      </label>
                                      <label className="col-md-4" >
                                        <input type="radio" name="gender" disabled={action == "view"} checked={patientBehavior?.demographics_info?.gender === "female"} onChange={() => handleStateChange("gender", "female")} /> Female
                                      </label>
                                      <label className="col-md-4" >
                                        <input type="radio" name="gender" disabled={action == "view"} checked={patientBehavior?.demographics_info?.gender === "others"} onChange={() => handleStateChange("gender", "others")} /> Others
                                      </label>
                                      <div className="text-danger col-md-6 ">{genderError}</div>
                                    </div>

                                  </div>

                                  < div class="form-group col-md-6 " style={{ display: "flex", flexDirection: "column" }}>
                                    <label htmlFor="exampleInputName">DOB
                                      {/* <span style={{ fontweight: "bold", color: "red" }} >*</span> */}
                                    </label>
                                    <DatePicker className='form-control'
                                      fullWidth
                                      disabled={action == "view"}
                                      selected={patientBehavior?.demographics_info?.dob}
                                      onChange={(date) => {
                                        setPatientBehavior({
                                          ...patientBehavior,
                                          demographics_info: {
                                            ...patientBehavior?.demographics_info,
                                            dob: date,
                                          },
                                        });
                                      }}
                                      dateFormat="MM/dd/yyyy"
                                      placeholderText="MM/DD/YYYY"
                                      maxDate={new Date()}
                                    />
                                    {/* <div className="text-danger">{dobError}</div> */}

                                  </div>
                                </div>


                                <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                  <div className=" form-group col-md-6" style={{ position: "relative" }}>
                                    <label >Address</label>
                                    {action === "view" ? (
                                      <div>
                                        <GooglePlacesAutocomplete
                                          apiKey="AIzaSyD9S5rAJ1RNWrG3fHjDKU8m2khTUxcr5u8"
                                          selectProps={{
                                            onChange: (e, a, b) => {
                                              handleStateChange("address", e.value)
                                              geocodeByPlaceId(e.value.place_id)
                                                .then(results => {
                                                  // console.log(results)
                                                  handleStateChange("addressValues", {
                                                    "zipcode": results[0].address_components.find(component => component.types.includes("postal_code")).long_name,
                                                    "country": results[0].address_components.find(component => component.types.includes("country")).long_name,
                                                    "state": results[0].address_components.find(component => component.types.includes("administrative_area_level_1")).long_name,
                                                    "city": results[0].address_components.find(component => component.types.includes("locality")).long_name,
                                                    "street": results[0].address_components.find(component => component.types.includes("route")).long_name,
                                                  })
                                                })
                                                .catch(error => console.error(error));
                                            }

                                          }}
                                        />
                                        <div style={{ position: "absolute", width: "95%", height: "37px", background: "black", opacity: "0.1", zIndex: 2, top: "24px" }}>
                                        </div>
                                      </div>
                                    ) : (
                                      <GooglePlacesAutocomplete
                                        apiKey="AIzaSyD9S5rAJ1RNWrG3fHjDKU8m2khTUxcr5u8"
                                        selectProps={{
                                          onChange: (e, a, b) => {
                                            handleStateChange("address", e.value)
                                            geocodeByPlaceId(e.value.place_id)
                                              .then(results => {
                                                // console.log(results)
                                                handleStateChange("addressValues", {
                                                  "zipcode": results[0].address_components.find(component => component.types.includes("postal_code")).long_name,
                                                  "country": results[0].address_components.find(component => component.types.includes("country")).long_name,
                                                  "state": results[0].address_components.find(component => component.types.includes("administrative_area_level_1")).long_name,
                                                  "city": results[0].address_components.find(component => component.types.includes("locality")).long_name,
                                                  "street": results[0].address_components.find(component => component.types.includes("route")).long_name,
                                                })
                                              })
                                              .catch(error => console.error(error));
                                          }

                                        }}
                                      />
                                    )}
                                  </div>

                                  <div class="form-group col-md-6">
                                    <label >Street</label>
                                    <div>
                                      <TextField type="text" fullWidth placeholder="Street"
                                        value={patientBehavior?.demographics_info?.street}
                                        onChange={(e) => handleStateChange("street", e.target.value)}
                                        disabled={action == "view"}
                                      />
                                      <div className="text-danger">{streetError}</div>
                                    </div>
                                  </div>

                                </div>


                                <div style={{ display: 'flex', gap:'40px',width:'100%' }}>
                                  <div class="form-group col-md-6">
                                    <label for="exampleInputName">City</label>
                                    <TextField type="text" fullWidth placeholder="City"
                                      value={patientBehavior?.demographics_info?.city}
                                      onChange={(e) => handleStateChange("city", e.target.value)}
                                      disabled={action == "view"}
                                    />
                                    <div className="text-danger">{cityError}</div>
                                  </div>


                                  <div class="form-group  col-md-6">
                                    <label for="exampleInputName2">State/Region</label>
                                    <TextField type="text" fullWidth placeholder="State/Region"
                                      value={patientBehavior?.demographics_info?.state}
                                      onChange={(e) => handleStateChange("state", e.target.value)}
                                      disabled={action == "view"}
                                    />
                                    <div className="text-danger">{stateError}</div>
                                  </div>
                                  <div class="form-group  col-md-6">
                                    <label for="exampleInputName2">Zip code</label>
                                    <TextField
                                      placeholder='Zip Code'
                                      fullWidth
                                      type="text"
                                      disabled={action == "view"}
                                      value={patientBehavior?.demographics_info?.zipcode}
                                      onChange={(event) => {
                                        const value = event.target.value;
                                        if (/^\d{0,5}$/.test(value)) {
                                          setInputValue(value);
                                          handleStateChange("zipcode", value);
                                        }
                                      }}
                                      inputProps={{
                                        pattern: '[0-9]*',
                                      }}
                                    />
                                  </div>
                                </div>


                                <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                  {/* <div class="form-group col-md-6">
                                    <label for="exampleInputName">Country</label>
                                    <TextField type="text" fullWidth placeholder="Country"
                                      value={patientBehavior?.demographics_info?.country}
                                      onChange={(e) => handleStateChange("country", e.target.value)}
                                      disabled={action == "view"}

                                    />
                                    <div className="text-danger">{countryError}</div>
                                  </div> */}
                                  {/* <div class="form-group  col-md-6">
                                    <label for="exampleInputName2">Zip code</label>
                                    <TextField
                                      placeholder='Zip Code'
                                      fullWidth
                                      type="text"
                                      disabled={action == "view"}
                                      value={patientBehavior?.demographics_info?.zipcode}
                                      onChange={(event) => {
                                        const value = event.target.value;
                                        if (/^\d{0,5}$/.test(value)) {
                                          setInputValue(value);
                                          handleStateChange("zipcode", value);
                                        }
                                      }}
                                      inputProps={{
                                        pattern: '[0-9]*',
                                      }}
                                    />
                                  </div> */}
                                </div>
                              </div>
                            </div>
                          </AccordionDetails>
                        </Accordion>
                      </div>
                    </div>
                  </div>
                </div>


                <div id="main-wrapper">
                  <div class="row">
                    <div>
                      <div class="panel panel-white">
                        <Accordion>
                          <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel1-content"
                            id="panel1-header"
                          >
                            <h4 class="panel-title"  >Incident  Details</h4>
                          </AccordionSummary>
                          <AccordionDetails>
                            <div class="panel-body">
                              <div class="form-horizontal col-md-12">
                                <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                  <div class="form-group col-md-6" style={{ display: "flex", flexDirection: "column" }}>
                                    <label htmlFor="surgeryType"  >Incident Date <span style={{ fontweight: "bold", color: "red" }} >*</span></label>

                                    <DatePicker className='form-control'
                                      fullWidth
                                      disabled={action == "view"}
                                      selected={patientBehavior?.incident_details?.incident_date}
                                      onChange={(date) => {
                                        setPatientBehavior({
                                          ...patientBehavior,
                                          incident_details: {
                                            ...patientBehavior?.incident_details,
                                            incident_date: date,
                                          },
                                        });
                                      }}
                                      dateFormat="MM/dd/yyyy"
                                      placeholderText="MM/DD/YYYY"
                                      maxDate={new Date()}
                                    />


                                    <div className="text-danger">{incidentdateError}</div>
                                  </div>
                                  <div class="form-group col-md-6">
                                    <label for="surgeryType" > Incident Time</label>
                                    <div>
                                      <Box sx={{ minWidth: 120 }}>
                                        <FormControl fullWidth>
                                          <TextField
                                            disabled={action == "view"}
                                            type="time"
                                            defaultValue={getCurrentTime()}
                                            onChange={(e) =>
                                              handleStateChange(
                                                "incident_time",
                                                e.target.value
                                              )
                                            }
                                            InputLabelProps={{
                                              shrink: true,
                                            }}
                                            InputProps={{
                                              inputProps: {
                                                max: getCurrentTime(),
                                              },
                                            }}
                                          />
                                        </FormControl>
                                      </Box>
                                    </div>
                                  </div>
                                </div>

                                <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                  <div class="form-group col-md-6">
                                    <label for="procedureDate"  >State Where Incident Occurred at<span style={{ fontweight: "bold", color: "red" }} >*</span></label>
                                    <div>
                                      {action === "view" || action === "edit" ? (
                                        <Autocomplete
                                          options={locationData}
                                          getOptionLabel={(location) => location?.state}

                                          disabled={action == "view"}

                                          onChange={(e, value) => {
                                            handleStateChange("incident_state", value ? value.state : 0);
                                          }}
                                          renderInput={(params) => (
                                            <TextField
                                              {...params}
                                              placeholder="Please Select State Where Incident Occured"
                                              variant="outlined" />
                                          )}
                                          // defaultValue={{ label: patientBehavior.incident_details.incident_state }}
                                          value={locationData.filter(location => {
                                            return location.location_state == patientBehavior.incident_details.location_state
                                          })[0] || null}
                                          defaultValue={
                                            locationData.filter(location => {
                                              return location.location_state == patientBehavior.incident_details.location_state
                                            })[0] || {}
                                          }
                                        />) : (
                                        <Autocomplete
                                          options={locationData}
                                          getOptionLabel={(location) => location?.state}
                                          onChange={(e, value) => {
                                            handleStateChange("incident_state", value ? value.state : 0);
                                          }}
                                          renderInput={(params) => (
                                            <TextField
                                              {...params}
                                              placeholder="Please Select State Where Incident Occured"
                                              variant="outlined" />

                                          )}
                                        />
                                      )}
                                      <div className="text-danger">{stateincidentoccuredError}</div>
                                    </div>
                                  </div>

                                  <div class="form-group col-md-6">
                                    <label for="procedureDate"  >Site Where Incident Occured 
                                      {/* <span style={{ fontweight: "bold", color: "red" }} >*</span> */}
                                      </label>
                                    <div>
                                      {action === "view" || action === "edit" ? (
                                        <Autocomplete
                                          options={locationData}
                                          getOptionLabel={(location) => location?.location_name}
                                          disabled={action == "view"}
                                          onChange={(e, value) => {
                                            handleStateChange("incident_site", value ? value.location_name : 0);
                                          }}
                                          renderInput={(params) => (
                                            <TextField
                                              {...params}
                                              placeholder="Please Select Site Where Incident Occured"
                                              variant="outlined" />
                                          )}

                                          value={locationData.filter(locationsite => {
                                            return locationsite.location?.location_name == patientBehavior.incident_details.location?.location_name
                                          })[0] || null}
                                          defaultValue={
                                            locationData.filter(locationsite => {
                                              return locationsite.location?.location_name == patientBehavior.incident_details.location?.location_name
                                            })[0] || {}
                                          }
                                        />) : (
                                        <Autocomplete
                                          options={locationData}
                                          getOptionLabel={(location) => location?.location_name}
                                          onChange={(e, value) => {
                                            handleStateChange("incident_site", value ? value.location_id : 0);
                                          }}
                                          renderInput={(params) => (
                                            <TextField
                                              {...params}
                                              placeholder="Please Select Site Where Incident Occured"
                                              variant="outlined" x />
                                          )}
                                        />
                                      )}

                                      <div className="text-danger">{siteincidentoccuredError}</div>
                                    </div>
                                  </div>
                                </div>

                                <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                  <div class="form-group col-md-6">
                                    <label for="procedureDate"  >File Owner </label>
                                    <div>
                                      <TextField type="text" placeholder='File Owner' fullWidth
                                        value={patientBehavior?.incident_details?.file_owner}
                                        onChange={(e) => handleStateChange("file_owner",capitalizeFirstLetter (e.target.value))}
                                        disabled={action == "view"}
                                      />
                                    </div>
                                  </div>

                                  <div class="form-group col-md-6">
                                    <label for="procedureDate"  >Entered By</label>
                                    <div>
                                      <TextField type="text" placeholder='Entered By' fullWidth
                                        value={patientBehavior?.incident_details?.entered_by}
                                        onChange={(e) => handleStateChange("entered_by", e.target.value)}
                                        disabled={action == "view"}
                                      />
                                    </div>
                                  </div>
                                </div>


                                <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                  <div class="form-group col-md-6" style={{ display: "flex", flexDirection: "column" }}>
                                    <label htmlFor="procedureDate"  >Entered Date</label>

                                    <DatePicker className='form-control'
                                      fullWidth
                                      disabled={action == "view"}
                                      selected={patientBehavior?.incident_details?.entered_date}
                                      onChange={(date) => {
                                        setPatientBehavior({
                                          ...patientBehavior,
                                          incident_details: {
                                            ...patientBehavior.incident_details,
                                            entered_date: date,
                                          },
                                        });
                                      }}
                                      dateFormat="MM/dd/yyyy"
                                      placeholderText="MM/DD/YYYY"
                                      maxDate={new Date()}
                                    />

                                  </div>


                                  <div class="form-group col-md-6">
                                    <label for="procedureDate">
                                      Entered Time (Estern Time Zone)
                                    </label>
                                    <div>
                                      <Box sx={{ minWidth: 120 }}>
                                        <FormControl fullWidth>
                                          <TextField
                                            disabled={action == "view"}
                                            type="time"
                                            defaultValue={getCurrentTime()}
                                            onChange={(e) =>
                                              handleStateChange(
                                                "entered_time",
                                                e.target.value
                                              )
                                            }
                                            InputLabelProps={{
                                              shrink: true,
                                            }}
                                            InputProps={{
                                              inputProps: {
                                                max: getCurrentTime(),
                                              },
                                            }}
                                          />
                                        </FormControl>
                                      </Box>
                                    </div>
                                  </div>
                                </div>

                                <div style={{ display: 'flex', justifyContent: "space-between" }}>
                                  <div class="form-group col-md-6">
                                    <label for="procedureDate"  >Witness Name</label>
                                    <div>
                                      <TextField type="text" placeholder='Witness Name' fullWidth
                                        value={patientBehavior?.incident_details?.witness_name}
                                        onChange={(e) => handleStateChange("witness_name", e.target.value)}
                                        disabled={action == "view"}
                                      />
                                    </div>
                                  </div>


                                  <div class="form-group col-md-6">
                                    <label for="procedureDate"  >Witness Phone</label>
                                    <div>

                                      <TextField
                                        placeholder='Witness Phone'
                                        fullWidth
                                        disabled={action == "view"}
                                        type="text"
                                        value={patientBehavior?.incident_details?.witness_phone}
                                        onChange={(event) => {
                                          const value = event.target.value;
                                          if (/^\d{0,10}$/.test(value)) {
                                            setPhoneNumber(value);
                                            handleStateChange("witness_phone", value);
                                          }
                                        }}
                                        inputProps={{
                                          pattern: '[0-9]*',
                                        }}
                                      />

                                    </div>
                                  </div>
                                </div>

                                <div class="form-group">
                                  <label for="procedureDate" style={{ marginLeft: '15px' }}>Witness Address </label>
                                  <div
                                    class="col-md-12"
                                  >
                                    <TextField type="text" placeholder='Witness Address' fullWidth
                                      value={patientBehavior?.incident_details?.witness_address}
                                      onChange={(e) => handleStateChange("witness_address", e.target.value)}
                                      disabled={action == "view"}
                                    />
                                  </div>
                                </div>
                              </div>
                            </div>
                          </AccordionDetails>
                        </Accordion>
                      </div>
                    </div>
                  </div>
                </div>

              <div id="main-wrapper">
                <div class="row">
                  <div>
                    <div class="panel panel-white">
                      <Accordion>
                        <AccordionSummary
                          expandIcon={<ExpandMoreIcon />}
                          aria-controls="panel1-content"
                          id="panel1-header"
                        >
                          <h4 class="panel-title">Special Additional Information</h4>
                        </AccordionSummary>

                        <AccordionDetails>
                          <div class="panel-body">
                            <div class="form-horizontal col-md-12">

                              {/* Surgery Details */}
                              <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                <div class="form-group col-md-6">

                                  <label for="exampleInputPassword1"   >Department<span style={{ fontweight: "bold", color: "red" }} >*</span></label>
                                  <div >
                                  {action === "view" || action === "edit"  ?(
                                      departmentData && departmentData.length > 0 ? (

                                      <Autocomplete
                                        options={departmentData}
                                        getOptionLabel={(department) => department.department_name}
                                        disabled={action === "view"}
                                        onChange={(e, value) => {
                                          handleStateChange("department_id", value ? value.department_id : 0);
                                        }}
                                        renderInput={(params) => (
                                          <TextField
                                            {...params}
                                            placeholder="Please Select department"
                                            variant="outlined"
                                          />
                                        )}
                                        value={departmentData.filter(department => {
                                          return department.department_id == patientBehavior.special_additional_info.department_id
                                        })[0] || null}
                                        defaultValue={
                                          departmentData.filter(department => {
                                            return department.department_id == patientBehavior.special_additional_info.department_id
                                          })[0] || {}
                                        }
                                      />
                                    ) : (
                                      // Handle case when departmentData is undefined or empty
                                      <p>No department data available</p>
                                    )
                                    ) : (
                                      <Autocomplete
                                        options={departmentData}
                                        getOptionLabel={(department) => department.department_name}
                                        disabled={action === "view"}
                                        onChange={(e, value) => {
                                          handleStateChange("department_id", value ? value.department_id : 0);
                                        }}
                                        renderInput={(params) => (
                                          <TextField
                                            {...params}
                                            placeholder="Please Select department"
                                            variant="outlined"
                                          />
                                        )}
                                      />
                                    )}

                                  </div>
                                  <div className="text-danger">{deptincidentoccuredError}</div>
                                </div>

                                <div class="form-group col-md-6">
                                  <label for="surgeryType" >Main Behavioral Concern</label>
                                  <div>
                                    <TextField
                                      type="text" disabled={action == "view"}
                                      fullWidth
                                      placeholder="Enter Main Behavioral Concern"
                                      value={patientBehavior?.special_additional_info?.main_behavioral_concern}

                                      onChange={(e) => handleStateChange("main_behavioral_concern", e.target.value)}


                                    />
                                  </div>
                                </div>
                              </div>

                              <div style={{ display: 'flex', justifyContent: 'space-between' }}>

                                <div class="form-group col-md-6">
                                  <label for="procedureDate" >Duration of the Issue</label>
                                  <div>
                                    <TextField disabled={action == "view"}
                                      type="text"
                                      fullWidth
                                      placeholder="Enter Duration of the Issue"
                                      value={patientBehavior?.special_additional_info?.issue_duration}

                                      onChange={(e) => handleStateChange("issue_duration", e.target.value)}
                                    />
                                  </div>
                                </div>

                                <div class="form-group col-md-6">
                                  <label for="procedureDate" >Triggers or Patterns</label>
                                  <div>
                                    <TextField type="text" disabled={action == "view"} fullWidth placeholder="Identify any known triggers or patterns associated with the behavior"
                                      value={patientBehavior?.special_additional_info?.triggers_or_patterns}

                                      onChange={(e) => handleStateChange("triggers_or_patterns", e.target.value)}
                                    />
                                  </div>
                                </div>
                              </div>

                              <div style={{ display: 'flex', justifyContent: 'space-between' }}>


                                <div class="form-group col-md-6">
                                  <label for="procedureDate" >Current Medications</label>
                                  <div>
                                    <TextField type="text" disabled={action == "view"} fullWidth placeholder='Enter Current Medications'
                                      value={patientBehavior?.special_additional_info?.current_medications}
                                      onChange={(e) => handleStateChange("current_medications", e.target.value)}
                                    />
                                  </div>
                                </div>

                                <div class="form-group col-md-6">
                                  <label for="procedureDate" >Past Medications</label>
                                  <div>
                                    <TextField type="text" fullWidth disabled={action == "view"}
                                      placeholder="Chronic illnesses or conditions/Previous psychiatric diagnoses or treatments"
                                      value={patientBehavior?.special_additional_info?.past_medications}

                                      onChange={(e) => handleStateChange("past_medications", e.target.value)}
                                    />
                                  </div>
                                </div>
                              </div>

                              <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                <div class="form-group col-md-6" >
                                  <label for="exampleInputPassword1" >Reaction for Patient</label>
                                  <div>
                                  {action === "view" || action === "edit"  ?(
                                    <Autocomplete
                                      disablePortal
                                      disabled={action == "view"}
                                      options={reactionofpatient}
                                      renderInput={(params) => (<TextField placeholder="Select reported incident severity"
                                        onChange={(e) => handleStateChange("patient_reaction", e.target.value)}
                                        fullWidth {...params} />)}
                                        value={reactionofpatient.find(option => option.label === patientBehavior.special_additional_info.patient_reaction) || null}
                                    />):( <Autocomplete
                                      disablePortal
                                      options={reactionofpatient}
                                      renderInput={(params) => (<TextField placeholder="Select reported incident severity"
                                        onChange={(e) => handleStateChange("patient_reaction", e.target.value)}
                                        fullWidth {...params} />)}

                                    />)}
                                  </div>
                                </div>
                                <div class="form-group col-md-6">
                                  <label for="medicalHistory" >Family History</label>
                                  <div>
                                    <TextField disabled={action == "view"}
                                      type="text"
                                      fullWidth
                                      placeholder="Any history of psychiatric or behavioral issues among family members"
                                      value={patientBehavior?.special_additional_info?.family_history}

                                      onChange={(e) => handleStateChange("family_history", e.target.value)}
                                    />
                                  </div>
                                </div>
                              </div>
                              <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                <div class="form-group col-md-6">
                                  <label for="allergies" >Social Support</label>
                                  <div>
                                    <TextField type="text" disabled={action == "view"} fullWidth placeholder="Identify key relationships and support systems"
                                      value={patientBehavior?.special_additional_info?.social_support}
                                      onChange={(e) => handleStateChange("social_support", e.target.value)}
                                    />
                                  </div>
                                </div>
                                <div class="form-group col-md-6">
                                  <label for="medications" >Previous Interventions</label>
                                  <div>
                                    <TextField type="text" disabled={action == "view"} fullWidth placeholder="Detail any previous treatments or interventions and their outcomes"
                                      value={patientBehavior?.special_additional_info?.previous_interventions}
                                      onChange={(e) => handleStateChange("previous_interventions", e.target.value)}
                                    />
                                  </div>
                                </div>
                              </div>
                              <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                <div class="form-group col-md-6">
                                  <label for="medications" >Other Information</label>
                                  <div>
                                    <TextField type="text" disabled={action == "view"} fullWidth placeholder="Other Information"
                                      value={patientBehavior?.special_additional_info?.other_information}
                                      onChange={(e) => handleStateChange("other_information", e.target.value)}
                                    />
                                  </div>
                                </div>

                                <div class="form-group col-md-6">
                                  <label for="procedureDate"  >Was a physician directly involved in Incident</label>
                                  <div style={{ marginTop: '10px' }}>

                                    <label className="col-md-3">
                                      <input type="radio" disabled={action == "view"} name="physician_involved" checked={patientBehavior.special_additional_info.physician_involved === true} onChange={() => handleStateChange("physician_involved", true)} /> Yes
                                    </label>
                                    <label className="col-md-3">
                                      <input type="radio" disabled={action == "view"} name="physician_involved" checked={patientBehavior.special_additional_info.physician_involved === false} onChange={() => handleStateChange("physician_involved", false)} /> No
                                    </label>
                                  </div>
                                </div>
                              </div>

                              <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                <div class="form-group col-md-6">
                                  <label for="procedureDate" >Contributing Factors</label>
                                  <div>
                                    <TextField type="text" disabled={action == "view"} fullWidth placeholder="Enter Contributing Factors"
                                      value={patientBehavior?.special_additional_info?.contributing_factors}
                                      onChange={(e) => handleStateChange("contributing_factors", e.target.value)}
                                    />
                                  </div>
                                </div>
                                <div class="form-group col-md-6">
                                  <label for="procedureDate" >Immediate Actions Taken</label>
                                  <div>
                                    <TextField type="text" disabled={action == "view"} fullWidth placeholder="Enter Immediate Actions"
                                      value={patientBehavior?.special_additional_info?.immediate_actions_taken}
                                      onChange={(e) => handleStateChange("immediate_actions_taken", e.target.value)}
                                    />
                                    <div className="text-danger">{immediateActionError}</div>
                                  </div>
                                </div>
                              </div>

                              <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                <div class="form-group col-md-6" >
                                  <label for="exampleInputPassword1" >Reported Incident Severity <span style={{ fontweight: "bold", color: "red" }} >*</span></label>
                                  <div >
                                  {action === "view" || action === "edit"  ?(
                                    <Autocomplete
                                      disablePortal
                                      disabled={action == "view"}
                                      options={reportedIncidentSeverityList}
                                      getOptionLabel={(option) => option.label}
                                      onChange={(event, value) => handleStateChange("reported_incident_severity", value ? value.label : "")}
                                      renderInput={(params) => (
                                        <TextField
                                          {...params}
                                          placeholder="Please Select reported incident severity"
                                          fullWidth
                                        />
                                      )}
                                      value={reportedIncidentSeverityList.find(option => option.label === patientBehavior.special_additional_info.reported_incident_severity) || null}

                                    />):( <Autocomplete
                                      disablePortal
                                      options={reportedIncidentSeverityList}
                                      getOptionLabel={(option) => option.label}
                                      onChange={(event, value) => handleStateChange("reported_incident_severity", value ? value.label : "")}
                                      renderInput={(params) => (
                                        <TextField
                                          {...params}
                                          placeholder="Please Select reported incident severity"
                                          fullWidth
                                        />
                                      )}

                                    />)}
                                  </div>
                                  <div className="text-danger">{reportedIncidentError}</div>
                                </div>




                                <div class="form-group col-md-6">
                                  <label for="procedureDate" >Incident Description - use SBAR (Situation,Background, Assessment,
                                    Recommendation )<span style={{ fontweight: "bold", color: "red" }} >*</span></label>
                                  <div >
                                    <TextField type="text" fullWidth placeholder="Enter Incident Description..."
                                      disabled={action == "view"}
                                      value={patientBehavior?.special_additional_info?.incident_description}
                                      onChange={(e) => handleStateChange("incident_description", e.target.value)}

                                    />
                                    <div className="text-danger">{incidentdescriptionError}</div>
                                  </div>
                                </div>
                              </div>

                              <div className="form-group col-md-6">
                                <label htmlFor="procedureDate" style={{ textAlign: "start" }}>File Upload</label>
                                <div>
                                  <input disabled={action == "view"} type="file" style={{ marginTop: "10px", color: 'transparent' }} multiple onChange={handleFileUpload} />
                                  {files.length > 0 ? (
                                    <ol>
                                      {files.map((file, index) => (
                                        <li disabled={action === "view"} key={index}>
                                          {file.name}
                                          <IconButton disabled={action == "view"} onClick={() => handleDeleteFile(index)} aria-label="delete">
                                            <DeleteIcon />
                                          </IconButton>
                                        </li>
                                      ))}
                                    </ol>
                                  ) : (
                                    <p>No files chosen</p>
                                  )}

                                </div>
                              </div>


                            </div>
                          </div>
                        </AccordionDetails>
                      </Accordion>
                    </div>
                  </div>
                </div>

              </div>
              <div style={{ marginLeft: "45%" }}>
              {canSubmit && (
                <Button
                disabled={action === "view"}
                  variant="contained"
                  type="submit"
                  style={{ height: "35px", width: "auto", fontSize: "15px", background: "#22baa0" }}
                  onClick={handleSubmit}
                >
                  {editIncidentData.incident_id ? "Update" : "Submit"}
                </Button>
              )}
              </div>

            </form>
            <Snackbar
              open={showSuccessPopup}
              autoHideDuration={6000}
              onClose={handleCloseSnackbar}
              anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
            >
              <MuiAlert onClose={handleCloseSnackbar}
                severity="success"
                variant="filled"
                sx={{ width: '100%' }}
                style={{ width: '300px', backgroundColor: "whitesmoke", color: "green", height: '45px', fontSize: '12px' }}>
                {snackbarMessage}
              </MuiAlert>
            </Snackbar>
          </div>
        </div>
      </div >
    </div >
    </div>
  );
}
export default PatientBehaviorForm;