import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import BarGraph1 from "../../Components/charts/bargraph";
import PieCharts from "../../Components/charts/year";
import Linegraph from "../../Components/charts/Linegraph";
import DoughnutChart from "../../Components/charts/Doughnutchart";
import DashboardDoughnutChart from "../../Components/charts/DashboardDoughnutchart";
import axios from "axios";
import { apis } from "../../configuration/configurationAPI";
import BarGraphDashboard from "../../Components/charts/BargraphDashboard";
import { commonActions } from "../../utils/commonstore";
import store from "../../utils/index";
import { useSelector } from 'react-redux';
import { commonSelectors } from '../../utils/commonstore';
import { set } from "date-fns";
import { DataGrid, GridToolbarQuickFilter } from '@mui/x-data-grid';
import Footer from "../../Components/footer/footer";



const dispatch = store.dispatch;
let commonStore = store.getState().commonStore;
store.subscribe(function () {
    commonStore = store.getState().commonStore;
});
const DashboardScreen = () => {

    const [error, setError] = useState(null);
    const [loading, setLoading] = useState(true);
    const [rows, setRows] = useState([]);
    const [filterModel, setFilterModel] = useState({ items: [] });


    const [fixedHeader, setFixedHeader] = useState(false);
    const [fixedSidebar, setFixedSidebar] = useState(false);
    const [horizontalBar, setHorizontalBar] = useState(false);
    const [toggleSidebar, setToggleSidebar] = useState(false);
    const [boxedLayout, setBoxedLayout] = useState(false);
    const [userData, setUserData] = useState([]);
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [bearerToken, setBearerToken] = useState("");
    const [data, setData] = useState([]);
    const loginUserId = useSelector(commonSelectors.selectLoginUserId);



    const profileId = useSelector(commonSelectors.selectUserProfileId);
    const permissionsetId = useSelector(commonSelectors.selectUserPermissionsetId);
    const accessInfo = useSelector(commonSelectors.selectAccessInfo);
    
    
    const [hasViewAccess, setHasViewAccess] = useState(false);

    // const convertPermissionsToActions = (binaryString) => {
    //     const permissionValue = parseInt(binaryString, 2); //binary string to decimal
    //     console.log(`Binary String: ${binaryString}`);
    //     console.log(`4-digit Decimal: ${permissionValue}`);
        
    //     return {
    //         view: (permissionValue & 8) === 8,
    //         create: (permissionValue & 4) === 4,
    //         update: (permissionValue & 2) === 2,
    //         delete: (permissionValue & 1) === 1,
    //     };
    // };
   
   
    // useEffect(() => {
    //     if (accessInfo) {
            
    //         const profileDepartments = accessInfo?.departments || '0000';
    //         const permissionSetDepartments = accessInfo?.permission_list || '0000';

    //         // CONSOLE original data 
    //         console.log("Departments from Profile (raw):", profileDepartments);
    //         console.log("Departments from Permission Set (raw):", permissionSetDepartments);

    //         // Convert binary strings to permission objects
    //         const profilePermissions = convertPermissionsToActions(profileDepartments);
    //         const permissionSetPermissions = convertPermissionsToActions(permissionSetDepartments);

           
    //         const combinedPermissions = {
    //             view: profilePermissions.view || permissionSetPermissions.view,
    //             create: profilePermissions.create || permissionSetPermissions.create,
    //             update: profilePermissions.update || permissionSetPermissions.update,
    //             delete: profilePermissions.delete || permissionSetPermissions.delete,
    //         };

    //         const { view } = combinedPermissions;
    //         console.log(`Permissions:`, combinedPermissions);

    //         // Set the view access state based on combined permissions
    //         setHasViewAccess(view);
    //         console.log(`Has View Access: ${view}`);
    //     }
    // }, [accessInfo]);

   
   
   
   
   
   
    const userInfo = useSelector(commonSelectors.selectUserInfo);
    const baererToken = useSelector(commonSelectors.selectBaererToken);
    

    const [day, setDay] = useState([]);
    const [month, setMonth] = useState([]);
    const [fall, setFall] = useState([]);
    const [medicalDeviceReporting, setMedicalDeviceReporting] = useState([]);
    const [totalCount, setTotalCount] = useState(0);
    const [percentage, setPercentage] = useState(0);

    const siderbar = () => {
        setFixedSidebar(true)
    }
    React.useEffect(() => {
        axios.get(apis.base + apis.GET_INCIDENTS_ALL_API, {
            headers: {
                userid: loginUserId,
                Authorization: "Bearer " + baererToken
            }
        }).then((response) => {
            const incidentWithIds = response.data.map((incident, index) => ({
                ...incident,
                id: index + 1, // Assuming you want to add an 'id' field to each incident
                incident_type: incident.spec.general_incident_info.incident_type || "", // Adjust property access based on your data structure
                last_name: incident.spec.demographics_info.last_name || "", // Adjust property access based on your data structure
                first_name: incident.spec.demographics_info.first_name || "",
                incident_date: incident.spec.incident_details.incident_date || "", // Adjust property access based on your data structure
                reported_incident_severity: incident.spec.special_additional_info.reported_incident_severity || "", // Adjust property access based on your data structure
            }));
            setRows(incidentWithIds); 
            fetchData();
            dayData();
            fallData();
            medicalDevicereportingData();
            setLoading(false); 
        }).catch((error) => {
            setError(error.message); 
            setLoading(false);
        });
    }, []);
        const fetchData = () => {
        const currentDate = new Date();
        const myyear = currentDate.getFullYear();
        const mymonth = currentDate.getMonth() + 1;
        axios.get(apis.base + apis.GET_INCIDENT_YEAR_MONTH_COUNT + `?years=${myyear}&months=${mymonth}`, {
            headers: {
                userid: loginUserId,
                Authorization: "Bearer " + baererToken
            },
        })
            .then(response => {            
                setMonth(response.data);
            })
            .catch(error => {
                console.error("Error fetching data:", error);
            });
    };
    const dayData = () => {
        const currentDate = new Date();
        const myyear = currentDate.getFullYear();
        const mymonth = currentDate.getMonth() + 1;
        const myday = currentDate.getDate();
        axios.get(apis.base + apis.GET_INCIDENT_YEAR_MONTH_DAY_COUNT + `?years=${myyear}&months=${mymonth}&days=${myday}`, {
            headers: {
                userid: loginUserId,
                Authorization: "Bearer " + baererToken
            },
        })
            .then(response => {
                setDay(response.data);
                const dayData = response.data;
                setTotalCount(dayData.totalCount);

                // Calculate the percentage completion
                const someMaxCount = 100; // Example maximum count, replace with your actual maximum count
                const calculatedPercentage = (dayData.totalCount / someMaxCount) * 100;
                setPercentage(calculatedPercentage);
            })

            .catch(error => {
                console.error("Error fetching data:", error);
            });
    };
    const fallData = () => {
        axios.get(apis.base + apis.GET_INCIDENT_BY_INCIDENTTYPECOUNT + `incidentTypes=fall`, {
            headers: {
                userid: loginUserId,
                Authorization: "Bearer " + baererToken
            },
        })
            .then(response => {
                setFall(response.data);
            })
            .catch(error => {
                console.error("Error fetching data:", error);
            });
    };
    const medicalDevicereportingData = () => {
        axios.get(apis.base + apis.GET_INCIDENT_BY_INCIDENTTYPECOUNT + `incidentTypes=Medical Device Reporting`, {
            headers: {
                userid: loginUserId,
                Authorization: "Bearer " + baererToken
            },
        })
            .then(response => {
                setMedicalDeviceReporting(response.data);
            })
            .catch(error => {
                console.error("Error fetching data:", error);
            });
    };

    const sortedData = [...rows].sort((a, b) => b.incident_id - a.incident_id);

    const columns = [
        { field: 'incident_id', headerName: <strong> Incident Id</strong>, width: 150 ,
        renderCell: (params) => (
            <Link to={`/workflowactions/${params.row.incident_id}`}>{params.row.incident_id}</Link>
          ) },
        {
            field: 'last_name',
            headerName: <strong>Patient Name</strong>,
            width: 150,
            valueGetter: (params) => {
                const lastName = params.row.last_name || '';
                const firstName = params.row.first_name || '';
                return `${lastName}${firstName ? ' ' + firstName : ''}`;
            }
        },
        // {
        //     field: ' incident_date',
        //     headerName: <strong>Incident Date</strong>,
        //     width: 200,
        //     valueGetter: (params) => new Date(params.row.updated_timestamp).toLocaleString('en-US')
        // },
        {
            field: 'created_timestamp',
            headerName: <strong>Incident Date</strong>,
            width: 200,
            valueGetter: (params) => new Date(params.row.created_timestamp).toLocaleString('en-US')
        },
       
        { field: 'reported_incident_severity', headerName: <strong>Severity</strong>, width: 200 },
        { field: 'department_name', headerName:<strong>Department</strong>, width: 200 },
        { field: 'incident_type', headerName:<strong>Incident Type</strong>, width: 250 },
    ];
   
   

 return (
    <div>
        {accessInfo?.dashboard === '0' ? (
            <div className="page-content">
                    <div className="page-inner">
            <h4 style={{textAlign:"center",color:"gray",height:"100vh"}}>You do not have access to this screen.</h4>
            </div>
            </div>
        ) : (
            <div>
                <div className="page-content">
                    <div className="page-inner">
                        <div className="page-title">
                            <h3>Dashboard</h3>
                            <div className="page-breadcrumb">
                                <ol className="breadcrumb">
                                    <li><Link to="/Dashboard">Home</Link></li>
                                    <li className="active">Dashboard</li>
                                </ol>
                            </div>
                        </div>
                        <div id="main-wrapper">
                            <div className="row">
                                <div className="col-lg-3 col-md-6">
                                    <div className="panel info-box panel-white">
                                        <div className="panel-body">
                                            <Link to="/fallevent">
                                                <div className="info-box-stats">
                                                    <p className="counter">{fall}</p>
                                                    <span className="info-box-title">Fall</span>
                                                </div>
                                                <div className="info-box-icon">
                                                    <img src="assets\images\Icons\GeneralLiability.jpg" height={"50px"} />
                                                </div>
                                                <div className="info-box-progress">
                                                    <div className="progress progress-xs progress-squared bs-n">
                                                        <div className="progress-bar progress-bar-success" role="progressbar" aria-valuenow="50" aria-valuemin="0" aria-valuemax="100" style={{ width: '100%' }}>
                                                        </div>
                                                    </div>
                                                </div>
                                            </Link>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-3 col-md-6">
                                    <div className="panel info-box panel-white">
                                        <div className="panel-body">
                                            <Link to="/medicaldevicereportingevent">
                                                <div className="info-box-stats">
                                                    <p className="counter">{medicalDeviceReporting}</p>
                                                    <span className="info-box-title">Medical Device Reporting</span>
                                                </div>
                                                <div className="info-box-icon">
                                                    <img src="assets\images\Icons\medical care and treatment.png" height={"50px"} />
                                                </div>
                                                <div className="info-box-progress">
                                                    <div className="progress progress-xs progress-squared bs-n">
                                                        <div className="progress-bar progress-bar-info" role="progressbar" aria-valuenow="50" aria-valuemin="0" aria-valuemax="100" style={{ width: '100%' }}>
                                                        </div>
                                                    </div>
                                                </div>
                                            </Link>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-3 col-md-6">
                                    <div className="panel info-box panel-white">
                                        <div className="panel-body">
                                            <Link to="/todayevents">
                                                <div className="info-box-stats">
                                                    <p><span className="counter">{day}</span></p>
                                                    <span className="info-box-title">Today Events</span>
                                                </div>
                                                <div className="info-box-icon">
                                                    <img src="assets\images\Icons\todayevents.png" height={"50px"} alt="Today Events" />
                                                </div>
                                                <div className="info-box-progress">
                                                    <div className="progress progress-xs progress-squared bs-n">
                                                        <div className="progress-bar progress-bar-warning" role="progressbar" aria-valuenow="50" aria-valuemin="0" aria-valuemax="100" style={{ width: '100%' }}>
                                                        </div>
                                                    </div>
                                                </div>
                                            </Link>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-3 col-md-6">
                                    <div className="panel info-box panel-white">
                                        <div className="panel-body">
                                            <Link to="/monthlyevents">
                                                <div className="info-box-stats">
                                                    <p className="counter">{month}</p>
                                                    <span className="info-box-title">This month events.</span>
                                                </div>
                                                <div className="info-box-icon">
                                                    <img src="assets\images\Icons\todayevents.png" height={"50px"} />
                                                </div>
                                                <div className="info-box-progress">
                                                    <div className="progress progress-xs progress-squared bs-n">
                                                        <div className="progress-bar progress-bar-danger" role="progressbar" aria-valuenow="50" aria-valuemin="0" aria-valuemax="100" style={{ width: '100%' }}>
                                                        </div>
                                                    </div>
                                                </div>
                                            </Link>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="row panel panel-white panel-heading clearfix">
                                <div className="col-lg-12 col-md-12" >
                                    <div className="">
                                        <div className="col-md-6">
                                            <div className="visitors-chart">
                                                <div>
                                                    <h4 className="panel-title">All Events</h4>
                                                </div>
                                                <div >
                                                    <div><DashboardDoughnutChart /></div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="visitors-chart">
                                                <div className="">
                                                    <h4 className="panel-title">Yearly All Events</h4>
                                                </div>
                                                <div className="panel-body" style={{paddingTop:"50px"}} >
                                                    <div id="barGraphContainer"><BarGraphDashboard /></div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>    
                            </div>
                        </div>
                        
                        <div id="main-wrapper">
                            <div className="row m-t-md">
                                <div className="col-md-12">
                                    <div className="mailbox-content" style={{ overflow: 'auto' }}>
                                        <div style={{ height: "100%", width: '100%', paddingTop: "20px" }}>
                                            {error && <p style={{ marginLeft: "30%", fontSize: "15px", color: "red" }}>{error}</p>}
                                            {loading && <p style={{ textAlign: "center", fontSize: "15px" }}>Loading...</p>}
                                            {!loading && !error && (
                                                <DataGrid
                                                    rows={sortedData}
                                                    columns={columns}
                                                    pageSize={5}
                                                    rowsPerPageOptions={[10, 20, 30]}
                                                    classes={{
                                                        overlayWrapper: 'customOverlayWrapper'
                                                    }}
                                                    sx={{
                                                        "& .customOverlayWrapper ": {
                                                            height: "63px !important"
                                                        },
                                                        fontWeight: 'fontWeightLight',
                                                        mx: 0.5,
                                                        fontSize: 14, fontFamily: "sans-serif",
                                                        boxShadow: 2
                                                    }}
                                                    components={{
                                                        Toolbar: () => (
                                                            <GridToolbarQuickFilter
                                                                classes={{
                                                                    toolbarQuickFilter: 'customtoolbarQuickFilter'
                                                                }}
                                                                sx={{
                                                                    width: '35% !important',
                                                                    position: 'absolute !important',
                                                                    right: 400,
                                                                    top: '-30px !important'
                                                                }}
                                                            />
                                                        ),
                                                        NoRowsOverlay: () => (
                                                            <div
                                                                style={{
                                                                    position: 'sticky',
                                                                    top: '50%',
                                                                    transform: 'translate(-50%, 0%)',
                                                                    textAlign: 'center',
                                                                    marginLeft: '45%'
                                                                }}
                                                            >
                                                                No data found
                                                            </div>
                                                        ),
                                                    }}
                                                />
                                            )}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* <div className="page-footer">
                        <p className="no-s">2024 &copy; Medbee by Stalwarts.</p>
                    </div> */}
                </div>
            </div>
        )}
    </div>
);

}
export default DashboardScreen;