import React, { useState, useEffect } from 'react';
import { Link, useParams } from "react-router-dom";
import axios from 'axios';
import { apis } from '../../configuration/configurationAPI';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Box from '@mui/material/Box';
import FormControl from '@mui/material/FormControl';
import { Button, TextField, Snackbar, } from "@mui/material";
import Autocomplete from '@mui/material/Autocomplete';
import MuiAlert from '@material-ui/lab/Alert';
import { useNavigate } from 'react-router-dom';
import { MenuItem } from "@material-ui/core";
import DatePicker from 'react-datepicker';
import { IconButton } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import GooglePlacesAutocomplete, { geocodeByPlaceId } from 'react-google-places-autocomplete';
import { useSelector } from 'react-redux';
import store from "../../utils/index";
import { commonSelectors } from "../../utils/commonstore";
import { act } from 'react';

const dispatch = store.dispatch;
let commonStore = store.getState().commonStore;
store.subscribe(function () {
  commonStore = store.getState().commonStore;
});

const DiagnosticImageForm = () => {

  const { action, incident_id } = useParams();
  const editIncidentData = useSelector(commonSelectors.selectEditIncidentData);
  const viewIncidentData = useSelector(commonSelectors.selectViewIncidentData);

  const getCurrentTime = () => {
    const now = new Date();
    const utcOffset = -4 * 60; // UTC offset for Eastern Time Zone (-4 hours)
    const utcTime = now.getTime() + (now.getTimezoneOffset() * 60 * 1000);
    const estTime = new Date(utcTime + (utcOffset * 60 * 1000));
    const hours = String(estTime.getHours()).padStart(2, '0');
    const minutes = String(estTime.getMinutes()).padStart(2, '0');
    return `${hours}:${minutes}`;
  };
  const [enteredTime, setEnteredTime] = useState(getCurrentTime());
  const [incidentTime, setIncidentTime] = useState(getCurrentTime());

  // general incident info    
  const [generalincidenterror, setGeneralIncidenterror] = useState("");
  const [patientTypeError, setPatientTypeError] = useState("");
  const [injuryIncurredError, setInjuryIncurredError] = useState("");
  const [equipmentMalfunctionedError, setEquipmentMalfunctionedError] = useState("");
  // demographics info
  const [firstNameError, setFirstNameError] = useState("");
  const [lastNameError, setLastNameError] = useState("");
  const [genderError, SetGenderError] = useState("");
  const [dobError, setdobError] = useState("");
  const [addressError, setaddressError] = useState("");
  const [streetError, setstreetError] = useState("");
  const [cityError, setcityError] = useState("");
  const [stateError, setstateError] = useState("");
  const [countryError, setcountryError] = useState("");
  const [zipCodeError, setzipCodeError] = useState("");
  // incident details
  const [incidentdateError, setIncidentdateError] = useState("");
  const [stateincidentoccuredError, setStateIncidentOccuredError] = useState("");
  const [siteincidentoccuredError, setSiteIncidentOccuredError] = useState("");
  // special additional info
  const [departmentError, setDepartmentError] = useState("");
  const [specificIncidentError, setSpecificIncidentError] = useState("");
  const [reportedIncidentError, setReportedIncidentError] = useState("");
  const [incidentdescriptionError, setIncidentdescriptionError] = useState("");


  //Successfully submitted popup
  const [showSuccessPopup, setShowSuccessPopup] = useState("");
  const [snackbarMessage, setSnackbarMessage] = useState('');

  const [selectedFiles, setSelectedFiles] = useState([]);

  const [inputValue, setInputValue] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");

  const baererToken = useSelector(commonSelectors.selectBaererToken);
  const loginUserId = useSelector(commonSelectors.selectLoginUserId);

  const accessInfo = useSelector(commonSelectors.selectAccessInfo);

  const createAllowed = [ "1100", "1101", "1110", "1111"];

// Check if the user has the permission to submit (create or update)
const isAnonymousUser = JSON.parse(sessionStorage.getItem('rl6User'))?.isVisitor;
const canSubmit =isAnonymousUser || (accessInfo?.forms && createAllowed.includes(accessInfo.forms));

// Determine if it's an "edit-only" permission (like 1010)
const isEditOnly = accessInfo?.forms === "1010" || accessInfo?.forms === "1011";

  const [diagnosticImageFormState, setDiagnosticImageFormState] = useState(
    viewIncidentData.spec ? viewIncidentData.spec :
      (editIncidentData.spec ? editIncidentData.spec : {
        "general_incident_info": {
          "incident_type": "",
          "patient_type": "",
          "injury_incurred": "",
          "equipment_involved": ""
        },
        "demographics_info": {
          "first_name": "",
          "last_name": "",
          "gender": 0,
          "dob": null,
          "address": "",
          "street": "",
          "city": "",
          "state": "",
          "country": "",
          "zipcode": ""
        },
        "incident_details": {
          "incident_date": new Date(),
          "incident_time": getCurrentTime(),
          "incident_state": 0,
          "incident_site": 0,
          "file_owner": "",
          "entered_by": "",
          "entered_date": new Date(),
          "entered_time": getCurrentTime(),
          "witness_name": "",
          "witness_phone": "",
          "witness_address": ""

        },
        "special_additional_info": {
          "department_id": 0,
          // "specific_incident_type": "",
          "location_of_incident_occurred":"",
          "hospitalized_On": new Date(),
          "image_procedure_type": "",
          "area_or_organ_studied": "",
          "contrast_media_used": "",
          "Radiologic_technologist_or_sonographer_name": "",
          "imaging_study_date": "",
          "imaging_study_time": new Date(),
          "asessment_of_technical_quality_images_": "",
          "patient_reaction": "",
          "outcome": "",
          "sequelae": "",
          "relevant_history": "",
          "physician_involved": false,
          "contributing_factors": "",
          "immediate_actions_taken": "",
          "reported_incident_severity": "",
          "other_information": "",
          "incident_description": "",
          "attachment": []

        }
      })
  );


  const diagnosticImageFormStateString = JSON.stringify(diagnosticImageFormState);

  const handleStateChange = (key, value) => {
    let tempDiagnosticImageFormState = JSON.parse(JSON.stringify(diagnosticImageFormState));
    switch (key) {
      // general incident info
      case "incident_type": tempDiagnosticImageFormState.general_incident_info.incident_type = value;
        setDiagnosticImageFormState(tempDiagnosticImageFormState);
        break;
      case "patient_type": tempDiagnosticImageFormState.general_incident_info.patient_type = value;
        setDiagnosticImageFormState(tempDiagnosticImageFormState);
        break;
      case "injury_incurred": tempDiagnosticImageFormState.general_incident_info.injury_incurred = value;
        setDiagnosticImageFormState(tempDiagnosticImageFormState);
        break;
      case "equipment_involved": tempDiagnosticImageFormState.general_incident_info.equipment_involved = value;
        setDiagnosticImageFormState(tempDiagnosticImageFormState);
        break;
      // demographics
      case "first_name": tempDiagnosticImageFormState.demographics_info.first_name = value;
        setDiagnosticImageFormState(tempDiagnosticImageFormState);
        break;
      case "last_name": tempDiagnosticImageFormState.demographics_info.last_name = value;
        setDiagnosticImageFormState(tempDiagnosticImageFormState);
        break;
      case "gender": tempDiagnosticImageFormState.demographics_info.gender = value;
        setDiagnosticImageFormState(tempDiagnosticImageFormState);
        break;
      case "dob": tempDiagnosticImageFormState.demographics_info.dob = value;
        setDiagnosticImageFormState(tempDiagnosticImageFormState);
        break;
      case "address": tempDiagnosticImageFormState.demographics_info.address = value;
        setDiagnosticImageFormState(tempDiagnosticImageFormState);
        break;
      case "street": tempDiagnosticImageFormState.demographics_info.street = value;
        setDiagnosticImageFormState(tempDiagnosticImageFormState);
        break;
      case "city": tempDiagnosticImageFormState.demographics_info.city = value;
        setDiagnosticImageFormState(tempDiagnosticImageFormState);
        break;
      case "state": tempDiagnosticImageFormState.demographics_info.state = value;
        setDiagnosticImageFormState(tempDiagnosticImageFormState);
        break;
      case "country": tempDiagnosticImageFormState.demographics_info.country = value;
        setDiagnosticImageFormState(tempDiagnosticImageFormState);
        break;
      case "zipcode": tempDiagnosticImageFormState.demographics_info.zipcode = value;
        setDiagnosticImageFormState(tempDiagnosticImageFormState);
        break;
      case "addressValues":
        tempDiagnosticImageFormState.demographics_info.zipcode = value.zipcode;
        tempDiagnosticImageFormState.demographics_info.country = value.country;
        tempDiagnosticImageFormState.demographics_info.state = value.state;
        tempDiagnosticImageFormState.demographics_info.city = value.city;
        tempDiagnosticImageFormState.demographics_info.street = value.street;
        tempDiagnosticImageFormState.demographics_info.address = value.address;

        // tempDiagnosticImageFormState.demographics_info.address = value;
        setDiagnosticImageFormState(tempDiagnosticImageFormState);
        break;
      // incident details
      case "incident_date": tempDiagnosticImageFormState.incident_details.incident_date = value;
        setDiagnosticImageFormState(tempDiagnosticImageFormState);
        break;
      case "incident_time": tempDiagnosticImageFormState.incident_details.incident_time = value;
        setDiagnosticImageFormState(tempDiagnosticImageFormState);
        break;
      case "incident_state": tempDiagnosticImageFormState.incident_details.incident_state = value;
        setDiagnosticImageFormState(tempDiagnosticImageFormState);
        break;
      case "incident_site": tempDiagnosticImageFormState.incident_details.incident_site = value;
        setDiagnosticImageFormState(tempDiagnosticImageFormState);
        break;
      case "file_owner": tempDiagnosticImageFormState.incident_details.file_owner = value;
        setDiagnosticImageFormState(tempDiagnosticImageFormState);
        break;
      case "entered_by": tempDiagnosticImageFormState.incident_details.entered_by = value;
        setDiagnosticImageFormState(tempDiagnosticImageFormState);
        break;
      case "entered_date": tempDiagnosticImageFormState.incident_details.entered_date = value;
        setDiagnosticImageFormState(tempDiagnosticImageFormState);
        break;
      case "entered_time": tempDiagnosticImageFormState.incident_details.entered_time = value;
        setDiagnosticImageFormState(tempDiagnosticImageFormState);
        break;
      case "witness_name": tempDiagnosticImageFormState.incident_details.witness_name = value;
        setDiagnosticImageFormState(tempDiagnosticImageFormState);
        break;
      case "witness_phone": tempDiagnosticImageFormState.incident_details.witness_phone = value;
        setDiagnosticImageFormState(tempDiagnosticImageFormState);
        break;
      case "witness_address": tempDiagnosticImageFormState.incident_details.witness_address = value;
        setDiagnosticImageFormState(tempDiagnosticImageFormState);
        break;
      // special incident info

      case "department_id": tempDiagnosticImageFormState.special_additional_info.department_id = value;
        setDiagnosticImageFormState(tempDiagnosticImageFormState);
        break;
      case "location_of_incident_occurred": tempDiagnosticImageFormState.special_additional_info.location_of_incident_occurred= value;
        setDiagnosticImageFormState(tempDiagnosticImageFormState);
        break;

      case "hospitalized_On": tempDiagnosticImageFormState.special_additional_info.hospitalized_On = value;
        setDiagnosticImageFormState(tempDiagnosticImageFormState);
        break;
      case "image_procedure_type": tempDiagnosticImageFormState.special_additional_info.image_procedure_type = value;
        setDiagnosticImageFormState(tempDiagnosticImageFormState);
        break;
      case "area_or_organ_studied": tempDiagnosticImageFormState.special_additional_info.area_or_organ_studied = value;
        setDiagnosticImageFormState(tempDiagnosticImageFormState);
        break;
      case "contrast_media_used": tempDiagnosticImageFormState.special_additional_info.contrast_media_used = value;
        setDiagnosticImageFormState(tempDiagnosticImageFormState);
        break;
      case "Radiologic_technologist_or_sonographer_name": tempDiagnosticImageFormState.special_additional_info.Radiologic_technologist_or_sonographer_name = value;
        setDiagnosticImageFormState(tempDiagnosticImageFormState);
        break;
      case "imaging_study_date": tempDiagnosticImageFormState.special_additional_info.imaging_study_date = value;
        setDiagnosticImageFormState(tempDiagnosticImageFormState);
        break;
      case "imaging_study_time": tempDiagnosticImageFormState.special_additional_info.imaging_study_time = value;
        setDiagnosticImageFormState(tempDiagnosticImageFormState);
        break;
      case "asessment_of_technical_quality_images_": tempDiagnosticImageFormState.special_additional_info.asessment_of_technical_quality_images_ = value;
        setDiagnosticImageFormState(tempDiagnosticImageFormState);
        break;

      case "patient_reaction": tempDiagnosticImageFormState.special_additional_info.patient_reaction = value;
        setDiagnosticImageFormState(tempDiagnosticImageFormState);
        break;
      case "outcome": tempDiagnosticImageFormState.special_additional_info.outcome = value;
        setDiagnosticImageFormState(tempDiagnosticImageFormState);
        break;
      case "sequelae": tempDiagnosticImageFormState.special_additional_info.sequelae = value;
        setDiagnosticImageFormState(tempDiagnosticImageFormState);
        break;
      case "relevant_history": tempDiagnosticImageFormState.special_additional_info.relevant_history = value;
        setDiagnosticImageFormState(tempDiagnosticImageFormState);
        break;
      case "physician_involved": tempDiagnosticImageFormState.special_additional_info.physician_involved = value;
        setDiagnosticImageFormState(tempDiagnosticImageFormState);
        break;

      case "contributing_factors": tempDiagnosticImageFormState.special_additional_info.contributing_factors = value;
        setDiagnosticImageFormState(tempDiagnosticImageFormState);
        break;
      case "immediate_actions_taken": tempDiagnosticImageFormState.special_additional_info.immediate_actions_taken = value;
        setDiagnosticImageFormState(tempDiagnosticImageFormState);
        break;
      case "reported_incident_severity": tempDiagnosticImageFormState.special_additional_info.reported_incident_severity = value;
        setDiagnosticImageFormState(tempDiagnosticImageFormState);
        break;
      case "other_information": tempDiagnosticImageFormState.special_additional_info.other_information = value;
        setDiagnosticImageFormState(tempDiagnosticImageFormState);
        break;
      case "incident_description": tempDiagnosticImageFormState.special_additional_info.incident_description = value;
        setDiagnosticImageFormState(tempDiagnosticImageFormState);
        break;
      default: break;
    }
  }
  useEffect(() => {

  }, []);

  const getCurrentDate = () => {
    const now = new Date();
    const year = now.getFullYear();
    const month = String(now.getMonth() + 1).padStart(2, '0');
    const day = String(now.getDate()).padStart(2, '0');
    return `${year}-${month}-${day}`;

  };


  const putdata = {

    "file_count": 0,
    "is_deleted": false,
    "spec": diagnosticImageFormStateString,
    "updated_by": loginUserId,
    "updated_timestamp": new Date().toISOString('en-US'),
    "files_info": ""

  }

  const handleSubmit = (e) => {
    e.preventDefault();

    let valid = true;
    const namePattern = /^[a-zA-Z]+$/;
    const zipCodePattern = /^\d{6}$/;


    if (!diagnosticImageFormState.general_incident_info.incident_type) {
      setGeneralIncidenterror("Please Select a General Incident Type.");
    } else {
      setGeneralIncidenterror("");
    }
    if (!diagnosticImageFormState.general_incident_info.patient_type) {
      setPatientTypeError("Please Select Patient Type.");
    } else {
      setPatientTypeError("");
    }
    if (!diagnosticImageFormState.general_incident_info.injury_incurred) {
      setInjuryIncurredError("Please Enter Injury Incurred.");
    } else {
      setInjuryIncurredError("");
    }
    if (!diagnosticImageFormState.general_incident_info.equipment_involved) {
      setEquipmentMalfunctionedError("Please Enter Equipment Involved/ Malfunctioned.");
    } else {
      setEquipmentMalfunctionedError("");
    }

    // demographics info
  
    if (!diagnosticImageFormState.demographics_info.last_name.match(namePattern)) {
      setLastNameError("Last name should only contain alphabets.");
      valid = false;
    } else {
      setLastNameError("");
    }
   
    // incidentdetails
    if (!diagnosticImageFormState.incident_details.incident_date) {
      setIncidentdateError("Please Enter Incident Date.");
    } else {
      setIncidentdateError("");
    }
    if (!diagnosticImageFormState.incident_details.incident_state) {
      setStateIncidentOccuredError("Please Enter State Incident Occured.");
    } else {
      setStateIncidentOccuredError("");
    }

    // if (!diagnosticImageFormState.incident_details.incident_site) {
    //   setSiteIncidentOccuredError("Please Enter Site Incident Occured.");
    // } else {
    //   setSiteIncidentOccuredError("");
    // }

    // special incident info
    if (!diagnosticImageFormState.special_additional_info.department_id) {
      setDepartmentError("Please Select Department.");
    } else {
      setDepartmentError("");
    }
    if (!diagnosticImageFormState.special_additional_info.specific_incident_type) {
      setSpecificIncidentError("Please Select Specific Incident.");
    } else {
      setSpecificIncidentError("");
    }
    if (!diagnosticImageFormState.special_additional_info.reported_incident_severity) {
      setReportedIncidentError("Please Select Incident Severity.");
    } else {
      setReportedIncidentError("");
    }
    if (!diagnosticImageFormState.special_additional_info.incident_description) {
      setIncidentdescriptionError("Please Select  Incident Description.");
    } else {
      setIncidentdescriptionError("");
    }



    // Function to validate inputs (ensure all fields are filled)
    const validateInputs = () => {
      // Check for errors in form fields
      if (
        !diagnosticImageFormState.general_incident_info.incident_type ||
        !diagnosticImageFormState.general_incident_info.patient_type ||
        !diagnosticImageFormState.general_incident_info.injury_incurred ||
        !diagnosticImageFormState.general_incident_info.equipment_involved ||

        // !diagnosticImageFormState.demographics_info.first_name ||
        !diagnosticImageFormState.demographics_info.last_name ||
        // !diagnosticImageFormState.demographics_info.gender ||
        !diagnosticImageFormState.incident_details.incident_date ||
        !diagnosticImageFormState.incident_details.incident_state ||
        !diagnosticImageFormState.incident_details.incident_site ||

        !diagnosticImageFormState.special_additional_info.department_id ||
        !diagnosticImageFormState.special_additional_info.specific_incident_type ||
        !diagnosticImageFormState.special_additional_info.reported_incident_severity ||
        !diagnosticImageFormState.special_additional_info.incident_description

      ) {
        return false;
      }
      return true;
    };
    if (editIncidentData.incident_id) {
      handleUpdate();
    } else {
      handlePost();
    }
  };
  const [files, setFiles] = useState([]);

  const handleFileUpload = (event) => {
    const selectedFiles = event.target.files;
    if (selectedFiles) {
      setFiles([...files, ...selectedFiles]);
    }
  };

  const handlePost = () => {
    const formData = new FormData();
    var reqb = {
      is_deleted: false,
      spec: diagnosticImageFormStateString,
      created_by: loginUserId,
      updated_by: loginUserId
    }
    formData.append("IncidentInfo", JSON.stringify(reqb));

    files.forEach((file, index) => {
      formData.append(`files`, file);
    });

    axios.post(apis.base + apis.POST_INCIDENT_API, formData, {
      headers: {
        userid: loginUserId,
        Authorization: "Bearer " + baererToken,
        "Content-Type": "multipart/form-data",
      },
    })
      .then((response) => {
        // console.log("Response data:", response.data);
        // console.log("Form submitted successfully!");
        setShowSuccessPopup(true);
        setSnackbarMessage('Form submitted successfully!');
        // resetFormState();
      })
      .catch((error) => {
        console.error("Error submitting form:", error);
      });
  };
  const handleUpdate = () => {
    axios.put(apis.base + apis.PUT_INCIDENT_BYID_API + editIncidentData.incident_id, putdata, {
      headers: {
        userid: loginUserId,
        Authorization: "Bearer " + baererToken
      }
    })
      .then((res) => {
        // console.log("putdata", res);
        // console.log("Form updated successfully!");
        // resetFormState();
        setShowSuccessPopup(true);
        setSnackbarMessage('Feedback updated successfully!');
      })
      .catch((error) => {
        console.error("Error updating form:", error);
      });
  }



  const [selectedFile, setSelectedFile] = useState(null);
  const [previewImage, setPreviewImage] = useState(null);

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      setSelectedFile(file);
      const reader = new FileReader();
      reader.onloadend = () => {
        setPreviewImage(reader.result);
      };
      reader.readAsDataURL(file);
    }
  };

  const handleUpload = () => {
    if (selectedFile) {
      console.log('Selected File:', selectedFile);
      console.log('Preview Image:', previewImage);
    } else {
      console.warn('No file selected for upload.');
    }
  };

  const navigateTo = useNavigate();
  const handleCloseSnackbar = () => {
    setShowSuccessPopup(false); // Hide snackbar when closed
    navigateTo('/forms')
  };

  const generalIncidentTypeList = [
    { label: 'Diagnostic Image' }
  ]
  const patientTypeList = [
    { label: 'In-Patient' },
    { label: 'Out-Patient' },
  ]

  const reportedIncidentSeverityList = [
    { label: '1 - Minor' },
    { label: '2 - Moderate' },
    { label: '3 - Major' },
    { label: 'P3 - Potential Major' },
    { label: '4 - Catastrophic' },
    { label: 'P4-Potential Catastrophic' },
  ]
  const specificIncidentTypeList = [
    { label: 'Accidental puncture or laceration' },
    { label: 'Air embolism' },
    { label: 'Blood incompatibility' },
    { label: 'Catheter associated UTI' },
    { label: 'Central venous catheter related blood stream infection' },
    { label: 'Death among surgical inpatients' },
    { label: 'Falls and trauma' },
    { label: 'Foreign object retained after surgery' },
    { label: 'Iatrogenic pneumothorax' },
    { label: 'Iatrogenic pneumothorax with venous catheterization' },
  ]
  const typeofLinetube = [
    { label: 'Airway-Endotracheal Tube' },
    { label: 'Airway-Laryngeal Mask Airway' },
    { label: 'Airway-Nasal Airway' },
    { label: 'Airway-Oral Airway' },
    { label: 'Airway-Tracheostomy Airway' },
    { label: 'catheter-IV' },
    { label: 'catheter-Urinary Urethral' },
  ]
  const typeofRestraints = [
    { label: 'Vest' },
    { label: 'Siderailsx4' },
    { label: 'Siderailsx3' },
    { label: 'Siderailsx2' },
    { label: 'Posey Jacket' },
    { label: 'Other' },
    { label: 'None' },
    { label: 'Laptray' },
    { label: 'Lapbelt' },
  ]

  const reactionForPatient = [
    { label: 'Allergic reaction' },
    { label: 'Local reaction' },
    { label: 'Systemic reaction' },
    { label: 'Neurological disorders' },
  ]
  const sequelaeList = [
    { label: 'Medically significant events' },
    { label: 'Persistent disability' },
    { label: 'Birth defect' },
    { label: 'Not Applicable' },
  ]

  const [departmentData, setDepartmentData] = useState([]);
  const [locationData, setLocationData] = useState([])
  useEffect(() => {
    axios.get(apis.base + apis.GET_LOCATION_API, {
      headers: {
        userid: loginUserId,
        Authorization: "Bearer " + baererToken
      }
    })
      .then((response) => {
        setLocationData(response.data);
      })
      .catch((errorresp) => {
        console.log(JSON.stringify(errorresp));
      });
 
 
    axios.get(apis.base + apis.GET_ALL_DEPARTMENT_API, {
      headers: {
        userid: loginUserId,
        Authorization: "Bearer " + baererToken
      }
    })
      .then((response) => {
        setDepartmentData(response.data);
      })
      .catch((errorresp) => {
        console.log(JSON.stringify(errorresp));
      });
      axios.get(apis.base + apis.GET_INCIDENT_BYID_API + incident_id, {
        headers: {
          userid: loginUserId,
          Authorization: "Bearer " + baererToken
        }
      })
        .then((response) => {
         if (incident_id) {
            setDiagnosticImageFormState(JSON.parse(response.data.form_info.spec));
          }
        })
        .catch((errorresp) => {
          console.log(JSON.stringify(errorresp));
        });
  }, []);


  const handleDeleteFile = (index) => {
    // Create a copy of the files array
    const updatedFiles = [...files];
    // Remove the file at the specified index
    updatedFiles.splice(index, 1);
    // Update the files state with the new array
    setFiles(updatedFiles);
  };

  // First name , Last name , File Owner name (first letter should be capitalized )
  const capitalizeFirstLetter = (string) => {
    return string.charAt(0).toUpperCase() + string.slice(1);
  };

  return (
    <div>
      <div className="page-content">
        <div className="page-inner">
          <div className="page-title">
            <h3>Diagnostic Image Form </h3>
            <div className="page-breadcrumb">
              <ol className="breadcrumb">
                <li><Link to="/Dashboard">Home</Link></li>
                <li><Link to="/forms">Forms</Link></li>
                <li className="active"> Diagnostic Image Form</li>
              </ol>
            </div>
          </div>

          <div class="col-md-12 ">
            <div class="col-md-12">
              <form onSubmit={handleSubmit}>
              <div id="main-wrapper">
                  <div class="row ">
                    <div >
                      <div class="panel panel-white">
                        <Accordion defaultExpanded>
                          <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel1-content"
                            id="panel1-header"
                          >
                            <h4 class="panel-title"  >General Incident Information</h4>
                          </AccordionSummary>
                          <AccordionDetails>
                            <div class="panel-body">
                              <div class="form-horizontal col-md-12"  >
                                <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                  <div class="form-group col-md-6"  >
                                    <label for="surgeryType"   >General Incident Type <span style={{ fontweight: "bold", color: "red" }} >*</span></label>
                                    <div>
                                      {action === "view" || action === "edit" ? (
                                        <Autocomplete
                                          disablePortal
                                          options={generalIncidentTypeList}
                                          getOptionLabel={(option) => option.label}
                                          disabled={action == "view"}
                                          onChange={(event, value) => handleStateChange("incident_type", value ? value.label : "")}
                                          renderInput={(params) => (
                                            <TextField
                                              {...params}
                                              placeholder="Please Select incident Type"
                                              fullWidth
                                            />
                                          )}
                                          value={generalIncidentTypeList.find(option => option.label === diagnosticImageFormState.general_incident_info.incident_type) || null}
                                        />) : (
                                        <Autocomplete
                                          disablePortal
                                          options={generalIncidentTypeList}
                                          getOptionLabel={(option) => option.label}
                                          onChange={(event, value) => handleStateChange("incident_type", value ? value.label : "")}
                                          renderInput={(params) => (
                                            <TextField
                                              {...params}
                                              placeholder="Please Select incident Type"
                                              fullWidth
                                            />
                                          )}
                                        />
                                      )}




                                      <div className="text-danger ">{generalincidenterror}</div>
                                    </div>

                                  </div>
                                  <div class="form-group col-md-6">
                                    <label for="surgeryType" >Patient Type <span style={{ fontweight: "bold", color: "red" }} >*</span></label>

                                    <div >
                                      {action === "view" || action === "edit" ? (
                                        <Autocomplete
                                          disablePortal
                                          options={patientTypeList}
                                          getOptionLabel={(option) => option.label}
                                          disabled={action == "view"}
                                          onChange={(event, value) => handleStateChange("patient_type", value ? value.label : "")}
                                          renderInput={(params) => (
                                            <TextField
                                              {...params}
                                              placeholder="Please Select patient Type"
                                              fullWidth
                                            />
                                          )}
                                          value={patientTypeList.find(option => option.label === diagnosticImageFormState.general_incident_info.patient_type) || null}
                                        />
                                      ) : (
                                        <Autocomplete
                                          disablePortal
                                          options={patientTypeList}
                                          getOptionLabel={(option) => option.label}
                                          onChange={(event, value) => handleStateChange("patient_type", value ? value.label : "")}
                                          renderInput={(params) => (
                                            <TextField
                                              {...params}
                                              placeholder="Please Select patient Type"
                                              fullWidth
                                            />
                                          )}
                                        />
                                      )}
                                      <div className="text-danger ">{patientTypeError}</div>
                                    </div>
                                  </div>
                                </div>

                                <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                  <div class="form-group col-md-6">
                                    <label for="surgeryType" >Injury Incurred <span style={{ fontweight: "bold", color: "red" }} >*</span></label>
                                    <div >
                                      <TextField type="text"
                                        fullWidth
                                        placeholder="Injury Incurred"
                                        disabled={action == "view"}
                                        value={diagnosticImageFormState.general_incident_info.injury_incurred}
                                        onChange={(e) => {
                                          var temp = JSON.parse(JSON.stringify(diagnosticImageFormState))
                                          temp.general_incident_info.injury_incurred = e.target.value;
                                          setDiagnosticImageFormState(temp)
                                        }}
                                      />
                                      <div className="text-danger">{injuryIncurredError}</div>
                                    </div>
                                  </div>
                                  <div class="form-group col-md-6">
                                    <label for="procedureDate" >Equipment Involved/ Malfunctioned <span style={{ fontweight: "bold", color: "red" }} >*</span></label>
                                    <div >
                                      <TextField type="text"
                                        fullWidth
                                        placeholder="Equipment Involved/ Malfunctioned"
                                        disabled={action == "view"}
                                        value={diagnosticImageFormState.general_incident_info.equipment_involved}
                                        onChange={(e) => {
                                          const updatedState = {
                                            ...diagnosticImageFormState,
                                            general_incident_info: {
                                              ...diagnosticImageFormState.general_incident_info,
                                              equipment_involved: e.target.value
                                            }
                                          };
                                          setDiagnosticImageFormState(updatedState);
                                        }}
                                      />
                                      <div className="text-danger">{equipmentMalfunctionedError}</div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </AccordionDetails>
                        </Accordion>
                      </div>
                    </div>
                  </div>
                </div>


                <div id="main-wrapper">
                  <div class="row">
                    <  div>
                      <div class="panel panel-white">
                        <Accordion >
                          <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel1-content"
                            id="panel1-header"
                          >
                            <h4 class="panel-title"  >Demographics</h4>
                          </AccordionSummary>
                          <AccordionDetails>
                            <div class="panel-body">
                              <div class="form-horizontal col-md-12"  >

                                <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                  <div class="form-group col-md-6"  >
                                    <label  >First Name  </label>
                                    <div>
                                      <TextField type="text" fullWidth
                                        placeholder="First Name"

                                        disabled={action == "view"}

                                        value={diagnosticImageFormState.demographics_info.first_name}
                                        onChange={(e) => handleStateChange("first_name", capitalizeFirstLetter(e.target.value))}
                                      />

                                      <div className="text-danger">{firstNameError}</div>
                                    </div>
                                  </div>

                                  <div class="form-group col-md-6">
                                    <label >Last Name<span style={{ fontweight: "bold", color: "red" }} >*</span></label>
                                    <div>
                                      <TextField type="text" fullWidth
                                        placeholder="Last Name"

                                        disabled={action == "view"}

                                        value={diagnosticImageFormState?.demographics_info?.last_name}
                                        onChange={(e) => handleStateChange("last_name",capitalizeFirstLetter (e.target.value))}
                                      />

                                      <div className="text-danger">{lastNameError}</div>
                                    </div>
                                  </div>
                                </div>


                                <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                  <div className="form-group col-md-6">
                                    <label htmlFor="exampleInputName2">Sex</label>
                                    <div>
                                      <label className="col-md-4" >
                                        <input type="radio" name="gender" disabled={action == "view"} checked={diagnosticImageFormState?.demographics_info?.gender === "male"} onChange={() => handleStateChange("gender", "male")} /> Male
                                      </label>
                                      <label className="col-md-4" >
                                        <input type="radio" name="gender" disabled={action == "view"} checked={diagnosticImageFormState?.demographics_info?.gender === "female"} onChange={() => handleStateChange("gender", "female")} /> Female
                                      </label>
                                      <label className="col-md-4" >
                                        <input type="radio" name="gender" disabled={action == "view"} checked={diagnosticImageFormState?.demographics_info?.gender === "others"} onChange={() => handleStateChange("gender", "others")} /> Others
                                      </label>
                                      <div className="text-danger col-md-6 ">{genderError}</div>
                                    </div>

                                  </div>

                                  < div class="form-group col-md-6 " style={{ display: "flex", flexDirection: "column" }}>
                                    <label htmlFor="exampleInputName">DOB
                                      {/* <span style={{ fontweight: "bold", color: "red" }} >*</span> */}
                                    </label>
                                    <DatePicker className='form-control'
                                      fullWidth
                                      disabled={action == "view"}
                                      selected={diagnosticImageFormState?.demographics_info?.dob}
                                      onChange={(date) => {
                                        setDiagnosticImageFormState({
                                          ...diagnosticImageFormState,
                                          demographics_info: {
                                            ...diagnosticImageFormState?.demographics_info,
                                            dob: date,
                                          },
                                        });
                                      }}
                                      dateFormat="MM/dd/yyyy"
                                      placeholderText="MM/DD/YYYY"
                                      maxDate={new Date()}
                                    />
                                    {/* <div className="text-danger">{dobError}</div> */}

                                  </div>
                                </div>


                                <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                  <div className=" form-group col-md-6" style={{ position: "relative" }}>
                                    <label >Address</label>
                                    {action === "view" ? (
                                      <div>
                                        <GooglePlacesAutocomplete
                                          apiKey="AIzaSyD9S5rAJ1RNWrG3fHjDKU8m2khTUxcr5u8"
                                          selectProps={{
                                            onChange: (e, a, b) => {
                                              handleStateChange("address", e.value)
                                              geocodeByPlaceId(e.value.place_id)
                                                .then(results => {
                                                  // console.log(results)
                                                  handleStateChange("addressValues", {
                                                    "zipcode": results[0].address_components.find(component => component.types.includes("postal_code")).long_name,
                                                    "country": results[0].address_components.find(component => component.types.includes("country")).long_name,
                                                    "state": results[0].address_components.find(component => component.types.includes("administrative_area_level_1")).long_name,
                                                    "city": results[0].address_components.find(component => component.types.includes("locality")).long_name,
                                                    "street": results[0].address_components.find(component => component.types.includes("route")).long_name,
                                                  })
                                                })
                                                .catch(error => console.error(error));
                                            }

                                          }}
                                        />
                                        <div style={{ position: "absolute", width: "95%", height: "37px", background: "black", opacity: "0.1", zIndex: 2, top: "24px" }}>
                                        </div>
                                      </div>
                                    ) : (
                                      <GooglePlacesAutocomplete
                                        apiKey="AIzaSyD9S5rAJ1RNWrG3fHjDKU8m2khTUxcr5u8"
                                        selectProps={{
                                          onChange: (e, a, b) => {
                                            handleStateChange("address", e.value)
                                            geocodeByPlaceId(e.value.place_id)
                                              .then(results => {
                                                // console.log(results)
                                                handleStateChange("addressValues", {
                                                  "zipcode": results[0].address_components.find(component => component.types.includes("postal_code")).long_name,
                                                  "country": results[0].address_components.find(component => component.types.includes("country")).long_name,
                                                  "state": results[0].address_components.find(component => component.types.includes("administrative_area_level_1")).long_name,
                                                  "city": results[0].address_components.find(component => component.types.includes("locality")).long_name,
                                                  "street": results[0].address_components.find(component => component.types.includes("route")).long_name,
                                                })
                                              })
                                              .catch(error => console.error(error));
                                          }

                                        }}
                                      />
                                    )}
                                  </div>

                                  <div class="form-group col-md-6">
                                    <label >Street</label>
                                    <div>
                                      <TextField type="text" fullWidth placeholder="Street"
                                        value={diagnosticImageFormState?.demographics_info?.street}
                                        onChange={(e) => handleStateChange("street", e.target.value)}
                                        disabled={action == "view"}
                                      />
                                      <div className="text-danger">{streetError}</div>
                                    </div>
                                  </div>

                                </div>


                                <div style={{ display: 'flex', gap:'40px',width:'100' }}>
                                  <div class="form-group col-md-6">
                                    <label for="exampleInputName">City</label>
                                    <TextField type="text" fullWidth placeholder="City"
                                      value={diagnosticImageFormState?.demographics_info?.city}
                                      onChange={(e) => handleStateChange("city", e.target.value)}
                                      disabled={action == "view"}
                                    />
                                    <div className="text-danger">{cityError}</div>
                                  </div>


                                  <div class="form-group  col-md-6">
                                    <label for="exampleInputName2">State/Region</label>
                                    <TextField type="text" fullWidth placeholder="State/Region"
                                      value={diagnosticImageFormState?.demographics_info?.state}
                                      onChange={(e) => handleStateChange("state", e.target.value)}
                                      disabled={action == "view"}
                                    />
                                    <div className="text-danger">{stateError}</div>
                                  </div>

                                  <div class="form-group  col-md-6">
                                    <label for="exampleInputName2">Zip code</label>
                                    <TextField
                                      placeholder='Zip Code'
                                      fullWidth
                                      type="text"
                                      disabled={action == "view"}
                                      value={diagnosticImageFormState?.demographics_info?.zipcode}
                                      onChange={(event) => {
                                        const value = event.target.value;
                                        if (/^\d{0,5}$/.test(value)) {
                                          setInputValue(value);
                                          handleStateChange("zipcode", value);
                                        }
                                      }}
                                      inputProps={{
                                        pattern: '[0-9]*',
                                      }}
                                    />
                                  </div>
                                </div>



                                <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                  {/* <div class="form-group col-md-6">
                                    <label for="exampleInputName">Country</label>
                                    <TextField type="text" fullWidth placeholder="Country"
                                      value={diagnosticImageFormState?.demographics_info?.country}
                                      onChange={(e) => handleStateChange("country", e.target.value)}
                                      disabled={action == "view"}

                                    />
                                    <div className="text-danger">{countryError}</div>
                                  </div> */}
                                  {/* <div class="form-group  col-md-6">
                                    <label for="exampleInputName2">Zip code</label>
                                    <TextField
                                      placeholder='Zip Code'
                                      fullWidth
                                      type="text"
                                      disabled={action == "view"}
                                      value={diagnosticImageFormState?.demographics_info?.zipcode}
                                      onChange={(event) => {
                                        const value = event.target.value;
                                        if (/^\d{0,5}$/.test(value)) {
                                          setInputValue(value);
                                          handleStateChange("zipcode", value);
                                        }
                                      }}
                                      inputProps={{
                                        pattern: '[0-9]*',
                                      }}
                                    />
                                  </div> */}
                                </div>
                              </div>
                            </div>
                          </AccordionDetails>
                        </Accordion>
                      </div>
                    </div>
                  </div>
                </div>


                <div id="main-wrapper">
                  <div class="row">
                    <div>
                      <div class="panel panel-white">
                        <Accordion>
                          <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel1-content"
                            id="panel1-header"
                          >
                            <h4 class="panel-title"  >Incident  Details</h4>
                          </AccordionSummary>
                          <AccordionDetails>
                            <div class="panel-body">
                              <div class="form-horizontal col-md-12">
                                <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                  <div class="form-group col-md-6" style={{ display: "flex", flexDirection: "column" }}>
                                    <label htmlFor="surgeryType"  >Incident Date <span style={{ fontweight: "bold", color: "red" }} >*</span></label>

                                    <DatePicker className='form-control'
                                      fullWidth
                                      disabled={action == "view"}
                                      selected={diagnosticImageFormState?.incident_details?.incident_date}
                                      onChange={(date) => {
                                        setDiagnosticImageFormState({
                                          ...diagnosticImageFormState,
                                          incident_details: {
                                            ...diagnosticImageFormState?.incident_details,
                                            incident_date: date,
                                          },
                                        });
                                      }}
                                      dateFormat="MM/dd/yyyy"
                                      placeholderText="MM/DD/YYYY"
                                      maxDate={new Date()}
                                    />


                                    <div className="text-danger">{incidentdateError}</div>
                                  </div>
                                  <div class="form-group col-md-6">
                                    <label for="surgeryType" > Incident Time</label>
                                    <div>
                                      <Box sx={{ minWidth: 120 }}>
                                        <FormControl fullWidth>
                                          <TextField
                                            disabled={action == "view"}
                                            type="time"
                                            defaultValue={getCurrentTime()}
                                            onChange={(e) =>
                                              handleStateChange(
                                                "incident_time",
                                                e.target.value
                                              )
                                            }
                                            InputLabelProps={{
                                              shrink: true,
                                            }}
                                            InputProps={{
                                              inputProps: {
                                                max: getCurrentTime(),
                                              },
                                            }}
                                          />
                                        </FormControl>
                                      </Box>
                                    </div>
                                  </div>
                                </div>

                                <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                  <div class="form-group col-md-6">
                                    <label for="procedureDate"  >State Where Incident Occurred at<span style={{ fontweight: "bold", color: "red" }} >*</span></label>
                                    <div>
                                      {action === "view" || action === "edit" ? (
                                        <Autocomplete
                                          options={locationData}
                                          getOptionLabel={(location) => location?.state}

                                          disabled={action == "view"}

                                          onChange={(e, value) => {
                                            handleStateChange("incident_state", value ? value.state : 0);
                                          }}
                                          renderInput={(params) => (
                                            <TextField
                                              {...params}
                                              placeholder="Please Select State Where Incident Occured"
                                              variant="outlined" />
                                          )}
                                          // defaultValue={{ label: diagnosticImageFormState.incident_details.incident_state }}
                                          value={locationData.filter(location => {
                                            return location.location_state == diagnosticImageFormState.incident_details.location_state
                                          })[0] || null}
                                          defaultValue={
                                            locationData.filter(location => {
                                              return location.location_state == diagnosticImageFormState.incident_details.location_state
                                            })[0] || {}
                                          }
                                        />) : (
                                        <Autocomplete
                                          options={locationData}
                                          getOptionLabel={(location) => location?.state}
                                          onChange={(e, value) => {
                                            handleStateChange("incident_state", value ? value.state : 0);
                                          }}
                                          renderInput={(params) => (
                                            <TextField
                                              {...params}
                                              placeholder="Please Select State Where Incident Occured"
                                              variant="outlined" />

                                          )}
                                        />
                                      )}
                                      <div className="text-danger">{stateincidentoccuredError}</div>
                                    </div>
                                  </div>

                                  <div class="form-group col-md-6">
                                    <label for="procedureDate"  >Site Where Incident Occured 
                                      {/* <span style={{ fontweight: "bold", color: "red" }} >*</span> */}
                                      </label>
                                    <div>
                                      {action === "view" || action === "edit" ? (
                                        <Autocomplete
                                          options={locationData}
                                          getOptionLabel={(location) => location?.location_name}
                                          disabled={action == "view"}
                                          onChange={(e, value) => {
                                            handleStateChange("incident_site", value ? value.location_name : 0);
                                          }}
                                          renderInput={(params) => (
                                            <TextField
                                              {...params}
                                              placeholder="Please Select Site Where Incident Occured"
                                              variant="outlined" />
                                          )}

                                          value={locationData.filter(locationsite => {
                                            return locationsite.location?.location_name == diagnosticImageFormState.incident_details.location?.location_name
                                          })[0] || null}
                                          defaultValue={
                                            locationData.filter(locationsite => {
                                              return locationsite.location?.location_name == diagnosticImageFormState.incident_details.location?.location_name
                                            })[0] || {}
                                          }
                                        />) : (
                                        <Autocomplete
                                          options={locationData}
                                          getOptionLabel={(location) => location?.location_name}
                                          onChange={(e, value) => {
                                            handleStateChange("incident_site", value ? value.location_id : 0);
                                          }}
                                          renderInput={(params) => (
                                            <TextField
                                              {...params}
                                              placeholder="Please Select Site Where Incident Occured"
                                              variant="outlined" x />
                                          )}
                                        />
                                      )}

                                      <div className="text-danger">{siteincidentoccuredError}</div>
                                    </div>
                                  </div>
                                </div>

                                <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                  <div class="form-group col-md-6">
                                    <label for="procedureDate"  >File Owner </label>
                                    <div>
                                      <TextField type="text" placeholder='File Owner' fullWidth
                                        value={diagnosticImageFormState?.incident_details?.file_owner}
                                        onChange={(e) => handleStateChange("file_owner",capitalizeFirstLetter (e.target.value))}
                                        disabled={action == "view"}
                                      />
                                    </div>
                                  </div>

                                  <div class="form-group col-md-6">
                                    <label for="procedureDate"  >Entered By</label>
                                    <div>
                                      <TextField type="text" placeholder='Entered By' fullWidth
                                        value={diagnosticImageFormState?.incident_details?.entered_by}
                                        onChange={(e) => handleStateChange("entered_by", e.target.value)}
                                        disabled={action == "view"}
                                      />
                                    </div>
                                  </div>
                                </div>


                                <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                  <div class="form-group col-md-6" style={{ display: "flex", flexDirection: "column" }}>
                                    <label htmlFor="procedureDate"  >Entered Date</label>

                                    <DatePicker className='form-control'
                                      fullWidth
                                      disabled={action == "view"}
                                      selected={diagnosticImageFormState?.incident_details?.entered_date}
                                      onChange={(date) => {
                                        setDiagnosticImageFormState({
                                          ...diagnosticImageFormState,
                                          incident_details: {
                                            ...diagnosticImageFormState.incident_details,
                                            entered_date: date,
                                          },
                                        });
                                      }}
                                      dateFormat="MM/dd/yyyy"
                                      placeholderText="MM/DD/YYYY"
                                      maxDate={new Date()}
                                    />

                                  </div>


                                  <div class="form-group col-md-6">
                                    <label for="procedureDate">
                                      Entered Time (Estern Time Zone)
                                    </label>
                                    <div>
                                      <Box sx={{ minWidth: 120 }}>
                                        <FormControl fullWidth>
                                          <TextField
                                            disabled={action == "view"}
                                            type="time"
                                            defaultValue={getCurrentTime()}
                                            onChange={(e) =>
                                              handleStateChange(
                                                "entered_time",
                                                e.target.value
                                              )
                                            }
                                            InputLabelProps={{
                                              shrink: true,
                                            }}
                                            InputProps={{
                                              inputProps: {
                                                max: getCurrentTime(),
                                              },
                                            }}
                                          />
                                        </FormControl>
                                      </Box>
                                    </div>
                                  </div>
                                </div>

                                <div style={{ display: 'flex', justifyContent: "space-between" }}>
                                  <div class="form-group col-md-6">
                                    <label for="procedureDate"  >Witness Name</label>
                                    <div>
                                      <TextField type="text" placeholder='Witness Name' fullWidth
                                        value={diagnosticImageFormState?.incident_details?.witness_name}
                                        onChange={(e) => handleStateChange("witness_name", e.target.value)}
                                        disabled={action == "view"}
                                      />
                                    </div>
                                  </div>


                                  <div class="form-group col-md-6">
                                    <label for="procedureDate"  >Witness Phone</label>
                                    <div>

                                      <TextField
                                        placeholder='Witness Phone'
                                        fullWidth
                                        disabled={action == "view"}
                                        type="text"
                                        value={diagnosticImageFormState?.incident_details?.witness_phone}
                                        onChange={(event) => {
                                          const value = event.target.value;
                                          if (/^\d{0,10}$/.test(value)) {
                                            setPhoneNumber(value);
                                            handleStateChange("witness_phone", value);
                                          }
                                        }}
                                        inputProps={{
                                          pattern: '[0-9]*',
                                        }}
                                      />

                                    </div>
                                  </div>
                                </div>

                                <div class="form-group">
                                  <label for="procedureDate" style={{ marginLeft: '15px' }}>Witness Address </label>
                                  <div
                                    class="col-md-12"
                                  >
                                    <TextField type="text" placeholder='Witness Address' fullWidth
                                      value={diagnosticImageFormState?.incident_details?.witness_address}
                                      onChange={(e) => handleStateChange("witness_address", e.target.value)}
                                      disabled={action == "view"}
                                    />
                                  </div>
                                </div>
                              </div>
                            </div>
                          </AccordionDetails>
                        </Accordion>
                      </div>
                    </div>
                  </div>
                </div>

                {/*  Special additional Information */}

                <div id="main-wrapper">
                  <div class="row">
                    <div class="panel panel-white">
                      {/* <div class="panel-heading clearfix">
                    <h4 class="panel-title">Diagnostic Imaging Information</h4>
                  </div> */}
                      <Accordion >
                        <AccordionSummary
                          expandIcon={<ExpandMoreIcon />}

                          aria-controls="panel1-content"
                          id="panel1-header"
                        >
                          <h4 class="panel-title"  >Special Additional Info </h4>
                        </AccordionSummary>
                        <AccordionDetails>
                          <div class="panel-body">
                            <div class="form-horizontal col-md-12">

                              {/* Surgery Details */}
                              <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                <div class="form-group col-md-6" >
                                  <label for="exampleInputPassword1">Department
                                    <span style={{ fontweight: "bold", color: "red" }} >*</span>
                                  </label>
                                  <div>
                                    {action === "view" || action === "edit" ? (
                                      <Autocomplete
                                        options={departmentData}
                                        getOptionLabel={(department) => department.department_name}
                                        disabled={action === "view"}
                                        onChange={(e, value) => {
                                          handleStateChange("department_id", value ? value.department_id : 0);
                                        }}
                                        renderInput={(params) => (
                                          <TextField
                                            {...params}
                                            placeholder="Please Select department"
                                            variant="outlined"
                                          />
                                        )}
                                        value={departmentData.filter(department => {
                                          return department.department_id == diagnosticImageFormState.special_additional_info.department_id
                                        })[0] || null}
                                        defaultValue={
                                          departmentData.filter(department => {
                                            return department.department_id == diagnosticImageFormState.special_additional_info.department_id
                                          })[0] || {}
                                        }
                                      />
                                    ) : (
                                      <Autocomplete
                                        options={departmentData}
                                        getOptionLabel={(department) => department.department_name}
                                        disabled={action === "view"}
                                        onChange={(e, value) => {
                                          handleStateChange("department_id", value ? value.department_id : 0);
                                        }}
                                        renderInput={(params) => (
                                          <TextField
                                            {...params}
                                            placeholder="Please Select department"
                                            variant="outlined"
                                          />
                                        )}
                                      />
                                    )}
                                  </div>
                                  <div className="text-danger">{departmentError}</div>

                                </div>
                                <div class="form-group col-md-6">
                                  <label for="risks" >Location of incident occurred</label>
                                  <div >
                                    <TextField type="text" fullWidth placeholder="patient room ,corridor"
                                      disabled={action == "view"}
                                      value={diagnosticImageFormState?.special_additional_info?.location_of_incident_occurred}
                                      onChange={(e) => handleStateChange("location_of_incident_occurred", e.target.value)}
                                    />
                                  </div>
                                </div>
                                {/* <div class="form-group col-md-6" >
                                  <label for="exampleInputPassword1" >Specific Incident Type <span style={{ fontweight: "bold", color: "red" }} >*</span></label>
                                  <div>
                                  {action==="view" || action==="eidt" ?(  
                                    <Autocomplete
                                      disablePortal
                                      disabled={action == "view"}
                                      options={specificIncidentTypeList}
                                      getOptionLabel={(option) => option.label}
                                      onChange={(event, value) => handleStateChange("specific_incident_type", value ? value.label : "")}
                                      renderInput={(params) => (
                                        <TextField
                                          {...params}
                                          placeholder="Please Select Specific Incident Type"
                                          fullWidth
                                        />
                                      )}
                                      value={patientTypeList.find(option => option.label === diagnosticImageFormState.general_incident_info.patient_type) || null}
                                    />):(
                                      <Autocomplete
                                      disablePortal
                                      
                                      options={specificIncidentTypeList}
                                      getOptionLabel={(option) => option.label}
                                      onChange={(event, value) => handleStateChange("specific_incident_type", value ? value.label : "")}
                                      renderInput={(params) => (
                                        <TextField
                                          {...params}
                                          placeholder="Please Select Specific Incident Type"
                                          fullWidth
                                        />
                                      )}

                                    />
                                    )}
                                  </div>
                                  <div className="text-danger">{specificIncidentError}</div>
                                </div> */}
                              </div>
                              <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                <div class="form-group col-md-6" style={{ display: "flex", flexDirection: "column" }}>
                                  <label for="procedureDate" >Hospitalized On</label>

                                  <DatePicker className='form-control'
                                    fullWidth
                                    disabled={action == "view"}
                                    selected={diagnosticImageFormState.incident_details.incident_date}
                                    onChange={(date) => {
                                      setDiagnosticImageFormState({
                                        ...diagnosticImageFormState,
                                        incident_details: {
                                          ...diagnosticImageFormState.incident_details,
                                          incident_date: date,
                                        },
                                      });
                                    }}
                                    dateFormat="MM/dd/yyyy"
                                    placeholderText="MM/DD/YYYY"
                                    maxDate={new Date()}
                                  />

                                </div>
                                <div class="form-group col-md-6">
                                  <label for="risks" >Type of Imaging Procedure</label>
                                  <div >
                                    <TextField type="text" fullWidth placeholder="e.g., X-ray, CT scan, MRI, ultrasound"
                                      disabled={action == "view"}
                                      value={diagnosticImageFormState?.special_additional_info?.image_procedure_type}
                                      onChange={(e) => handleStateChange("image_procedure_type", e.target.value)}
                                    />
                                  </div>
                                </div>
                              </div>

                              <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                <div class="form-group col-md-6">
                                  <label for="risks" >Specific Area or Organ Studied</label>
                                  <div >
                                    <TextField type="text" fullWidth placeholder="Specific Area or Organ Studied"
                                      disabled={action == "view"}
                                      value={diagnosticImageFormState?.special_additional_info?.area_or_organ_studied}
                                      onChange={(e) => handleStateChange("area_or_organ_studied", e.target.value)}
                                    />
                                  </div>
                                </div>
                                <div class="form-group col-md-6">
                                  <label for="risks" >Contrast Media Used</label>
                                  <div >
                                    <TextField type="text" fullWidth placeholder="Contrast Media Used"
                                      disabled={action == "view"}
                                      value={diagnosticImageFormState?.special_additional_info?.contrast_media_used}
                                      onChange={(e) => handleStateChange("contrast_media_used", e.target.value)} />
                                  </div>
                                </div>
                              </div>
                              <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                <div class="form-group col-md-6">
                                  <label for="risks" >Name of the Radiologic Technologist or Sonographer</label>
                                  <div >
                                    <TextField type="text" fullWidth placeholder="Name of the Radiologic Technologist or Sonographer"
                                      disabled={action == "view"}
                                      value={diagnosticImageFormState?.special_additional_info?.Radiologic_technologist_or_sonographer_name}
                                      onChange={(e) => handleStateChange("Radiologic_technologist_or_sonographer_name", e.target.value)} />
                                  </div>
                                </div>

                                <div class="form-group col-md-6" style={{ display: "flex", flexDirection: "column" }}>
                                  <label for="procedureDate" >Date of the Imaging Study</label>
                                  <DatePicker className='form-control'
                                    fullWidth
                                    disabled={action == "view"}
                                    selected={diagnosticImageFormState.incident_details.entered_date}
                                    onChange={(date) => {
                                      setDiagnosticImageFormState({
                                        ...diagnosticImageFormState,
                                        incident_details: {
                                          ...diagnosticImageFormState.incident_details,
                                          entered_date: date,
                                        },
                                      });
                                    }}
                                    dateFormat="MM/dd/yyyy"
                                    placeholderText="MM/DD/YYYY"
                                    maxDate={new Date()}
                                  />

                                </div>
                              </div>
                              <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                <div class="form-group col-md-6">
                                  <label for="procedureDate">Time of the Imaging Study</label>
                                  <div >
                                    <TextField
                                     disabled={action == "view"}
                                      onChange={(e) => handleStateChange("imaging_study_time", e.target.value)}
                                      defaultValue={getCurrentTime()}

                                      type="Time" fullWidth placeholder="Time of the Imaging Study" />
                                  </div>
                                </div>

                                <div class="form-group col-md-6">
                                  <label for="procedureDate" >Assessment of the technical quality of the images</label>
                                  <div >
                                    <TextField type="text" fullWidth placeholder="Assessment of the technical quality of the images"
                                      disabled={action == "view"}
                                      value={diagnosticImageFormState?.special_additional_info?.asessment_of_technical_quality_images_}
                                      onChange={(e) => handleStateChange("asessment_of_technical_quality_images_", e.target.value)} />
                                  </div>
                                </div>
                              </div>
                              <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                <div class="form-group col-md-6">
                                  <label for="exampleInputPassword1"  >Reaction for Patient</label>
                                  <div>
                                  {action==="view" || action==="eidt" ?(  
                                    <Autocomplete
                                      disablePortal
                                      disabled={action == "view"}
                                      options={reactionForPatient}
                                      getOptionLabel={(option) => option.label}
                                      onChange={(event, value) => handleStateChange("patient_reaction", value ? value.label : "")}
                                      renderInput={(params) => (
                                        <TextField
                                          {...params}
                                          fullWidth
                                          placeholder=' Please select patient reaction'
                                        />
                                      )}
                                      value={reactionForPatient.find(option => option.label === diagnosticImageFormState.special_additional_info.patient_reaction ) || null}

                                    />):(
                                      <Autocomplete
                                      disablePortal
                                      options={reactionForPatient}
                                      getOptionLabel={(option) => option.label}
                                      onChange={(event, value) => handleStateChange("patient_reaction", value ? value.label : "")}
                                      renderInput={(params) => (
                                        <TextField
                                          {...params}
                                          fullWidth
                                          placeholder=' Please select patient reaction'
                                        />
                                      )}

                                    />

                                    )}
                                  </div>
                                </div>
                                <div class="form-group col-md-6">
                                  <label for="medicalHistory" >Outcome</label>
                                  <div >
                                    <TextField
                                      fullWidth type="text"
                                      disabled={action == "view"}
                                      value={diagnosticImageFormState?.special_additional_info?.outcome}
                                      onChange={(e) => handleStateChange("outcome", e.target.value)}
                                      placeholder="Recovered On/Not yet recovered/Died On" />
                                  </div>
                                </div>
                              </div>
                              {/* Allergies */}
                              <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                <div class="form-group col-md-6">
                                  <label for="allergies" >Sequelae</label>
                                  <div >
                                    {action==="view" || action==="edit" ?(
                                    <Autocomplete
                                      disablePortal
                                      disabled={action == "view"}
                                      options={sequelaeList}
                                      getOptionLabel={(option) => option.label}
                                      onChange={(event, value) => handleStateChange("sequelae", value ? value.label : "")}
                                      renderInput={(params) => (
                                        <TextField
                                          {...params}
                                          placeholder="Please Select Sequelae"
                                          fullWidth
                                        />
                                      )}
                                      value={sequelaeList.find(option => option.label ===diagnosticImageFormState.special_additional_info.sequelae ) || null}

                                    />):(
                                      <Autocomplete
                                      disablePortal
                                      options={sequelaeList}
                                      getOptionLabel={(option) => option.label}
                                      onChange={(event, value) => handleStateChange("sequelae", value ? value.label : "")}
                                      renderInput={(params) => (
                                        <TextField
                                          {...params}
                                          placeholder="Please Select Sequelae"
                                          fullWidth
                                        />
                                      )}

                                    />
                                    )}
                                  </div>
                                </div>

                                {/* Medications */}
                                <div class="form-group col-md-6">
                                  <label for="medications" >Allergies or other relevant history</label>
                                  <div >
                                    <TextField
                                      type="text" fullWidth
                                      disabled={action == "view"}
                                      value={diagnosticImageFormState?.special_additional_info?.relevant_history}
                                      onChange={(e) => handleStateChange("relevant_history", e.target.value)}
                                      placeholder="Including medical history, liver / kidney problems, smoking, alcohol use etc" />
                                  </div>
                                </div>
                              </div>
                              <div style={{ display: 'flex', justifyContent: 'space-between' }}>

                                <div class="form-group col-md-6">
                                  <label for="procedureDate" >Was a physician directly involved in Incident</label>
                                  <div style={{ marginTop: '10px' }}>

                                    <label className="col-md-3">
                                      <input type="radio" name="physician_involved" disabled={action == "view"} checked={diagnosticImageFormState.special_additional_info.physician_involved === true} onChange={() => handleStateChange("physician_involved", true)} /> Yes

                                    </label>
                                    <label className="col-md-3">
                                      <input type="radio" name="physician_involved" disabled={action == "view"} checked={diagnosticImageFormState.special_additional_info.physician_involved === false} onChange={() => handleStateChange("physician_involved", false)} /> No
                                    </label>
                                  </div>
                                </div>



                                <div class="form-group col-md-6">
                                  <label for="procedureDate"  >Contributing Factors</label>
                                  <div >
                                    <TextField type="text" fullWidth placeholder="Enter Contributing Factors"
                                      disabled={action == "view"}
                                      value={diagnosticImageFormState?.special_additional_info?.contributing_factors}
                                      onChange={(e) => handleStateChange("contributing_factors", e.target.value)}

                                    />
                                  </div>
                                </div>
                              </div>
                              <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                <div class="form-group col-md-6">
                                  <label for="procedureDate"   >Immediate Actions Taken</label>
                                  <div >
                                    <TextField type="text" fullWidth placeholder="Enter Immediate Actions Taken"
                                      value={diagnosticImageFormState?.special_additional_info?.immediate_actions_taken}
                                      onChange={(e) => handleStateChange("immediate_actions_taken", e.target.value)}
                                      disabled={action == "view"}
                                    />
                                  </div>
                                </div>

                                <div class="form-group col-md-6" >
                                  <label for="exampleInputPassword1" >Reported Incident Severity <span style={{ fontweight: "bold", color: "red" }} >*</span></label>
                                  <div >
                                    {action==="edit" || action==="view" ?(
                                    <Autocomplete
                                      disablePortal
                                      disabled={action == "view"}
                                      options={reportedIncidentSeverityList}
                                      getOptionLabel={(option) => option.label}
                                      onChange={(event, value) => handleStateChange("reported_incident_severity", value ? value.label : "")}
                                      renderInput={(params) => (
                                        <TextField
                                          {...params}
                                          placeholder="Please Select reported incident severity"
                                          fullWidth
                                        />
                                      )}
                                      defaultValue={{ label: diagnosticImageFormState.special_additional_info.reported_incident_severity }}
                                    />):(
                                      <Autocomplete
                                      disablePortal
                                      options={reportedIncidentSeverityList}
                                      getOptionLabel={(option) => option.label}
                                      onChange={(event, value) => handleStateChange("reported_incident_severity", value ? value.label : "")}
                                      renderInput={(params) => (
                                        <TextField
                                          {...params}
                                          placeholder="Please Select reported incident severity"
                                          fullWidth
                                        />
                                      )}
                                    />   
                                    )}
                                  </div>
                                  <div className="text-danger">{reportedIncidentError}</div>
                                </div>

                              </div>


                              <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                <div class="form-group col-md-6">
                                  <label for="allergies" >Other Information</label>
                                  <div >
                                    <TextField
                                      type="text" fullWidth
                                      disabled={action == "view"}
                                      value={diagnosticImageFormState?.special_additional_info?.other_information}
                                      onChange={(e) => handleStateChange("other_information", e.target.value)}
                                      placeholder="Other Information" />
                                  </div>
                                </div>

                                <div class="form-group col-md-6">
                                  <label for="procedureDate" >Incident Description - use SBAR (Situation,Background, Assessment,
                                    Recommendation )<span style={{ fontweight: "bold", color: "red" }} >*</span></label>
                                  <div >
                                    <TextField type="text" fullWidth placeholder="Enter Incident Description..."
                                      value={diagnosticImageFormState?.special_additional_info?.incident_description}
                                      onChange={(e) => handleStateChange("incident_description", e.target.value)}
                                      disabled={action == "view"}
                                    />
                                    <div className="text-danger">{incidentdescriptionError}</div>
                                  </div>
                                </div>

                              </div>
                              <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                <div className="form-group col-md-6">
                                  <label htmlFor="procedureDate" style={{ textAlign: "start" }}>File Upload</label>
                                  <div>
                                    <input type="file" disabled={action == "view"} style={{ marginTop: "10px", color: 'transparent' }} multiple onChange={handleFileUpload} />
                                    {files.length > 0 ? (
                                      <ol>
                                        {files.map((file, index) => (
                                          <li disabled={action === "view"} key={index}>
                                            {file.name}
                                            <IconButton disabled={action === "view"} onClick={() => handleDeleteFile(index)} aria-label="delete">
                                              <DeleteIcon />
                                            </IconButton>
                                          </li>
                                        ))}
                                      </ol>
                                    ) : (
                                      <p>No files chosen</p>
                                    )}
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </AccordionDetails>
                      </Accordion>
                    </div>
                  </div>

                </div>

                <div style={{ marginLeft: "45%" }}>
                {canSubmit && (
                  <Button
                  disabled={action === "view"}
                    variant="contained"
                    type="submit"
                    style={{ height: "35px", width: "auto", fontSize: "15px", background: "#22baa0" }}
                    onClick={handleSubmit}
                  >
                    {editIncidentData.incident_id ? "Update" : "Submit"}
                  </Button>
                )}
                </div>
              </form>
              <Snackbar
                open={showSuccessPopup}
                autoHideDuration={3000}
                onClose={handleCloseSnackbar}
                anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
              >
                <MuiAlert onClose={handleCloseSnackbar}
                  severity="success"
                  variant="filled"
                  sx={{ width: '100%' }}
                  style={{ width: '300px', backgroundColor: "whitesmoke", color: "green", height: '45px', fontSize: '12px' }}>
                  {snackbarMessage}
                </MuiAlert>
              </Snackbar>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default DiagnosticImageForm;
