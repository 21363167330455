import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import store from "../../utils";
import { commonActions, commonSelectors } from "../../utils/commonstore";
import { useSelector } from 'react-redux';

const dispatch = store.dispatch;
let commonStore = store.getState().commonStore;
store.subscribe(function () {
    commonStore = store.getState().commonStore;
});

const FormsList = () => {
    React.useEffect(() => {
        dispatch(commonActions.setEditIncidentData({}));
        dispatch(commonActions.setViewIncidentData({}));
    }, [])

    const [windowWidth, setWindowWidth] = useState(window.innerWidth);

    useEffect(() => {
        const handleResize = () => setWindowWidth(window.innerWidth);
        window.addEventListener("resize", handleResize);
        return () => window.removeEventListener("resize", handleResize);
    }, []);

    // Apply media query styles
    const isMobile = windowWidth < 768;
    const containerStyle = {
        width: isMobile ? "90vw" : "40vw",
        backgroundColor: "white",
        height: "70px",
        marginLeft: isMobile ? "10px" : "30px",
    };

    const accessInfo = useSelector(commonSelectors.selectAccessInfo);

    // Define the permissions that allow navigation (clickable link)
    const navigationAllowed = ["1100", "1101", "1110", "1111"];

    // Check if the user has permission to navigate (link should be clickable)
    const isAnonymousUser = JSON.parse(sessionStorage.getItem('rl6User'))?.isVisitor;
    const canNavigate = isAnonymousUser || (accessInfo?.forms && navigationAllowed.includes(accessInfo.forms));

    return (
        <div>
            <div className="page-content">
                <div class="page-inner">
                    <div id="main-wrapper">
                        <div class="row">
                            <div style={{ display: "flex", flexDirection: isMobile ? "column" : "row" }}>
                                {/* Airway Management Form */}
                                <div style={containerStyle}>
                                    <div style={{ display: "flex", flexDirection: "row" }}>
                                        {canNavigate ? (
                                            <Link to="/airwaysmanagementform/0/create">
                                                <h1 style={{ fontFamily: "-apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif", fontWeight: "17", fontSize: "17px", margin: "10px 0px 10px 10px", color: "black" }}>
                                                    Airway Management
                                                </h1>
                                                <p style={{ fontFamily: "-apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif", fontWeight: "Bold", fontSize: "12px", margin: "10px 0px 0px 10px" }}>
                                                    Report incidents like endotracheal, nasal and oral airway, etc.
                                                </p>
                                            </Link>
                                        ) : (
                                            <div>
                                                <h1 style={{ fontFamily: "-apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif", fontWeight: "17", fontSize: "17px", margin: "10px 0px 10px 10px", color: "black" }}>
                                                    Airway Management
                                                </h1>
                                                <p style={{ fontFamily: "-apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif", fontWeight: "Bold", fontSize: "12px", margin: "10px 0px 0px 10px" }}>
                                                    Report incidents like endotracheal, nasal and oral airway, etc.
                                                </p>
                                            </div>
                                        )}

                                        <img src="assets/images/Icons/air-management.png" alt="ImagiDiagnostic" style={{ height: "60px", width: "60px", margin: "6px 0px 20px 82px" }} />
                                    </div>
                                </div>

                                {/* Coding Complications Form */}
                                <div style={{ width: "40vw", backgroundColor: "white", height: "70px", marginLeft: "30px" }}>
                                    <div style={{ display: "flex", flexDirection: "row" }}>
                                        {canNavigate ? (
                                            <Link to="/codingcomplicationsform/0/create">
                                                <h1 style={{ fontFamily: "-apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif", fontWeight: "17", fontSize: "17px", margin: "10px 0px 10px 10px", color: "black" }}>
                                                    Coding Complication
                                                </h1>
                                                <p style={{ fontFamily: "-apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif", fontWeight: "Bold", fontSize: "12px", margin: "10px 0px 0px 10px" }}>
                                                    Report incidents like blood incompatibility, falls and trauma, etc.
                                                </p>
                                            </Link>
                                        ) : (
                                            <div>
                                                <h1 style={{ fontFamily: "-apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif", fontWeight: "17", fontSize: "17px", margin: "10px 0px 10px 10px", color: "black" }}>
                                                    Coding Complication
                                                </h1>
                                                <p style={{ fontFamily: "-apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif", fontWeight: "Bold", fontSize: "12px", margin: "10px 0px 0px 10px" }}>
                                                    Report incidents like blood incompatibility, falls and trauma, etc.
                                                </p>
                                            </div>
                                        )}

                                        <img src="assets/images/Icons/coding-complications.png" alt="Patient Experience" style={{ height: "60px", width: "60px", margin: "6px 0px 20px 57px" }} />
                                    </div>
                                </div>
                            </div>

                            {/* Anesthesia Or Sedation Form  */}
                            <div style={{ display: "flex", flexDirection: "row", marginTop: "20px" }}>
                                <div style={{ width: "40vw", backgroundColor: "white", height: "70px", marginLeft: "30px" }}>
                                    <div style={{ display: "flex", flexDirection: "row" }}>
                                        {canNavigate ? (
                                            <Link to="/anesthesiaform/0/create">
                                                <h1 style={{ fontFamily: "-apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif", fontWeight: "17", fontSize: "17px", margin: "10px 0px 10px 10px", color: "black" }}>Anesthesia or Sedation</h1>
                                                <p style={{ fontFamily: "-apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif", fontWeight: "Bold", fontSize: "12px", margin: "10px 0px 0px 10px" }}>report incidents like Local and General anesthetics,Analgesics etc.,</p></Link>
                                        ) : (
                                            <div>
                                                <h1 style={{ fontFamily: "-apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif", fontWeight: "17", fontSize: "17px", margin: "10px 0px 10px 10px", color: "black" }}>
                                                    Anesthesia or Sedation
                                                </h1>
                                                <p style={{ fontFamily: "-apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif", fontWeight: "Bold", fontSize: "12px", margin: "10px 0px 0px 10px" }}>
                                                    report incidents like Local and General anesthetics,Analgesics etc.,
                                                </p>
                                            </div>
                                        )}

                                        <img src="assets\images\Icons\anesthesia.png" alt="  ImagiDiagnostic" style={{ height: "60px", width: "60px", margin: "6px 0px 20px 47px" }} />


                                    </div>
                                </div>
                                {/* Restraints form */}
                                <div style={{ width: "40vw", backgroundColor: "white", height: "70px", marginLeft: "30px" }}>
                                    <div style={{ display: "flex", flexDirection: "row" }}>
                                        {canNavigate ? (
                                            <Link to="/restraintsForm/0/create">
                                                <h1 style={{ fontFamily: "-apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif", fontWeight: "17", fontSize: "17px", margin: "10px 0px 10px 10px", color: "black" }}>Restraints</h1>
                                                <p style={{ fontFamily: "-apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif", fontWeight: "Bold", fontSize: "12px", margin: "10px 0px 0px 10px" }}>Report incidents like phyiscal,chemical,environmental,etc.,</p></Link>
                                        ) : (
                                            <div>
                                                <h1 style={{ fontFamily: "-apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif", fontWeight: "17", fontSize: "17px", margin: "10px 0px 10px 10px", color: "black" }}>Restraints</h1>
                                                <p style={{ fontFamily: "-apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif", fontWeight: "Bold", fontSize: "12px", margin: "10px 0px 0px 10px" }}>Report incidents like phyiscal,chemical,environmental,etc.,</p>
                                            </div>
                                        )}

                                        <img src="assets\images\Icons\Restraints.png" alt="Patient Experience" style={{ height: "60px", width: "60px", margin: "6px 0px 20px 90px" }} />

                                    </div>
                                </div>

                            </div>
                            {/* Patient Behaviour form */}
                            <div style={{ display: "flex", flexDirection: "row", marginTop: "20px" }}>
                                <div style={{ width: "40vw", backgroundColor: "white", height: "70px", marginLeft: "30px" }}>
                                    <div style={{ display: "flex", flexDirection: "row" }}>
                                        {canNavigate ? (
                                            <Link to="/patientBehaviorForm/0/create">
                                                <h1 style={{ fontFamily: "-apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif", fontWeight: "17", fontSize: "17px", margin: "10px 0px 10px 10px", color: "black" }}>Patient Behavior</h1>
                                                <p style={{ fontFamily: "-apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif", fontWeight: "Bold", fontSize: "12px", margin: "10px 0px 0px 10px" }}>Report--repeated difficult, disruptive, threatening,etc.,</p></Link>
                                        ) : (
                                            <div>
                                                <h1 style={{ fontFamily: "-apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif", fontWeight: "17", fontSize: "17px", margin: "10px 0px 10px 10px", color: "black" }}>Patient Behavior</h1>
                                                <p style={{ fontFamily: "-apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif", fontWeight: "Bold", fontSize: "12px", margin: "10px 0px 0px 10px" }}>Report--repeated difficult, disruptive, threatening,etc.,</p>
                                            </div>

                                        )}
                                        <img src="assets/images/Icons/behaviour.png" alt="Patient Experience" style={{ height: "60px", width: "60px", margin: "6px 0px 20px 116px" }} />

                                    </div>
                                </div>
                                {/* fall form */}
                                <div style={{ width: "40vw", backgroundColor: "white", height: "70px", marginLeft: "30px" }}>
                                    <div style={{ display: "flex", flexDirection: "row" }}>
                                        {canNavigate ? (
                                            <Link to="/fallForm/0/create">
                                                <h1 style={{ fontFamily: "-apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif", fontWeight: "17", fontSize: "17px", margin: "10px 0px 10px 10px", color: "black" }}>Fall</h1>
                                                <p style={{ fontFamily: "-apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif", fontWeight: "Bold", fontSize: "12px", margin: "10px 0px 0px 10px" }}>Report a patient’s or visitor’s slip,trip,fall or injury</p></Link>
                                        ) : (
                                            <div>
                                                <h1 style={{ fontFamily: "-apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif", fontWeight: "17", fontSize: "17px", margin: "10px 0px 10px 10px", color: "black" }}>Fall</h1>
                                                <p style={{ fontFamily: "-apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif", fontWeight: "Bold", fontSize: "12px", margin: "10px 0px 0px 10px" }}>Report a patient’s or visitor’s slip,trip,fall or injury</p>
                                            </div>

                                        )}
                                        <img src="assets\images\Icons\GeneralLiability.jpg" alt="GeneralLiability" style={{ height: "60px", width: "60px", margin: "6px 0px 20px 135px" }} />

                                    </div>
                                </div>
                            </div>
                            {/* Patient Authorization Form  */}
                            <div style={{ display: "flex", flexDirection: "row", marginTop: "20px" }}>
                                <div style={{ width: "40vw", backgroundColor: "white", height: "70px", marginLeft: "30px" }}>
                                    {/* <div style={{ display: "flex", flexDirection: "row" }}>
                                    <Link to="/labSpecimenCollectionForm/0/create"><h1 style={{ fontFamily: "-apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif", fontWeight: "17", fontSize: "17px", margin: "10px 0px 10px 10px", color: "black" }}>Lab/Specimen Collection</h1>
                                        <p style={{ fontFamily: "-apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif", fontWeight: "Bold", fontSize: "12px", margin: "10px 0px 0px 10px" }}> Report incidents like delays in testing/reporting,lost specimens,etc.,</p></Link>

                                    <img src="assets\images\Icons\Lab.png" alt="  Patient Experience" style={{ height: "60px", width: "60px", margin: "6px 0px 20px 35px" }} />

                                </div> */}
                                    <div style={{ display: "flex", flexDirection: "row" }}>
                                        {canNavigate ? (
                                            <Link to="/patientPrivacyAuthorizationForm/0/create">
                                                <h1 style={{ fontFamily: "-apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif", fontWeight: "17", fontSize: "17px", margin: "10px 0px 10px 10px", color: "black" }}>Patient Privacy Authorization</h1>
                                                <p style={{ fontFamily: "-apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif", fontWeight: "Bold", fontSize: "12px", margin: "10px 0px 0px 10px" }}> Report-HIPAA violations,errors in mailing or faxing,etc.,</p></Link>
                                        ) : (
                                            <div>
                                                <h1 style={{ fontFamily: "-apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif", fontWeight: "17", fontSize: "17px", margin: "10px 0px 10px 10px", color: "black" }}>Patient Privacy Authorization</h1>
                                                <p style={{ fontFamily: "-apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif", fontWeight: "Bold", fontSize: "12px", margin: "10px 0px 0px 10px" }}> Report-HIPAA violations,errors in mailing or faxing,etc.,</p>

                                            </div>

                                        )}
                                        <img src="assets\images\Icons\privacy.png" alt="  Patient Privacy" style={{ height: "60px", width: "60px", margin: "5px -40px 0px 105px" }} />

                                    </div>
                                </div>
                                {/* Obstetrics form */}
                                <div style={{ width: "40vw", backgroundColor: "white", height: "70px", marginLeft: "30px" }}>
                                    <div style={{ display: "flex", flexDirection: "row" }}>
                                        {canNavigate ? (
                                            <Link to="/obstetricsForm/0/create">
                                                <h1 style={{ fontFamily: "-apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif", fontWeight: "17", fontSize: "17px", margin: "10px 0px 10px 10px", color: "black" }}>Obstetrics Form</h1>
                                                <p style={{ fontFamily: "-apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif", fontWeight: "Bold", fontSize: "12px", margin: "10px 0px 0px 10px" }}> Report incidents like prenatal management and More.... </p></Link>
                                        ) : (
                                            <div>
                                                <h1 style={{ fontFamily: "-apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif", fontWeight: "17", fontSize: "17px", margin: "10px 0px 10px 10px", color: "black" }}>Obstetrics Form</h1>
                                                <p style={{ fontFamily: "-apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif", fontWeight: "Bold", fontSize: "12px", margin: "10px 0px 0px 10px" }}> Report incidents like prenatal management and More.... </p>

                                            </div>
                                        )}
                                        <img src="assets\images\Icons\pregnant.jpg" alt=" Obstetrics" style={{ height: "60px", width: "60px", margin: "6px 0px 20px 105px" }} />

                                    </div>
                                </div>
                            </div>
                            {/* feedback form */}
                            <div style={{ display: "flex", flexDirection: "row", marginTop: "20px" }}>
                                <div style={{ width: "40vw", backgroundColor: "white", height: "70px", marginLeft: "30px" }}>
                                    <div style={{ display: "flex", flexDirection: "row" }}>
                                    {canNavigate ? (
                                        <Link to="/feedbackForm/0/create">
                                            <h1 style={{ fontFamily: "-apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif", fontWeight: "17", fontSize: "17px", margin: "10px 0px 10px 10px", color: "black" }}>Feedback</h1>
                                            <p style={{ fontFamily: "-apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif", fontWeight: "Bold", fontSize: "12px", margin: "10px 0px 0px 10px" }}> Report patient complaints,quality of care,etc.,</p></Link>
                                    ) : (
                                        <div>
                                             <h1 style={{ fontFamily: "-apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif", fontWeight: "17", fontSize: "17px", margin: "10px 0px 10px 10px", color: "black" }}>Feedback</h1>
                                            <p style={{ fontFamily: "-apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif", fontWeight: "Bold", fontSize: "12px", margin: "10px 0px 0px 10px" }}> Report patient complaints,quality of care,etc.,</p>
                                  
                                            </div>
                                    )}
                                        <img src="assets\images\Icons\Patient Experience.png" alt="  Patient Experience" style={{ height: "60px", width: "60px", margin: "6px 0px 20px 163px" }} />

                                    </div>
                                </div>
                                                    {/* adverse drug form */}
                                <div style={{ width: "40vw", backgroundColor: "white", height: "70px", marginLeft: "30px" }}>
                                    <div style={{ display: "flex", flexDirection: "row" }}>
                                        {canNavigate ? (
                                            // Clickable Link if the user has navigation permissions
                                            <Link to="/adversedrugreactionForm/0/create">
                                                <h1 style={{ fontFamily: "-apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif", fontWeight: "17", fontSize: "17px", margin: "10px 0px 10px 10px", color: "black" }}>
                                                    Adverse Drug Reaction
                                                </h1>
                                                <p style={{ fontFamily: "-apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif", fontWeight: "Bold", fontSize: "12px", margin: "10px 0px 0px 10px" }}>
                                                    Report incidents like dose adjusted, drug level obtained, etc.
                                                </p>
                                            </Link>
                                        ) : (
                                            // Non-clickable content if the user doesn't have navigation permissions
                                            <div>
                                                <h1 style={{ fontFamily: "-apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif", fontWeight: "17", fontSize: "17px", margin: "10px 0px 10px 10px", color: "black" }}>
                                                    Adverse Drug Reaction
                                                </h1>
                                                <p style={{ fontFamily: "-apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif", fontWeight: "Bold", fontSize: "12px", margin: "10px 0px 0px 10px" }}>
                                                    Report incidents like dose adjusted, drug level obtained, etc.
                                                </p>
                                            </div>
                                        )}

                                        <img
                                            src="assets/images/Icons/Adversedrugreaction.png"
                                            alt="ImagiDiagnostic"
                                            style={{ height: "60px", width: "60px", margin: "6px 0px 20px 87px" }}
                                        />
                                    </div>
                                </div>
                            </div>
                            {/* surgery procedure consent form  */}
                            <div style={{ display: "flex", flexDirection: "row", marginTop: "20px" }}>
                                <div style={{ width: "40vw", backgroundColor: "white", height: "70px", marginLeft: "30px" }}>
                                    <div style={{ display: "flex", flexDirection: "row" }}>
                                    {canNavigate ? (
                                        <Link to="/surgeryProcedureConsentForm/0/create">
                                            <h1 style={{ fontFamily: "-apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif", fontWeight: "17", fontSize: "17px", margin: "10px 0px 10px 10px", color: "black" }}>Surgery Procedure Consent</h1>
                                            <p style={{ fontFamily: "-apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif", fontWeight: "Bold", fontSize: "12px", margin: "10px 0px 0px 10px" }}> Report-complications of anesthesia,unexpected death,etc.,</p></Link>
                                    ) : (
                                            <div>
                                                 <h1 style={{ fontFamily: "-apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif", fontWeight: "17", fontSize: "17px", margin: "10px 0px 10px 10px", color: "black" }}>Surgery Procedure Consent</h1>
                                            <p style={{ fontFamily: "-apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif", fontWeight: "Bold", fontSize: "12px", margin: "10px 0px 0px 10px" }}> Report-complications of anesthesia,unexpected death,etc.,</p>
                                  
                                                </div>
                                    )}
                                        <img src="assets\images\Icons\surgery.jpg" alt="Surgery/Procedure" style={{ height: "60px", width: "60px", margin: "5px -40px 0px 90px" }} />

                                    </div>
                                </div>
                                {/* Blood and blood products form  */}
                                <div style={{ width: "40vw", backgroundColor: "white", height: "70px", marginLeft: "30px" }}>
                                    <div style={{ display: "flex", flexDirection: "row" }}>
                                    {canNavigate ? (
                                        <Link to="/bloodAndBloodProductsForm/0/create">
                                            <h1 style={{ fontFamily: "-apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif", fontWeight: "17", fontSize: "17px", margin: "10px 0px 10px 10px", color: "black" }}>Blood and Blood Products</h1>
                                            <p style={{ fontFamily: "-apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif", fontWeight: "Bold", fontSize: "12px", margin: "10px 0px 0px 10px" }}> Report-airborne isolation breaches, hand hygiene problems,etc., </p></Link>
                                    ) : (
                                        <div>
                                        <h1 style={{ fontFamily: "-apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif", fontWeight: "17", fontSize: "17px", margin: "10px 0px 10px 10px", color: "black" }}>Blood and Blood Products</h1>
                                        <p style={{ fontFamily: "-apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif", fontWeight: "Bold", fontSize: "12px", margin: "10px 0px 0px 10px" }}> Report-airborne isolation breaches, hand hygiene problems,etc., </p>
                                </div>
                                    )}
                                        <img src="assets\images\Icons\virus.png" alt="Infection" style={{ height: "60px", width: "60px", margin: "6px 0px 20px 60px" }} />

                                    </div>
                                </div>
                            </div>
                            {/* medicationorChemotherpyForm/ */}
                            <div style={{ display: "flex", flexDirection: "row", marginTop: "20px" }}>
                                <div style={{ width: "40vw", backgroundColor: "white", height: "70px", marginLeft: "30px" }}>
                                    <div style={{ display: "flex", flexDirection: "row" }}>
                                    {canNavigate ? (
                                        <Link to="/medicationorChemotherpyForm/0/create">
                                            <h1 style={{ fontFamily: "-apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif", fontWeight: "17", fontSize: "17px", margin: "10px 0px 10px 10px", color: "black" }}>Medication/Chemotherapy</h1>
                                            <p style={{ fontFamily: "-apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif", fontWeight: "Bold", fontSize: "12px", margin: "10px 0px 0px 10px" }}>Report-medication, pharmacy issues, chemo chemical spills,etc.,</p></Link>
                                    ) : (
                                        <div>
                                            <h1 style={{ fontFamily: "-apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif", fontWeight: "17", fontSize: "17px", margin: "10px 0px 10px 10px", color: "black" }}>Medication/Chemotherapy</h1>
                                            <p style={{ fontFamily: "-apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif", fontWeight: "Bold", fontSize: "12px", margin: "10px 0px 0px 10px" }}>Report-medication, pharmacy issues, chemo chemical spills,etc.,</p>
                           
                                            </div>

                                    )}

                                        <img src="assets\images\Icons\Medication.png" alt="Medication/Chemotherapy" style={{ height: "60px", width: "60px", margin: "5px -40px 0px 62px" }} />

                                    </div>
                                </div>
                                {/* medical care and treatment  */}
                                <div style={{ width: "40vw", backgroundColor: "white", height: "70px", marginLeft: "30px" }}>
                                    <div style={{ display: "flex", flexDirection: "row" }}>
                                    {canNavigate ? (
                                        <Link to="/medicalCareTreatmentForm/0/create">
                                            <h1 style={{ fontFamily: "-apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif", fontWeight: "17", fontSize: "17px", margin: "10px 0px 10px 10px", color: "black" }}>Medical Care and Treatment</h1>
                                            <p style={{ fontFamily: "-apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif", fontWeight: "Bold", fontSize: "12px", margin: "10px 0px 0px 10px" }}> Report missed/delayed diagnosis,adverse clinical outcomes,etc., </p></Link>
                                    ) : (
                                        <div>
                                        <h1 style={{ fontFamily: "-apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif", fontWeight: "17", fontSize: "17px", margin: "10px 0px 10px 10px", color: "black" }}>Medical Care and Treatment</h1>
                                        <p style={{ fontFamily: "-apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif", fontWeight: "Bold", fontSize: "12px", margin: "10px 0px 0px 10px" }}> Report missed/delayed diagnosis,adverse clinical outcomes,etc., </p>
                                </div>
                                    )}
                                        <img src="assets\images\Icons\medical care and treatment.png" alt="Patient Experience" style={{ height: "60px", width: "60px", margin: "6px 0px 20px 57px" }} />

                                    </div>
                                </div>
                            </div>
                            {/* UNPROFESSIONAL BEHAVIOUR FORM  */}
                            <div style={{ display: "flex", flexDirection: "row", marginTop: "20px" }}>
                                <div style={{ width: "40vw", backgroundColor: "white", height: "70px", marginLeft: "30px" }}>
                                    <div style={{ display: "flex", flexDirection: "row" }}>
                                          { canNavigate ? (

                                          
                                        <Link to="/unprofessionalBehaviorForm/0/create">
                                        <h1 style={{ fontFamily: "-apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif", fontWeight: "17", fontSize: "17px", margin: "10px 0px 10px 10px", color: "black" }}>Unprofessional Behavior</h1>
                                            <p style={{ fontFamily: "-apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif", fontWeight: "Bold", fontSize: "12px", margin: "10px 0px 0px 10px" }}>Report-Abusive behavior,defamatory statements,theft,etc.,</p></Link>
                                          ) : (
                                             <div>
                                                 <h1 style={{ fontFamily: "-apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif", fontWeight: "17", fontSize: "17px", margin: "10px 0px 10px 10px", color: "black" }}>Unprofessional Behavior</h1>
                                            <p style={{ fontFamily: "-apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif", fontWeight: "Bold", fontSize: "12px", margin: "10px 0px 0px 10px" }}>Report-Abusive behavior,defamatory statements,theft,etc.,</p>
                                        
                                                </div>
                                          )}
                                        <img src="assets\images\Icons\unprofessionalBehavior.png" alt="Patient Experience" style={{ height: "60px", width: "60px", margin: "6px 0px 20px 90px" }} />

                                    </div>
                                </div>
                                {/* Safety Security facility  */}
                                <div style={{ width: "40vw", backgroundColor: "white", height: "70px", marginLeft: "30px" }}>
                                    <div style={{ display: "flex", flexDirection: "row" }}>
                                        { canNavigate ?  (
                                        <Link to="/safetySecurityFacilityForm/0/create">
                                        <h1 style={{ fontFamily: "-apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif", fontWeight: "17", fontSize: "17px", margin: "10px 0px 10px 10px", color: "black" }}>Safety/Security/Facility</h1>
                                            <p style={{ fontFamily: "-apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif", fontWeight: "Bold", fontSize: "12px", margin: "10px 0px 0px 10px" }}>Report-Environment,cleanliness,safety,parking,etc.,</p></Link>
                                                ) : (
                                            <div>
                    <h1 style={{ fontFamily: "-apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif", fontWeight: "17", fontSize: "17px", margin: "10px 0px 10px 10px", color: "black" }}>Safety/Security/Facility</h1>
                                            <p style={{ fontFamily: "-apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif", fontWeight: "Bold", fontSize: "12px", margin: "10px 0px 0px 10px" }}>Report-Environment,cleanliness,safety,parking,etc.,</p>
   
                                                   </div>

                                                      )}
                                        <img src="assets\images\Icons\safetyfacility.png" alt="Patient Experience" style={{ height: "60px", width: "60px", margin: "6px 0px 20px 126px" }} />

                                    </div>
                                </div>
                            </div>
                            {/* diagnosticimageform */}
                            <div style={{ display: "flex", flexDirection: "row", marginTop: "20px" }}>
                                <div style={{ width: "40vw", backgroundColor: "white", height: "70px", marginLeft: "30px" }}>
                                    <div style={{ display: "flex", flexDirection: "row" }}>
                                    {canNavigate ? (
                                        <Link to="/diagnosticImageForm/0/create">
                                            <h1 style={{ fontFamily: "-apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif", fontWeight: "17", fontSize: "17px", margin: "10px 0px 10px 10px", color: "black" }}>Diagnostic Imaging</h1>
                                            <p style={{ fontFamily: "-apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif", fontWeight: "Bold", fontSize: "12px", margin: "10px 0px 0px 10px" }}>report imaging (X-Ray, CT, MRI, ultrasound, mammography),etc.,</p></Link>
                                    ) : (
                                        <div>
                                        <h1 style={{ fontFamily: "-apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif", fontWeight: "17", fontSize: "17px", margin: "10px 0px 10px 10px", color: "black" }}>Diagnostic Imaging</h1>
                                        <p style={{ fontFamily: "-apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif", fontWeight: "Bold", fontSize: "12px", margin: "10px 0px 0px 10px" }}>report imaging (X-Ray, CT, MRI, ultrasound, mammography),etc.,</p>
                                        </div>
                                
                                    )}
                                        <img src="assets\images\Icons\Diagnostic.png" alt="  ImagiDiagnostic" style={{ height: "60px", width: "60px", margin: "6px 0px 20px 57px" }} />


                                    </div>
                                </div>
                                {/* medical device reporting  */}
                                <div style={{ width: "40vw", backgroundColor: "white", height: "70px", marginLeft: "30px" }}>
                                    <div style={{ display: "flex", flexDirection: "row" }}>
                                    {canNavigate ? (
                                        <Link to="/medicalDeviceReportingForm/0/create">
                                            <h1 style={{ fontFamily: "-apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif", fontWeight: "17", fontSize: "17px", margin: "10px 0px 10px 10px", color: "black" }}>Medical Device Reporting</h1>
                                            <p style={{ fontFamily: "-apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif", fontWeight: "Bold", fontSize: "12px", margin: "10px 0px 0px 10px" }}>Report-Involving equipment, failed medical devices,etc.,</p></Link>
                                    ) : (
                                        <div>

                                        <h1 style={{ fontFamily: "-apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif", fontWeight: "17", fontSize: "17px", margin: "10px 0px 10px 10px", color: "black" }}>Medical Device Reporting</h1>
                                        <p style={{ fontFamily: "-apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif", fontWeight: "Bold", fontSize: "12px", margin: "10px 0px 0px 10px" }}>Report-Involving equipment, failed medical devices,etc.,</p>
                                </div>
                                    )}
                                        <img src="assets\images\Icons\medicaldevicereporting.png" alt="Patient Experience" style={{ height: "60px", width: "60px", margin: "6px 0px 20px 102px" }} />

                                    </div>
                                </div>

                            </div>
                            {/* <div style={{ display: "flex", flexDirection: "row", marginTop:"20px"}}>
                            <div style={{ width: "40vw", backgroundColor: "white", height: "70px", marginLeft: "30px" }}>
                                <div style={{ display: "flex", flexDirection: "row" }}>
                                {canNavigate ? (
                                    <Link to="/patientPrivacyAuthorizationForm/0/create">
                                        <h1 style={{ fontFamily: "-apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif", fontWeight: "17", fontSize: "17px", margin: "10px 0px 10px 10px", color: "black" }}>Patient Privacy Authorization</h1>
                                        <p style={{ fontFamily: "-apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif", fontWeight: "Bold", fontSize: "12px", margin: "10px 0px 0px 10px" }}> Report-HIPAA violations,errors in mailing or faxing,etc.,</p></Link>
                                ) : (
                                    <div>
                                
                                        <h1 style={{ fontFamily: "-apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif", fontWeight: "17", fontSize: "17px", margin: "10px 0px 10px 10px", color: "black" }}>Patient Privacy Authorization</h1>
                                        <p style={{ fontFamily: "-apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif", fontWeight: "Bold", fontSize: "12px", margin: "10px 0px 0px 10px" }}> Report-HIPAA violations,errors in mailing or faxing,etc.,</p>
                                
                                        </div>

                                )}

                                    <img src="assets\images\Icons\privacy.png" alt="  Patient Privacy" style={{ height: "60px", width: "60px",  margin: "5px -40px 0px 105px" }} />

                                </div>
                                </div>
                            </div> */}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )

}

export default FormsList;